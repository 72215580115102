
const metricsMap = () => {
    return {
        defensive_interception_yards_per_attempt: {label: 'Defensive Interception Yards Per Attempt', spark: () => 'SUM(IF(interception=1, return_yards,0))/SUM(pass_attempt)'},
        defensive_interception_yards_per_game: {label: 'Defensive Interception Yards Per Game', spark: () => 'SUM(IF(interception=1, return_yards,0))/COUNT(DISTINCT game_id)'},
        defensive_interceptions: {label: 'Defensive Interceptions', spark: () => 'SUM(interception)'},
        defensive_interceptions_yards: {label: 'Defensive Interceptions Yards', spark: () => 'SUM(IF(interception=1, return_yards,0))'},
        field_goal_attempts: {label: 'Field Goal Attempts', spark: () => 'SUM(field_goal_attempt)'},
        field_goal_attempts_20_40_yards: {label: 'Field Goal Attempts 20-40 Yards', spark: () => 'SUM(IF(kick_distance >= 20 AND kick_distance <=40 AND field_goal_attempt=1, 1, 0))'},
        field_goal_attempts_40_50_yards: {label: 'Field Goal Attempts 40-50 Yards', spark: () => 'SUM(IF(kick_distance >= 40 AND kick_distance <=50 AND field_goal_attempt=1, 1, 0))'},
        field_goal_attempts_greater_than_50_yards: {label: 'Field Goal Attempts Greater Than 50 Yards', spark: () => 'SUM(IF(kick_distance > 50 AND field_goal_attempt=1, 1, 0))'},
        field_goal_attempts_less_than_20_yards: {label: 'Field Goal Attempts Less Than 20 Yards', spark: () => 'SUM(IF(kick_distance < 50 AND field_goal_attempt=1, 1, 0))'},
        field_goal_completion_rate: {label: 'Field Goal Completion Rate', spark: () => 'SUM(field_goal_result)/SUM(field_goal_attempt)'},
        field_goal_completion_rate_20_40_yards: {label: 'Field Goal Completion Rate 20-40 Yards', spark: () => 'SUM(IF(kick_distance >= 20 AND kick_distance <=40 AND field_goal_result="made", 1, 0))/SUM(IF(kick_distance >= 20 AND kick_distance <=40, 1, 0))'},
        field_goal_completion_rate_40_50_yards: {label: 'Field Goal Completion Rate 40-50 Yards', spark: () => 'SUM(IF(kick_distance >= 40 AND kick_distance <=50 AND field_goal_result="made", 1,0))/SUM(IF(kick_distance >= 40 AND kick_distance <=50, 1, 0))'},
        field_goal_completion_rate_greater_than_50_yards: {label: 'Field Goal Completion Rate Greater Than 50 Yards', spark: () => 'SUM(IF(kick_distance > 50 AND field_goal_result="made", 1, 0))/SUM(IF(kick_distance > 50, 1, 0))'},
        field_goal_completion_rate_less_than_20_yards: {label: 'Field Goal Completion Rate Less Than 20 Yards', spark: () => 'SUM(IF(kick_distance < 50 AND field_goal_result="made", 1, 0))/SUM(IF(kick_distance < 20, 1, 0))'},
        field_goals_made: {label: 'Field Goals Made', spark: () => 'SUM(IF(field_goal_result="made", 1, 0))'},
        field_goals_made_20_40_yards: {label: 'Field Goals Made 20-40 Yards', spark: () => 'SUM(IF(kick_distance >= 20 AND kick_distance <=40 AND field_goal_result="made", 1, 0))/SUM(IF(kick_distance >= 20 AND kick_distance <=40, 1, 0))'},
        field_goals_made_40_50_yards: {label: 'Field Goals Made 40-50 Yards', spark: () => 'SUM(IF(kick_distance >= 40 AND kick_distance <=50 AND field_goal_result="made", 1,0))/SUM(IF(kick_distance >= 40 AND kick_distance <=50, 1, 0))'},
        field_goals_made_greater_than_50_yards: {label: 'Field Goals Made Greater Than 50 Yards', spark: () => 'SUM(IF(kick_distance > 50 AND field_goal_result="made", 1, 0))/SUM(IF(kick_distance > 50, 1, 0))'},
        field_goals_made_less_than_20_yards: {label: 'Field Goals Made Less Than 20 Yards', spark: () => 'SUM(IF(kick_distance < 50 AND field_goal_result="made", 1, 0))/SUM(IF(kick_distance < 20, 1, 0))'},
        forced_fumbles: {label: 'Forced Fumbles', spark: () => 'SUM(fumble_forced)'},
        fumbles: {label: 'Fumbles', spark: () => 'SUM(fumble)'},
        fumbles_recovered: {label: 'Fumbles Recovered', spark: () => 'SUM(IF(fumble_recovery_1_team = "None" OR fumble_recovery_1_team = "None", 1, 0))'},
        games_played: {label: 'Games Played', spark: () => 'COUNT(DISTINCT game_id)'},
        kickoff_net_yards: {label: 'Kickoff Net Yards', spark: () => 'SUM(kick_distance)-SUM(return_yards)'},
        kickoff_net_yards_per_kick: {label: 'Kickoff Net Yards Per Kick', spark: () => '(SUM(kick_distance)-SUM(return_yards))/SUM(kickoff_attempt)'},
        kickoff_return_yards_20_40_yards: {label: 'Kickoff Return Yards 20-40 Yards', spark: () => 'SUM(IF(return_yards >= 20 AND return_yards <=40, 1, 0))'},
        kickoff_return_yards_40_50_yards: {label: 'Kickoff Return Yards 40-50 Yards', spark: () => 'SUM(IF(return_yards >= 40 AND return_yards <=50, 1, 0))'},
        kickoff_return_yards_greater_than_50_yards: {label: 'Kickoff Return Yards Greater Than 50 Yards', spark: () => 'SUM(IF(return_yards > 50, 1, 0))'},
        kickoff_return_yards_less_than_20_yards: {label: 'Kickoff Return Yards Less Than 20 Yards', spark: () => 'SUM(IF(return_yards < 50, 1, 0))'},
        kickoff_return_yards_per_kick: {label: 'Kickoff Return Yards Per Kick', spark: () => 'SUM(return_yards)/SUM(kickoff_attempt)'},
        kickoff_returns: {label: 'Kickoff Returns', spark: () => 'SUM(kickoff_attempt)'},
        kickoff_yards: {label: 'Kickoff Yards', spark: () => 'SUM(kick_distance)'},
        kickoff_yards_per_kick: {label: 'Kickoff Yards Per Kick', spark: () => 'SUM(kick_distance)/SUM(kickoff_attempt)'},
        kickoff_yards_returned: {label: 'Kickoff Yards Returned', spark: () => 'SUM(return_yards)'},
        kickoffs: {label: 'Kickoffs', spark: () => 'SUM(kickoff_attempt)'},
        pass_air_yards_per_attempt: {label: 'Pass Air Yards Per Attempt', spark: () => 'SUM(IF(complete_pass = 1, air_yards, 0))/SUM(pass_attempt)'},
        pass_air_yards_total: {label: 'Pass Air Yards Total', spark: () => 'SUM(air_yards)'},
        pass_air_yards_total_per_game: {label: 'Pass Air Yards Total Per Game', spark: () => 'SUM(air_yards)/COUNT(DISTINCT game_id)'},
        pass_attempts: {label: 'Pass Attempts', spark: () => 'SUM(pass_attempt)'},
        pass_completion_per_attempt: {label: 'Pass Completion Per Attempt', spark: () => 'SUM(complete_pass)/SUM(pass_attempt)'},
        pass_completions: {label: 'Pass Completions', spark: () => 'SUM(complete_pass)'},
        pass_completions_per_game: {label: 'Pass Completions Per Game', spark: () => 'SUM(complete_pass)/COUNT(DISTINCT game_id)'},
        pass_defensed: {label: 'Pass Defensed', spark: () => 'SUM(IF(pass_defense_1_player_id != "None" OR pass_defense_2_player_id != "None", 1, 0))'},
        pass_defensed_per_game: {label: 'Pass Defensed Per Game', spark: () => 'SUM(IF(pass_defense_1_player_id != "None" OR pass_defense_2_player_id != "None", 1, 0))/COUNT(DISTINCT game_id)'},
        pass_first_down: {label: 'Pass First Down', spark: () => 'sum(first_down)'},
        pass_first_down_per_game: {label: 'Pass First Down Per Game', spark: () => 'sum(first_down)/COUNT(DISTINCT game_id)'},
        pass_intended_air_yards_per_attempt: {label: 'Pass Intended Air Yards Per Attempt', spark: () => 'SUM(air_yards)/SUM(pass_attempt)'},
        pass_intended_air_yards_total: {label: 'Pass Intended Air Yards Total', spark: () => 'SUM(air_yards)'},
        pass_intended_air_yards_total_per_game: {label: 'Pass Intended Air Yards Total Per Game', spark: () => 'SUM(air_yards)/COUNT(DISTINCT game_id)'},
        pass_interception_to_touchdown_ratio: {label: 'Pass Interception To Touchdown Ratio', spark: () => 'SUM(interception)/SUM(touchdown)'},
        pass_interceptions: {label: 'Pass Interceptions', spark: () => 'SUM(interception)'},
        pass_interceptions_per_attempt: {label: 'Pass Interceptions Per Attempt', spark: () => 'SUM(interception)/SUM(pass_attempt)'},
        pass_interceptions_per_game: {label: 'Pass Interceptions Per Game', spark: () => 'SUM(interception)/COUNT(DISTINCT game_id)'},
        pass_touchdowns: {label: 'Pass Touchdowns', spark: () => 'SUM(pass_touchdown)'},
        pass_touchdowns_per_attempt: {label: 'Pass Touchdowns Per Attempt', spark: () => 'SUM(pass_touchdown)/SUM(pass_attempt)'},
        pass_touchdowns_per_game: {label: 'Pass Touchdowns Per Game', spark: () => 'SUM(pass_touchdown)/COUNT(DISTINCT game_id)'},
        pass_yards_per_attempt: {label: 'Pass Yards Per Attempt', spark: () => 'SUM(passing_yards)/SUM(pass_attempt)'},
        pass_yards_total: {label: 'Pass Yards Total', spark: () => 'SUM(passing_yards)'},
        pass_yards_total_per_game: {label: 'Pass Yards Total Per Game', spark: () => 'SUM(passing_yards)/COUNT(DISTINCT game_id)'},
        punt_net_yards: {label: 'Punt Net Yards', spark: () => 'SUM(IF(punt_attempt=1, kick_distance, 0))-SUM(IF(punt_attempt=1, return_yards, 0))'},
        punt_net_yards_per_kick: {label: 'Punt Net Yards Per Kick', spark: () => '(SUM(IF(punt_attempt=1, kick_distance, 0))-SUM(IF(punt_attempt=1,return_yards, 0))/SUM(punt_attempt)'},
        punt_return_net_yards_per_kick: {label: 'Punt Return Net Yards Per Kick', spark: () => '(SUM(IF(punt_attempt=1, kick_distance, 0))-SUM(IF(punt_attempt=1, return_yards, 0)))/SUM(punt_attempt)'},
        punt_return_yards: {label: 'Punt Return Yards', spark: () => 'SUM(IF(punt_attempt=1, return_yards, 0))'},
        punt_return_yards_20_40_yards: {label: 'Punt Return Yards 20-40 Yards', spark: () => 'SUM(IF(return_yards >= 20 AND return_yards <=40 AND punt_attempt, 1, 0))'},
        punt_return_yards_40_50_yards: {label: 'Punt Return Yards 40-50 Yards', spark: () => 'SUM(IF(return_yards >= 40 AND return_yards <=50 AND punt_attempt, 1, 0))'},
        punt_return_yards_greater_than_50_yards: {label: 'Punt Return Yards Greater Than 50 Yards', spark: () => 'SUM(IF(return_yards > 50 AND punt_attempt, 1, 0))'},
        punt_return_yards_less_than_20_yards: {label: 'Punt Return Yards Less Than 20 Yards', spark: () => 'SUM(IF(return_yards < 50 AND punt_attempt, 1, 0))'},
        punt_return_yards_per_kick: {label: 'Punt Return Yards Per Kick', spark: () => 'SUM(IF(punt_attempt=1, return_yards, 0))/SUM(punt_attempt)'},
        punt_returns: {label: 'Punt Returns', spark: () => 'SUM(punt_attempt)'},
        punt_yards: {label: 'Punt Yards', spark: () => 'SUM(IF(punt_attempt=1, kick_distance, 0))'},
        punt_yards_per_kick: {label: 'Punt Yards Per Kick', spark: () => 'SUM(IF(punt_attempt=1, kick_distance, 0))/SUM(punt_attempt)'},
        punt_yards_returned: {label: 'Punt Yards Returned', spark: () => 'SUM(IF(punt_attempt=1, return_yards, 0))'},
        punt_yards_returned_per_kick: {label: 'Punt Yards Returned Per Kick', spark: () => 'SUM(IF(punt_attempt=1, return_yards, 0))/SUM(punt_attempt)'},
        punts: {label: 'Punts', spark: () => 'SUM(punt_attempt)'},
        punts_in_20: {label: 'Punts In 20', spark: () => 'SUM(punt_inside_twenty)'},
        punts_out_of_bounds: {label: 'Punts Out Of Bounds', spark: () => 'SUM(punt_out_of_bounds)'},
        qb_hit: {label: 'QB Hit', spark: () => 'SUM(qb_hit)'},
        reception_first_down: {label: 'Reception First Down', spark: () => 'SUM(first_down_pass)'},
        reception_first_down_per_game: {label: 'Reception First Down Per Game', spark: () => 'SUM(first_down_pass)/COUNT(DISTINCT game_id)'},
        reception_fumble_to_touchdown_ratio: {label: 'Reception Fumble To Touchdown Ratio', spark: () => 'SUM(fumble)/SUM(pass_touchdown)'},
        reception_fumbles: {label: 'Reception Fumbles', spark: () => 'SUM(fumble)'},
        reception_fumbles_per_game: {label: 'Reception Fumbles Per Game', spark: () => 'SUM(fumble)/COUNT(DISTINCT game_id)'},
        reception_touchdowns: {label: 'Reception Touchdowns', spark: () => 'SUM(pass_touchdown)'},
        reception_touchdowns_per_game: {label: 'Reception Touchdowns Per Game', spark: () => 'SUM(pass_touchdown)/COUNT(DISTINCT game_id)'},
        reception_yards_after_catch: {label: 'Reception Yards After Catch', spark: () => 'SUM(yards_after_catch)'},
        reception_yards_for_loss_per_game: {label: 'Reception Yards For Loss Per Game', spark: () => 'SUM(IF(receiving_yardsd < 0, 1, 0))/COUNT(DISTINCT game_id)'},
        reception_yards_for_loss_total: {label: 'Reception Yards For Loss Total', spark: () => 'SUM(IF(receiving_yardsd < 0, 1, 0))'},
        reception_yards_greater_than_10_yards_per_game: {label: 'Reception Yards Greater Than 10 Yards Per Game', spark: () => 'SUM(IF(receiving_yardsd > 10, 1, 0))/COUNT(DISTINCT game_id)'},
        reception_yards_greater_than_10_yards_total: {label: 'Reception Yards Greater Than 10 Yards Total', spark: () => 'SUM(IF(receiving_yardsd > 10, 1, 0))'},
        reception_yards_greater_than_5_yards_per_game: {label: 'Reception Yards Greater Than 5 Yards Per Game', spark: () => 'SUM(IF(receiving_yardsd > 10, 1, 0))/COUNT(DISTINCT game_id)'},
        reception_yards_greater_than_5_yards_total: {label: 'Reception Yards Greater Than 5 Yards Total', spark: () => 'SUM(IF(receiving_yardsd > 5, 1, 0))'},
        reception_yards_greater_than_5_yards_total: {label: 'Reception Yards Greater Than 5 Yards Total', spark: () => 'SUM(IF(receiving_yardsd > 20, 1, 0))'},
        reception_yards_less_than_1_yards_per_game: {label: 'Reception Yards Less Than 1 Yards Per Game', spark: () => 'SUM(IF(receiving_yardsd > 0 AND rushing_yards <= 1, 1, 0))/COUNT(DISTINCT game_id)'},
        reception_yards_less_than_1_yards_total: {label: 'Reception Yards Less Than 1 Yards Total', spark: () => 'SUM(IF(receiving_yardsd > 0 AND rushing_yards <= 1, 1, 0))'},
        reception_yards_total: {label: 'Reception Yards Total', spark: () => 'SUM(receiving_yardsd)'},
        reception_yards_total_per_game: {label: 'Reception Yards Total Per Game', spark: () => 'SUM(receiving_yardsd)/COUNT(DISTINCT game_id)'},
        receptions: {label: 'Receptions', spark: () => 'SUM(complete_pass)'},
        rush_attempts: {label: 'Rush Attempts', spark: () => 'SUM(rush_attempt)'},
        rush_first_down: {label: 'Rush First Down', spark: () => 'SUM(IF(rush_attempt=1,first_down,0))'},
        rush_first_down_per_game: {label: 'Rush First Down Per Game', spark: () => 'SUM(IF(rush_attempt=1,first_down,0))/COUNT(DISTINCT game_id)'},
        rush_fumble_to_touchdown_ratio: {label: 'Rush Fumble To Touchdown Ratio', spark: () => 'SUM(IF(rush_attempt=1,fumble,0))/SUM(rush_touchdown)'},
        rush_fumbles: {label: 'Rush Fumbles', spark: () => 'SUM(IF(rush_attempt=1,fumble,0))'},
        rush_fumbles_per_attempt: {label: 'Rush Fumbles Per Attempt', spark: () => 'SUM(IF(rush_attempt=1,fumble,0))/SUM(rush_attempt)'},
        rush_fumbles_per_game: {label: 'Rush Fumbles Per Game', spark: () => 'SUM(IF(rush_attempt=1,fumble,0))/COUNT(DISTINCT game_id)'},
        rush_touchdowns: {label: 'Rush Touchdowns', spark: () => 'SUM(rush_touchdown)'},
        rush_touchdowns_per_attempt: {label: 'Rush Touchdowns Per Attempt', spark: () => 'SUM(rush_touchdown)/SUM(rush_attempt)'},
        rush_touchdowns_per_game: {label: 'Rush Touchdowns Per Game', spark: () => 'SUM(IF(rushing_yards < 0, rush_attempt, 0))'},
        rush_yards_for_loss_per_attempt: {label: 'Rush Yards For Loss Per Attempt', spark: () => 'SUM(IF(rushing_yards < 0, rush_attempt, 0))/SUM(rush_attempt)'},
        rush_yards_for_loss_per_game: {label: 'Rush Yards For Loss Per Game', spark: () => 'SUM(IF(rushing_yards < 0, rush_attempt, 0))/COUNT(DISTINCT game_id)'},
        rush_yards_per_attempt: {label: 'Rush Yards Per Attempt', spark: () => 'SUM(rushing_yards)/SUM(rush_attempt)'},
        rush_yards_total: {label: 'Rush Yards Total', spark: () => 'SUM(rushing_yards)'},
        rush_yards_total_per_game: {label: 'Rush Yards Total Per Game', spark: () => 'SUM(rushing_yards)/COUNT(DISTINCT game_id)'},
        rushes_for_loss_total: {label: 'Rushes For Loss Total', spark: () => 'SUM(IF(rushing_yards < 0, rush_attempt, 0))'},
        rushes_greater_than_10_yards_per_attempt: {label: 'Rushes Greater Than 10 Yards Per Attempt', spark: () => 'SUM(IF(rushing_yards > 10, rush_attempt, 0))/SUM(rush_attempt)'},
        rushes_greater_than_10_yards_per_game: {label: 'Rushes Greater Than 10 Yards Per Game', spark: () => 'SUM(IF(rushing_yards > 10, rush_attempt, 0))/COUNT(DISTINCT game_id)'},
        rushes_greater_than_10_yards_total: {label: 'Rushes Greater Than 10 Yards Total', spark: () => 'SUM(IF(rushing_yards > 10, rush_attempt, 0))'},
        rushes_greater_than_20_yards_total: {label: 'Rushes Greater Than 20 Yards Total', spark: () => 'SUM(IF(rushing_yards > 20, rush_attempt, 0))'},
        rushes_greater_than_5_yards_per_attempt: {label: 'Rushes Greater Than 5 Yards Per Attempt', spark: () => 'SUM(IF(rushing_yards > 5, rush_attempt, 0))/SUM(rush_attempt)'},
        rushes_greater_than_5_yards_per_game: {label: 'Rushes Greater Than 5 Yards Per Game', spark: () => 'SUM(IF(rushing_yards > 5, rush_attempt, 0))/COUNT(DISTINCT game_id)'},
        rushes_greater_than_5_yards_total: {label: 'Rushes Greater Than 5 Yards Total', spark: () => 'SUM(IF(rushing_yards > 5, rush_attempt, 0))'},
        rushes_less_than_1_yards_per_attempt: {label: 'Rushes Less Than 1 Yards Per Attempt', spark: () => 'SUM(IF(rushing_yards > 0 AND rushing_yards <= 1, rush_attempt, 0))/SUM(rush_attempt)'},
        rushes_less_than_1_yards_per_game: {label: 'Rushes Less Than 1 Yards Per Game', spark: () => 'SUM(IF(rushing_yards > 0 AND rushing_yards <= 1, rush_attempt, 0))/COUNT(DISTINCT game_id)'},
        rushes_less_than_1_yards_total: {label: 'Rushes Less Than 1 Yards Total', spark: () => 'SUM(IF(rushing_yards > 0 AND rushing_yards <= 1, rush_attempt, 0))'},
        sack_yards: {label: 'Sack Yards', spark: () => 'SUM(if(sack=1,yards_gained,0))'},
        sack_yards_per_sack: {label: 'Sack Yards Per Sack', spark: () => 'SUM(if(sack=1,yards_gained,0))/SUM(sack)'},
        sacks: {label: 'Sacks', spark: () => 'SUM(sack)'},
        scramble_yards: {label: 'Scramble Yards', spark: () => 'SUM(IF(qb_scramble=1, rushing_yards,0))'},
        scrambles_per_attempt: {label: 'Scrambles Per Attempt', spark: () => 'SUM(IF(qb_scramble=1, rushing_yards,0))/SUM(pass_attempt)'},
        tackles_assisted: {label: 'Tackles Assisted', spark: () => 'SUM(IF(assist_tackle=1, 1, 0))'},
        tackles_solo: {label: 'Tackles Solo', spark: () => 'SUM(IF(solo_tackle=1,1,0))'},
        timeouts_called: {label: 'Timeouts Called', spark: () => 'SUM(timeout_called)'},
        total_sack_yards: {label: 'Total Sack Yards', spark: () => 'SUM(IF(sack=1,yards_gained, 0))'},
        tackles_for_loss: {label: 'Tackles For Loss', spark: () => 'SUM(tackled_for_loss)'},
        fumbles_lost: {label: 'Fumbles Lost', spark: () => 'SUM(fumble_lost)'},
        rushing_first_downs: {label: 'Rushing First Downs', spark: () => 'SUM(first_down_rush)'},
        passing_first_downs: {label: 'Passing First Downs', spark: () => 'SUM(first_down_pass)'},
        penalty_first_downs: {label: 'Penalty First Downs', spark: () => 'SUM(first_down_penalty)'},
        converted_third_downs: {label: 'Converted Third Downs', spark: () => 'SUM(third_down_converted)'},
        third_down_conversion_fails: {label: 'Third Down Conversion Fails', spark: () => 'SUM(third_down_failed)'},
        converted_fourth_downs: {label: 'Converted Fourth Downs', spark: () => 'SUM(fourth_down_converted)'},
        fourth_down_conversion_fails: {label: 'Fourth Down Conversion Fails', spark: () => 'SUM(fourth_down_failed)'},
        penalties: {label: 'Penalties', spark: () => 'SUM(penalty)'},
        penalty_yards: {label: 'Penalty Yards', spark: () => 'SUM(penalty_yards)'}
    }
}

export {metricsMap}