const analyzerFunction = (fieldDataTypes, selected_analysis) => {
    let timeseries = false
    if (Object.values(fieldDataTypes).includes('date')) {
        timeseries = true
    }
    const analysis_to_analyzers = {
        BubbleChart: 'THREE_METRIC_RANKER',
        Candlestick: 'TIME_SERIES_TREND',
        Chord: 'CHORD',
        NoDimManyMetrics: "N_METRIC_TIME_SERIES",
        OneDimManyMetrics: timeseries ? 'N_METRIC_TIME_SERIES' : 'N_METRIC_RANKER',//TODO create an n-metric ranker that isn't a timeseries
        OneDimOneMetric: timeseries ? 'TIME_SERIES_TREND' : 'ONE_METRIC_RANKER',
        ShotChart: 'SHOTCHART',
        TwoDimOneMetric: timeseries ? 'N_METRIC_TIME_SERIES' : 'TWO_METRIC_RANKER'
    }
    return analysis_to_analyzers[selected_analysis]
}

export default analyzerFunction;