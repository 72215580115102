const VictoryStandardGraphFunction = (props) => {

    const graph_outer_inputs = {}
    const outer_inputs = ['padding_top', 'padding_bottom', 'padding_left', 'padding_right', 'domain_min', 'domain_max', 'range_min', 'range_max', 'domain_padding_min', 'domain_padding_max', 'range_padding_min', 'range_padding_max', 'horizontal', 'label_points', 'scale_x', 'scale_y']
    outer_inputs.map(x => {
        try {
            graph_outer_inputs[x] = JSON.parse(props.graph_inputs[x])
        } catch (e) {
            let a = 1
        }
    })

    if (Object.keys(graph_outer_inputs).includes('domain_min') || Object.keys(graph_outer_inputs).includes('domain_max') || Object.keys(graph_outer_inputs).includes('range_min') || Object.keys(graph_outer_inputs).includes('range_max'))
        graph_outer_inputs['domain'] = { x: [graph_outer_inputs['domain_min'] ?? 0, graph_outer_inputs['domain_max'] ?? 50], y: [graph_outer_inputs['range_min'] ?? 0, graph_outer_inputs['range_max'] ?? 50] }
    if (Object.keys(graph_outer_inputs).includes('domain_padding_min') || Object.keys(graph_outer_inputs).includes('domain_padding_max') || Object.keys(graph_outer_inputs).includes('range_padding_min') || Object.keys(graph_outer_inputs).includes('range_padding_max')) {
        graph_outer_inputs['domainPadding'] = { x: [graph_outer_inputs['domain_padding_min'] ?? 0, graph_outer_inputs['domain_padding_max'] ?? 0], y: [graph_outer_inputs['range_padding_min'] ?? 0, graph_outer_inputs['range_padding_max'] ?? 0] }
    }
    if (Object.keys(graph_outer_inputs).includes('padding_top') || Object.keys(graph_outer_inputs).includes('padding_bottom') || Object.keys(graph_outer_inputs).includes('padding_left') || Object.keys(graph_outer_inputs).includes('padding_right')) {
        graph_outer_inputs['padding'] = { top: graph_outer_inputs['padding_top'] ?? 0, bottom: graph_outer_inputs['padding_bottom'] ?? 0, left: graph_outer_inputs['padding_left'] ?? 0, right: graph_outer_inputs['padding_right'] ?? 0 }
    }
    if (Object.keys(graph_outer_inputs).includes('scale_x') || Object.keys(graph_outer_inputs).includes('scale_y')) {
        graph_outer_inputs['scale'] = { x: graph_outer_inputs['scale_x'] ?? "linear", y: graph_outer_inputs['scale_y'] ?? "linear" }
    }
    const y_tick_inputs = {}
    const y_label_inputs = { 'y_orientation': 'orientation', 'y_offsetY': 'offsetY' }
    Object.keys(y_label_inputs).map(x => {
        try {
            y_tick_inputs[y_label_inputs[x]] = JSON.parse(props.graph_inputs[x])
        } catch (e) {
            let a = 1
        }
    })
    if (Object.keys(props.graph_inputs).includes('y_tick_scaler')) {
        y_tick_inputs['tickFormat'] = (t) => `${Math.round(t / props.graph_inputs['y_tick_scaler'])}`
    } else {
        y_tick_inputs['tickFormat'] = (t) => `${t}`
    }

    const y_tick_style = {}
    const y_tick_style_inputs = {
        'y_tick_size': 'size'
    }

    Object.keys(y_tick_style_inputs).map(x => {
        try {
            y_tick_style[y_tick_style_inputs[x]] = JSON.parse(props.graph_inputs[x])
        } catch (e) {
            let a = 1
        }
    })

    const y_tick_label_s = {}
    const y_tick_label_s_inputs = {
        'y_tick_label_font_size': 'fontSize',
        'y_tick_label_padding': 'padding',
        'y_tick_label_angle': 'angle',
    }

    Object.keys(y_tick_label_s_inputs).map(x => {
        try {
            y_tick_label_s[y_tick_label_s_inputs[x]] = JSON.parse(props.graph_inputs[x])
        } catch (e) {
            let a = 1
        }
    })

    const y_axis_label_style = {}
    const y_axis_label_style_inputs = { 'y_axis_label_padding': 'padding', 'y_axis_label_angle': 'angle' }

    Object.keys(y_axis_label_style_inputs).map(x => {
        try {
            y_axis_label_style[y_axis_label_style_inputs[x]] = JSON.parse(props.graph_inputs[x])
        } catch (e) {
            let a = 1
        }
    })

    y_axis_label_style['fontSize'] = 10

    const tickyaxisStyle = {
        ticks: { ...y_tick_style },
        tickLabels: { ...y_tick_label_s },
        axisLabel: { ...y_axis_label_style }
    };

    const x_tick_inputs = {}
    if (Object.keys(props.graph_inputs).includes('x_tick_scaler') && props.graph_inputs['x_tick_scaler'] > 0) {
        x_tick_inputs['tickFormat'] = (t) => `${Math.round(t / props.graph_inputs['x_tick_scaler'])}`
    } else {
        x_tick_inputs['tickFormat'] = (t) => `${t}`
    }

    const x_tick_style = {}
    const x_tick_style_inputs = {
        'x_tick_size': 'size'
    }

    Object.keys(x_tick_style_inputs).map(x => {
        try {
            x_tick_style[x_tick_style_inputs[x]] = JSON.parse(props.graph_inputs[x])
        } catch (e) {
            let a = 1
        }
    })

    const x_tick_label_s = {}
    const x_tick_label_s_inputs = {
        'x_tick_label_font_size': 'fontSize',
        'x_tick_label_padding': 'padding',
        'x_tick_label_angle': 'angle',
    }

    Object.keys(x_tick_label_s_inputs).map(x => {
        try {
            x_tick_label_s[x_tick_label_s_inputs[x]] = JSON.parse(props.graph_inputs[x])
        } catch (e) {
            let a = 1
        }
    })

    const x_axis_label_style = {}
    const x_axis_label_style_inputs = { 'x_axis_label_padding': 'padding', 'x_axis_label_angle': 'angle' }

    Object.keys(x_axis_label_style_inputs).map(x => {
        try {
            x_axis_label_style[x_axis_label_style_inputs[x]] = JSON.parse(props.graph_inputs[x])
        } catch (e) {
            let a = 1
        }
    })
    const tickxaxisStyle = {
        ticks: { ...x_tick_style },
        tickLabels: { ...x_tick_label_s },
        axisLabel: { ...x_axis_label_style },
    };

    tickxaxisStyle['grid'] = {strokeWidth: 0}
    tickyaxisStyle['grid'] = {strokeWidth: 0}


    return {
        'graph_outer_inputs': graph_outer_inputs,
        'tickxaxisStyle': tickxaxisStyle,
        'tickyaxisStyle': tickyaxisStyle,
        'y_tick_inputs': y_tick_inputs,
        'x_tick_inputs': x_tick_inputs
    }
}

export default VictoryStandardGraphFunction;