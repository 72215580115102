const metrics = [
    {
        "display_text": "Population",
        "value": "population"
    },
    {
        "display_text": "Violent Crime",
        "value": "violent_crime"
    },
    {
        "display_text": "Rape (Legacy)",
        "value": "rape_legacy"
    }, {
        "display_text": "Rape (Revised)",
        "value": "rape_revised"
    }, {
        "display_text": "Robbery",
        "value": "robbery"
    }, {
        "display_text": "Aggravated Assault",
        "value": "aggravated_assault"
    }, {
        "display_text": "Propert Crime",
        "value": "property_crime"
    }, {
        "display_text": "Burglary",
        "value": "burglary"
    }, {
        "display_text": "Larceny",
        "value": "larceny"
    }, {
        "display_text": "Motor Vehicle Theft",
        "value": "motor_vehicle_theft"
    }, {
        "display_text": "Arson",
        "value": "arson"
    }
]
const metricsMap = () => {
    return {
        crime_type: { label: 'Crime Type', spark: (data_code) => `${data_code}`, input_type: 'selector', inputs: metrics },
    }
}
export { metricsMap };



