import React from 'react';
import './ResponseLoading.css'
import Close from '@mui/icons-material/Close';


const ResponseLoading = (props) => {
    if (props.loading_message.show_loading === true && props.loading_message.loading_error_message == '') {
        return (
            <div className="overlay">
                <div className="spinner-container">
                    <div style={{ 'padding': '10%', 'float': 'right' }} onClick={() => props.close_response_loader_dialog()}>
                        <Close />
                    </div>
                    <div style={{ 'padding': '10%' }}>Loading</div>
                    <div className="spinner">
                        <div></div>
                    </div>
                </div>
            </div>
        )
    } else if (props.loading_message.show_loading === true && props.loading_message.loading_error_message === "There is no data, try adjusting your filters and confirming the metric exists for your dimension.") {
        return (
            <div className="error_message">
                <div style={{ 'padding': '5%', 'backgroundColor': '#ffffff', display:'flex', flexFlow:'column' }}>
                    <div style={{ 'padding': '3%', display:'flex', 'justifyContent': 'flex-end', 'top': 0, position: 'relative' }} onClick={() => props.close_response_loader_dialog()}>
                        <Close />
                    </div>
                    <div>
                        {props.loading_message.loading_error_message}
                    </div>
                </div>
            </div>
        )
    } else if (props.loading_message.show_loading === true && props.loading_message.loading_error === false) {
        return (
        <div className="overlay">
            <div className="spinner-container">
                <div style={{ 'padding': '10%', 'float': 'right' }} onClick={() => props.close_response_loader_dialog()}>
                    <Close />
                </div>
                <div style={{ 'padding': '10%' }}>{props.loading_message.loading_error_message}</div>
                <div className="spinner">
                    <div></div>
                </div>
            </div>
        </div>
        )
    } else if (props.loading_message.show_loading === true && props.loading_message.loading_error === true) {
        return (
            <div className="error_message">
                <div style={{ 'padding': '5%', 'backgroundColor': '#ffffff', display:'flex', flexFlow:'column' }}>
                    <div style={{ 'padding': '3%', display:'flex', 'justifyContent': 'flex-end', 'top': 0, position: 'relative' }} onClick={() => props.close_response_loader_dialog()}>
                        <Close />
                    </div>
                    <div>
                        {props.loading_message.loading_error_message}
                    </div>
                </div>
            </div>
        )
    } else {
        return <></>
    }
}

export default ResponseLoading;