import React from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import {IconButton, Typography, Toolbar, MenuItem, Menu, Button, Link} from '@mui/material';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Logout from '@mui/icons-material/Logout';
import {useState} from "react";
import {NavLink as RouterNavLink} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import './DdNavBar.css'
import {Dropdown} from "react-bootstrap";
import { withRouter } from 'react-router';

// const sections = ["World", "US", "Economy", "Markets", "NBA", "NFL", "Climate", "Crime", "Energy", "Real Estate"]
const sections = ["World", "US", "Markets", "NBA", "Real Estate"]


function DdNavBarMobile() {
    const [isOpen, setIsOpen] = useState(false);
    const {
        user,
        isAuthenticated,
        loginWithRedirect,
        logout,
    } = useAuth0();
    const toggle = () => setIsOpen(!isOpen);

    const logoutWithRedirect = () =>
        logout({
            returnTo: window.location.origin,
        });

    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [visibility, setVisibility] = useState("none")
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const updateVisibility = () => {
        if (visibility == "none") {
            setVisibility("flex")
        } else {
            setVisibility("none")
        }
    }
    return (
        <Toolbar style={{backgroundColor: "#ECECEC", color: "black", flexWrap: "wrap", display:"flex", alignItems: "center", justifyContent: "center",}}>
            {/* <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={updateVisibility}
                sx={{mr: 2}}
            >
                <MenuIcon/>
            </IconButton> */}


            <Typography variant="h6" style={{fontWeight: "bold", padding: "10px", fontFamily: "JoanRegular"}}>
                <Link href="/" underline="none" color="black"> The Data News-Journal </Link>
            </Typography>

            {/* {!isAuthenticated && (
                <IconButton
                    id="qsLoginBtn"
                    color="primary"
                    className="btn-margin"
                    onClick={() => loginWithRedirect()}
                    sx={{fontSize: 15}}
                >
                    Sign in
                </IconButton>
            )}

            {isAuthenticated && (
                <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ml: 'auto'}}
                    aria-controls={Boolean(anchorEl) ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={Boolean(anchorEl) ? 'true' : undefined}
                >
                <span className="user-info">
                    <img
                        src={user.picture}
                        alt="Profile"
                        style={{borderRadius: 50}}
                        className="nav-user-profile d-inline-block rounded-circle mr-3"
                        width="30"
                    />
                  </span>
                </IconButton>)}
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={Boolean(anchorEl)}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
            >
                <MenuItem>
                    <RouterNavLink
                        to="/profile"
                        activeClassName="router-link-exact-active"
                        style={{ textDecoration: 'none', color: 'black' }}
                    >
                        <Avatar/>
                    </RouterNavLink>
                    <RouterNavLink
                        to="/profile"
                        activeClassName="router-link-exact-active"
                        style={{ textDecoration: 'none', color: 'black' }}
                    >
                        Profile
                    </RouterNavLink>
                </MenuItem>
                <Divider/>
                <MenuItem>
                        <RouterNavLink
                            to="/myanalyses"
                            id="acctButton"
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            My Articles
                        </RouterNavLink>
                    </MenuItem>
                    <Divider />
                <MenuItem>
                    <ListItemIcon>
                        <RouterNavLink
                            to="#"
                            id="qsLogoutBtn"
                            onClick={() => logoutWithRedirect()}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <Logout fontSize="small"/>
                        </RouterNavLink>
                    </ListItemIcon>
                    <RouterNavLink
                        to="#"
                        id="qsLogoutBtn"
                        onClick={() => logoutWithRedirect()}
                        style={{ textDecoration: 'none', color: 'black' }}
                    >
                        Log out
                    </RouterNavLink>
                </MenuItem>
            </Menu> */}

            <div className="navbardiv" style={{display: visibility}}>
                <div>
                    <div style={{fontWeight: "900", fontSize: "1.25rem", fontFamily: "JoanRegular"}}>Sections</div>
                    <div className="sectionlist">
                        {sections.map(section => {
                            return (
                                <div className="sectionlist" key={section}>
                                    <RouterNavLink className="sectionlist" to={`/content_section/${section}`} key={section}
                                          onClick={updateVisibility}>
                                        {section}
                                    </RouterNavLink>
                                    <br/>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </Toolbar>
    )
}

export default withRouter(DdNavBarMobile);


