import React from 'react'

const filtersMap = (props, on_change_event) => {
    return {
        start_date: {
            operators: ["equals"],
            label: 'Start year',
            spark_left_operand: () => 'START_YEAR',
            right_operand: (curr_value, on_change_event) => {
                return (
                    <input
                        type="number"
                        onChange={(e) => { on_change_event(e, "right_operand") }}
                        value={curr_value} />
                )
            }
        },
        end_date: {
            operators: ["equals"],
            label: 'End Year',
            spark_left_operand: () => 'END_YEAR',
            right_operand: (curr_value, on_change_event) => {
                return (
                    <input
                        type="number"
                        onChange={(e) => { on_change_event(e, "right_operand") }}
                        value={curr_value} />
                )
            }
        },
        state: {
            operators: ["equals"],
            label: 'State',
            spark_left_operand: () => 'STATE',
            right_operand: (curr_value, on_change_event) => {
                return (
                    <input
                        type="text"
                        onChange={(e) => { on_change_event(e, "right_operand") }}
                        value={curr_value} />
                )
            }
        }
    }
}

export {filtersMap};