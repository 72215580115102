import React from 'react';
import filters_value_map from './filters_values_map';

const get_index = (inputs_map) => {
    let temp_index = '';
    Object.keys(inputs_map).map( x =>{
         if(inputs_map[x].left_operand == 'index') temp_index = inputs_map[x].right_operand
        })
    return temp_index
}

const filtersMap = (props, on_change_event) => {
    return {
        index: {
            operators: ["equals"],
            label: 'Index',
            spark_left_operand: () => 'index',
            right_operand: (curr_value, on_change_event) => {
                return (
                    <select onChange={(e) => { on_change_event(e, "right_operand")}}>
                        <option></option>
                        {Object.keys(filters_value_map).map( x => <option fieldalias="none" value={x} type="" key={x}>{filters_value_map[x].label}</option>)}
                    </select>
                )
            }
        },
        seasonal_adjustment_code: {
        operators: ["equals"],
        label: 'Seasonal Adjustment Code',
        spark_left_operand: () => 'seasonal_adjustment_code',
        right_operand: (curr_value, on_change_event) => {
            const index_name = get_index(props.component_inputs)
            if(Object.keys(filters_value_map[index_name]).includes('seasonal_adjustment_code')){
                return (
                    <select onChange={(e) => { on_change_event(e, "right_operand")}}>
                        <option></option>
                        {Object.keys(filters_value_map[index_name]['seasonal_adjustment_code']).map( x => <option fieldalias="none" value={x} key={x} type="" >{filters_value_map[index_name]['seasonal_adjustment_code'][x]}</option>)}
                    </select>
                )    
            } else {
                return (
                    <select onChange={(e) => { on_change_event(e, "right_operand")}}>
                        <option> Not Required</option>
                    </select>
                )
            }
        }
    },
        prefix: {
            operators: ["equals"],
            label: 'Prefix',
            spark_left_operand: () => 'prefix',
            right_operand: (curr_value, on_change_event) => {
                const index_name = get_index(props.component_inputs)
                if(Object.keys(filters_value_map[index_name]).includes('prefix')){
                    return (
                        <select onChange={(e) => { on_change_event(e, "right_operand")}}>
                            <option></option>
                            {Object.keys(filters_value_map[index_name]['prefix']).map( x => <option fieldalias="none" value={x} key={x} type="" >{filters_value_map[index_name]['prefix'][x]}</option>)}
                        </select>
                    )    
                } else {
                    return (
                        <select onChange={(e) => { on_change_event(e, "right_operand")}}>
                            <option> Not Required</option>
                        </select>
                    )
                }
            }
        },
        industry_code: {
            operators: ["equals"],
            label: 'Industry Code',
            spark_left_operand: () => 'industry_code',
            right_operand: (curr_value, on_change_event) => {
                const index_name = get_index(props.component_inputs)
                if(Object.keys(filters_value_map[index_name]).includes('industry_code')){
                    return (
                        <select onChange={(e) => { on_change_event(e, "right_operand")}}>
                            <option></option>
                            {Object.keys(filters_value_map[index_name]['industry_code']).map( x => <option fieldalias="none" value={x} key={x} type="" >{filters_value_map[index_name]['industry_code'][x]}</option>)}
                        </select>
                    )    
                } else {
                    return (
                        <select onChange={(e) => { on_change_event(e, "right_operand")}}>
                            <option> Not Required</option>
                        </select>
                    )
                }
            }
        },
        supersector_code: {
            operators: ["equals"],
            label: 'Supersector Code',
            spark_left_operand: () => 'supersector_code',
            right_operand: (curr_value, on_change_event) => {
                const index_name = get_index(props.component_inputs)
                if(Object.keys(filters_value_map[index_name]).includes('supersector_code')){
                    return (
                        <select onChange={(e) => { on_change_event(e, "right_operand")}}>
                            <option></option>
                            {Object.keys(filters_value_map[index_name]['supersector_code']).map( x => <option fieldalias="none" value={x} key={x} type="" >{filters_value_map[index_name]['supersector_code'][x]}</option>)}
                        </select>
                    )    
                } else {
                    return (
                        <select onChange={(e) => { on_change_event(e, "right_operand")}}>
                            <option> Not Required</option>
                        </select>
                    )
                }
            }
        },
        data_type_code: {
            operators: ["equals"],
            label: 'Data Type Code',
            spark_left_operand: () => 'data_type_code',
            right_operand: (curr_value, on_change_event) => {
                const index_name = get_index(props.component_inputs)
                if(Object.keys(filters_value_map[index_name]).includes('data_type_code')){
                    return (
                        <select onChange={(e) => { on_change_event(e, "right_operand")}}>
                            <option></option>
                            {Object.keys(filters_value_map[index_name]['data_type_code']).map( x => <option fieldalias="none" value={x} key={x} type="" >{filters_value_map[index_name]['data_type_code'][x]}</option>)}
                        </select>
                    )    
                } else {
                    return (
                        <select onChange={(e) => { on_change_event(e, "right_operand")}}>
                            <option> Not Required</option>
                        </select>
                    )
                }
            }
        },
        state_code: {
            operators: ["equals"],
            label: 'State Code',
            spark_left_operand: () => 'state_code',
            right_operand: (curr_value, on_change_event) => {
                const index_name = get_index(props.component_inputs)
                if(Object.keys(filters_value_map[index_name]).includes('state_code')){
                    return (
                        <select onChange={(e) => { on_change_event(e, "right_operand")}}>
                            <option></option>
                            {Object.keys(filters_value_map[index_name]['state_code']).map( x => <option fieldalias="none" value={x} key={x} type="" >{filters_value_map[index_name]['state_code'][x]}</option>)}
                        </select>
                    )    
                } else {
                    return (
                        <select onChange={(e) => { on_change_event(e, "right_operand")}}>
                            <option> Not Required</option>
                        </select>
                    )
                }
            }
        },
        area_code: {
            operators: ["equals"],
            label: 'Area Code',
            spark_left_operand: () => 'area_code',
            right_operand: (curr_value, on_change_event) => {
                const index_name = get_index(props.component_inputs)
                if(Object.keys(filters_value_map[index_name]).includes('area_code')){
                    return (
                        <select onChange={(e) => { on_change_event(e, "right_operand")}}>
                            <option></option>
                            {Object.keys(filters_value_map[index_name]['area_code']).map( x => <option fieldalias="none" value={x} key={x} type="" >{filters_value_map[index_name]['area_code'][x]}</option>)}
                        </select>
                    )    
                } else {
                    return (
                        <select onChange={(e) => { on_change_event(e, "right_operand")}}>
                            <option> Not Required</option>
                        </select>
                    )
                }
            }
        },
        base: {
            operators: ["equals"],
            label: 'Base',
            spark_left_operand: () => 'base',
            right_operand: (curr_value, on_change_event) => {
                const index_name = get_index(props.component_inputs)
                if(Object.keys(filters_value_map[index_name]).includes('base')){
                    return (
                        <select onChange={(e) => { on_change_event(e, "right_operand")}}>
                            <option></option>
                            {Object.keys(filters_value_map[index_name]['base']).map( x => <option fieldalias="none" value={x} key={x} type="" >{filters_value_map[index_name]['base'][x]}</option>)}
                        </select>
                    )    
                } else {
                    return (
                        <select onChange={(e) => { on_change_event(e, "right_operand")}}>
                            <option> Not Required</option>
                        </select>
                    )
                }
            }
        }
    }
}

export {filtersMap};