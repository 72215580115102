import useMediaQuery from '@mui/material/useMediaQuery';
import DdNavBarDesktop from './DdNavBarDesktop';
import DdNavBarMobile from './DdNavBarMobile';


const DdNavBar = (props) => {
    const mobile_v_desktop = useMediaQuery('(min-width:600px)');
    return !mobile_v_desktop ? <DdNavBarMobile {...props}/> : <DdNavBarDesktop {...props} />

}

export default DdNavBar;