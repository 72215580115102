import { React, useState } from 'react';

const SortByComponent = (props) => {
    const dimension_data_collector = (e) => {
        const state_component_json = { 'SORT_BY_KEY': e.target.value }
        const query_input_json = {
            input_name: 'SORT_BY_KEY',
            data: e.target.value,
            type: "unquoted_text_input"
        }
        props.input_data_collector(query_input_json, state_component_json);
    }
    return (
        <>
            <p> Sort Data By </p>
            <select onChange={(e) => { dimension_data_collector(e) }} value={props.component_inputs['SORT_BY_KEY']}>
                <option value=""></option>
                <option value="x">X Dimension</option>
                <option value="y">Y Dimension</option>
                <option value="second_dimension">Second Dimension</option>
                <option value="amount">Radius</option>
            </select>
        </>
    )
}

export default SortByComponent