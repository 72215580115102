import React from 'react';
import ArticleEditInput from "./ArticleEditInput";

const ChordInput = (props) => {
    return (
        <>
            <label htmlFor="textchunk_top"> text above graph</label>
            <div>
                <ArticleEditInput
                    name="textchunk_top"
                    value={props.graph_inputs['textchunk_top']}
                    onChange={(e) => {
                        props.graph_input_collector({textchunk_top: e});
                    }}/>
            </div>
            <br/><br/><br/><br/><br/>
            <label htmlFor="textchunk_bottom"> text below graph </label>
            <div>
                <ArticleEditInput
                    name="textchunk_bottom"
                    value={props.graph_inputs['textchunk_bottom']}
                    onChange={(e) => {
                        props.graph_input_collector({textchunk_bottom: e});
                    }}/>
            </div>
        </>
    )
}

export default ChordInput;