import {
    ComposableMap,
    Geographies,
    Geography,
    Sphere,
    Graticule
  } from "react-simple-maps";
  
import React, { useEffect, useState } from "react";
import { csv } from "d3-fetch";
import { scaleLinear } from "d3-scale";
import TextChunk from './TextChunk';

const geoUrl = "/world_features.json";


const MapChart = (props) => {
    const y_vals = props.data_for_graph.map( x => {return x['y']});

    const min_val = Math.min(...y_vals);
    const max_val = Math.max(...y_vals);

    const colorScale = scaleLinear()
    .domain([min_val, max_val])
    .range(["#ffedea", "#ff5233"]);

    const data=props.data_for_graph;

    const legend = (y, i) =>{
        const rect_width = 20
        const lllm = {}
        const y_step = (max_val-min_val)/5
        if (Object.keys(props.graph_inputs).includes('legend_label_scaler')) {
          lllm['legend_label_scaler'] = (t) => `${Math.round(t / props.graph_inputs['legend_label_scaler'])}`
        } else {
          lllm['legend_label_scaler'] = (t) => `${t}`
        }
        const y_string_val = Math.round(lllm['legend_label_scaler'](y*max_val))
        return (<>
            <rect
                x={i*rect_width*2}
                style={{width:`${rect_width}px`, height:`${rect_width}`}}
                y={rect_width/2+20}
                key={Math.random()}
                fill={colorScale(y_step*i+min_val)}>
            </rect>
            <text style={{ fill: "black" }} fontSize={`.${props.graph_inputs.legend_font_size}em`} x={i*rect_width*2} y={65}>{y_string_val}</text>
        </>)}
    
    
    const bottom_text = {graph_inputs:{textchunk: props.graph_inputs.textchunk_bottom}}
    const top_text = {graph_inputs:{textchunk: props.graph_inputs.textchunk_top}}  
    return (
        <div>
        <TextChunk {...top_text} />
        <div>{props.graph_inputs.graph_title ? props.graph_inputs.graph_title : ''}</div>
        <ComposableMap
        projectionConfig={{
            rotate: [-10, 0, 0],
            scale: 147
        }}
        >
        <Sphere stroke="#E4E5E6" strokeWidth={0.5} />
        <Graticule stroke="#E4E5E6" strokeWidth={0.5} />
        {data.length > 0 && (
            <Geographies geography={geoUrl}>
            {({ geographies }) =>
                geographies.map((geo) => {
                const d = data.find((s) => s.x === geo.id);
                return (
                    <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill={d ? colorScale(d["y"]) : "#F5F4F6"}
                    />
                );
                })
            }
            </Geographies>
        )}
        </ComposableMap>
        <div style={{width:"100%", display:"flex", justifyContent:"left"}}>
        <svg style={{height:"100px"}}>
        <text x={"15%"} y={20} fontSize={`${props.graph_inputs.legend_label_font_size}em`}>{props.graph_inputs.legend_label ?? ""}</text>
          {[0.1, 0.2, 0.4, 0.6, 0.8, 1].map((y, i) => {return legend(y, i)})}
        </svg>
        </div>
    <TextChunk {...bottom_text} />
    </div>
    );
    };

export default MapChart;
