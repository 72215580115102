import { React, useState } from 'react';

const VictoryStandardLegendInput = (props) => {
    const [isLegendOpen, setIsLegendOpen] = useState("none");

    return (
        <>
        <br/>
            <button onClick={() => isLegendOpen == "flex" ? setIsLegendOpen("none") : setIsLegendOpen("flex")} > Hide/Show Legend Inputs</button>
            <div hidden={isLegendOpen} style={{ display: isLegendOpen, flexDirection: "column", justifyContent: "left", alignItems: "start" }}>

                <label htmlFor="legend_x"> Legend X</label>
                <input
                    name="legend_x"
                    type="number"
                    value={props.graph_inputs.legend_x}
                    onChange={(e) => props.graph_input_collector({ legend_x: e.target.value })}
                />
                <label htmlFor="legend_y"> Legend Y</label>
                <input
                    name="legend_y"
                    type="number"
                    value={props.graph_inputs.legend_y}
                    onChange={(e) => props.graph_input_collector({ legend_y: e.target.value })}
                />
                <label htmlFor="legend_title"> Legend Title</label>
                <input
                    name="legend_title"
                    type="text"
                    value={props.graph_inputs.legend_title}
                    onChange={(e) => props.graph_input_collector({ legend_title: e.target.value })}
                />
                <label htmlFor="gutter"> Space Between Legend Columns </label>
                <input
                    name="gutter"
                    type="number"
                    value={props.graph_inputs.gutter}
                    onChange={(e) => props.graph_input_collector({ gutter: e.target.value })}
                />
                <label htmlFor="row_gutter"> Space Between Legend Rows </label>
                <input
                    name="row_gutter"
                    type="number"
                    value={props.graph_inputs.row_gutter}
                    onChange={(e) => props.graph_input_collector({ row_gutter: e.target.value })}
                />
                <label htmlFor="legend_width"> Legend Width (As %) </label>
                <input
                    name="legend_width"
                    type="number"
                    value={props.graph_inputs.legend_width}
                    onChange={(e) => props.graph_input_collector({ legend_width: e.target.value })}
                />
                <label htmlFor="legend_items_per_row"> Legend Items Per Row </label>
                <input
                    name="legend_items_per_row"
                    type="number"
                    value={props.graph_inputs.legend_items_per_row}
                    onChange={(e) => props.graph_input_collector({ legend_items_per_row: e.target.value })}
                />
                <label htmlFor="legend_center_title"> Center Legend Title </label>
                <select
                    name="legend_center_title"
                    value={props.graph_inputs.legend_center_title}
                    onChange={(e) => props.graph_input_collector({ legend_center_title: e.target.value })}
                >
                    <option value={null}></option>
                    <option value={true}>Center</option>
                    <option value={false}>Don't Center</option>
                </select>
                <label htmlFor="legend_orientation"> Legend legend_Orientation </label>
                <select
                    name="legend_orientation"
                    value={props.graph_inputs.legend_orientation}
                    onChange={(e) => props.graph_input_collector({ legend_orientation: e.target.value })}
                >
                    <option value={null}></option>
                    <option value={'horizontal'}>Horizontal</option>
                    <option value={'vertical'}>Vertical</option>
                </select>
            </div>
            <br/>
        </>
    )
}

export default VictoryStandardLegendInput;