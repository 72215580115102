import React, { Component, useEffect, useState } from 'react';
import server_configs from '../../server_configs';
import { withRouter } from 'react-router';


import '../../containers/blogger/AnalysisBuilder.css';
import Folder from "@mui/icons-material/Folder";
import FolderOpen from "@mui/icons-material/FolderOpen";

import TreeItem from '@mui/lab/TreeItem';
import TreeView from '@mui/lab/TreeView';
import Close from '@mui/icons-material/Close';

import GroupedFilter from '../inputs/rawelements/filtercomponents/GroupedFilters';
import StandardDimMetInput from '../inputs/rawelements/dimmetriccomponents/StandardDimMetInput.js'

const AnalysisFromTemplateBuilderEmbedded = (props) => {
    const [allTemplates, setAllTemplates] = useState({})
    const [currentTemplate, setCurrentTemplate] = useState({})

    const AnalysisFromTemplateCloser = () => {
        props.analysis_from_template_closer()
    }

    const componentRouter = (component_name) => {
        const downstream_props = {}
        downstream_props['component_inputs'] = currentTemplate.component_inputs
        downstream_props['input_name'] = component_name
        downstream_props['filter_type'] = 'nested_or_join'
        downstream_props['query_inputs'] = currentTemplate.query_inputs
        downstream_props['input_data_collector'] = props.inputDataCollector
        downstream_props['dataset'] = currentTemplate.selected_dataset
        if (component_name == 'FILTER') {
            return (<>{component_name}<br/><GroupedFilter
                {...downstream_props} /> </>)
        } else if (['X_DIMENSION', 'LABEL', '2ND_DIMENSION'].includes(component_name)) {
            return (<>{component_name}<br/>
                <StandardDimMetInput
                    {...downstream_props}
                    component_type="dimension">
                </StandardDimMetInput> </>)
        } else if (['Y_DIMENSION', 'RADIUS'].includes(component_name)) {
            return (<>{component_name}<br/>
                <StandardDimMetInput
                    {...downstream_props}
                    component_type="metric">
                </StandardDimMetInput> </>)
        }
    }

    const get_all_templates = () => {

        let method = "GET";

        const request_content = {
            method: method,
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            }
        };

        let request_url = `${server_configs['api_domain']}/data_blogging/get_all_templates/`;

        fetch(request_url, request_content).then(response => {
            response.json().then(templates => { setAllTemplates(templates)})
        });
    }

    useEffect(() => {
        get_all_templates();
    }, [])

    const get_template = (t_id) => {
        let method = "GET";

        const request_content = {
            method: method,
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            }
        };

        let request_url = `${server_configs['api_domain']}/data_blogging/get_template/?template_id=${t_id}`;

        fetch(request_url, request_content).then(response => {
            response.json().then(templates => { 
                props.simple_choice_update("selected_dataset",templates['selected_dataset'])
                props.simple_choice_update("selected_analysis",templates['selected_analysis'])
                props.graph_setter(templates['selected_graph'])
                props.bruteForceCollector(templates['query_inputs'], templates['component_inputs']) //this is wrong, this needs to loop through the component inputs and then pass each one into the collector
                props.graphInputCollector(templates['graph_inputs'])
                props.text_generation_input_collector(templates['text_generation_data'])
                setCurrentTemplate(templates)
            })
        });
    }

        return (
            <>
            {Object.keys(allTemplates).length > 0 ? <div style={{ display: "flex", flexDirection: "row", height:"100%", width: "100%" }}>
            <div style={{ display: "flex", flexDirection: "column", width: "80%", height: "100%" }}>
                <div style={{ width:"100%", alignItems: "stretch", display: "flex", flexDirection: "row", height: "80%", paddingLeft: "10px", paddingTop:"20px"}}>
                    <div style={{ flexDirection: "column", width: "40%", alignItems: "start", padding: "10px", borderRadius: "5px", borderColor: "rgb(226, 245, 253)", borderStyle: "solid", borderWidth: "2px", padding: "10px" }}>
                        <div style={{ textAlign: "left", height:"100%", width: "80%" }}>
                        <h3>Template Browser</h3>
                            <TreeView
                                aria-label="template browser"
                                defaultCollapseIcon={<Folder />}
                                defaultExpandIcon={<FolderOpen />}
                                sx={{ padding: 2, height: 240, flexGrow: 1, maxWidth: "100%", overflowY: 'auto', alignItems: "start", justifyContent: "start" }}>
                                {Object.entries(allTemplates).length > 0 ? Object.keys(allTemplates).map((x, idx) => {
                                    return (<TreeItem label={x} nodeId={"_"+idx}>
                                        {Object.keys(allTemplates[x]).map((y, idy) => {
                                            return (<TreeItem label={y} nodeId={idx+"_"+idy}>
                                                {allTemplates[x][y].map((z, idz) => {
                                                    return (<TreeItem 
                                                                label={z['name']} 
                                                                nodeId={idx+"_"+idy+"_"+idz} 
                                                                ContentProps={{template_id:z['id']['$oid']}}
                                                                onClick={()=>get_template(z['id']['$oid'])}/>
                                                            )
                                                })}
                                            </TreeItem>)
                                        })}
                                    </TreeItem>)
                                }) : <></>}
                            </TreeView>
                        </div>
                    </div>
                    <div style={{width:"20px"}}/>
                    <div style={{ display: "flex", flexDirection: "column", width: "40%", alignItems:"start", overflow:"scroll", borderColor: "rgb(226, 245, 253)", borderStyle: "solid", borderWidth: "2px", padding: "10px"}}>
                        <h3>Configure Analysis</h3>
                        <br/>
                        {Object.keys(currentTemplate).length > 0 ? currentTemplate['configurable_component_inputs'].map(x => {return componentRouter(x, {[x]: currentTemplate.component_inputs[x]})}) : <></>}
                        {Object.keys(currentTemplate).length > 0 ? currentTemplate['configurable_graph_inputs'].map(x => {return componentRouter(x, {[x]: currentTemplate.graph_inputs[x]})}) : <></>}
                    </div>
                </div>
            </div>
        </div> : <></>}
        <div style={{ height:"20px", width:"20px", display: "flex", flexFlow: "column", alignItems: "end" }} onClick={AnalysisFromTemplateCloser}>
                <Close />
        </div>
        </>)
}

export default withRouter(AnalysisFromTemplateBuilderEmbedded);
