import React from 'react'

const StandardSelector = (
    props, 
    on_change_event, 
    selection_dict, 
    expression_type, 
    default_selector_text
    ) => {
    return (
        <select 
            onChange={(e) => {on_change_event(e, expression_type)}} 
            value={props.component_inputs[props.input_name][expression_type]}>
            <option 
                value="" >
                {default_selector_text}
            </option>
            {Object.keys(selection_dict).map((v) => {
                return <option value={v} key={v}>{selection_dict[v]}</option>
            })}
        </select>
    )
}

export default StandardSelector