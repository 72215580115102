import GenericKVSelector from '../../filtercomponents/GenericKVSelector';
import selector_data from './selector_data';

const filtersMap = (props, on_change_event) => {
    return {
        run_gap: { operators: ["equals", "notequals"], label: 'Run Gap', spark_left_operand: () => 'run_gap', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['run_gap']) },
        run_location: { operators: ["equals", "notequals"], label: 'Run Location', spark_left_operand: () => 'run_location', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['run_location']) },
        run_location_detailed: { operators: ["like", "notlike"], label: 'Run Location Detailed', spark_left_operand: () => 'CONCAT(run_location, " ",run_gap)', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['run_location_detailed']) },
        qb_starting_position: { operators: ["equals", "notequals"], label: 'QB Starting Position', spark_left_operand: () => 'IF(shotgun=1,"shotgun","under center")', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['qb_starting_position']) },
        offense_team: { operators: ["equals", "notequals"], label: 'Offense Team', spark_left_operand: () => 'posteam', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['offense_team']) },
        offense_team_home_away: { operators: ["equals", "notequals"], label: 'Offense Team Home/Away', spark_left_operand: () => 'posteam_type', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['offense_team_home_away']) },
        defense_team: { operators: ["equals", "notequals"], label: 'Defense Team', spark_left_operand: () => 'defteam', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['defense_team']) },
        quarter: { operators: ["equals", "notequals"], label: 'Quarter', spark_left_operand: () => 'qtr', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['quarter']) },
        down: { operators: ["equals", "notequals"], label: 'Down', spark_left_operand: () => 'down', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['down']) },
        game_half: { operators: ["equals", "notequals"], label: 'Game Half', spark_left_operand: () => 'game_half', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['game_half']) },
        play_type: { operators: ["equals", "notequals"], label: 'Play Type', spark_left_operand: () => 'play_type', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['play_type']) },
        huddle_or_no_huddle: { operators: ["equals", "notequals"], label: 'Huddle Or No Huddle', spark_left_operand: () => 'if(no_hudle=1,"No Huddle", "Huddle")', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['huddle_or_no_huddle']) },
        qb_dropback: { operators: ["equals", "notequals"], label: 'QB Dropback', spark_left_operand: () => 'qb_dropback', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['qb_dropback']) },
        qb_kneel: { operators: ["equals", "notequals"], label: 'QB Kneel', spark_left_operand: () => 'qb_kneel', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['qb_kneel']) },
        spike: { operators: ["equals", "notequals"], label: 'Spike', spark_left_operand: () => 'qb_spike', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['spike']) },
        scramble_play: { operators: ["equals", "notequals"], label: 'Scramble Play', spark_left_operand: () => 'qb_scramble', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['scramble_play']) },
        pass_length_category: { operators: ["equals", "notequals"], label: 'Pass Length Category', spark_left_operand: () => 'pass_length', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['pass_length_category']) },
        pass_location_category: { operators: ["equals", "notequals"], label: 'Pass Location Category', spark_left_operand: () => 'pass_location', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['pass_location_category']) },
        pass_location_detailed: { operators: ["like", "notlike"], label: 'Pass Location Detailed', spark_left_operand: () => 'CONCAT(pass_length, " ",pass_location)', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['pass_location_detailed']) },
        stadium_roof_type: { operators: ["equals", "notequals"], label: 'Stadium Roof Type', spark_left_operand: () => 'roof', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['stadium_roof_type']) },
        field_surface_type: { operators: ["equals", "notequals"], label: 'Field Surface Type', spark_left_operand: () => 'trim(surface)', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['field_surface_type']) },
        home_team_coach: { operators: ["equals", "notequals"], label: 'Home Team Coach', spark_left_operand: () => 'home_coach', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['home_team_coach']) },
        away_team_coach: { operators: ["equals", "notequals"], label: 'Away Team Coach', spark_left_operand: () => 'away_coach', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['away_team_coach']) },
        passer_name: { operators: ["equals", "notequals"], label: 'Passer Name', spark_left_operand: () => 'passer', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['passer_name']) },
        rusher_name: { operators: ["equals", "notequals"], label: 'Rusher Name', spark_left_operand: () => 'rusher', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['rusher_name']) },
        receiver_name: { operators: ["equals", "notequals"], label: 'Receiver Name', spark_left_operand: () => 'receiver', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['receiver_name']) },
        penalty_type: { operators: ["equals", "notequals"], label: 'Penalty Type', spark_left_operand: () => 'penalty_type', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['penalty_type']) },
        penalty_team: { operators: ["equals", "notequals"], label: 'Penalty Team', spark_left_operand: () => 'penalty_team', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['penalty_team']) },
        penalty_player: { operators: ["equals", "notequals"], label: 'Penalty Player', spark_left_operand: () => 'penalty_player_named', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['penalty_player']) },
        sacker_name: { operators: ["equals", "notequals"], label: 'Sacker Name', spark_left_operand: () => 'sack_player_named', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['sacker_name']) },
        partial_sackers: { operators: ["like", "notlike"], label: 'Partial Sackers', spark_left_operand: () => 'CONCAT(half_sack_1_player_name, " ",half_sack_2_player_name)', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['partial_sackers']) },
        fumble_recovery_teams: { operators: ["like", "notlike"], label: 'Fumble Recovery Teams', spark_left_operand: () => 'CONCAT(fumble_recovery_1_team, " ",fumble_recovery_2_team)', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['fumble_recovery_teams']) },
        fumble_recovery_players: { operators: ["like", "notlike"], label: 'Fumble Recovery Players', spark_left_operand: () => 'CONCAT(fumble_recovery_1_player_name, " ",fumble_recovery_2_player_name)', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['fumble_recovery_players']) },
        fumble_teams: { operators: ["like", "notlike"], label: 'Fumble Teams', spark_left_operand: () => 'CONCAT(fumbled_1_team, " ",fumbled_2_team)', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['fumble_teams']) },
        fumble_players: { operators: ["like", "notlike"], label: 'Fumble Players', spark_left_operand: () => 'CONCAT(fumbled_1_player_name, " ",fumbled_2_player_name)', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['fumble_players']) },
        passes_defensed_teams: { operators: ["like", "notlike"], label: 'Passes Defensed Teams', spark_left_operand: () => 'CONCAT(pass_defense_1_player_name, " ",pass_defense_2_player_name)', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['passes_defensed_teams']) },
        assisted_tackle_players: { operators: ["like", "notlike"], label: 'Assisted Tackle Players', spark_left_operand: () => 'CONCAT(tackle_with_assist_1_player_name, " ",tackle_with_assist_2_player_name)', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['assisted_tackle_players']) },
        assisted_tackle_teams: { operators: ["like", "notlike"], label: 'Assisted Tackle Teams', spark_left_operand: () => 'CONCAT(tackle_with_assist_1_team, " ",tackle_with_assist_2_team)', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['assisted_tackle_teams']) },
        solo_tackle_team: { operators: ["like", "notlike"], label: 'Solo Tackle Team', spark_left_operand: () => 'CONCAT(solo_tackle_1_team, " ",solo_tackle_2_team)', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['solo_tackle_team']) },
        solo_tackle_player: { operators: ["like", "notlike"], label: 'Solo Tackle Player', spark_left_operand: () => 'CONCAT(solo_tackle_1_player_name, " ",solo_tackle_2_player_name)', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['solo_tackle_player']) },
        interception_name: { operators: ["equals", "notequals"], label: 'Interception Name', spark_left_operand: () => 'interception_player_name', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['interception_name']) },
        punt_returner_name: { operators: ["equals", "notequals"], label: 'Punt Returner Name', spark_left_operand: () => 'punt_returner_player_name', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['punt_returner_name']) },
        kickoff_player_name: { operators: ["equals", "notequals"], label: 'Kickoff Player Name', spark_left_operand: () => 'kickoff_returner_player_name', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['kickoff_player_name']) },
        punter_name: { operators: ["equals", "notequals"], label: 'Punter Name', spark_left_operand: () => 'punter_player_name', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['punter_name']) },
        fg_kicker_name: { operators: ["equals", "notequals"], label: 'FG Kicker Name', spark_left_operand: () => 'kicker_player_name', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['fg_kicker_name']) },
        kickoff_recovery_player_name: { operators: ["equals", "notequals"], label: 'Kickoff Recovery Player Name', spark_left_operand: () => 'own_kickoff_recovery_player_name', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['kickoff_recovery_player_name']) },
        blocked_kick_player_name: { operators: ["equals", "notequals"], label: 'Blocked Kick Player Name', spark_left_operand: () => 'blocked_player_named', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['blocked_kick_player_name']) },
        tackle_for_loss: { operators: ["like", "notlike"], label: 'Tackle For Loss', spark_left_operand: () => 'CONCAT(tackle_for_loss_1_player_name, " ", tackle_for_loss_2_player_name)', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['tackle_for_loss']) },
        qb_hit: { operators: ["like", "notlike"], label: 'QB Hit', spark_left_operand: () => 'CONCAT(qb_hit_1_player_name, " ", qb_hit_2_player_name)', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['qb_hit']) },
        forced_fumple_player_name: { operators: ["like", "notlike"], label: 'Forced Fumple Player Name', spark_left_operand: () => 'CONCAT(forced_fumble_player_1_player_name, " ", forced_fumble_player_2_team)', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['forced_fumple_player_name']) },
        forced_fumble_team: { operators: ["like", "notlike"], label: 'Forced Fumble Team', spark_left_operand: () => 'CONCAT(forced_fumble_player_1_team, " ", forced_fumble_player_2_team)', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['forced_fumble_team']) },
        yards_to_first_down: { operators: ["equals", "notequals", "lt", "lte", "gt", "gte"], label: 'Yards To First Down', spark_left_operand: () => "ydstogo", right_operand: (curr_value, on_change_event) => { return (<input type='number' onChange={(e) => { on_change_event(e, 'right_operand') }} value={curr_value} />) } },
        date: { operators: ["equals", "notequals", "lt", "lte", "gt", "gte"], label: 'Date', spark_left_operand: () => "game_dated", right_operand: (curr_value, on_change_event) => { return (<input type='date' onChange={(e) => { on_change_event(e, 'right_operand') }} value={curr_value} />) } },
        drive_number: { operators: ["equals", "notequals", "lt", "lte", "gt", "gte"], label: 'Drive Number', spark_left_operand: () => "drive", right_operand: (curr_value, on_change_event) => { return (<input type='number' onChange={(e) => { on_change_event(e, 'right_operand') }} value={curr_value} />) } },
        yards_to_goal: { operators: ["equals", "notequals", "lt", "lte", "gt", "gte"], label: 'Yards To Goal', spark_left_operand: () => "yardline_100", right_operand: (curr_value, on_change_event) => { return (<input type='number' onChange={(e) => { on_change_event(e, 'right_operand') }} value={curr_value} />) } },
        score_differential: { operators: ["equals", "notequals", "lt", "lte", "gt", "gte"], label: 'Score Differential', spark_left_operand: () => "score_differential", right_operand: (curr_value, on_change_event) => { return (<input type='number' onChange={(e) => { on_change_event(e, 'right_operand') }} value={curr_value} />) } }
    }
}
export { filtersMap };