import React from 'react';
import {dimensionsMap as nbaDimensionsMap} from '../data/nba/dimensionsMap';
import {metricsMap as nbaMetricsMap} from '../data/nba/metricsMap';
import {dimensionsMap as marketsDimensionsMap} from '../data/markets/dimensionsMap';
import {metricsMap as marketsMetricsMap} from '../data/markets/metricsMap';
import {dimensionsMap as worldBankDimensionsMap} from '../data/world_bank/dimensionsMap';
import {metricsMap as worldBankMetricsMap} from '../data/world_bank/metricsMap';
import { metricsMap as bLaborStatsMetricsMap } from '../data/b_labor_stats/metricsMap';
import { dimensionsMap as bLaborStatsDimensionsMap } from '../data/b_labor_stats/dimensionsMap';
import { metricsMap as fredStatsMetricsMap } from '../data/fred/metricsMap';
import { dimensionsMap as fredStatsDimensionsMap } from '../data/fred/dimensionsMap';
import { metricsMap as nflStatsMetricsMap } from '../data/nfl/metricsMap';
import { dimensionsMap as nflStatsDimensionsMap } from '../data/nfl/dimensionsMap';
import { metricsMap as fbiCrimeMetricsMap } from '../data/fbi_crime/metricsMap';
import { dimensionsMap as fbiCrimeDimensionsMap } from '../data/fbi_crime/dimensionsMap';
import { metricsMap as googleTrendsMetricsMap } from '../data/google_trends/metricsMap';
import { dimensionsMap as googleTrendsDimensionsMap } from '../data/google_trends/dimensionsMap';

const all_dims_metrics = {
    'nba':  {
        'dimensionsMap': nbaDimensionsMap,
        'metricsMap': nbaMetricsMap
    },
    'nfl':  {
        'dimensionsMap': nflStatsDimensionsMap,
        'metricsMap': nflStatsMetricsMap
    },
    'markets': {
        'dimensionsMap': marketsDimensionsMap,
        'metricsMap': marketsMetricsMap
    },
    'world_bank': {
        'dimensionsMap': worldBankDimensionsMap,
        'metricsMap': worldBankMetricsMap
    },
    'b_labor_stats': {
        'dimensionsMap': bLaborStatsDimensionsMap,
        'metricsMap': bLaborStatsMetricsMap        
    },
    'fred': {
        'dimensionsMap': fredStatsDimensionsMap,
        'metricsMap': fredStatsMetricsMap
    },
    'fbi_crime': {
        'dimensionsMap': fbiCrimeDimensionsMap,
        'metricsMap': fbiCrimeMetricsMap
    },
    'google_trends': {
        'dimensionsMap': googleTrendsDimensionsMap,
        'metricsMap': googleTrendsMetricsMap
    }
}


const StandardDimMetInput = (props) => {
    const dim_metric = all_dims_metrics[props.dataset]
    const standard_dimensions = props.component_type === 'metric' ? dim_metric.metricsMap() : dim_metric.dimensionsMap();

    const listen_for_data = (dim_name, e) => {
        const state_component_json = { [`${props.input_name}_secondary`]: e.target.value }
        const query_input_json = {
            input_name: props.input_name,
            data: dim_name.spark(e.target.value),
            type: "unquoted_text_input"
        }
        props.input_data_collector(query_input_json, state_component_json);
    }

    const dimension_data_collector = (e) => {
        const dimension = e.target.value;
        const state_component_json = { [props.input_name]: dimension }
        const query_input_json = {
            input_name: props.input_name,
            data: standard_dimensions[dimension].spark.length == 0 ? standard_dimensions[dimension].spark() : '',
            type: "unquoted_text_input"
        }
        props.input_data_collector(query_input_json, state_component_json, `${props.input_name}_secondary`);
    }
    const value_function = standard_dimensions[props.component_inputs[props.input_name]] ? standard_dimensions[props.component_inputs[props.input_name]].spark : () => { }
    return (
        <div>
            <select
                onChange={(e) => { dimension_data_collector(e) }}
                value={props.component_inputs[props.input_name]}>
                <option
                    value="" >
                    {""}
                </option>
                {Object.keys(standard_dimensions).map(dim => {
                    return (<option
                        value={dim}
                        key={dim}>
                        {standard_dimensions[dim].label}
                    </option>);
                }
                )}
            </select>
            {value_function.length < 1 ? <div></div> :
                standard_dimensions[props.component_inputs[props.input_name]].input_type == "selector" ? 
                <select onChange={(e) => listen_for_data(standard_dimensions[props.component_inputs[props.input_name]], e)} value={props.component_inputs[`${props.input_name}_secondary`]}>
                    <option> Choose One </option>
                    {standard_dimensions[props.component_inputs[props.input_name]].inputs.map( x => <option value={x.value} key={x.value+Math.random()}>{x.display_text}</option>)}
                </select> : 
                <input
                    onChange={(e) => listen_for_data(standard_dimensions[props.component_inputs[props.input_name]], e)}
                    value={props.component_inputs[`${props.input_name}_secondary`]}>
                </input>
            }
        </div>
    );
};

export default StandardDimMetInput;