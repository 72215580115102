import React from 'react';
import './components/table.css'
import parse from 'html-react-parser';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.core.css';


const TextChunk = (props) => {
    return (
        <div style={{textAlign: 'left', fontFamily: "JoanRegular"}}>
            <div className="ql-editor">
                {parse(String(props.graph_inputs.textchunk))}
            </div>
        </div>
    )
}

export default TextChunk;