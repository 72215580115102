import  React  from 'react';
import GraphRouter from '../graphs/GraphRouter';

const ArticleElementRouter = (props) => {
    const analysis = {...props};
    const min_height_width = Math.min(window.innerHeight, window.innerWidth)
    const which_one = window.innerWidth < window.innerHeight ? 'width' : 'height'
    analysis['min_height_width'] = {value: min_height_width, which_one:which_one}
    
    const routed = () => {if(typeof analysis == 'string') {
        return <>{analysis}</>
    } else {
        analysis['chooseGraph'] = () => analysis['selected_graph']
        analysis['display'] = true;
        return <GraphRouter container_style={{pointerEvents: "auto", userSelect: "auto", touchAction: "auto"}} {...analysis} />
    }
}
    return (
        <>
            { routed() }
        </>
    )
}

export default ArticleElementRouter;
