import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {withRouter} from "react-router";

const modules  = {
    toolbar: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script:  "sub" }, { script:  "super" }],
        ["blockquote", "code-block"],
        [{ list:  "ordered" }, { list:  "bullet" }],
        [{ indent:  "-1" }, { indent:  "+1" }, { align: [] }],
        ["link"],
        ["clean"],
    ],
};

function ArticleEditInput(props) {
    return <ReactQuill style={{height:"250px"}} value={props.value} name={props.name} modules={modules} onChange={props.onChange} theme="snow" placeholder="Content goes here..."/>;
}

export default withRouter(ArticleEditInput);