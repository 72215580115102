import  React  from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';


const CompletionBar = (props) => {
    const analysis_length = props.analysis_length
    const current_position = props.current_position
    const vw = Math.round(49/props.analysis_length)+"vw"
    const mobile_v_desktop = useMediaQuery('(min-width:600px)');
    const trans = !mobile_v_desktop ? `translate(calc(25vw))` : `translate(calc(5vw))`

    return (
        <div style={{ width:"50vw", display:"inline-block", transform:trans, verticalAlign:"top"}}>
            {Array(analysis_length).fill(1).map((j, index) => 
            <div 
              key={index} 
              opacity={0.7}
              style={{
                  width:vw,
                    height:".5vh", 
                    background: index==current_position ? "grey" : "lightgrey",
                    float:"left",
                    opacity:0.6,
                    verticalAlign:"center"
                }}
              />
            )}
        </div>
    )
}

export default CompletionBar;