import React from 'react';
import ArticleEditInput from "./ArticleEditInput";

const TextChunkInput = (props) => {
  return (
    <>
        <label htmlFor="textchunk"> Section Text</label>
        <div>
            <ArticleEditInput
                name="textchunk"
                value={props.graph_inputs['textchunk']}
                onChange={(e) => {
                    props.graph_input_collector({textchunk: e});
                }}/>
        </div>
    </>
  )
};

export default TextChunkInput;