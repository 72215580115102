import React from 'react'
import GroupedFilter from '../rawelements/filtercomponents/GroupedFilters';

const Chord = (props) => {
  return (
    <>
      <div>Filter</div>
      <GroupedFilter
        {...props}
        input_name="FILTER"
        filter_type="nested_or_join">
      </GroupedFilter>
    </>
  )
}

export default Chord