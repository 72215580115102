import React from 'react';
import ArticleEditInput from "./ArticleEditInput";
import VictoryStandardGraphInput from './VictoryStandardGraphInput';

const CandlestickInput = (props) => {
    return (
        <>
            <label htmlFor="graphtitle"> Graph Title</label>
                <input
                    name="graphtitle"
                    value={props.graph_inputs.graphtitle}
                    onChange={(e) => props.graph_input_collector({graphtitle:e.target.value})} />
                    <br/>
            <label htmlFor="xaxislabel"> xAxis Name</label>
            <input
                name="xaxislabel"
                value={props.graph_inputs.xaxis}
                onChange={(e) => props.graph_input_collector({xaxis:e.target.value})} />
                <br/>
            <label htmlFor="yaxislabel"> yAxis Name</label>
            <input
                name="yaxislabel"
                value={props.graph_inputs.yaxis}
                onChange={(e) => props.graph_input_collector({yaxis:e.target.value})} />
            <br/>
            <VictoryStandardGraphInput {...props}/>
            <br/>
            <label htmlFor="textchunk_top"> text above graph</label>
            <div>
                <ArticleEditInput
                    name="textchunk_top"
                    value={props.graph_inputs['textchunk_top']}
                    onChange={(e) => {
                        props.graph_input_collector({textchunk_top: e});
                    }}/>
            </div>
            <br/><br/><br/><br/><br/>
            <label htmlFor="textchunk_bottom"> text below graph </label>
            <div>
                <ArticleEditInput
                    name="textchunk_bottom"
                    value={props.graph_inputs['textchunk_bottom']}
                    onChange={(e) => {
                        props.graph_input_collector({textchunk_bottom: e});
                    }}/>
            </div>
        </>
    )
}

export default CandlestickInput;