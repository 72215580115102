import React, { useEffect } from 'react';
import server_configs from '../../../server_configs';
import { useState } from 'react';
import ArticleEditInput from "./ArticleEditInput";

const ImageGraphInput = (props) => {
    const [images, setImages] = useState([])

    const GetAllImages = () => useEffect(() => {
        fetch(
          `${server_configs['api_domain']}/data_blogging/get_all_images/`,
            {
              method: "GET",
              mode: "cors",
              headers: {
                "Content-Type": "application/json",
              }
            })
              .then(response => {
                return response.json()
                  .then(json => (setImages(json)))})
              }, [])
      GetAllImages()

      return (
        <>
            <label htmlFor="imagegraph"> Image </label>
            <select
                name="imagegraph"
                value={props.graph_inputs['imagegraph']}
                onChange={(e) => props.graph_input_collector({imagegraph:e.target.value})}>
                    <option></option>
                    {images.map(x => <option key={x.file_id} value={x.file_id}> {x.file_name}</option>)}
            </select>
            <label htmlFor="textchunk_top"> text above graph</label>
            <div>
                <ArticleEditInput
                    name="textchunk_top"
                    value={props.graph_inputs['textchunk_top']}
                    onChange={(e) => {
                        props.graph_input_collector({textchunk_top: e});
                    }}/>
            </div>
        </>
    )
}

export default ImageGraphInput;