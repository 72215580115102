import React from 'react';

export default class AdComponent extends React.Component {
  componentDidMount () {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

    render () {
        return (
            <ins className='adsbygoogle'
            style={{ display: 'block', width:'300px', height:'250px' }}
            data-ad-client='ca-pub-8588307498896448'
            data-ad-slot='9933504538'
            data-ad-format='auto' />
        );
    }
}