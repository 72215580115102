import React, { Component, useEffect, useState } from 'react';
import server_configs from '../../server_configs';
import { Link } from "react-router-dom";
import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import BloggerNavBar from '../../components/blogger/BloggerNavBar';
import TagManager from 'react-gtm-module'

import './AnalysisListView.css'
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../../utils/Loading";

const Review = () => {
    const [articles, setArticles] = useState([]);
    const [approvalStatus, setApprovalStatus] = useState(null);
    const [rejectionReason, setRejectionReason] = useState(null);
    const [reviewingAnalysis, setReviewingAnalysis] = useState(null);

    const {
        user,
        isAuthenticated
    } = useAuth0();

    const getArticles = (user_name) => {
        fetch(`${server_configs['api_domain']}/data_blogging/get_review_analyses?user_name=` + user_name, {
            method: "GET",
            mode: "cors",
            headers: { "Content-Type": "application/json" }
        })
            .then(response => {
                response.json()
                    .then(json => {
                        setArticles(json)
                    });
            })
    }

    useEffect(() => {
        getArticles(user.name);
        TagManager.dataLayer({
            dataLayer: {
                event: 'PageView',
                pagePath: 'review-list'
            },
        });
    }, []);

    const ApproveReject = (analysis_id) => {
        const method = "POST";

        const request_content = {
            method: method,
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ approval_status: approvalStatus, rejection_reason: rejectionReason, analysis_id: analysis_id, reviewer: user.name })
        };

        let request_url = `${server_configs['api_domain']}/data_blogging/approve_reject_analysis/`;

        fetch(request_url, request_content)
            .then(response => {
                getArticles(user.name);
            })
    }

    const rejectionSelector = () => {
        return (
            <select onChange={e => setRejectionReason(e.target.value)} value={rejectionReason}>
                <option value={null}></option>
                <option value={"MISINFORMATION"}>Misinformation</option>
                <option value={"PORNOGRAPHIC"}>Pornographic</option>
                <option value={"RACISM"}>Racism</option>
                <option value={"SOLICITATION"}>Solicitation</option>
                <option value={"SPAM"}>Spam</option>
                <option value={"VIOLENCE"}>Violence</option>
            </select>
        )
    }

    if (isAuthenticated) {
        return (
            <div className="pagecontainerlist">
                <BloggerNavBar />
                <div className="lrcontainerlist">
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ fontSize: "20px" }}>Title</TableCell>
                                <TableCell style={{ fontSize: "20px" }}>Description</TableCell>
                                <TableCell style={{ fontSize: "20px" }}>Create Date</TableCell>
                                <TableCell style={{ fontSize: "20px" }}>Review</TableCell>
                                <TableCell style={{ fontSize: "20px" }}>Approve / Reject</TableCell>
                                {user.name == "Colin Usala" ?
                                    <TableCell style={{ fontSize: "20px" }}>Analysis Approval Status</TableCell>
                                    : <></>
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {articles.map(x =>
                                <TableRow>
                                    <TableCell style={{ fontSize: "15px" }}>
                                        {` ${x.analytics_metadata.title}`}
                                    </TableCell>
                                    <TableCell style={{ fontSize: "15px" }}>
                                        {` ${x.analytics_metadata.description}`}
                                    </TableCell>
                                    <TableCell style={{ fontSize: "15px" }}>
                                        {` ${x.analytics_metadata.create_date}`}
                                    </TableCell>
                                    <TableCell style={{ fontSize: "15px" }}>
                                        <Link to={`/analysis/${x.analysis_id.$oid}`}
                                            key={x.analysis_id.$oid}> View</ Link>
                                    </TableCell>
                                    <TableCell style={{ fontSize: "15px" }}>
                                        {reviewingAnalysis != x.analysis_id.$oid ? <button onClick={(e) => setReviewingAnalysis(e.target.value)} value={x.analysis_id.$oid}>
                                            Review
                                        </button> : <></>}
                                        <div hidden={reviewingAnalysis == x.analysis_id.$oid ? "" : true}>
                                            <select onChange={(e) => setApprovalStatus(e.target.value)} value={approvalStatus}>
                                                <option value={null}></option>
                                                <option value="approve">Approve</option>
                                                <option value="reject">Reject</option>
                                            </select>
                                            <br />
                                            {approvalStatus == 'reject' ? <><br />Rejection Reason<br />{rejectionSelector()}</> : <></>}
                                            <button disabled={approvalStatus == "approve" || (approvalStatus == "reject" && rejectionReason != null) ? false : true}
                                                className="metadatadiv"
                                                onClick={() => ApproveReject(x.analysis_id.$oid)}
                                                key={x.analysis_id.$oid}> Submit
                                            </button>
                                        </div>
                                    </TableCell>
                                    {user.name == "Colin Usala" ?
                                        <TableCell style={{ fontSize: "15px" }}>
                                            {` ${JSON.stringify(x.submissions, null, '\t')}`}
                                        </TableCell> : <></>}
                                </TableRow>)}
                        </TableBody>
                    </Table>
                </div>
            </div>
        )
    } else {
        return <p>You have to login first</p>
    }
}

export default withAuthenticationRequired(Review, {
    onRedirecting: () => <Loading />,
});