import React from 'react';
import TextChunk from './TextChunk';
import Imgix from "react-imgix";
import { Background } from 'react-imgix';


const ImageGraph = (props) => {
    const image_url = "https://driving-data-journalism.imgix.net/"+props.graph_inputs.imagegraph;
    console.log(image_url)
    return (
    <div>
        <TextChunk {...props} />
        {props.graph_inputs.imagegraph ? 
            <Background src={image_url} imgixParams={{ w: props.screen_width, h: props.screen_height }} name={props.graph_inputs.imagegraph}>
                    <div style={{height:props.screen_height*.8, width:props.screen_width}} >
                        <h3 style={{padding:"20px", color:"red"}}> stupid text</h3>
                    </div>
            </ Background>
            // <div
            // style={{ height:props.screen_height, width:props.screen_width, backgroundImage: `url(https://driving-data-journalism.imgix.net/${props.graph_inputs.imagegraph})` }}
            // ></div>
        //  <Imgix src={`https://driving-data-journalism.imgix.net/${props.graph_inputs.imagegraph}`} sizes="calc(10% - 10px)" imgixParams={{ ar: "16:9" }} /> 
         : <div />}
        <TextChunk {...props} />
    </div>
    )
}

export default ImageGraph;

