import React, { Component } from 'react';
import { withRouter } from 'react-router';
import TagManager from 'react-gtm-module'
import server_configs from '../../server_configs';
import ArticleElementRouter from '../../components/reader/ArticleElementRouter';
import Footer from '../../components/reader/Footer';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CompletionBar from '../../components/reader/CompletionBar';
import ReactGA from 'react-ga';

import './DataVizDaily.css'

class DataVizDaily extends Component {
    state = {
        article_data: null,
        current_element_col: 0,
        dataset: '',
        datasets_visible:false
    }

    getArticleData = () => {
        const { analysis_id } = this.props.match.params;
        let uri = `${server_configs['api_domain']}/data_blogging/get_analysis?daily_data_viz=true`
        if(this.state.article_data != null) {
            uri += `&ddv_analysis_id=${this.state.article_data._id.$oid}`
        }
        if(this.state.dataset != '') {
            uri += `&section_name=${this.state.dataset}`
        } 
        fetch(uri, {
            method: "GET",
            mode: "cors",
            headers: { "Content-Type": "application/json" }
        })
            .then(response => {
                response.json()
                    .then(json => {
                        this.setState((state, props) => ({ article_data: json, current_element_col: 0 })); 
                        document.getElementById('main_div').scrollTo(0,0);
                        TagManager.dataLayer({
                            dataLayer: {
                              event: 'PageView',
                              pagePath: 'data-viz-daily'
                            },
                          });
                    });
            })
    }

    componentDidMount = () => {
        this.getArticleData()
    }

    goToDivTop = (id_div) => {
        const myDiv = document.getElementById("main_div");
        myDiv.scrollTop = 0;
    }

    scrollToTop = () => {
        console.log(document.getElementById('main_div'))
        document.getElementById('main_div').scroll({top:0,behavior:'smooth'});
    };

    showBottomComponent = (analyses) => {
        const click_down = () => {
            this.scrollToTop()
            this.setState({ current_element_col: this.state.current_element_col + 1, 
                            lastTouchLocation: null, 
                            touchStartLocation: null
                        })
        }
        const click_up = () => {
            this.scrollToTop()
            this.setState({ current_element_col: this.state.current_element_col - 1, 
                            lastTouchLocation: null, 
                            touchStartLocation: null
                        })
        }
        return (<div
            style={{width:"40vw", display:"flex", alignItems:"center", flexDirection:"column", justifyContent: "flex-end"}}>
        {this.state.current_element_col > 0 ? 
                <div 
                    onClick={click_up}
                    className="nextvizbutton">
                    {<ArrowUpwardIcon />}
                </ div>
         : <></>}
         <div style={{height:"3px"}}></div>
        {this.state.current_element_col == 0 || this.state.current_element_col <= analyses.length-2 ? 
                <div 
                    onClick={click_down}
                    className="nextvizbutton">
                    <ArrowDownwardIcon />
                </ div>
            :   <div 
                    onClick={this.getArticleData}
                    className="nextvizbutton">
                        <svg className="nextvizbutton">
                            <text style={{fontFamily: "JoanRegular"}} x="25%" y="60%">Load Another Feed</text>
                        </svg>
                </ div>
        }
        </div>)
    } 

    choose_datasets = () => {
        const choose_datasets = (dataset) => {
            this.setState({dataset: dataset, datasets_visible: false}, () => this.getArticleData())
        }
        const showHideDatasets = () => {
            if(!this.state.datasets_visible){
                this.setState({datasets_visible: true })
            }
        }
        const datasetsTab = () => {
            const sections = ["World", "US", "Economy", "Markets", "NBA", "Climate", "Crime"]
            const datasets = ["World Bank", "Bureau Of Labor & Statistics", "Markets", "NBA"]
            return (
                <div
                style={{display: !this.state.datasets_visible ? "none": "flex",
                        flexDirection:"column",
                        backgroundColor: "#ECECEC",
                        width:"100%",
                        borderRadius: "25px",
                        alignItems:"center",
                        justifyItems:"center",
                        paddingTop:"10px"}}>
                {sections.map(x => <><div key={x} onClick={() => choose_datasets(x)}>{x}</div><br/></>)}
                </div>
            )
        }
        return (
            <div style={{height:"100%",
                width:"25%", 
                display:"flex",
                flexDirection:"row",
                alignItems:"center",
                justifyItems:"center",
                paddingRight:"5%",
                paddingTop: "10px",
                fontFamily: "JoanRegular"}}>
                <div 
                    onClick={showHideDatasets}
                    className="moardata"
                    style={{display: this.state.datasets_visible ? "none": "flex"}}>
                    Choose Dataset
                </ div>
                {datasetsTab()}
            </div>
        )
    }

    moarData = () => {
        return <div style={{height:"100%", 
                            width:"25%", 
                            display:"flex", 
                            flexDirection:"row", 
                            alignItems:"center", 
                            justifyItems:"center",
                            paddingRight:"5%",
                            fontFamily: "JoanRegular"}}><div 
        onClick={this.getArticleData}
        className="moardata">

    </ div>
    </div>
    }

    render() {
        const which_ui = window.innerWidth < window.innerHeight ? 'mobile' : 'desktop'
        const { article_data } = this.state;
        const analyses = article_data ? article_data.analytics_states : [];
        const description = article_data!==null ? article_data.analytics_metadata.description : ""
        const date = article_data!==null && article_data.analytics_metadata.create_date !== undefined? "Published " + article_data.analytics_metadata.create_date : ""

        return (
            <div style={{display:"flex", flexDirection:"column", alignItems:"center", alignContent:"stretch"}}>
                <div style={{width:"100%", display:"flex", flexDirection:"row", alignItems:"flex-start", justifyContent:"flex-start"}}>
                    <div style={{width:"75%", display:"flex", flexDirection:"column", alignItems:"flex-start", alignContent:"flex-start", paddingLeft:"5%"}}>
                        <h1 style={{textAlign:"left", fontFamily: "JoanRegular", marginBottom:"0em"}}>The Data Feed</h1>
                        <h4 style={{textAlign:"left", fontFamily: "JoanRegular", marginTop:"0.03em", marginBottom:"0.03em"}}>{description}</h4>
                        <h5 style={{textAlign:"left", fontFamily: "JoanRegular", marginTop:"0.01em", marginBottom:"0.01em"}}>{date}</h5>
                    </div>
                    {this.choose_datasets()}
                </div>
                <div
                    style={{minWidth: "400px",
                            maxWidth: "750px",
                            minHeight:"80vh", 
                            maxHeight:"70vh", 
                            display:"flex", 
                            flexDirection:"column", 
                            alignItems:"center",
                            paddingTop:"2%", 
                            overflow:"scroll"}}>
                    <ArticleElementRouter
                        {...analyses[this.state.current_element_col]} />
                    {this.showBottomComponent(analyses)}
                </div>
            </div>
        )
    }
}

export default withRouter(DataVizDaily);