import useMediaQuery from '@mui/material/useMediaQuery';
import BarChartIcon from '@mui/icons-material/BarChart';
import DescriptionIcon from '@mui/icons-material/Description';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router';
import DesktopFooter from "./DesktopFooter";


const BottomNavBar = (props) => {
    const mobile_v_desktop = useMediaQuery('(min-width:600px)');
    return <div>{!mobile_v_desktop ?
        <BottomNavigation style={{ color: "black", zIndex: 1000, height: "7vh", position: 'fixed', bottom: '0', width: "100vw", backgroundColor: "#ECECEC", root: { color: "#ECECEC" }, selected: { color: "blue" } }} >
            <BottomNavigationAction label="Articles" component={Link} to="/" value="home" icon={<DescriptionIcon fontSize={window.innerWidth > 600 ? "large" : "small"} />} />
            <BottomNavigationAction component={Link} to="/dataviz" icon={<BarChartIcon fontSize={window.innerWidth > 600 ? "large" : "small"} />} />
        </BottomNavigation>
        : <DesktopFooter/>}</div>
}

export default withRouter(BottomNavBar);