import React from 'react'
import GroupedFilter from '../rawelements/filtercomponents/GroupedFilters';
import StandardDimMetInput from '../rawelements/dimmetriccomponents/StandardDimMetInput';

const Candlestick = (props) => {
  return (
    <>
      <div>filter</div>
      <GroupedFilter
        {...props}
        input_name="FILTER"
        filter_type="nested_or_join">
      </GroupedFilter>
      <div>dimension</div>
      <StandardDimMetInput
        {...props}
        input_name="X_DIMENSION"
        component_type="dimension">
      </StandardDimMetInput>
      <div>metric</div>
      <StandardDimMetInput
        {...props}
        input_name="Y_DIMENSION"
        component_type="metric">
      </StandardDimMetInput>
    </>
  )
}

export default Candlestick