
const metricsMap = () => {
    return {
        stock_daily_open: {label: 'Stock Daily Open', spark: () => '1. open'},
        stock_daily_high: {label: 'Stock Daily High', spark: () => 'stock_daily_high'},
        stock_daily_low: {label: 'Stock Daily Low', spark: () => 'stock_daily_low'},
        stock_daily_close: {label: 'Stock Daily Close', spark: () => 'stock_daily_close'},
        stock_daily_adjusted_close: {label: 'Stock Daily Adjusted Close', spark: () => 'stock_daily_adjusted_close'},
        indexed_stock_daily_adjusted_close: {label: 'Indexed Stock Daily Adjusted Close', spark: () => 'indexed_stock_daily_adjusted_close'},
        stock_weekly_adjusted_close: {label: 'Stock Weekly Adjusted Close', spark: () => 'stock_weekly_adjusted_close'},
        indexed_stock_weekly_adjusted_close: {label: 'Indexed Stock Weekly Adjusted Close', spark: () => 'indexed_stock_weekly_adjusted_close'},
        stock_daily_volume: {label: 'Stock Daily Volume', spark: () => 'stock_daily_volume'},
        stock_weekly_volume: {label: 'Stock Weekly Volume', spark: () => 'stock_weekly_volume'},
        stock_daily_dividend_amount: {label: 'Stock Daily Dividend Amount', spark: () => 'stock_daily_dividend_amount'},
        stock_dailiy_split_coefficient: {label: 'Stock Dailiy Split Coefficient', spark: () => 'stock_dailiy_split_coefficient'},
        quarterly_gross_profit: {label: 'Quarterly Gross Profit', spark: () => 'quarterly_gross_profit'},
        quarterly_total_revenue: {label: 'Quarterly Total Revenue', spark: () => 'quarterly_total_revenue'},
        quarterly_cost_of_revenue: {label: 'Quarterly Cost of Revenue', spark: () => 'quarterly_cost_of_revenue'},
        quarterly_cost_of_goods_and_services_sold: {label: 'Quarterly Cost of Goods And Services Sold', spark: () => 'quarterly_cost_of_goods_and_services_sold'},
        quarterly_operating_income: {label: 'Quarterly Operating Income', spark: () => 'quarterly_operating_income'},
        quarterly_selling_general_and_administrative: {label: 'Quarterly Selling General & Administrative', spark: () => 'quarterly_selling_general_and_administrative'},
        quarterly_randd: {label: 'Quarterly R&D', spark: () => 'quarterly_randd'},
        quarterly_operating_expenses: {label: 'Quarterly Operating Expenses', spark: () => 'quarterly_operating_expenses'},
        quarterly_investment_income_net: {label: 'Quarterly Investment Income Net', spark: () => 'quarterly_investment_income_net'},
        quarterly_net_interest_income: {label: 'Quarterly Net Interest Income', spark: () => 'quarterly_net_interest_income'},
        quarterly_interest_income: {label: 'Quarterly Interest Income', spark: () => 'quarterly_interest_income'},
        quarterly_interest_expense: {label: 'Quarterly Interest Expense', spark: () => 'quarterly_interest_expense'},
        quarterly_noninterest_income: {label: 'Quarterly Non-Interest Income', spark: () => 'quarterly_noninterest_income'},
        quarterly_other_nonoperating_income: {label: 'Quarterly Other Non-Operating Income', spark: () => 'quarterly_other_nonoperating_income'},
        quarterly_depreciation: {label: 'Quarterly Depreciation', spark: () => 'quarterly_depreciation'},
        quarterly_depreciation_and_amortization: {label: 'Quarterly Depreciation & Amortization', spark: () => 'quarterly_depreciation_and_amortization'},
        quarterly_income_before_tax: {label: 'Quarterly Income Before Tax', spark: () => 'quarterly_income_before_tax'},
        quarterly_income_tax_expense: {label: 'Quarterly Income Tax Expense', spark: () => 'quarterly_income_tax_expense'},
        quarterly_interest_and_debt_expense: {label: 'Quarterly Interest & Debt Expense', spark: () => 'quarterly_interest_and_debt_expense'},
        quarterly_net_income_from_continuing_operations: {label: 'Quarterly Net Income From Continuing Operations', spark: () => 'quarterly_net_income_from_continuing_operations'},
        quarterly_comprehensive_income_net_of_tax: {label: 'Quarterly Comprehensive Income Net Of Tax', spark: () => 'quarterly_comprehensive_income_net_of_tax'},
        quarterly_ebit: {label: 'Quarterly EBIT', spark: () => 'quarterly_ebit'},
        quarterly_ebitda: {label: 'Quarterly EBITDA', spark: () => 'quarterly_ebitda'},
        quarterly_net_income: {label: 'Quarterly Net Income', spark: () => 'quarterly_net_income'},
        quarterly_total_assets: {label: 'Quarterly Total Assets', spark: () => 'quarterly_total_assets'},
        quarterly_total_current_assets: {label: 'Quarterly Total Current Assets', spark: () => 'quarterly_total_current_assets'},
        quarterly_cash_and_cash_equivalents_at_carrying_value: {label: 'Quarterly Cash & Cash Equivalents At Carrying Value', spark: () => 'quarterly_cash_and_cash_equivalents_at_carrying_value'},
        quarterly_cash_and_short_term_investments: {label: 'Quarterly Cash & Short Term Investments', spark: () => 'quarterly_cash_and_short_term_investments'},
        quarterly_inventory: {label: 'Quarterly Inventory', spark: () => 'quarterly_inventory'},
        quarterly_current_net_receivables: {label: 'Quarterly Current Net Receivables', spark: () => 'quarterly_current_net_receivables'},
        quarterly_total_noncurrent_assets: {label: 'Quarterly Total Non-Current Assets', spark: () => 'quarterly_total_noncurrent_assets'},
        quarterly_property_plant_equipment: {label: 'Quarterly Property Plant Equipment', spark: () => 'quarterly_property_plant_equipment'},
        quarterly_accumulated_depreciation_amortization_ppe: {label: 'Quarterly Accumulated Depreciation AMortization PPE', spark: () => 'quarterly_accumulated_depreciation_amortization_ppe'},
        quarterly_intangible_assets: {label: 'Quarterly Intangible Assets', spark: () => 'quarterly_intangible_assets'},
        quarterly_intangible_assets_excluding_goodwill: {label: 'Quarterly Intangible Assets Excluding Goodwill', spark: () => 'quarterly_intangible_assets_excluding_goodwill'},
        quarterly_goodwill: {label: 'Quarterly Goodwill', spark: () => 'quarterly_goodwill'},
        quarterly_investments: {label: 'Quarterly Investments', spark: () => 'quarterly_investments'},
        quarterly_long_term_investments: {label: 'Quarterly Long Term Investments', spark: () => 'quarterly_long_term_investments'},
        quarterly_short_term_investments: {label: 'Quarterly Short Term Investments', spark: () => 'quarterly_short_term_investments'},
        quarterly_other_current_assets: {label: 'Quarterly Other Current Assets', spark: () => 'quarterly_other_current_assets'},
        quarterly_other_noncurrent_assets: {label: 'Quarterly Other Non-Current Assets', spark: () => 'quarterly_other_noncurrent_assets'},
        quarterly_total_liabilities: {label: 'Quarterly Total Liabilities', spark: () => 'quarterly_total_liabilities'},
        quarterly_total_current_liabilities: {label: 'Quarterly Total Current Liabilities', spark: () => 'quarterly_total_current_liabilities'},
        quarterly_current_accounts_payable: {label: 'Quarterly Current Accounts Payable', spark: () => 'quarterly_current_accounts_payable'},
        quarterly_deferred_revenue: {label: 'Quarterly Deferred Revenue', spark: () => 'quarterly_deferred_revenue'},
        quarterly_current_debt: {label: 'Quarterly Current Debt', spark: () => 'quarterly_current_debt'},
        quarterly_short_term_debt: {label: 'Quarterly Short Term Debt', spark: () => 'quarterly_short_term_debt'},
        quarterly_total_noncurrent_liabilities: {label: 'Quarterly Total Non-Current Liabilities', spark: () => 'quarterly_total_noncurrent_liabilities'},
        quarterly_capital_lease_obligations: {label: 'Quarterly Capital Lease Obligations', spark: () => 'quarterly_capital_lease_obligations'},
        quarterly_long_term_debt: {label: 'Quarterly Long Term Debt', spark: () => 'quarterly_long_term_debt'},
        quarterly_current_long_term_debt: {label: 'Quarterly Current Long Term Debt', spark: () => 'quarterly_current_long_term_debt'},
        quarterly_long_term_debt_nonconcurrent: {label: 'Quarterly Long Term Debt Nonconcurrent', spark: () => 'quarterly_long_term_debt_nonconcurrent'},
        quarterly_short_long_term_debt_total: {label: 'Quarterly Short Long Term Debt Total', spark: () => 'quarterly_short_long_term_debt_total'},
        quarterly_other_current_liabilities: {label: 'Quarterly Other Current Liabilities', spark: () => 'quarterly_other_current_liabilities'},
        quarterly_other_noncurrent_liabilties: {label: 'Quarterly Other Non-Current Liabilties', spark: () => 'quarterly_other_noncurrent_liabilties'},
        quarterly_total_shareholder_equity: {label: 'Quarterly Total Shareholder Equity', spark: () => 'quarterly_total_shareholder_equity'},
        quarterly_treasury_stock: {label: 'Quarterly Treasury Stock', spark: () => 'quarterly_treasury_stock'},
        quarterly_retained_earnings: {label: 'Quarterly Retained Earnings', spark: () => 'quarterly_retained_earnings'},
        quarterly_common_stock: {label: 'Quarterly Common Stock', spark: () => 'quarterly_common_stock'},
        quarterly_common_stock_shares_outstanding: {label: 'Quarterly Common Stock Shares Outstanding', spark: () => 'quarterly_common_stock_shares_outstanding'},
        quarterly_operating_cash_flow: {label: 'Quarterly Operating Cash Flow', spark: () => 'quarterly_operating_cash_flow'},
        quarterly_payments_for_operating_activities: {label: 'Quarterly Payments For Operating Activities', spark: () => 'quarterly_payments_for_operating_activities'},
        quarterly_proceeds_from_operating_activities: {label: 'Quarterly Proceeds From Operating Activities', spark: () => 'quarterly_proceeds_from_operating_activities'},
        quarterly_change_in_operating_liabilities: {label: 'Quarterly Change In Operating Liabilities', spark: () => 'quarterly_change_in_operating_liabilities'},
        quarterly_change_in_operating_assets: {label: 'Quarterly Change In Operating Assets', spark: () => 'quarterly_change_in_operating_assets'},
        quarterly_depreciation_depletion_and_amortization: {label: 'Quarterly Depreciation Depletion And Amortization', spark: () => 'quarterly_depreciation_depletion_and_amortization'},
        quarterly_capital_expenditures: {label: 'Quarterly Capital Expenditures', spark: () => 'quarterly_capital_expenditures'},
        quarterly_change_in_receivables: {label: 'Quarterly Change In Receivables', spark: () => 'quarterly_change_in_receivables'},
        quarterly_change_in_inventory: {label: 'Quarterly Change In Inventory', spark: () => 'quarterly_change_in_inventory'},
        quarterly_profit_loss: {label: 'Quarterly Profit Loss', spark: () => 'quarterly_profit_loss'},
        quarterly_cash_flow_from_investment: {label: 'Quarterly Cash Flow From Investment', spark: () => 'quarterly_cash_flow_from_investment'},
        quarterly_cash_flow_from_financing: {label: 'Quarterly Cash Flow From Financing', spark: () => 'quarterly_cash_flow_from_financing'},
        quarterly_proceeds_from_repayments_of_short_term_debt: {label: 'Quarterly Proceeds From Repayments Of Short Term Debt', spark: () => 'quarterly_proceeds_from_repayments_of_short_term_debt'},
        quarterly_payments_for_repurchase_of_common_stock: {label: 'Quarterly Payments For Repurchase Of Common Stock', spark: () => 'quarterly_payments_for_repurchase_of_common_stock'},
        quarterly_payments_for_repurchase_of_equity: {label: 'Quarterly Payments For Repurchase Of Equity', spark: () => 'quarterly_payments_for_repurchase_of_equity'},
        quarterly_payments_for_repurchase_of_preferred_stock: {label: 'Quarterly Payments For Repurchase Of Preferred Stock', spark: () => 'quarterly_payments_for_repurchase_of_preferred_stock'},
        quarterly_dividend_payout: {label: 'Quarterly Dividend Payout', spark: () => 'quarterly_dividend_payout'},
        quarterly_dividend_payout_common_stock: {label: 'Quarterly Dividend Payout Common Stock', spark: () => 'quarterly_dividend_payout_common_stock'},
        quarterly_dividend_payout_preferred_stock: {label: 'Quarterly Dividend Payout Preferred Stock', spark: () => 'quarterly_dividend_payout_preferred_stock'},
        quarterly_proceeds_from_inssuance_of_long_term_debt_and_capital_securities_net: {label: 'Quarterly Proceeds From Inssuance Of Long Term Debt And Capital Securities Net', spark: () => 'quarterly_proceeds_from_inssuance_of_long_term_debt_and_capital_securities_net'},
        quarterly_proceeds_from_issuance_of_long_term_debt_and_capital_securities_net: {label: 'Quarterly Proceeds From Issuance Of Long Term Debt And Capital Securities Net', spark: () => 'quarterly_proceeds_from_issuance_of_long_term_debt_and_capital_securities_net'},
        quarterly_proceeds_from_issuance_of_preferred_stock: {label: 'Quarterly Proceeds From Issuance Of Preferred Stock', spark: () => 'quarterly_proceeds_from_issuance_of_preferred_stock'},
        quarterly_proceeds_from_repurchase_of_equity: {label: 'Quarterly Proceeds From Repurchase Of Equity', spark: () => 'quarterly_proceeds_from_repurchase_of_equity'},
        quarterly_proceeds_from_sale_of_treasury_stock: {label: 'Quarterly Proceeds From Sale Of Treasury Stock', spark: () => 'quarterly_proceeds_from_sale_of_treasury_stock'},
        quarterly_change_in_cash_and_cash_equivalents: {label: 'Quarterly Change In Cash & Cash Equivalents', spark: () => 'quarterly_change_in_cash_and_cash_equivalents'},
        quarterly_change_in_exchange_rate: {label: 'Quarterly Change In Exchange Rate', spark: () => 'quarterly_change_in_exchange_rate'},
        quarterly_earnings_per_share: {label: 'Quarterly Earnings Per Share (EPS)', spark: () => 'quarterly_earnings_per_share_(eps)'},
        quarterly_earnings_suprise_eps: {label: 'Quarterly Earnings Suprise EPS', spark: () => 'quarterly_earnings_suprise_eps'},
        quarterly_earnings_suprise_eps_percentage: {label: 'Quarterly Earnings Suprise EPS Percentage', spark: () => 'quarterly_earnings_suprise_eps_percentage'},
        quarterly_earnings_estimated_eps: {label: 'Quarterly Earnings Estimated EPS', spark: () => 'quarterly_earnings_estimated_eps'},
        annual_gross_profit: {label: 'Annual Gross Profit', spark: () => 'annual_gross_profit'},
        annual_total_revenue: {label: 'Annual Total Revenue', spark: () => 'annual_total_revenue'},
        annual_cost_of_revenue: {label: 'Annual Cost of Revenue', spark: () => 'annual_cost_of_revenue'},
        annual_cost_of_goods_and_services_sold: {label: 'Annual Cost of Goods And Services Sold', spark: () => 'annual_cost_of_goods_and_services_sold'},
        annual_operating_income: {label: 'Annual Operating Income', spark: () => 'annual_operating_income'},
        annual_selling_general_and_administrative: {label: 'Annual Selling General & Administrative', spark: () => 'annual_selling_general_and_administrative'},
        annual_randd: {label: 'Annual R&D', spark: () => 'annual_randd'},
        annual_operating_expenses: {label: 'Annual Operating Expenses', spark: () => 'annual_operating_expenses'},
        annual_investment_income_net: {label: 'Annual Investment Income Net', spark: () => 'annual_investment_income_net'},
        annual_net_interest_income: {label: 'Annual Net Interest Income', spark: () => 'annual_net_interest_income'},
        annual_interest_income: {label: 'Annual Interest Income', spark: () => 'annual_interest_income'},
        annual_interest_expense: {label: 'Annual Interest Expense', spark: () => 'annual_interest_expense'},
        annual_noninterest_income: {label: 'Annual Non-Interest Income', spark: () => 'annual_noninterest_income'},
        annual_other_nonoperating_income: {label: 'Annual Other Non-Operating Income', spark: () => 'annual_other_nonoperating_income'},
        annual_depreciation: {label: 'Annual Depreciation', spark: () => 'annual_depreciation'},
        annual_depreciation_and_amortization: {label: 'Annual Depreciation & Amortization', spark: () => 'annual_depreciation_and_amortization'},
        annual_income_before_tax: {label: 'Annual Income Before Tax', spark: () => 'annual_income_before_tax'},
        annual_income_tax_expense: {label: 'Annual Income Tax Expense', spark: () => 'annual_income_tax_expense'},
        annual_interest_and_debt_expense: {label: 'Annual Interest & Debt Expense', spark: () => 'annual_interest_and_debt_expense'},
        annual_net_income_from_continuing_operations: {label: 'Annual Net Income From Continuing Operations', spark: () => 'annual_net_income_from_continuing_operations'},
        annual_comprehensive_income_net_of_tax: {label: 'Annual Comprehensive Income Net Of Tax', spark: () => 'annual_comprehensive_income_net_of_tax'},
        annual_ebit: {label: 'Annual EBIT', spark: () => 'annual_ebit'},
        annual_ebitda: {label: 'Annual EBITDA', spark: () => 'annual_ebitda'},
        annual_net_income: {label: 'Annual Net Income', spark: () => 'annual_net_income'},
        annual_total_assets: {label: 'Annual Total Assets', spark: () => 'annual_total_assets'},
        annual_total_current_assets: {label: 'Annual Total Current Assets', spark: () => 'annual_total_current_assets'},
        annual_cash_and_cash_equivalents_at_carrying_value: {label: 'Annual Cash & Cash Equivalents At Carrying Value', spark: () => 'annual_cash_and_cash_equivalents_at_carrying_value'},
        annual_cash_and_short_term_investments: {label: 'Annual Cash & Short Term Investments', spark: () => 'annual_cash_and_short_term_investments'},
        annual_inventory: {label: 'Annual Inventory', spark: () => 'annual_inventory'},
        annual_current_net_receivables: {label: 'Annual Current Net Receivables', spark: () => 'annual_current_net_receivables'},
        annual_total_noncurrent_assets: {label: 'Annual Total Non-Current Assets', spark: () => 'annual_total_noncurrent_assets'},
        annual_property_plant_equipment: {label: 'Annual Property Plant Equipment', spark: () => 'annual_property_plant_equipment'},
        annual_accumulated_depreciation_amortization_ppe: {label: 'Annual Accumulated Depreciation AMortization PPE', spark: () => 'annual_accumulated_depreciation_amortization_ppe'},
        annual_intangible_assets: {label: 'Annual Intangible Assets', spark: () => 'annual_intangible_assets'},
        annual_intangible_assets_excluding_goodwill: {label: 'Annual Intangible Assets Excluding Goodwill', spark: () => 'annual_intangible_assets_excluding_goodwill'},
        annual_goodwill: {label: 'Annual Goodwill', spark: () => 'annual_goodwill'},
        annual_investments: {label: 'Annual Investments', spark: () => 'annual_investments'},
        annual_long_term_investments: {label: 'Annual Long Term Investments', spark: () => 'annual_long_term_investments'},
        annual_short_term_investments: {label: 'Annual Short Term Investments', spark: () => 'annual_short_term_investments'},
        annual_other_current_assets: {label: 'Annual Other Current Assets', spark: () => 'annual_other_current_assets'},
        annual_other_noncurrent_assets: {label: 'Annual Other Non-Current Assets', spark: () => 'annual_other_noncurrent_assets'},
        annual_total_liabilities: {label: 'Annual Total Liabilities', spark: () => 'annual_total_liabilities'},
        annual_total_current_liabilities: {label: 'Annual Total Current Liabilities', spark: () => 'annual_total_current_liabilities'},
        annual_current_accounts_payable: {label: 'Annual Current Accounts Payable', spark: () => 'annual_current_accounts_payable'},
        annual_deferred_revenue: {label: 'Annual Deferred Revenue', spark: () => 'annual_deferred_revenue'},
        annual_current_debt: {label: 'Annual Current Debt', spark: () => 'annual_current_debt'},
        annual_short_term_debt: {label: 'Annual Short Term Debt', spark: () => 'annual_short_term_debt'},
        annual_total_noncurrent_liabilities: {label: 'Annual Total Non-Current Liabilities', spark: () => 'annual_total_noncurrent_liabilities'},
        annual_capital_lease_obligations: {label: 'Annual Capital Lease Obligations', spark: () => 'annual_capital_lease_obligations'},
        annual_long_term_debt: {label: 'Annual Long Term Debt', spark: () => 'annual_long_term_debt'},
        annual_current_long_term_debt: {label: 'Annual Current Long Term Debt', spark: () => 'annual_current_long_term_debt'},
        annual_long_term_debt_nonconcurrent: {label: 'Annual Long Term Debt Nonconcurrent', spark: () => 'annual_long_term_debt_nonconcurrent'},
        annual_short_long_term_debt_total: {label: 'Annual Short Long Term Debt Total', spark: () => 'annual_short_long_term_debt_total'},
        annual_other_current_liabilities: {label: 'Annual Other Current Liabilities', spark: () => 'annual_other_current_liabilities'},
        annual_other_noncurrent_liabilties: {label: 'Annual Other Non-Current Liabilties', spark: () => 'annual_other_noncurrent_liabilties'},
        annual_total_shareholder_equity: {label: 'Annual Total Shareholder Equity', spark: () => 'annual_total_shareholder_equity'},
        annual_treasury_stock: {label: 'Annual Treasury Stock', spark: () => 'annual_treasury_stock'},
        annual_retained_earnings: {label: 'Annual Retained Earnings', spark: () => 'annual_retained_earnings'},
        annual_common_stock: {label: 'Annual Common Stock', spark: () => 'annual_common_stock'},
        annual_common_stock_shares_outstanding: {label: 'Annual Common Stock Shares Outstanding', spark: () => 'annual_common_stock_shares_outstanding'},
        annual_operating_cash_flow: {label: 'Annual Operating Cash Flow', spark: () => 'annual_operating_cash_flow'},
        annual_payments_for_operating_activities: {label: 'Annual Payments For Operating Activities', spark: () => 'annual_payments_for_operating_activities'},
        annual_proceeds_from_operating_activities: {label: 'Annual Proceeds From Operating Activities', spark: () => 'annual_proceeds_from_operating_activities'},
        annual_change_in_operating_liabilities: {label: 'Annual Change In Operating Liabilities', spark: () => 'annual_change_in_operating_liabilities'},
        annual_change_in_operating_assets: {label: 'Annual Change In Operating Assets', spark: () => 'annual_change_in_operating_assets'},
        annual_depreciation_depletion_and_amortization: {label: 'Annual Depreciation Depletion And Amortization', spark: () => 'annual_depreciation_depletion_and_amortization'},
        annual_capital_expenditures: {label: 'Annual Capital Expenditures', spark: () => 'annual_capital_expenditures'},
        annual_change_in_receivables: {label: 'Annual Change In Receivables', spark: () => 'annual_change_in_receivables'},
        annual_change_in_inventory: {label: 'Annual Change In Inventory', spark: () => 'annual_change_in_inventory'},
        annual_profit_loss: {label: 'Annual Profit Loss', spark: () => 'annual_profit_loss'},
        annual_cash_flow_from_investment: {label: 'Annual Cash Flow From Investment', spark: () => 'annual_cash_flow_from_investment'},
        annual_cash_flow_from_financing: {label: 'Annual Cash Flow From Financing', spark: () => 'annual_cash_flow_from_financing'},
        annual_proceeds_from_repayments_of_short_term_debt: {label: 'Annual Proceeds From Repayments Of Short Term Debt', spark: () => 'annual_proceeds_from_repayments_of_short_term_debt'},
        annual_payments_for_repurchase_of_common_stock: {label: 'Annual Payments For Repurchase Of Common Stock', spark: () => 'annual_payments_for_repurchase_of_common_stock'},
        annual_payments_for_repurchase_of_equity: {label: 'Annual Payments For Repurchase Of Equity', spark: () => 'annual_payments_for_repurchase_of_equity'},
        annual_payments_for_repurchase_of_preferred_stock: {label: 'Annual Payments For Repurchase Of Preferred Stock', spark: () => 'annual_payments_for_repurchase_of_preferred_stock'},
        annual_dividend_payout: {label: 'Annual Dividend Payout', spark: () => 'annual_dividend_payout'},
        annual_dividend_payout_common_stock: {label: 'Annual Dividend Payout Common Stock', spark: () => 'annual_dividend_payout_common_stock'},
        annual_dividend_payout_preferred_stock: {label: 'Annual Dividend Payout Preferred Stock', spark: () => 'annual_dividend_payout_preferred_stock'},
        annual_proceeds_from_inssuance_of_long_term_debt_and_capital_securities_net: {label: 'Annual Proceeds From Inssuance Of Long Term Debt And Capital Securities Net', spark: () => 'annual_proceeds_from_inssuance_of_long_term_debt_and_capital_securities_net'},
        annual_proceeds_from_issuance_of_long_term_debt_and_capital_securities_net: {label: 'Annual Proceeds From Issuance Of Long Term Debt And Capital Securities Net', spark: () => 'annual_proceeds_from_issuance_of_long_term_debt_and_capital_securities_net'},
        annual_proceeds_from_issuance_of_preferred_stock: {label: 'Annual Proceeds From Issuance Of Preferred Stock', spark: () => 'annual_proceeds_from_issuance_of_preferred_stock'},
        annual_proceeds_from_repurchase_of_equity: {label: 'Annual Proceeds From Repurchase Of Equity', spark: () => 'annual_proceeds_from_repurchase_of_equity'},
        annual_proceeds_from_sale_of_treasury_stock: {label: 'Annual Proceeds From Sale Of Treasury Stock', spark: () => 'annual_proceeds_from_sale_of_treasury_stock'},
        annual_change_in_cash_and_cash_equivalents: {label: 'Annual Change In Cash & Cash Equivalents', spark: () => 'annual_change_in_cash_and_cash_equivalents'},
        annual_change_in_exchange_rate: {label: 'Annual Change In Exchange Rate', spark: () => 'annual_change_in_exchange_rate'},
        annual_earnings: {label: 'Annual Earnings', spark: () => 'annual_earnings'},
        crypto_to_usd: {label: 'Crypto To USD', spark: () => 'crypto_to_usd'},
        crypto_to_usd_volume: {label: 'Crypto To USD Volume', spark: () => 'crypto_to_usd_volume'},
        crypto_market_cap: {label: 'Crypto Market Cap', spark: () => 'crypto_market_cap'},
        }
}
export {metricsMap};