import { React, useState } from 'react';

const LimitDataComponent = (props) => {
    const dimension_data_collector = (e) => {
        const state_component_json = { 'LIMIT_DATA_VALUE': e.target.value }
        const query_input_json = {
            input_name: 'LIMIT_DATA_VALUE',
            data: e.target.value,
            type: "unquoted_text_input"
        }
        props.input_data_collector(query_input_json, state_component_json);
    }
    return (
        <>
            <p> Limit Record Count </p>
            <input onChange={(e) => { dimension_data_collector(e) }} value={props.component_inputs['LIMIT_DATA_VALUE']} type="number"/>
        </>
    )
}

export default LimitDataComponent