import React from 'react';
import { makeStyles } from '@mui/styles';
import { Link } from "react-router-dom";

import Typography from '@mui/material/Typography';
import Imgix from 'react-imgix';
import { autoType } from 'd3';

const useStyles = makeStyles({
  root: {
    maxWidth: "90%",
    align: "center"
  },
  media: {
    height: 300,
  },
  title: {
    fontSize: 10,
  },
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap"
});

export default function ArticleCardSummarySmallImage(props) {
  const classes = useStyles();
  const image_url = "https://driving-data-journalism.imgix.net/" + props.analytics_metadata.cover_image;
  return (
    <div style={{
      paddingTop: "3px", borderRadius: "5px", borderBottom: "2px solid lightgrey", 
      borderLeft: "1px solid lightgrey", borderRight: "1px solid lightgrey", 
      overflow: "hidden", justifyContent: "space-between", 
      display: "flex", flexDirection: "row", flexWrap: "nowrap", 
      maxWidth: '1100px', margin: "none", maxHeight: "300px", width: "100%"
    }} >
      <Link to={props.link} key={props.link} style={{ zIndex: 999, minWidth: "100%", maxWidth: '1100px', textDecoration: "none", flexDirection: "row", color: "black" }}>
        <div style={{ alignItems: "space-between", display: "flex", flexDirection: "row", flexWrap: "nowrap", maxWidth: '1100px', margin: "none", minHeight: "75px", minWidth: "100%", gap: "5px" }} >
          <div style={{ paddingLeft: "10px", display: "flex", flexDirection: "column", textDecoration: "none", width: "60%" }}>
            <Typography gutterBottom variant="h5" component="h2" align="left" style={{ fontFamily: "JoanRegular" }}>
              {props.analytics_metadata.title}
            </Typography>
            <Typography className={classes.title} color="textSecondary" align="left" gutterBottom style={{ fontFamily: "JoanRegular" }}>
              {props.analytics_metadata.author ? props.analytics_metadata.author : ''} {props.analytics_metadata.create_date}
            </Typography>
          </div>
          <div style={{ minWidth: "116px", marginBottom:"auto", marginTop:"auto", display:"block"}}>
            <Imgix src={image_url} sizes="30vw" style={{ flexGrow: 1, objectFit: "cover", float: "right", width: "30%", fontFamily: "JoanRegular" }} />
          </div>
        </div>

      </Link>
    </div>

  );
}



// export default function ArticleCardSummarySmallImage(props) {
//     const classes = useStyles();
//     return (
//         <Link to={props.link} key={props.link} style={{textDecoration: "none"}}>
//           <div style={{display: "flex", flexDirection:"row"}}>
//               {props.analytics_metadata.author} {props.analytics_metadata.create_date}
//               {props.analytics_metadata.title}
//               {props.analytics_metadata.description}
//             </div>
//           </Link>
//     );
//   }