import React from 'react';
import styled from "styled-components";
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    RedditShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton
} from "react-share";

import {
    EmailIcon,
    FacebookIcon,
    LinkedinIcon,
    RedditIcon,
    TelegramIcon,
    TwitterIcon,
    WhatsappIcon,
  } from "react-share";

const ShareTermsPopup = styled.div`
    text-align: center;
    font-family: 'DM Sans';
    font-style: normal;
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: center;
    padding: 10px;
`;

const ShareHeader = styled.div`
    font-weight: 500;
    font-size: 18px;
    color: #000000;
    line-height: 23px;
`;

const ShareDescription = styled.div`
    font-weight: 400;
    font-size: 12.64px;
    line-height: 16px;
    color: #727272;
`;

const ShareButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
`;

const Button = styled.button`
    align-items: center;
    background-color: #fff;
    border-radius: 24px;
    border-style: none;
    box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;
    font-size: 14px;
    font-weight: 500;
    height: 48px;
    justify-content: center;
    letter-spacing: .25px;
    line-height: normal;
    max-width: 100%;
    overflow: visible;
    padding: 2px 24px;
    position: relative;
    text-align: center;
    width: auto;
    &:hover {
        box-shadow: rgba(60, 64, 67, .3) 0 2px 3px 0, rgba(60, 64, 67, .15) 0 6px 10px 4px;
        background: #F6F9FE;
        color: #174ea6;
    }
`;

const ShareModal = (props) => {
    const {onClick} = props;
    return (
        <ShareTermsPopup>
            <ShareHeader>Spread the Word and Stay Informed!</ShareHeader>
            <ShareDescription>Help us spread the news and keep everyone informed. Share the article you just read and let others benefit from the knowledge you've gained. Together, we can create a well-informed community. Thank you for being a part of our news-sharing network. Happy sharing!</ShareDescription>
            <div style={{ display:"flex", flexDirection:"row", maxWidth: '800px', margin: "auto"}}>
                <EmailShareButton url={window.location.href} subject={props.analytics_metadata.title} body={props.analytics_metadata.description}>
                    <EmailIcon size={32} bgStyle={{fill:"none"}} iconFillColor="grey"/>
                </EmailShareButton >
                <FacebookShareButton url={window.location.href} quote={props.analytics_metadata.description}>
                    <FacebookIcon size={32} bgStyle={{fill:"none"}} iconFillColor="grey"/>
                </ FacebookShareButton>
                <TwitterShareButton url={window.location.href} title={props.analytics_metadata.title}>
                    <TwitterIcon size={32} bgStyle={{fill:"none"}} iconFillColor="grey"/>
                </ TwitterShareButton>
                <LinkedinShareButton url={window.location.href} title={props.analytics_metadata.title} summary={props.analytics_metadata.description} source={"The Data News-Journal"}>
                    <LinkedinIcon size={32} bgStyle={{fill:"none"}} iconFillColor="grey"/>
                </ LinkedinShareButton>
                <RedditShareButton url={window.location.href} title={props.analytics_metadata.title}>
                    <RedditIcon size={32} bgStyle={{fill:"none"}} iconFillColor="grey"/>
                </ RedditShareButton>
                <TelegramShareButton url={window.location.href} title={props.analytics_metadata.title}>
                    <TelegramIcon size={32} bgStyle={{fill:"none"}} iconFillColor="grey"/>
                </ TelegramShareButton>
                <WhatsappShareButton url={window.location.href} title={props.analytics_metadata.title}>
                    <WhatsappIcon size={32} bgStyle={{fill:"none"}} iconFillColor="grey"/>
                </ WhatsappShareButton>
                <WhatsappShareButton url={window.location.href} />
            </div>
            <ShareButtonContainer>
                <Button onClick={onClick}>Dismiss</Button>
            </ShareButtonContainer>
        </ShareTermsPopup>
    );
};

export default ShareModal;
