import React from 'react';
import TextChunk from './TextChunk';
import { VictoryLine, VictoryChart, VictoryTheme, VictoryLabel, VictoryAxis, VictoryContainer, VictoryLegend } from 'victory';
import VictoryStandardGraphFunction from './components/VictoryStandardGraphFunction';

const MultiLineGraph = (props) => {
  const bottom_text = { graph_inputs: { textchunk: props.graph_inputs.textchunk_bottom } }
  const top_text = { graph_inputs: { textchunk: props.graph_inputs.textchunk_top } }
  const colors = Object.keys(props.data_for_graph)
  const palette = ['#F67280', '#6C5B7B', '#355C7D', '#99B898', '#2A363B', '#CC527A', '#F26B38', '#A7226E', '#003B46', '#0F1F38', '#8E7970', '#F55449', '#2A3132', '#763626']
  const color_array = colors.map(x => { return { "name": x } })
  const processed_graph_configs = VictoryStandardGraphFunction(props);
  const all_x_arrays = Object.keys(props.data_for_graph).map(x => props.data_for_graph[x].map(y => {return y['x']}))
  const all_x_flat_set = new Set(all_x_arrays.flat())
  for(let k in Object.keys(props.data_for_graph)) {
    let this_sub_stuff = props.data_for_graph[Object.keys(props.data_for_graph)[k]]
    let this_set = new Set(this_sub_stuff.map(y => {return y['x']}))
    let complement = new Set();
    // Iterate over the elements in setB
    for (let element of all_x_flat_set) {
      // Check if the element is not in setA
      if (!this_set.has(element)) {
        // If the element is not in setA, add it to the complement set
        complement.add(element);
      }
    }
    for (let new_element of complement) {
      props.data_for_graph[Object.keys(props.data_for_graph)[k]].push({'x': new_element, 'y': null})
    }
  }
  function compare(a, b) {
    // Use the < operator to compare the values of the 'y' key
    if (a.x < b.x) {
      return -1;
    }
    if (a.x > b.x) {
      return 1;
    }
    return 0;
  }
  
  // Sort the array using the compare function
  for(let k in Object.keys(props.data_for_graph)) {
    let this_sub_stuff = props.data_for_graph[Object.keys(props.data_for_graph)[k]]
    this_sub_stuff.sort(compare);
    props.data_for_graph[Object.keys(props.data_for_graph)[k]] = this_sub_stuff
  }
  return (
    <div>
      <TextChunk {...top_text} />
      <VictoryChart
        theme={VictoryTheme.material}
        // animate={{ duration: 100, easing: "bounce", delay: 0 }}
        {...processed_graph_configs['graph_outer_inputs']}
        containerComponent={
          <VictoryContainer
            style={{ ...props.container_style }}
          />
        }
      >
        <VictoryLabel
          text={props.graph_inputs.graphtitle ? props.graph_inputs.graphtitle : ''}
          x={100}
          y={25}
          style={{fontSize: 11}} >
        </VictoryLabel>
        <VictoryLegend
          center={props.graph_inputs.legend_center_title}
          x={+props.graph_inputs.legend_x ?? 0} 
          y={+props.graph_inputs.legend_y ?? 0}
          title={props.graph_inputs.legend_title}
          gutter={+props.graph_inputs.gutter}
          rowGutter={+props.graph_inputs.row_gutter}
          orientation={props.graph_inputs.legend_orientation}
          itemsPerRow={props.graph_inputs.legend_items_per_row}
          style={{ width: `${+props.graph_inputs.legend_width}%`, height: `${+props.graph_inputs.legend_height}px` }}
          colorScale={palette}
          data={color_array}
        />
        {Object.keys(props.data_for_graph).map((x, i) => {
          return (<VictoryLine
            style={{
              data: { stroke: palette[i] },
              parent: { border: "1px solid #ccc" }
            }}
            data={props.data_for_graph[x]}
            key={x.replace(" ", "_")}
          />)
        })}
        <VictoryAxis
          dependentAxis
          label={props.graph_inputs.yaxis ? props.graph_inputs.yaxis : ''}
          standalone={false}
          style={processed_graph_configs['tickyaxisStyle']}
          {...processed_graph_configs['y_tick_inputs']}
        />
        <VictoryAxis
          label={props.graph_inputs.xaxis ? props.graph_inputs.xaxis : ''}
          standalone={false}
          style={processed_graph_configs['tickxaxisStyle']}
          {...processed_graph_configs['x_tick_inputs']}
          tickLabelComponent={<VictoryLabel textAnchor={props.graph_inputs.x_tick_label_anchor ?? "start"} dy={+props.graph_inputs.x_tick_label_offset ?? 0} />}
          fixLabelOverlap={true}
        />
      </VictoryChart>

      <TextChunk {...bottom_text} />
    </div>
  )

}

export default MultiLineGraph