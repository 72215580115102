import React from 'react'
import { VictoryScatter, VictoryChart, VictoryTheme, VictoryLabel, VictoryAxis, VictoryContainer } from 'victory';
import TextChunk from './TextChunk';
import VictoryStandardGraphFunction from './components/VictoryStandardGraphFunction';
import * as d3Scale from 'd3-scale';


const BubbleChart = (props) => {
  const axisStyle = {
      ticks: { stroke: "grey", size: 3 },
      tickLabels: { fontSize: 9, padding: 1, angle:90, verticalAnchor: "middle", textAnchor:'start' },
      axisLabel: {padding: 35, angle:90}
    };
  const graph_input_collector = props.graph_input_collector
  const current_graph_input = props.graph_inputs
  const bottom_text = {graph_inputs:{textchunk: props.graph_inputs.textchunk_bottom}}
  const top_text = {graph_inputs:{textchunk: props.graph_inputs.textchunk_top}}

  const processed_graph_configs = VictoryStandardGraphFunction(props);

  const amounts = props.data_for_graph.map(x => x['amount'])
  const radius_min_max = {min_radius:Math.min(...amounts), max_radius:Math.max(...amounts)}
  const radius_scaler = d3Scale.scaleLinear()
      .domain([radius_min_max['min_radius'], radius_min_max['max_radius']])
      .range([props.graph_inputs['min_bubble_size'] ?? 1, props.graph_inputs['max_bubble_size'] ?? 15]);

  const legend = (y, i) =>{
    const circle_radius = radius_scaler(radius_min_max.min_radius + (radius_min_max.max_radius - radius_min_max.min_radius)*y)
    const spacing = props.graph_inputs.legend_x_spacing

    const lllm = {}
    if (Object.keys(props.graph_inputs).includes('legend_label_scaler')) {
      lllm['legend_label_scaler'] = (t) => `${Math.round(t / props.graph_inputs['legend_label_scaler'])}`
    } else {
      lllm['legend_label_scaler'] = (t) => `${t}`
    }
    const y_string_val = lllm['legend_label_scaler'](radius_min_max.min_radius + (radius_min_max.max_radius - radius_min_max.min_radius)*y)
    return (<>
        <circle
            cx={i*radius_scaler(radius_min_max.max_radius)*spacing+20}
            cy={40}
            key={Math.random()}
            r={circle_radius}
            fill={props.graph_inputs.default_color ?? "#c43a31"}>
        </circle>
        <text style={{ fill: "black" }} fontSize={`.${props.graph_inputs.legend_font_size}em`} x={i*radius_scaler(radius_min_max.max_radius)*spacing+20} y={+radius_scaler(radius_min_max.max_radius)+55}>{y_string_val}</text>
    </>)}

    return (
      <div>
        <TextChunk {...top_text} />
        <VictoryChart
          theme={VictoryTheme.material}
          // animate={{ duration: 500, easing: "bounce" }}
          {...processed_graph_configs['graph_outer_inputs']}
          containerComponent={
            <VictoryContainer
              style={{...props.container_style}}
            />
          }
        >
          <VictoryLabel 
            text={props.graph_inputs.graphtitle ? props.graph_inputs.graphtitle : ''} 
            x={100} 
            y={25} />
            {/* the variables for the victory scatter are x, y, amount, data_label*/}
            <VictoryScatter
                labelComponent={<VictoryLabel dy={+props.graph_inputs.label_y_offset ?? 0} dx={+props.graph_inputs.label_x_offset ?? 0} />}
                bubbleProperty="amount"
                style={{ labels: {fontSize: +props.graph_inputs.label_font_size ?? 5, fill: props.graph_inputs.label_fill_color ?? "black"}, data: { fill: data => Object.keys(current_graph_input).includes('clicked') && current_graph_input.clicked.includes(data.data_label) ? props.graph_inputs.selected_color ?? "red": props.graph_inputs.default_color ?? "#c43a31" } }}
                data={props.data_for_graph}
                labels={data => Object.keys(current_graph_input).includes('clicked') && current_graph_input.clicked.includes(data.data_label) ? data.data_label : null}
                events={[{
                    target: "data",
                    eventHandlers: {
                      onClick: () => {
                        return [{
                            target: "labels",
                            mutation: (props) => {
                                if(Object.keys(current_graph_input).includes('clicked')) {
                                    if(!current_graph_input.clicked.includes(props.datum.data_label)) {
                                        current_graph_input.clicked.push(props.datum.data_label)
                                        graph_input_collector({'clicked': current_graph_input.clicked})
                                    } else {
                                        const input_removed = current_graph_input.clicked.filter(x => x != props.datum.data_label)
                                        graph_input_collector({'clicked': input_removed})
                                    }
                                } else {
                                    graph_input_collector({'clicked': [props.datum.data_label]})
                                }
                            }
                          }
                        ];
                      }
                    }
                  }]}
                  size={data => radius_scaler(data.amount)}
            />
            <VictoryAxis 
              dependentAxis
              label={props.graph_inputs.yaxis ? props.graph_inputs.yaxis : ''} 
              standalone={false}
              style={processed_graph_configs['tickyaxisStyle']} 
              {...processed_graph_configs['y_tick_inputs']}
            />
            <VictoryAxis 
              label={props.graph_inputs.xaxis ? props.graph_inputs.xaxis : ''} 
              standalone={false} 
              style={processed_graph_configs['tickxaxisStyle']} 
              {...processed_graph_configs['x_tick_inputs']}
              tickLabelComponent={<VictoryLabel textAnchor={props.graph_inputs.x_tick_label_anchor ?? "start"} dy={+props.graph_inputs.x_tick_label_offset ?? 0}/>} 
            />
        </VictoryChart>
        <div style={{width:"100%", display:"flex", justifyContent:"left"}}>
        <svg style={{height:"100px"}}>
        <text x={"30%"} y={20} fontSize={`${props.graph_inputs.legend_label_font_size}em`}>{props.graph_inputs.legend_label ?? ""}</text>
          {[0.1, 0.2, 0.4, 0.6, 0.8, 1].map((y, i) => {return legend(y, i)})}
        </svg>
        </div>
        <TextChunk {...bottom_text} />
      </div>
    )

}

export default BubbleChart