import CreateIcon from '@mui/icons-material/Create';
import ListAltIcon from '@mui/icons-material/ListAlt';
import SettingsIcon from '@mui/icons-material/Settings';
import BarChartIcon from '@mui/icons-material/BarChart';
import PanoramaIcon from '@mui/icons-material/Panorama';
import GradingIcon from '@mui/icons-material/Grading';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';

import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import React, { Component } from 'react';
import './BloggerNavBar.css'

import AnalysisBuilder from '../../containers/blogger/AnalysisBuilder';


const BloggerNavBar = () => {
    return (
        <div className="navbarcontainer">
            <Link to="/myanalyses" style={{textDecoration: 'none'}}><ListAltIcon /><br/>My Articles</Link>
            <Link to="/analysisbuilder" style={{textDecoration: 'none'}}><CreateIcon /><br/>Create</Link>
            {/* <Link to="/analysisfromtemplatebuilder" style={{textDecoration: 'none'}}><PrecisionManufacturingIcon /><br/>Create From Templates</Link> */}
            <Link to="/myanalysismetrics" style={{textDecoration: 'none'}}><BarChartIcon /><br/>Reports</Link>
            <Link to="/review" style={{textDecoration: 'none'}}><GradingIcon /><br/>Review</Link>
            <Link to="/imagemanager" style={{textDecoration: 'none'}}><PanoramaIcon /><br/>Manage Images</Link>
            <Link to="/settings" style={{textDecoration: 'none'}}><SettingsIcon /><br/>Settings</Link>
        </div>
    )
}

export default BloggerNavBar;