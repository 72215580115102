import React from 'react'
import {filtersMap as nbaFiltersMap} from '../data/nba/filtersMap'
import {filtersMap as marketsFiltersMap} from '../data/markets/filtersMap'
import {filtersMap as worldBankFiltersMap} from '../data/world_bank/filtersMap'
import {filtersMap as bLaborStatsFiltersMap} from '../data/b_labor_stats/filtersMap'
import {filtersMap as fredStatsFiltersMap} from '../data/fred/filtersMap'
import {filtersMap as nflStatsFiltersMap} from '../data/nfl/filtersMap'
import {filtersMap as fbiCrimeStatsFiltersMap} from '../data/fbi_crime/filtersMap'
import {filtersMap as googleTrendsStatsFiltersMap} from '../data/google_trends/filtersMap'

const StandardFilter = (props) => {

    const operator_map = {
        in: { symbol: "IN", ss_component: "or_multi_in" },
        notin: { symbol: "NOT IN", ss_component: "and_multi_negative_in" },
        equals: { symbol: "=", ss_component: "and_multi_predicates" },
        notequals: { symbol: "!=", ss_component: "and_multi_negative_predicates" },
        lt: { symbol: "<", ss_component: "less_than" },
        lte: { symbol: "<=", ss_component: "less_than_equals" },
        gt: { symbol: ">", ss_component: "greater_than" },
        gte: { symbol: ">=", ss_component: "greater_than_equals" },
        like: { symbol: "LIKE", ss_component: "like" },
        notlike: { symbol: "NOT LIKE", ss_component: "notlike" },
        rightonly: { symbol: "IS", ss_component: "right_only" }
    }

    const component_state = props.component_inputs[props.input_name] !== "undefined" ? { ...props.component_inputs[props.input_name] } : {}

    const updateGraphInputElement = (event, filtertype) => {
        component_state[filtertype] = event.target.value
        if (filtertype === "left_operand") {
            component_state["right_operand"] = ""
            component_state["operator"] = ""
        }
        const filter_input = {
            input_name: props.input_name,
            data: {
                [filters_map_instance[component_state.left_operand]['spark_left_operand']()]: typeof component_state.right_operand !== "undefined" && component_state.right_operand !== "" ? component_state.right_operand : null
            },
            type: typeof component_state.operator !== "undefined" && component_state.operator !== "" ? operator_map[component_state.operator]['ss_component'] : ""
        }
        props.input_data_collector(filter_input, { [props.input_name]: component_state }, props.query_inputs)
    }

    const all_filters = {
        'nba': nbaFiltersMap,
        'markets': marketsFiltersMap,
        'world_bank': worldBankFiltersMap,
        'b_labor_stats': bLaborStatsFiltersMap,
        'fred': fredStatsFiltersMap,
        'nfl': nflStatsFiltersMap,
        'fbi_crime':fbiCrimeStatsFiltersMap,
        'google_trends': googleTrendsStatsFiltersMap
    }
    
    const filters_map_instance = all_filters[props.dataset](props, updateGraphInputElement)

    const sqlOperatorDropDown = () => {
        return (
            <select
                onChange={(e) => { updateGraphInputElement(e, "operator") }}
                value={component_state.operator}>
                <option value="" >Choose an operator</option>
                {component_state.left_operand ? filters_map_instance[component_state.left_operand]['operators'].map((operator) => {
                    return (
                        <option
                            value={operator}
                            key={operator}>
                            {operator_map[operator]['symbol']}
                        </option>
                    )
                }) : null}
            </select>
        )
    }

    const leftOperandDropDown = () => {
        return (
            <select onChange={(e) => { updateGraphInputElement(e, "left_operand") }} value={component_state.left_operand ? component_state.left_operand : ""}>
                <option value="" >Choose a field to filter on</option>
                {Object.keys(filters_map_instance).map((entry) => {
                    return (
                        <option
                            value={entry}
                            key={entry}>
                            {filters_map_instance[entry]['label']}
                        </option>
                    )
                })}
            </select>
        )
    }

    const rightOperand = () => {
        const show = Object.keys(component_state).filter(x => x === "left_operand").length === 1
        return (
            show ? filters_map_instance[component_state.left_operand].right_operand(component_state.right_operand, updateGraphInputElement) : <div></div>
        )
    }

    return (
        <>
            {leftOperandDropDown()}
            {sqlOperatorDropDown()}
            {rightOperand()}
        </>
    )
}

export default StandardFilter