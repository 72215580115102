import industry_code_map from './industry_code_map';
import supersector_code_map from './supersector_code_map';


const filters_value_map = {
    national_employment_hours_and_earnings : {
        prefix: {CE: "CE"},
        label: "National Employment Hours And Earnings",
        abbreviation: {EE: "EE"},
        seasonal_adjustment_code: {S: "Seasonally Adjusted", U: "Not Seasonally Adjusted"},
        industry_code: industry_code_map,
        // supersector_code: supersector_code_map,
        data_type_code: {
            '01':"ALL EMPLOYEES, THOUSANDS",
            '02':"AVERAGE WEEKLY HOURS OF ALL EMPLOYEES",
            '03':"AVERAGE HOURLY EARNINGS OF ALL EMPLOYEES",
            '04':"AVERAGE WEEKLY OVERTIME HOURS OF ALL EMPLOYEES",
            '06':"PRODUCTION AND NONSUPERVISORY EMPLOYEES, THOUSANDS",
            '07':"AVERAGE WEEKLY HOURS OF PRODUCTION AND NONSUPERVISORY EMPLOYEES",
            '08':"AVERAGE HOURLY EARNINGS OF PRODUCTION AND NONSUPERVISORY EMPLOYEES",
            '09':"AVERAGE WEEKLY OVERTIME HOURS OF PRODUCTION AND NONSUPERVISORY EMPLOYEES",
            '10':"WOMEN EMPLOYEES, THOUSANDS",
            '11':"AVERAGE WEEKLY EARNINGS OF ALL EMPLOYEES",
            '12':"AVERAGE WEEKLY EARNINGS OF ALL EMPLOYEES, 1982-1984 DOLLARS",
            '13':"AVERAGE HOURLY EARNINGS OF ALL EMPLOYEES, 1982-1984 DOLLARS",
            '15':"AVERAGE HOURLY EARNINGS OF ALL EMPLOYEES, EXCLUDING OVERTIME",
            '16':"INDEXES OF AGGREGATE WEEKLY HOURS OF ALL EMPLOYEES, 2007=100",
            '17':"INDEXES OF AGGREGATE WEEKLY PAYROLLS OF ALL EMPLOYEES, 2007=100",
            '19':"AVERAGE WEEKLY HOURS OF ALL EMPLOYEES, QUARTERLY AVERAGES, SEASONALLY ADJUSTED",
            '20':"AVERAGE WEEKLY OVERTIME HOURS OF ALL EMPLOYEES, QUARTERLY AVERAGES, SEASONALLY ADJUSTED",
            '21':"DIFFUSION INDEXES, 1-MONTH SPAN, SEASONALLY ADJUSTED",
            '22':"DIFFUSION INDEXES, 3-MONTH SPAN, SEASONALLY ADJUSTED",
            '23':"DIFFUSION INDEXES, 6-MONTH SPAN, SEASONALLY ADJUSTED",
            '24':"DIFFUSION INDEXES, 12-MONTH SPAN, NOT SEASONALLY ADJUSTED",
            '25':"ALL EMPLOYEES, QUARTERLY AVERAGES, SEASONALLY ADJUSTED, THOUSANDS",
            '26':"ALL EMPLOYEES, 3-MONTH AVERAGE CHANGE, SEASONALLY ADJUSTED, THOUSANDS",
            '30':"AVERAGE WEEKLY EARNINGS OF PRODUCTION AND NONSUPERVISORY EMPLOYEES",
            '31':"AVERAGE WEEKLY EARNINGS OF PRODUCTION AND NONSUPERVISORY EMPLOYEES, 1982-84 DOLLARS",
            '32':"AVERAGE HOURLY EARNINGS OF PRODUCTION AND NONSUPERVISORY EMPLOYEES, 1982-84 DOLLARS",
            '33':"AVERAGE HOURLY EARNINGS OF PRODUCTION AND NONSUPERVISORY EMPLOYEES, EXCLUDING OVERTIME",
            '34':"INDEXES OF AGGREGATE WEEKLY HOURS OF PRODUCTION AND NONSUPERVISORY EMPLOYEES, 2002=100",
            '35':"INDEXES OF AGGREGATE WEEKLY PAYROLLS OF PRODUCTION AND NONSUPERVISORY EMPLOYEES, 2002=100",
            '36':"AVERAGE WEEKLY HOURS, PRODUCTION/NONSUPERVISORY EMPLOYEES, QUARTERLY AVERAGES, SEASONALLY ADJUSTED",
            '37':"AVERAGE WEEKLY OVERTIME HOURS,PRODUCTION/NONSUPERVISORY EMPLOYEES,QUARTERLY AVG,SEASONALLY ADJUSTED",
            '38':"PRODUCTION AND NONSUPERVISORY EMPLOYEES-TO-ALL EMPLOYEES RATIO",
            '39':"WOMEN EMPLOYEES-TO-ALL EMPLOYEES RATIO",
            '56':"AGGREGATE WEEKLY HOURS OF ALL EMPLOYEES, THOUSANDS",
            '57':"AGGREGATE WEEKLY PAYROLLS OF ALL EMPLOYEES, THOUSANDS",
            '58':"AGGREGATE WEEKLY OVERTIME HOURS OF ALL EMPLOYEES, THOUSANDS",
            '81':"AGGREGATE WEEKLY HOURS OF PRODUCTION AND NONSUPERVISORY EMPLOYEES, THOUSANDS",
            '82':"AGGREGATE WEEKLY PAYROLLS OF PRODUCTION AND NONSUPERVISORY EMPLOYEES, THOUSANDS",
            '83':"AGGREGATE WEEKLY OVERTIME HOURS OF PRODUCTION AND NONSUPERVISORY EMPLOYEES, THOUSANDS",
            '86':"1-MONTH CONFIDENCE INTERVAL, ALL EMPLOYEES",
            '87':"3-MONTH CONFIDENCE INTERVAL, ALL EMPLOYEES",
            '88':"6-MONTH CONFIDENCE INTERVAL, ALL EMPLOYEES",
            '89':"12-MONTH CONFIDENCE INTERVAL, ALL EMPLOYEES",
            '98':"CPI-U 1982-84",
            '99':"CPI-W 1982-84"
        }
    }
}

// const filters_value_map = {}
export default filters_value_map;