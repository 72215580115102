import React from "react";
import './reader_css/footer.css'

const Footer = () => (
  <div className="footer">
        <footer>
            <p className="copyright">The Data News-Journal © 2022</p>
        </footer>
  </div>
);

export default Footer;