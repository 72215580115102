import React from 'react';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import TextChunk from './TextChunk';

const Tweet = (props) => {
  const bottom_text = {graph_inputs:{textchunk: props.graph_inputs.textchunk_bottom}}
  const top_text = {graph_inputs:{textchunk: props.graph_inputs.textchunk_top}}
  
    return (
    <div>
        <TextChunk {...top_text} />
        {props.graph_inputs.tweet ? <TwitterTweetEmbed tweetId={props.graph_inputs.tweet} /> : <div />}
        <TextChunk {...bottom_text} />
    </div>
    )
}

export default Tweet;