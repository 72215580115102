import React, { Component } from 'react';
import * as d3Array from 'd3-array';
import * as d3Hexbin from 'd3-hexbin';
import * as d3Scale from 'd3-scale';
import BballCourt from './components/BballCourt'
import TextChunk from './TextChunk';

const Props = {
    // testing if can just use JS for these
    // screen_width: Number,
    // screen_height: Number,
    hover_event: Function,
    hover_away_event: Function,
    click_event: Function,
    // props.data_for_graph: Array<Object>, this is still required, but the props stuff needs to be redone
    court_width: Number,
    court_length: Number,
    color_var: String,
    opacity_var: String
};

const ShotChart = (props) => {
    const court_width = 50;
    const court_length = 47;
    const hover_event = () => { };
    const hover_away_event = () => { };
    const click_event = () => { };
    const color_var = 'color';
    const opacity_var = 'opacity';

    const gen_svg_dimensions = () => {
        const rad = gen_hex_radius();
        return {
            svg_height: (court_length + 1 / 3) * 1.5 * rad,
            svg_width: (court_width + 1 / 2) * 1.75 * rad,
        }
    }

    const gen_hex_radius = () => {
        console.log(props.screen_width)
        const screen_height = props.screen_height;
        const screen_width = Math.min(props.screen_width * .9, 600);
        const radius = d3Array.min([
            screen_width / ((court_width + 0.5) * Math.sqrt(3)),
            screen_height / ((court_length + 1 / 3) * 1.5)]);
        return radius;
    }

    const gen_hexbin_centers = () => {
        let centers = [];
        const hex_radius = gen_hex_radius();
        for (let i = 0; i < court_length; i++) {
            for (let j = 0; j < court_width; j++) {
                centers.push([(hex_radius * j * 1.75 + hex_radius),
                (hex_radius * i * 1.5)]);
            }
        }
        return centers;
    }

    const gen_opacity_stats = () => {
        const opacities = props.data_for_graph.map((dat) => {
            return Number(dat[opacity_var])
        });
        return {
            // min_opacity: d3Array.min(opacities),
            min_opacity: -0.1,
            max_opacity: d3Array.max(opacities)
        }
    }

    const gen_color_stats = () => {
        const colors = props.data_for_graph.map((dat) => {
            return Number(dat[color_var])
        });
        return {
            min_color: d3Array.min(colors),
            max_color: d3Array.max(colors)
        }
    }

    const opacity_stats = gen_opacity_stats()
    const opacity_scaler = d3Scale.scaleLinear()
        .domain([opacity_stats.min_opacity, opacity_stats.max_opacity])
        .range([0.1, 1]);

    const color_stats = gen_color_stats()
    const color_scaler = d3Scale.scaleLinear()
        .domain([color_stats.min_color, color_stats.max_color])
        .range(["yellow", "blue"]);

    const gen_hexbin_fill_data = () => {
        const opacity_stats = gen_opacity_stats()
        const opacity_scaler = d3Scale.scaleLinear()
            .domain([opacity_stats.min_opacity, opacity_stats.max_opacity])
            .range([0.1, 1]);

        const color_stats = gen_color_stats()
        const color_scaler = d3Scale.scaleLinear()
            .domain([color_stats.min_color, color_stats.max_color])
            .range(["yellow", "blue"]);

        const data_for_graph_scaled = props.data_for_graph.map((dat) => {
            let opacity = 0;
            if (!isNaN(opacity_scaler(dat.opacity))) {
                opacity = opacity_scaler(dat.opacity);
            }
            return {
                opacity: dat.opacity,
                color: dat.color,
                opacity_scaled: opacity,
                color_scaled: color_scaler(dat.color),
                hexbin_index: dat.hexbin
            }
        });
        const output_array = [];
        data_for_graph_scaled.map(dat => {
            output_array[dat.hexbin_index] = dat;
        })
        return output_array;
    }

    const gen_hexbins = () => {
        const centers = gen_hexbin_centers();
        const hex_radius = gen_hex_radius();
        const hexbin_paths = d3Hexbin.hexbin();
        const hex_append = hexbin_paths.radius(hex_radius);
        const hex_data = gen_hexbin_fill_data();
        return hexbin_paths(centers).map((dat, index) => {
            return (<path d={"M" +
                dat.x + "," +
                dat.y + hex_append.hexagon()
            }
                key={dat.x + dat.y}
                onMouseOver={hover_event}
                onMouseOut={hover_away_event}
                onClick={click_event}
                data-event={JSON.stringify({
                    index: index,
                    xloc: dat.x,
                    yloc: dat.y,
                    opacity: hex_data[index] ? hex_data[index].opacity : 0,
                    color: hex_data[index] ? hex_data[index].color : 0
                }
                )}
                stroke={"grey"}
                strokeOpacity={0.005}
                fillOpacity={hex_data[index] ? hex_data[index]["opacity_scaled"] : 0}
                fill={hex_data[index] ? hex_data[index]["color_scaled"] : 0} />
            )
        })
    }

    const svg_width = gen_svg_dimensions().svg_width;
    const svg_height = gen_svg_dimensions().svg_height;
    const bottom_text = { graph_inputs: { textchunk: props.graph_inputs.textchunk_bottom } }
    const top_text = { graph_inputs: { textchunk: props.graph_inputs.textchunk_top } }


    return (
        <div>
            <TextChunk {...top_text} />
            <div>{props.data_for_graph != null && typeof props.data_for_graph != undefined && props.data_for_graph.length > 0 ?
                <>
                <div>{props.graph_inputs.graph_title ? props.graph_inputs.graph_title : ''}</div>
                <svg
                    width={svg_width}
                    height={svg_height}
                    id={'shotchartsvg'}>
                    <BballCourt
                        screen_width={svg_width}
                        screen_height={svg_height}
                        court_width={court_width}
                        court_length={court_length} />
                    {gen_hexbins()}
                </svg>
                    <br />
                    <svg
                        width={svg_width}
                        height={"200px"}
                    >
                        <text x={0} y={15} style={{ fill: "black" }} fontSize=".5em" >
                            {props.graph_inputs.filter_1_name ? props.graph_inputs.filter_1_name : "Unconfigured Label"}
                        </text>
                        <text x={0} y={150} style={{ fill: "black" }} fontSize=".5em" >
                            {props.graph_inputs.filter_1_name ? props.graph_inputs.filter_1_name : "Unconfigured Label"}
                        </text>
                        <text x={250} y={15} style={{ fill: "black" }} fontSize=".5em" >
                            {props.graph_inputs.filter_1_name ? props.graph_inputs.filter_1_name : "Unconfigured Label"}
                        </text>
                        <text x={250} y={150} style={{ fill: "black" }} fontSize=".5em" >
                            {props.graph_inputs.filter_1_name ? props.graph_inputs.filter_1_name : "Unconfigured Label"}
                        </text>
                        <text x={0} y={25} style={{ fill: "black" }} fontSize=".5em" >
                               Shot Worse & Less Often
                        </text>
                        <text x={250} y={25} style={{ fill: "black" }} fontSize=".5em" >
                               Shot Better & Less Often
                        </text>
                        <text x={0} y={160} style={{ fill: "black" }} fontSize=".5em" >
                               Shot Worse & More Often
                        </text>
                        <text x={250} y={160} style={{ fill: "black" }} fontSize=".5em" >
                               Shot Better & More Often
                        </text>
                        <text x={150} y={30} style={{ fill: "black" }} fontSize=".5em" >
                               Field Goal % Difference
                        </text>
                        <g transform="rotate(270 0 0)
                                        translate(-200 0)
                                        skewX(0)
                                        scale(1 1)">
                        <text x={80} y={30} style={{ fill: "black" }} fontSize=".5em" >
                               FGA Per Game Diff
                        </text>
                        </g>
                        {[0.1, 0.2, 0.4, 0.45, 0.5, 0.55, 0.6, 0.8, 1].map((x, i) => {
                            const min_max = gen_color_stats()
                            return (<text x={i * 30 + 67} y={42} style={{ fill: "black" }} fontSize=".5em" >
                                {`${i==0 ? '<' : ''}${i==8 ? '>' : ''}${Math.round(100*(min_max.min_color + (min_max.max_color - min_max.min_color) * x))}`}
                            </text>)
                            })
                        }
                        {[0.1, 0.2, 0.4, 0.6, 0.8, 1].map((y, i) => {
                            const min_max = gen_opacity_stats()
                            return (<text x={35} y={i*15 + 52} style={{ fill: "black" }} fontSize=".5em" >
                                {(min_max.min_opacity + (min_max.max_opacity - min_max.min_opacity) * y).toFixed(2)}
                            </text>)
                            })
                        }
                        {[0.1, 0.2, 0.4, 0.45, 0.5, 0.55, 0.6, 0.8, 1].map((x, i) => {
                            const color_min_max = gen_color_stats()
                            return [0.1, 0.2, 0.4, 0.6, 0.8, 1].map((y, i2) => {
                                const opacity_min_max = gen_opacity_stats()
                                return (<>
                                    <rect
                                        x={i * 30 + 60}
                                        y={i2*15 + 44}
                                        key={i+i2*10}
                                        opacity={opacity_scaler(opacity_min_max.min_opacity + (opacity_min_max.max_opacity - opacity_min_max.min_opacity) * y)}
                                        fill={color_scaler(color_min_max.min_color + (color_min_max.max_color - color_min_max.min_color) * x)}
                                        height="15px"
                                        width="30px">
                                    </rect>
                                </>)
                            })
                        })}
                    </svg>
                    <br />
                </> : <></>}
            </div>
            <TextChunk {...bottom_text} />
        </div>
    );
}

export default ShotChart;