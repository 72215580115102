import React from 'react'
import TeamSelector from '../../filtercomponents/TeamSelector'
import OffensivePlayTypeSelector from '../../filtercomponents/OffensivePlayTypeSelector';
import PlayTypeSelector from '../../filtercomponents/PlayTypeSelector'
import GenericKVSelector from '../../filtercomponents/GenericKVSelector';
import selector_data from './selector_data';

const filtersMap = (props, on_change_event) => {
    return {
        assisting_player:{operators:['equals', 'notequals'], label:'Assisting Player', spark_left_operand: () => 'IF(assist IS NOT NULL, assist, "No Assist")', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['assisting_player'])},
        assisted_player:{operators:['equals', 'notequals'], label:'Assisted Player', spark_left_operand: () => 'IF(assist IS NOT NULL, player, "No Assist")', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['assisted_player'])},
        assisted_shot:{operators:['equals', 'notequals'], label:'Assisted Shot', spark_left_operand: () => 'IF(shot_result="made", IF(assist IS NOT NULL, "Assisted","Unassisted"), "No Made Shot")', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['assisted_shot'])},
        away_players:{operators:['like', 'notlike'], label:'Away Players', spark_left_operand: () => 'away_players', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['away_players'])},
        away_team:{operators:['equals', 'notequals'], label:'Away Team', spark_left_operand: () => 'away_team', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['away_team'])},
        blocking_player:{operators:['equals', 'notequals'], label:'Blocking Player', spark_left_operand: () => 'IF(block IS NOT NULL, block, "No block")', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['blocking_player'])},
        blocked_player:{operators:['equals', 'notequals'], label:'Blocked Player', spark_left_operand: () => 'IF(block IS NOT NULL, player, "No block")', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['blocked_player'])},
        blocked_shot:{operators:['equals', 'notequals'], label:'Blocked Shot', spark_left_operand: () => 'IF(shot_result="missed", IF(block IS NOT NULL, "Blocked","Missed"), IF(shot_result="made", "Made Shot", "No Shot Attempt"))', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['blocked_shot'])},
        date: {operators:['equals', 'notequals', 'lt', 'lte', 'gt', 'gte'] ,label: 'Date',spark_left_operand: () => 'Date',right_operand: (curr_value, on_change_event) => {return (<input type='date'onChange={(e) => { on_change_event(e, 'right_operand') }} value={curr_value} />)}},
        defense_players:{operators:['like', 'notlike'], label:'defense_players', spark_left_operand: () => 'defense_players', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['defense_players'])},
        defense_team:{operators:['equals', 'notequals'], label:'Defense Team', spark_left_operand: () => 'defense_team', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['defense_team'])},
        elapsed_time_quarter : {operators:['equals', 'notequals', 'lt', 'lte', 'gt', 'gte'] ,label: 'Elapsed Time IN Quarter',spark_left_operand: () => "'elapsed_time_in_quarter'",right_operand: (curr_value, on_change_event) => {return (<input type='number' onChange={(e) => { on_change_event(e, 'right_operand') }} value={curr_value} />)}},
        event_type: {operators:['equals', 'notequals'], label:'Event Type', spark_left_operand: () => 'event_type', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['event_type'])},
        free_throw_num : {operators:['equals', 'notequals', 'lt', 'lte', 'gt', 'gte'] ,label: 'Free Throws Awarded',spark_left_operand: () => "'free_throws_awarded'",right_operand: (curr_value, on_change_event) => {return (<input type='number' onChange={(e) => { on_change_event(e, 'right_operand') }} value={curr_value} />)}},
        free_throw_outof : {operators:['equals', 'notequals', 'lt', 'lte', 'gt', 'gte'] ,label: 'Free Throw Out Of',spark_left_operand: () => "'free_throws_out_of'",right_operand: (curr_value, on_change_event) => {return (<input type='number' onChange={(e) => { on_change_event(e, 'right_operand') }} value={curr_value} />)}},
        home:{operators:['equals', 'notequals'], label:'Home', spark_left_operand: () => 'home', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['home'])},
        home_players:{operators:['like', 'notlike'], label:'Home Players', spark_left_operand: () => 'home_players', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['home_players'])},
        home_team:{operators:['equals', 'notequals'], label:'Home Team', spark_left_operand: () => 'home_team', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['home_team'])},
        offense_players:{operators:['like', 'notlike'], label:'offense_players', spark_left_operand: () => 'offense_players', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['offense_players'])},
        offense_team:{operators:['equals', 'notequals'], label:'Offense Team', spark_left_operand: () => 'offense_team', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['offense_team'])},
        opponent:{operators:['equals', 'notequals'], label:'Opponent', spark_left_operand: () => 'opponent', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['opponent'])},
        period:{operators:['equals', 'notequals', 'lt', 'lte', 'gt', 'gte'], label:'Period', spark_left_operand: () => 'period', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['period'])},
        play_length : {operators:['equals', 'notequals', 'lt', 'lte', 'gt', 'gte'] ,label: 'Play Length',spark_left_operand: () => "'play_length'",right_operand: (curr_value, on_change_event) => {return (<input type='number' onChange={(e) => { on_change_event(e, 'right_operand') }} value={curr_value} />)}},
        player:{operators:['equals', 'notequals'], label:'Player', spark_left_operand: () => 'player', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['player'])},
        points : {operators:['equals', 'notequals', 'lt', 'lte', 'gt', 'gte'] ,label: 'Points',spark_left_operand: () => "'points'",right_operand: (curr_value, on_change_event) => {return (<input type='number' onChange={(e) => { on_change_event(e, 'right_operand') }} value={curr_value} />)}},
        possession:{operators:['equals', 'notequals'], label:'Posession', spark_left_operand: () => 'possession', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['possession'])},
        reason:{operators:['equals', 'notequals'], label:'Reason', spark_left_operand: () => 'reason', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['reason'])},
        regular_playoffs:{operators:['equals', 'notequals'], label:'Regular Seaon Or Playoffs', spark_left_operand: () => 'regular_playoffs', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['regular_playoffs'])},
        remaining_time_quarter : {operators:['equals', 'notequals', 'lt', 'lte', 'gt', 'gte'] ,label: 'Remaining Time In Quarter',spark_left_operand: () => "'remaining_time_in_quarter'",right_operand: (curr_value, on_change_event) => {return (<input type='number' onChange={(e) => { on_change_event(e, 'right_operand') }} value={curr_value} />)}},
        season:{operators:['equals', 'notequals'], label:'Season', spark_left_operand: () => 'season', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['season'])},
        shot_distance : {operators:['equals', 'notequals', 'lt', 'lte', 'gt', 'gte'] ,label: 'Shot Distance',spark_left_operand: () => 'shot_distance',right_operand: (curr_value, on_change_event) => {return (<input type='number' onChange={(e) => { on_change_event(e, 'right_operand') }} value={curr_value} />)}},
        shot_result:{operators:['equals', 'notequals'], label:'Shot Result', spark_left_operand: () => 'shot_result', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['shot_result'])},
        steal:{operators:['equals', 'notequals'], label:'Steal', spark_left_operand: () => 'IF(steal IS NOT NULL, steal, "No steal")', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['steal'])},
        stealing_player:{operators:['equals', 'notequals'], label:'Stealing Player', spark_left_operand: () => 'IF(steal IS NOT NULL, steal, "No steal")', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['stealing_player'])},
        team:{operators:['equals', 'notequals'], label:'Team', spark_left_operand: () => 'team', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['team'])},
        turnover_player:{operators:['equals', 'notequals'], label:'Turnover Player', spark_left_operand: () => 'IF(event="turnover", player, "No Turnover")', right_operand: () => GenericKVSelector(props, on_change_event, 'right_operand', selector_data['turnover_player'])},
    }
}

export { filtersMap };