import React, { Component } from 'react';
import server_configs from '../../server_configs';
import  {Link} from "react-router-dom";
import { withRouter } from 'react-router';
import ArticleCardSummary from "../../components/reader/ArticleCardSummary";
import ArticleCardSummaryNoImage from "../../components/reader/ArticleCardSummaryNoImage";
import ArticleCardSummarySmallImage from "../../components/reader/ArticleCardSummarySmallImage";
import TagManager from 'react-gtm-module'
import GoogleAdMobile from '../../components/app/GoogleAdMobile';

import './Blog.css'

class MobileBlog extends Component {
    state = {
        articles: []
    }

    componentDidMount = () => {
        TagManager.dataLayer({
            dataLayer: {
              event: 'PageView',
              pagePath: 'main-page'
            },
          });
    }

    getArticles = () => {
        let query_string = ''
        if(this.props.match != undefined && this.props.match.params.section_name != undefined){
            const { section_name } = this.props.match.params;
            query_string = '?section='.concat(section_name.toLowerCase().replaceAll(' ','_'))
        }
        fetch(`${server_configs['api_domain']}/data_blogging/get_analyses${query_string}`, {
                method: "GET",
                mode: "cors",
                headers: { "Content-Type": "application/json" }
            })
                .then(response => {
                    response.json()
                        .then(json => {
                            this.setState((state, props) => ({ articles: json }));
                            TagManager.dataLayer({
                                dataLayer: {
                                  event: 'PageView',
                                  pagePath: 'data-viz-daily'
                                },
                              });
                        });
                })    
        }

    componentDidMount = () => {
        let section_name = ''
        if(this.props.match != undefined && this.props.match.params.section_name != undefined){
            ({ section_name } = this.props.match.params);
        }
        this.setState((state, props) => ({section_name: section_name}))
        this.getArticles();
    }

    componentDidUpdate = () => {
        let section_name = ''
        if(this.props.match != undefined && this.props.match.params.section_name != undefined){
            ({ section_name } = this.props.match.params);
        }
        if(this.state.section_name != section_name) {
            this.setState((state, props) => ({section_name: section_name}))
            this.getArticles();
        }
    }


    chooseArticleCardRenderFormat = (article, index) => {
        if(!Object.keys(article.analytics_metadata).includes("cover_image")) {
            return (
                <>
                    <ArticleCardSummaryNoImage
                        link={`/analysis/${article.analysis_id.$oid}`} 
                        key={article.analysis_id.$oid} 
                        analytics_metadata={article.analytics_metadata}/> 
                    <br />
                </>
            ) 
        } else if((index == 1 || index % 3 == 0) && index != 0) {
            return (
                <>
                    <ArticleCardSummarySmallImage
                        link={`/analysis/${article.analysis_id.$oid}`} 
                        key={article.analysis_id.$oid} 
                        analytics_metadata={article.analytics_metadata}/> 
                    <br />
                </>
            ) 
        } else if(index % 4 == 0 && index != 0){
            return (
                <>
                     <GoogleAdMobile />
                    <ArticleCardSummaryNoImage
                        link={`/analysis/${article.analysis_id.$oid}`} 
                        key={article.analysis_id.$oid} 
                        analytics_metadata={article.analytics_metadata}/> 
                    <br />
                </>
            ) 
        } else {
            return (
                <>
                     <GoogleAdMobile />
                    <ArticleCardSummary
                        link={`/analysis/${article.analysis_id.$oid}`} 
                        key={article.analysis_id.$oid} 
                        analytics_metadata={article.analytics_metadata}/> 
                    <br />
                </>
            ) 
        }
    }

    renderSection = (article_array) => {
        return article_array.map((x, idx) => {
            if(['title','create_date','tags'].every(value => Object.keys(x.analytics_metadata).includes(value))) {
            return this.chooseArticleCardRenderFormat(x, idx)}
        })
    }


    render = () => {
        const {articles} = this.state;
        let section_name = 'Top Stories'
        if(Object.keys(this.props.match.params).includes('section_name')) {
            section_name  = this.props.match.params.section_name;
        } else {
            section_name= 'Top Stories'
        }
        return (
            <>            
                <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                    {<><div style={{borderBottom: "1px solid grey", width:"90%", display:"flex", textAlign:"left"}}><h1 style={{fontFamily:"JoanRegular"}}>{section_name}</h1></div><br/></>}
                    {this.renderSection(articles)}
                </div>
                <div style={{height:"50px"}}>
                    {/* this dive keeps the bottom from being cut off */}
                </div>
            </>
            )
    }
}

export default MobileBlog;