import React from 'react';


const metricsMap = () => {
    return {
        fred_metric: {label: 'FRED Metric', spark: (metric) => { 
            return (`${metric}`)}
        }
    }
}
export {metricsMap};