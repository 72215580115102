import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Loading from "../utils/Loading";
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import StripeButton from "./StripeButton";
import arrow from "../assets/arrow.svg";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import axios from "axios";

const axiosInstance = axios.create({
    baseURL: 'https://dnj-user-67yqetmaoa-uc.a.run.app/v2/api/user/',
    timeout: 15000,
    validateStatus: function (status) {
        return status >= 200 && status < 500;
    }
});

const ProfileContainer = styled.div`
    width: 95%;
    border-radius: 8px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
    background: #FFFFFF;
    align-self: center;
    font-family: 'DM Sans';
    font-style: normal;
    margin: 20px;
`;

const ProfileBody = styled.div`
    flex-direction: column;
    display: flex;
    justify-content: center;
    margin: 20px;
    gap: 20px;
`;

const ProfileHeader = styled.div`
    width: 100%;
    text-align: left;
    display: flex;
    flex-direction: row;
    gap: 12px;
`;

const ProfileImage = styled.img`
    border-radius: 50%;
    width: 48px;
    height: 48px;
`;

const ProfileUser = styled.div`
    display: flex;
    flex-direction: column;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    justify-content: center;
`;

const ProfileUserTitle = styled.div`
`;

const ProfileUserSubitle = styled.div`
    font-weight: 400;
    font-size: 12.64px;
    line-height: 16px;
    color: #727272;
`;

const MyProfile = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
`;

const MyProfileHeader = styled.div`
    font-weight: 500;
    font-size: 18px;
    color: #000000;
    line-height: 23px;
    background: #F9F8F9;
    padding: 20px;
    text-align: left;
`;

const MyProfileRightHeader = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
`;

const MyProfileBody = styled.div`
    font-weight: 400;
    font-size: 12.64px;
    line-height: 16px;
    color: #727272;
    background: #FFFFFF;
    padding: 20px;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const MyProfileText = styled.div`
    display: flex;
    flex-direction: row;
    padding: 5px;
    border-bottom: 1px solid whitesmoke;
    &:hover {
        background: #F6F9FE;
        color: #174ea6;
    }
`;

const MyProfileAttribute = styled.div`
    flex: 1;
`;

const MyProfileValue = styled.div`
    flex: 2;
    color: #000000;
`;

const BecomeWriterButton = styled.div`
    box-sizing: border-box;
    border-top: 1px solid #DDE2E8;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    color: #000000;
    &:hover {
        background: #F6F9FE;
        color: #174ea6;
    }
`;

const ButtonArrow = styled.img`
    width: 12.64px;
    height: 12.64px;
`;

const LegalTermsPopup = styled.div`
    text-align: center;
    font-family: 'DM Sans';
    font-style: normal;
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: center;
    padding: 10px;
`;

const LegalHeader = styled.div`
    font-weight: 500;
    font-size: 18px;
    color: #000000;
    line-height: 23px;
`;

const LegalDescription = styled.div`
    font-weight: 400;
    font-size: 12.64px;
    line-height: 16px;
    color: #727272;
`;

const LegalButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
`;

const Button = styled.button`
    align-items: center;
    background-color: #fff;
    border-radius: 24px;
    border-style: none;
    box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;
    font-size: 14px;
    font-weight: 500;
    height: 48px;
    justify-content: center;
    letter-spacing: .25px;
    line-height: normal;
    max-width: 100%;
    overflow: visible;
    padding: 2px 24px;
    position: relative;
    text-align: center;
    width: auto;
    &:hover {
        box-shadow: rgba(60, 64, 67, .3) 0 2px 3px 0, rgba(60, 64, 67, .15) 0 6px 10px 4px;
        background: #F6F9FE;
        color: #174ea6;
    }
`;

export const ProfileComponent = () => {
    const {user, logout} = useAuth0();
    const [isWritter, setIsWritter] = useState(false);
    
    useEffect(() => {
        setIsWritter(user['dnj/roles'].includes('writer'));
    }, [user]);

    const userText = () => {
        const attributeMap = {
            'dnj/roles': 'Role',
            'given_name': 'First Name',
            'family_name': 'Last Name',
            'nickname': 'Username',
            'email': 'Email'            
        };
        return (Object.keys(user).filter(key => Object.keys(attributeMap).includes(key)).map(key => {
            const value = user[key];
            return (
                <MyProfileText key={key + value}>
                    <MyProfileAttribute key={key}>
                        {attributeMap[key]}
                    </MyProfileAttribute>
                    <MyProfileValue key={value}>
                        {user[key]}
                    </MyProfileValue>
                </MyProfileText>
            );
        }));
    };

    const logoutWithRedirect = () =>
    logout({
        returnTo: window.location.origin,
    });

    const renderStripeButtonIfNeeded = () => {
        if (isWritter) {
            return (
                <StripeButton user={user} />
            );
        }
        return (null);
    };

    const renderBecomeWriterButtonIfNeeded = () => {
        const acceptTerms = async (close) => {
            close();
            const registerResponse = await axiosInstance.get('register_writer', { params: { userId: user.sub } });
            if (registerResponse.data.status === 'success') {
                setIsWritter(true);
            }
        };
        
        const declineTerms = (close) => {
            close();
        };

        if (!isWritter) {
            return (
            <Popup trigger=
                {
                    <BecomeWriterButton>
                        Become a Writer
                        <ButtonArrow src={arrow} />
                    </BecomeWriterButton>
                }
                modal nested>
                {
                    close => (
                        <LegalTermsPopup>
                            <LegalHeader>Legal Terms</LegalHeader>
                            <LegalDescription>Here are the legal terms for using our app...</LegalDescription>
                            <LegalButtonContainer>
                                <Button onClick={() => acceptTerms(close)}>I Accept</Button>
                                <Button onClick={() => declineTerms(close)}>I Decline</Button>
                            </LegalButtonContainer>
                        </LegalTermsPopup>
                    )
                }
            </Popup>
            );
        }
        return (null);
    };

    return (
        <ProfileContainer>
            <ProfileBody>
                <ProfileHeader>
                    <ProfileImage src={user.picture} />
                    <ProfileUser>
                        <ProfileUserTitle>
                            {user.name}
                        </ProfileUserTitle>
                        <ProfileUserSubitle>
                            {user.email}
                        </ProfileUserSubitle>
                    </ProfileUser>
                    <MyProfileRightHeader>
                        <Button onClick={logoutWithRedirect}>Sign Out</Button>
                    </MyProfileRightHeader>
                </ProfileHeader>
                <MyProfile>
                    <MyProfileHeader>
                        My Profile
                    </MyProfileHeader>
                    <MyProfileBody>
                        <MyProfile>
                            <MyProfileHeader>
                                Manage Account
                            </MyProfileHeader>
                            <MyProfileBody>
                                You current status is: {isWritter ? 'Writer' : 'Reader'}
                                {renderStripeButtonIfNeeded()}
                            </MyProfileBody>
                            {renderBecomeWriterButtonIfNeeded()}
                        </MyProfile>
                        <MyProfile>
                            <MyProfileHeader>
                                Profile
                            </MyProfileHeader>
                            <MyProfileBody style={{gap: 5}}>
                                {userText()}
                            </MyProfileBody>
                        </MyProfile>
                    </MyProfileBody>
                </MyProfile>
            </ProfileBody>
        </ProfileContainer>
    );
};

export default withAuthenticationRequired(ProfileComponent, {
    onRedirecting: () => <Loading/>,
});
