import React from 'react';
import TextChunk from './TextChunk';
import { VictoryContainer, VictoryCandlestick, VictoryChart, VictoryTheme, VictoryLabel, VictoryAxis } from 'victory';
import VictoryStandardGraphFunction from './components/VictoryStandardGraphFunction';

const Candlestick = (props) => {
  const graph_input_collector = props.graph_input_collector
  const current_graph_input = props.graph_inputs
  const bottom_text = {graph_inputs:{textchunk: props.graph_inputs.textchunk_bottom}}
  const top_text = {graph_inputs:{textchunk: props.graph_inputs.textchunk_top}}

  const processed_graph_configs = VictoryStandardGraphFunction(props);

    return (
      <div>
        <TextChunk {...top_text} />
        <VictoryChart
        theme={VictoryTheme.material}
        // animate={{ duration: 500, easing: "bounce" }}
        {...processed_graph_configs['graph_outer_inputs']}
        containerComponent={
          <VictoryContainer
            style={{ ...props.container_style }}
          />
        }
        >
        <VictoryLabel 
          text={props.graph_inputs.graphtitle ? props.graph_inputs.graphtitle : ''} 
          x={100} 
          y={25}>
        </VictoryLabel>
          <VictoryCandlestick
            labelComponent={<VictoryLabel dy={+props.graph_inputs.label_y_offset ?? 0} dx={+props.graph_inputs.label_x_offset ?? 0} />}
            candleColors={{ positive: "#03C04A", negative: "#E3242B" }}
            style={{
              data: { stroke: "#000000", strokeWidth: ".25px" },
            }}
            data={props.data_for_graph.sort((a,b)=> (a.x > b.x ? 1 : -1))}
            sortOrder="ascending"
            sortKey="x"
            animate={{
                duration: 2000,
                onLoad: { duration: 200 }
              }}              
          />
        <VictoryAxis
          dependentAxis
          label={props.graph_inputs.yaxis ? props.graph_inputs.yaxis : ''}
          standalone={false}
          style={processed_graph_configs['tickyaxisStyle']}
          {...processed_graph_configs['y_tick_inputs']}
        />
        <VictoryAxis
          label={props.graph_inputs.xaxis ? props.graph_inputs.xaxis : ''}
          standalone={false}
          style={processed_graph_configs['tickxaxisStyle']}
          {...processed_graph_configs['x_tick_inputs']}
          tickLabelComponent={<VictoryLabel textAnchor={props.graph_inputs.x_tick_label_anchor ?? "start"} dy={+props.graph_inputs.x_tick_label_offset ?? 0} />}
          fixLabelOverlap={true}
        />
        </VictoryChart>
        <TextChunk {...bottom_text} />
      </div>
    )

}

export default Candlestick