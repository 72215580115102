import React from 'react';
import TextChunk from './TextChunk';
import { VictoryArea, VictoryChart, VictoryTheme, VictoryLabel, VictoryAxis, VictoryContainer, VictoryLegend, VictoryStack} from 'victory';
import VictoryStandardGraphFunction from './components/VictoryStandardGraphFunction';

const StackedAreaGraph = (props) => {
  const bottom_text = {graph_inputs:{textchunk: props.graph_inputs.textchunk_bottom}}
  const top_text = {graph_inputs:{textchunk: props.graph_inputs.textchunk_top}}
  const colors = Object.keys(props.data_for_graph)
  const palette = ['#F67280', '#6C5B7B', '#355C7D', '#99B898', '#2A363B', '#CC527A', '#F26B38', '#A7226E', '#003B46', '#0F1F38', '#8E7970', '#F55449', '#2A3132', '#763626']
  const color_array = colors.map(x => {return {"name": x}})
  const processed_graph_configs = VictoryStandardGraphFunction(props);

  return (
      <div>
        <TextChunk {...top_text} />
        <VictoryChart
          theme={VictoryTheme.material}
          // animate={{ duration: 100, easing: "bounce", delay: 0 }}
          {...processed_graph_configs['graph_outer_inputs']}
          containerComponent={
            <VictoryContainer
              style={{ ...props.container_style }}
            />
          }
          >
        <VictoryLabel 
          text={props.graph_inputs.graphtitle ? props.graph_inputs.graphtitle : ''} 
          x={100} 
          y={25}
          style={{fontSize: 11}} >
        </VictoryLabel>
        <VictoryStack>
          {Object.keys(props.data_for_graph).map( (x, i) => {return (<VictoryArea
            style={{
              data: { fill: palette[i] },
              parent: { border: "1px solid #ccc"}
            }}
            data={props.data_for_graph[x]}
            key={x.replace(" ", "_")}
          />)})}
        </VictoryStack>
          <VictoryAxis 
          dependentAxis
          label={props.graph_inputs.yaxis ? props.graph_inputs.yaxis : ''}
          standalone={false}
          style={processed_graph_configs['tickyaxisStyle']}
          {...processed_graph_configs['y_tick_inputs']}
          />
          <VictoryAxis 
          label={props.graph_inputs.xaxis ? props.graph_inputs.xaxis : ''}
          standalone={false}
          style={processed_graph_configs['tickxaxisStyle']}
          {...processed_graph_configs['x_tick_inputs']}
          tickLabelComponent={<VictoryLabel textAnchor={props.graph_inputs.x_tick_label_anchor ?? "start"} dy={+props.graph_inputs.x_tick_label_offset ?? 0} />}
          fixLabelOverlap={true}
          />
        <VictoryLegend
          center={props.graph_inputs.legend_center_title}
          x={+props.graph_inputs.legend_x ?? 0} 
          y={+props.graph_inputs.legend_y ?? 0}
          title={props.graph_inputs.legend_title}
          gutter={+props.graph_inputs.gutter}
          rowGutter={+props.graph_inputs.row_gutter}
          orientation={props.graph_inputs.legend_orientation}
          itemsPerRow={props.graph_inputs.legend_items_per_row}
          style={{ width: `${+props.graph_inputs.legend_width}%`, height: `${+props.graph_inputs.legend_height}px`}}
          colorScale={palette}
          data={color_array}
        />
        </VictoryChart>

        <TextChunk {...bottom_text} />
      </div>
    )

}

export default StackedAreaGraph