import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { rgb } from 'd3-color';

const getAngle = (group) => ((group.startAngle + group.endAngle) / 2);

const darkerOrNot = (group_id,graph_inputs=null) => {
    if(!graph_inputs.clicked_group) {
        return true
    } else if(graph_inputs.clicked_group && group_id == graph_inputs.clicked_group) {
        return true
    }
    else {
        return false
    }
}

const Groups = ({
    componentId,
    chords,
    color,
    arc,
    outerRadius,
    setMouseOverGroup,
    groupLabels,
    labelColors,
    disableHover,
    hoverPersist,
    setHoverPersist,
    onClick,
    graph_inputs
}) => (
    <g className="groups">
        {chords.groups.map((group, groupIndex) => (
            <g
                key={groupIndex}
                onMouseOver={(!disableHover && !hoverPersist) ? () => setMouseOverGroup(group.index) : null}
                onMouseOut={(!disableHover && !hoverPersist) ? () => setMouseOverGroup(null) : null}
                onClick={ () => { setHoverPersist(!hoverPersist); onClick(groupIndex) } }

            >
                <path
                    id={`component${componentId}-group${groupIndex}`}
                    fill={`${color(groupIndex)}`}
                    stroke={`${darkerOrNot(groupIndex, graph_inputs) ? rgb(color(groupIndex)).darker() : rgb(color(groupIndex))}`} d={arc(group)}
                />

                <text
                    dy=".35em"
                    transform={`rotate(${getAngle(group) * 180 / Math.PI - 90 }) translate(${outerRadius + 15}) ${getAngle(group) > Math.PI ? "rotate(-140)" : "rotate(45)"}`}
                    // transform={`rotate(${getAngle(group) * 180 / Math.PI - 90 }) translate(${outerRadius + 10}) ${getAngle(group) > Math.PI ? "rotate(120)" : ""}`}
                    fill={labelColors.length === 1 ? labelColors[0] : labelColors[groupIndex]}
                    style={{textAnchor: (group.startAngle + group.endAngle) / 2 > Math.PI ? "end" : null}}
                >
                    {groupLabels[groupIndex]}
                </text>
            </g>
        ))}
    </g>
);

Groups.propTypes = {
    componentId: PropTypes.number.isRequired,
    chords: PropTypes.array.isRequired,
    color: PropTypes.func.isRequired,
    arc: PropTypes.func.isRequired,
    setMouseOverGroup: PropTypes.func.isRequired,
    groupLabels: PropTypes.array,
    labelColors: PropTypes.array,
    disableHover: PropTypes.bool,
    persistHoverOnClick: PropTypes.bool,
    onClick: PropTypes.func,
    graph_inputs: PropTypes.object
};

export default Groups;
