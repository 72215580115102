import React from 'react'

import LineGraph from './graphs/LineGraph'
import StackedAreaGraph from './graphs/StackedAreaGraph'
import StackedBarchart from './graphs/StackedBarchart'
import MultiLineGraph from './graphs/MultiLineGraph'
import BarGraph from './graphs/BarGraph'
import ShotChart from './graphs/ShotChart'
import DynamicHexShotChart from './graphs/DynamicHexShotChart'
import TextChunk from './graphs/TextChunk';
import Tweet from './graphs/Tweet';
import Chord from './graphs/Chord';
import BubbleChart from './graphs/BubbleChart';
import ImageGraph from './graphs/ImageGraph';
import Candlestick from './graphs/Candlestick';
import WorldChoropleth from './graphs/WorldChoropleth';
import UsChoropleth from './graphs/UsChoropleth';

const GraphRouter = (props) => {
  const graph_analysis_map = {
    OneDimOneMetric: ['BarGraph', 'LineGraph', 'WorldChoropleth', 'UsChoropleth'],
    TwoDimOneMetric: ['MultiLineGraph', 'StackedAreaGraph', 'StackedBarchart'],
    NoDimManyMetrics: ['BarGraph'],
    OneDimManyMetrics: ['MultiLineGraph', 'StackedAreaGraph', 'StackedBarchart'],
    TextChunk: ['TextChunk'],
    Tweet: ['Tweet'],
    ShotChart: ['ShotChart'],
    Chord: ['Chord'],
    BubbleChart: ['BubbleChart'],
    Candlestick: ['Candlestick']
  }

  const renderSwitch = {
    'BarGraph': <BarGraph {...props}/>,
    'LineGraph': <LineGraph  {...props} />,
    'MultiLineGraph': <MultiLineGraph  {...props} />,
    'StackedAreaGraph': <StackedAreaGraph  {...props} />,
    'StackedBarchart': <StackedBarchart  {...props} />,
    'Candlestick': <Candlestick {...props} />,
    'WorldChoropleth': <WorldChoropleth {...props}/>,
    'UsChoropleth': <UsChoropleth {...props}/>,
    'TextChunk': <TextChunk  {...props} />,
    'ShotChart': <ShotChart {...props} screen_height={window.innerHeight} screen_width={window.innerWidth}/>,
    'DynamicHexShotChart': <DynamicHexShotChart {...props} screen_height={window.innerHeight} screen_width={window.innerWidth}/>,
    'Tweet': <Tweet {...props} />,
    'Chord': <Chord {...props} screen_height={window.innerHeight} screen_width={window.innerWidth}/>,
    'BubbleChart': <BubbleChart {...props} />,
    'ImageGraph': <ImageGraph {...props} screen_height={window.innerHeight} screen_width={window.innerWidth} />
  }
  const graphSelector = () => {
    return (
    <>
      <> { props.display ? <></> : 
      <select onChange={props.chooseGraph.bind(this, "selected_graph")} value={props.selected_graph}>
      <option value="">Choose A Graph</option>
        {graph_analysis_map[props.selected_analysis].map((graph) => {
            return (<option key = {graph} value={graph}> {graph} </option>)
          })
        }
      </select>}
    </>
    <>
      {renderSwitch[props.selected_graph]}
    </>
    </>)
  }
return (
<div style={{display:'inline-block', width:"95%", maxWidth:'700px'}}>
  { props.selected_analysis !== "" ? graphSelector() : ""}
</div>)
}

export default GraphRouter