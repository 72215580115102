import React from 'react';
import TextChunk from './TextChunk';
import { VictoryBar, VictoryChart, VictoryTheme, VictoryLabel, VictoryAxis, VictoryContainer, VictoryLegend, VictoryStack } from 'victory';
import VictoryStandardGraphFunction from './components/VictoryStandardGraphFunction';


const StackedBarchart = (props) => {
  const bottom_text = { graph_inputs: { textchunk: props.graph_inputs.textchunk_bottom } }
  const top_text = { graph_inputs: { textchunk: props.graph_inputs.textchunk_top } }
  const colors = Object.keys(props.data_for_graph)
  let data_for_graph_copy = {}
  if('component_inputs' in props && 'SORT_BY_KEY' in props.component_inputs && props.component_inputs.SORT_BY_KEY != 'x'){
    const summed_y = {}
    Object.keys(props.data_for_graph).map(x => {
      props.data_for_graph[x].map(y => {
        let v = 0
        y['x'] in summed_y ? v = summed_y[y['x']] : v = 0
        summed_y[y['x']] = y['y'] + v
        })
    })
    let sorted_totals = Object.entries(summed_y)
    .sort(([,a],[,b]) => a-b)
    .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});

    if('ASC_DESC' in props.component_inputs && props.component_inputs.ASC_DESC == 'DESC') {
      sorted_totals = Object.entries(summed_y)
        .sort(([,a],[,b]) => b-a)
        .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    }
    Object.keys(props.data_for_graph).map(second_dim => {Object.keys(sorted_totals).map((xz, i) => {
      if(!(second_dim in data_for_graph_copy)) {data_for_graph_copy[second_dim] = []}
      const map_val = props.data_for_graph[second_dim].filter(z => z['x'] == xz)[0]
      data_for_graph_copy[second_dim][i] = map_val ? map_val : {x: xz, y : 0}
    }) 
    })
  } else {
    data_for_graph_copy = {...props.data_for_graph}
  }
  const palette = ['#F67280', '#6C5B7B', '#355C7D', '#99B898', '#2A363B', '#CC527A', '#F26B38', '#A7226E', '#003B46', '#0F1F38', '#8E7970', '#F55449', '#2A3132', '#763626']
  const color_array = colors.map(x => { return { "name": x } })
  const processed_graph_configs = VictoryStandardGraphFunction(props);

  return (
    <div>
      <TextChunk {...top_text} />
      <VictoryChart
        theme={VictoryTheme.material}
        // animate={{ duration: 100, easing: "bounce", delay: 0 }}
        {...processed_graph_configs['graph_outer_inputs']}
        containerComponent={
          <VictoryContainer
            style={{ ...props.container_style }}
          />
        }
      >
        <VictoryLabel
          text={props.graph_inputs.graphtitle ? props.graph_inputs.graphtitle : ''}
          x={100}
          y={25}
          style={{fontSize: 11}} >
        </VictoryLabel>
        <VictoryLegend
          center={props.graph_inputs.legend_center_title}
          x={+props.graph_inputs.legend_x ?? 0} 
          y={+props.graph_inputs.legend_y ?? 0}
          title={props.graph_inputs.legend_title}
          gutter={+props.graph_inputs.gutter}
          rowGutter={+props.graph_inputs.row_gutter}
          orientation={props.graph_inputs.legend_orientation}
          itemsPerRow={props.graph_inputs.legend_items_per_row}
          style={{ width: `${+props.graph_inputs.legend_width}%`, height: `${+props.graph_inputs.legend_height}px` }}
          colorScale={palette}
          data={color_array}
        />
        <VictoryStack>
          {Object.keys(data_for_graph_copy).map((x, i) => {
            return (<VictoryBar
              style={{
                data: { fill: palette[i] },
                parent: { border: "1px solid #ccc" }
              }}
              data={data_for_graph_copy[x]}
              key={x.replace(" ", "_")}
            />)
          })}
        </VictoryStack>
        <VictoryAxis
          dependentAxis
          label={props.graph_inputs.yaxis ? props.graph_inputs.yaxis : ''}
          standalone={false}
          style={processed_graph_configs['tickyaxisStyle']}
          {...processed_graph_configs['y_tick_inputs']}
        />
        <VictoryAxis
          label={props.graph_inputs.xaxis ? props.graph_inputs.xaxis : ''}
          standalone={false}
          style={processed_graph_configs['tickxaxisStyle']}
          {...processed_graph_configs['x_tick_inputs']}
          tickLabelComponent={<VictoryLabel textAnchor={props.graph_inputs.x_tick_label_anchor ?? "start"} dy={+props.graph_inputs.x_tick_label_offset ?? 0} />}
        />
      </VictoryChart>

      <TextChunk {...bottom_text} />
    </div>
  )

}

export default StackedBarchart