import React, { Component } from 'react';
import { withRouter } from 'react-router';
import useMediaQuery from '@mui/material/useMediaQuery';
import AnalysisRendererDesktop from './AnalysisRendererDesktop';
import AnalysisRendererMobile from './AnalysisRendererMobile';

const AnalysisRenderer = (props) => {
    const mobile_v_desktop = useMediaQuery('(min-width:600px)');

    return !mobile_v_desktop ? <AnalysisRendererMobile {...props}/> : <AnalysisRendererDesktop {...props} />

}

export default withRouter(AnalysisRenderer);