import React, { useState, useEffect } from 'react';
import ReactSlider from 'react-slider';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import AnalysisInputsToGraphVariableMap from '../inputs/rawelements/AnalysisInputsToGraphVariableMap';
import 'react-quill/dist/quill.snow.css';
import analyzerFunction from './AnalyzerFunction';

import '../../containers/blogger/AnalysisBuilder.css'
import './OpenAIDialog.css'
import server_configs from '../../server_configs';
const { htmlToText } = require('html-to-text');

const capitalize = (str, lc, all) => {
  str = str.replaceAll("_", " ")
  if (all) {
      return str.split(" ")
          .map(word => capitalize(word, lc))
          .join(" ")
          .split("-")
          .map(word => capitalize(word, false))
          .join("-");
  } else {
      return lc
          ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
          : str.charAt(0).toUpperCase() + str.slice(1);
  }
}


const OpenAIDialog = (props) => {
  const [response, setResponse] = useState(null);
  const [getOpenAiData, setGetOpenAiData] = useState(0);

  const set_analyzer = () => {
    if(Object.keys(props.text_generation_data).includes('prompt_variable_data_types')){
      const analyzer = analyzerFunction(props.text_generation_data['prompt_variable_data_types'], props.selected_analysis)
      props.text_generation_input_collector({'analyzer': analyzer})
    }
  }

  const tempFunc = (temp) => {
    props.text_generation_input_collector({'temp': temp})
  }

  const maxTokensFunc = (max_tokens) => {
    props.text_generation_input_collector({'max_tokens': max_tokens})
  }

  const topPFunc = (top_p) => {
    props.text_generation_input_collector({'top_p': top_p})
  }

  const frequencyPenaltyFunc = (freq_p) => {
    props.text_generation_input_collector({'frequency_penalty': freq_p})
  }

  const presencePenaltyFunc = (p_pen) => {
    props.text_generation_input_collector({'presence_penalty': p_pen})
  }

  const promptTextFunc = (prompt_text) => {
    props.text_generation_input_collector({'prompt_text': prompt_text.target.value})
  }

  const collectAiPromptStrings = (e, x) => {
    const ai_prompts = JSON.parse(JSON.stringify(props.text_generation_data['prompt_variable_replacements']))
    ai_prompts[x] = e.target.value
    ai_prompts[AnalysisInputsToGraphVariableMap[props.selected_analysis][x]] = e.target.value
    let analyzer = 'UNKNOWN'
    if(Object.keys(props.text_generation_data).includes('prompt_variable_data_types')){
      analyzer = analyzerFunction(props.text_generation_data['prompt_variable_data_types'], props.selected_analysis)
    }
    props.text_generation_input_collector({'prompt_variable_replacements': ai_prompts, 'analyzer': analyzer})
  }
  
  const updateDataTypes = (e, x) => {
    const field_data_types = JSON.parse(JSON.stringify(props.text_generation_data['prompt_variable_data_types']))
    field_data_types[x] = e.target.value
    field_data_types[AnalysisInputsToGraphVariableMap[props.selected_analysis][x]] = e.target.value
    let analyzer = 'UNKNOWN'
    if(Object.keys(props.text_generation_data).includes('prompt_variable_data_types')){
      analyzer = analyzerFunction(props.text_generation_data['prompt_variable_data_types'], props.selected_analysis)
    }
    props.text_generation_input_collector({'prompt_variable_data_types': field_data_types, 'analyzer': analyzer})
  }

  const templateFieldOption = (x) => {
    const skipValue = (y) => {
      if(['SORT_BY_KEY', 'LIMIT_DATA_VALUE', 'ASC_DESC', 'FILTER'].includes(y) || (props.selected_analysis == 'OneDimManyMetrics' && ['Y_DIMENSION', 'Y_DIMENSION_secondary'].includes(y))) { 
        return true} else {
          return false}
    }
    return (skipValue(x) || !Object.keys(props.text_generation_data).includes('prompt_variable_data_types') ? <></> : <TableRow key={`tr_tf_${x}`}>
        <TableCell style={{ fontSize: "20px" }} key={`tr_name${x}`}>{capitalize(x)}</TableCell>
        <TableCell style={{ fontSize: "20px" }} key={`tr_dtype_${x}`}>
            {!x.includes('FILTER') ? <select key={`ai_dtype_${x}`} value={props.text_generation_data['prompt_variable_data_types'][x]} onChange={e => { updateDataTypes(e, x) }} >
                <option value=""> </option>
                <option value="date">Date</option>
                <option value="number">Number</option>
                <option value="string">String</option>
            </select> : <></>}
        </TableCell>
        <TableCell style={{ fontSize: "20px" }} key={`tr_filter_${x}`}>
            {!x.includes('FILTER') ? <input key={`ai_name_${x}`} type="text" value={props.text_generation_data['prompt_variable_replacements'][x]} onChange={e => { collectAiPromptStrings(e, x) }} /> : <></>}
        </TableCell>
    </TableRow>)
  }

  useEffect(() => {
    if(getOpenAiData > 0) {
        const fetchData = async () => {
            setResponse('Loading...')
            const response = await fetch(`${server_configs['api_domain']}/data_blogging/generate_open_ai_text/`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                prompt: htmlToText(props.text_generation_data['prompt_text'], {
                  wordwrap: 130
                }),
                temperature: props.text_generation_data['temp'],
                max_tokens: props.text_generation_data['max_tokens'],
                top_p: props.text_generation_data['top_p'],
                frequency_penalty: props.text_generation_data['frequency_penalty'],
                presence_penalty: props.text_generation_data['presence_penalty']
              })
            });
            const json = await response.json();
            setResponse(json['response_text']);
          };
          fetchData(); 
        }
    }, [getOpenAiData]);

  return (
    <>
      {props.text_generation_data === null ? <></> : <>
        <label>Temperature {props.text_generation_data['temp'] ? props.text_generation_data['temp'] : 0.8}</label>
        <ReactSlider
            className="horizontal-slider"
            thumbClassName="example-thumb"
            trackClassName="example-track"
            min={0} 
            max={100} 
            onAfterChange={(x) => tempFunc(x/100)}
        />
        <label>Max Tokens {props.text_generation_data['max_tokens'] ? props.text_generation_data['max_tokens'] : 75}</label>
        <ReactSlider
            className="horizontal-slider"
            thumbClassName="example-thumb"
            trackClassName="example-track"
            min={0} 
            max={300} 
            onAfterChange={(x) => maxTokensFunc(x)}
        />
        <label>Top P {props.text_generation_data['top_p'] ? props.text_generation_data['top_p'] : 0.62 }</label>
        <ReactSlider
            className="horizontal-slider"
            thumbClassName="example-thumb"
            trackClassName="example-track"
            min={0} 
            max={100} 
            onAfterChange={(x) => topPFunc(x/100)}
        />
        <label>Presence Pentalty {props.text_generation_data['presence_penalty'] ? props.text_generation_data['presence_penalty'] : 0.85} </label>
        <ReactSlider
            className="horizontal-slider"
            thumbClassName="example-thumb"
            trackClassName="example-track"
            min={0} 
            max={200} 
            onAfterChange={(x) => presencePenaltyFunc(x/100)}
        />
        <label>Frequency Penalty {props.text_generation_data['frequency_penalty'] ? props.text_generation_data['frequency_penalty'] : 0.85}</label>
        <ReactSlider
            className="horizontal-slider"
            thumbClassName="example-thumb"
            trackClassName="example-track"
            min={0} 
            max={200} 
            onAfterChange={(x) => frequencyPenaltyFunc(x/100)}
        />
        <br/>
        <div style={{textAlign:"left"}}>Token Replacements: These are strings that represent things you want the AI to highlight (i.e., to focus on a player or country add that name here)</div >
        <br/>
        <input type="text" value={props.text_generation_data['tokens']['MAIN_TOKEN_0']} onChange={e => props.text_generation_input_collector({'tokens': {'MAIN_TOKEN_0': e.target.value}})} />
        {/* <button onClick={() => {appendToken()}}> + </button> */}
        <br/>
        <br/>
        {props.skip_field_input ? <></> : 
        <Table size="small">
          <TableHead>
              <TableRow>
                  <TableCell style={{ fontSize: "20px" }}>Field</TableCell>
                  <TableCell style={{ fontSize: "20px" }}>Datatype</TableCell>
                  <TableCell style={{ fontSize: "20px" }}>String For AI Prompt </TableCell>
              </TableRow>
          </TableHead>
          <TableBody>
              {Object.keys(props.component_inputs).map(x => templateFieldOption(x))}
          </TableBody>
        </Table>}
        <br/>
        <label>Write Your Prompt</label>
        <textarea style={{height:"200px", width:"500px"}} value={props.text_generation_data['prompt_text'] ? props.text_generation_data['prompt_text'] : ''} onChange={promptTextFunc} placeholder="Enter Prompt"/>
        <br/>
        </>}
        <label>Ouput Of Text Generation Without Data</label>
        <div style={{height:200, width:"100%", border: "1px solid #ccc"}}> {response ? response : ''} </div>
        <br/>
        <button onClick={() => {set_analyzer(); props.text_generation_api_function()}}> Generate Text With Data</button>
        <br/>
        <button onClick={() => {setGetOpenAiData(getOpenAiData+1)}}> Generate Text Without Data</button>
        <br/>
    </>
  );
};

export default OpenAIDialog;
