import React from 'react'

const filtersMap = (props, on_change_event) => {
    return {
        start_date: {
            operators: ["equals"],
            label: 'Start Date',
            spark_left_operand: () => 'start_date',
            right_operand: (curr_value, on_change_event) => {
                return (
                    <input
                        type="date"
                        onChange={(e) => { on_change_event(e, "right_operand") }}
                        value={curr_value} />
                )
            }
        },
        end_date: {
            operators: ["equals"],
            label: 'End Date',
            spark_left_operand: () => 'end_date',
            right_operand: (curr_value, on_change_event) => {
                return (
                    <input
                        type="date"
                        onChange={(e) => { on_change_event(e, "right_operand") }}
                        value={curr_value} />
                )
            }
        }
    }
}

export {filtersMap};