import React, { Component } from 'react';
import server_configs from '../../server_configs';
import { Link, withRouter} from "react-router-dom";
import ArticleCardSummary from "../../components/reader/ArticleCardSummary";
import ArticleCardSummaryNoImage from "../../components/reader/ArticleCardSummaryNoImage";
import ArticleCardSummarySmallImage from "../../components/reader/ArticleCardSummarySmallImage";
import TagManager from 'react-gtm-module';
import DataVizDailyDesktop from './DataVizDailyDesktop';
import GoogleAd from '../../components/app/GoogleAd';

import './Blog.css'

class DesktopBlog extends Component {
    state = {
        articles: []
    }

    componentDidMount = () => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'PageView',
                pagePath: 'main-page'
            },
        });
    }

    getArticles = () => {
        let query_string = ''
        if (this.props.match != undefined && this.props.match.params.section_name != undefined) {
            const { section_name } = this.props.match.params;
            query_string = '?section='.concat(section_name.toLowerCase().replaceAll(' ', '_'))
        }
        fetch(`${server_configs['api_domain']}/data_blogging/get_analyses${query_string}`, {
            method: "GET",
            mode: "cors",
            headers: { "Content-Type": "application/json" }
        })
            .then(response => {
                response.json()
                    .then(json => {
                        this.setState((state, props) => ({ articles: json }));
                        TagManager.dataLayer({
                            dataLayer: {
                                event: 'PageView',
                                pagePath: 'data-viz-daily'
                            },
                        });
                    });
            })
    }

    componentDidMount = () => {
        let section_name = ''
        if (this.props.match != undefined && this.props.match.params.section_name != undefined) {
            ({ section_name } = this.props.match.params);
        }
        this.setState((state, props) => ({ section_name: section_name }))
        this.getArticles();
    }

    componentDidUpdate = () => {
        let section_name = ''
        if (this.props.match != undefined && this.props.match.params.section_name != undefined) {
            ({ section_name } = this.props.match.params);
        }
        if (this.state.section_name != section_name) {
            this.setState((state, props) => ({ section_name: section_name }))
            this.getArticles();
        }
    }


    chooseArticleCardRenderFormat = (article, index, section = "left") => {
        if (!Object.keys(article.analytics_metadata).includes("cover_image") || section == 'right') {
            return (
                <>
                    <ArticleCardSummaryNoImage
                        link={`/analysis/${article.analysis_id.$oid}`}
                        key={article.analysis_id.$oid}
                        analytics_metadata={article.analytics_metadata} />
                    <br />
                </>
            )
        } else if ((index == 1 || index % 3 == 0) && index != 0) {
            return (
                <>
                    <ArticleCardSummarySmallImage
                        link={`/analysis/${article.analysis_id.$oid}`}
                        key={article.analysis_id.$oid}
                        analytics_metadata={article.analytics_metadata} />
                    <br />
                </>
            )
        } else if (index % 4 == 0 && index != 0) {
            return (
                <>
                    <ArticleCardSummaryNoImage
                        link={`/analysis/${article.analysis_id.$oid}`}
                        key={article.analysis_id.$oid}
                        analytics_metadata={article.analytics_metadata} />
                    <br />
                </>
            )
        } else {
            return (
                <>
                    <ArticleCardSummary
                        link={`/analysis/${article.analysis_id.$oid}`}
                        key={article.analysis_id.$oid}
                        analytics_metadata={article.analytics_metadata} />
                    <br />
                </>
            )
        }
    }

    renderTopLeft = (article_array) => {
        return article_array.map((x, idx) => {
            if (['title', 'create_date', 'tags'].every(value => Object.keys(x.analytics_metadata).includes(value))) {
                return this.chooseArticleCardRenderFormat(x, idx)
            }
        })
    }
    renderTopRight = (article_array) => {
        return article_array.map((x, idx) => {
            if (['title', 'create_date', 'tags'].every(value => Object.keys(x.analytics_metadata).includes(value))) {
                return this.chooseArticleCardRenderFormat(x, idx, 'right')
            }
        })
    }

    renderBottomRight = (article_array) => {
        return article_array.map((x, idx) => {
            if (['title', 'create_date', 'tags'].every(value => Object.keys(x.analytics_metadata).includes(value))) {
                return (
                    <>
                        <Link to={`/analysis/${x.analysis_id.$oid}`} key={x.analysis_id.$oid} style={{float:"left", textDecoration: "none", color: "black" }}>
                            <p align="left" style={{fontSize:"20px"}}>
                            {x.analytics_metadata.title}
                            </p>
                            <p align="left" style={{fontSize:"15px", paddingTop:"0px"}}>
                            {x.analytics_metadata.description}
                            </p>
                        </Link>
                        <br />
                    </>
                )
            }
        })
    }

    render = () => {
        const { articles } = this.state;
        let section_name = 'Top Stories'
        if (Object.keys(this.props.match.params).includes('section_name')) {
            section_name = this.props.match.params.section_name;
        } else {
            section_name = 'Top Stories'
        }
        return (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "flex-start", maxWidth: "90%", paddingLeft: "5%", paddingRight: "5%" }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", maxWidth: "600px" }}>
                        {<><div style={{ borderBottom: "1px solid grey", display: "flex", textAlign: "left", width: "100%" }}><h1 style={{ fontFamily: "JoanRegular" }}>{section_name}</h1></div><br /></>}
                        {this.renderTopLeft(articles.slice(0, 2))}
                        <div style={{ width:"100%", height: "800px" }}>
                            <DataVizDailyDesktop {...this.props} />
                        </div>
                        {this.renderTopLeft(articles.slice(2, 5))}
                    </div>
                    <div style={{ width: "10px" }}></div>
                    <div style={{ display: "flex", flexDirection: "column", maxWidth: "400px", paddingTop: "33px", alignItems: "flex-start", justifyContent: "flex-start" }}>
                        <h2 style={{ borderBottom: "1px solid grey", fontFamily: "JoanRegular", width: "100%", textAlign: "left" }}>Most Recent</h2>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start" }}>
                            {this.renderTopRight(articles.slice(0, 2))}
                        </div>
                        <GoogleAd />
                        {/* <div style={{ height: "250px", width: "300px", backgroundColor: "red" }}> Ad </div> */}
                        <br />
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start" }}>
                            {this.renderTopRight(articles.slice(2, 5))}
                        </div>
                        {/* <div style={{ height: "250px", width: "300px", backgroundColor: "red" }}> Ad </div> */}
                        <GoogleAd />
                        <br/>
                        <div style={{ display: "flex", 
                                      flexDirection: "column", 
                                      alignItems: "flex-start", justifyContent: "flex-start", 
                                      fontFamily: "JoanRegular", paddingTop:"1px", backgroundColor: "#f7fafa", 
                                      width:"100%", paddingLeft:"6px", paddingRight: "5px", alignContent:"flex-start" }}>
                            {this.renderBottomRight(articles.slice(5, 20))}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(DesktopBlog);