import React, { Component } from 'react';
import server_configs from '../../server_configs';
import { withRouter } from 'react-router';
import {Table, TableHead, TableBody, TableRow, TableCell} from '@mui/material';
import BloggerNavBar from '../../components/blogger/BloggerNavBar';
import TagManager from 'react-gtm-module';
import './ImageManager.css';
import { withAuth0 } from '@auth0/auth0-react';

class ImageManager extends Component {
  state = {
    file_name: '',
    categories: '',
    owner: '',
    accessibility: '',
    image_credits: '',
    all_images: []
  }

  componentDidMount = () => {
    this.getAllImages();
    TagManager.dataLayer({
      dataLayer: {
        event: 'PageView',
        pagePath: 'image-manager'
      },
    });
}

  uploadPictureToState = (e) => {
    const image_data_to_send = {
      picturePreview: URL.createObjectURL(e.target.files[0]),
      uploaded_image_post: e.target.files[0],
      file_name: this.state.file_name,
      categories: this.state.categories,
      owner: this.state.owner,
      accessibility: this.state.accessibility
    }
    this.setState(image_data_to_send)
  }

  uploadPictureToServer = () => {
    const formData = new FormData();
    formData.append("uploaded_image",this.state.uploaded_image_post);
      formData.append("file_name", this.state.file_name)
      formData.append("categories", this.state.categories)
      formData.append("owner", this.state.owner)
      formData.append("accessibility", this.state.accessibility)
      formData.append("image_credits", this.state.image_credits)
    fetch(
      `${server_configs['api_domain']}/data_blogging/upload_image/`, 
        { 
          method: "POST",
          mode: "cors",
          body: formData
        })
          .then(response => {
            response.json()
              .then(json => {
                this.setState((state, props) => ({ ...json}));
                this.getAllImages()
              })
          })
  }

  getAllImages = () => {
    fetch(
      `${server_configs['api_domain']}/data_blogging/get_all_images/`, 
        { 
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          }
        })
          .then(response => {
            response.json()
              .then(json => this.setState((state, props) => ({ all_images: json})))
          })
  }

  deleteImage = (image_id) => {
    fetch(
      `${server_configs['api_domain']}/data_blogging/delete/?image_id=${image_id}`, 
        { 
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          }
        })
          .then(response => {
            response.json()
              .then(json => this.setState((state, props) => ({ all_images: json})))
          })
  }

  setFileName = (e) => {
    e.persist()
    this.setState((state, props) => ({file_name: e.target.value}))
  }
  setImageCredits = (e) => {
    e.persist()
    this.setState((state, props) => ({image_credits: e.target.value}))
  }
  setCategories = (e) => {
    this.setState((state, props) => ({categories: e}))
  }
  setAccessibility = (e) => {
    this.setState((state, props) => ({accessibility: e}))
  }

  render() {
    const {all_images} = this.state;
    if (this.props.auth0.user ? true : false) {
      return (
        <div style={{display:"flex", flexDirection:"row", width:"90%"}}>
        <BloggerNavBar />
          <div style={{paddingLeft: "10px", display:"flex", flexDirection:"row", alignItems:"flex-start"}}>
          <div style={{ display:"flex", flexDirection:"column", alignItems:"flex-start"}}>
            <h2> Add An Image</h2>
            <form style={{ display:"flex", flexDirection:"column", alignItems:"flex-start"}}>
            <div>Choose  File</div>
            <input type="file" name="uploaded_image_post" onChange={(e) => this.uploadPictureToState(e)}/>
            <br/><br/>
            <div>Image Name</div>
            <input type='text' value={this.state.file_name} name="file_name" onChange={(e) => this.setFileName(e)} />
            <br/><br/>
            <div>Image Credits</div>
            <input type='text' value={this.state.image_credits} name="image_credits" onChange={(e) => this.setImageCredits(e)} />
            <br/><br/>
            <div> Categories </div>
            <select
                  name="categories"
                  value={this.state.categories}
                  onChange={(e) => this.setCategories(e.target.value)}>
                      <option></option>
                      <option value="food_and_beverage">Food And Beverage</option>
                      <option value="lifestyle"> Lifestyle</option>
                      <option value="pets"> Pets</option>
                      <option value="family"> Family</option>
                      <option value="landscape_nature"> Landscapes & Nature </option>
                      <option value="beauty"> Beauty</option>
                      <option value="healthcare_medicine"> Medicine</option>
                      <option value="business_finance"> Business & Finance</option>
                      <option value="travel"> Travel </option>
                      <option value="sports_recreation"> Sports & Recreation </option>
                      <option value="holiday_celebration"> Holiday Celebration </option>
                      <option value="education"> Education </option>
                      <option value="weather"> Weather </option>
                      <option value="architecture"> Architecture </option>
                      <option value="science"> Science </option>
                      <option value="education"> Education </option>
                      <option value="environment"> Environment </option>
                      <option value="industry"> Industry </option>
                      <option value="technology"> Technology </option>
                      <option value="transportation"> Transportation </option>
                      <option value="music"> Music </option>
                      <option value="fitness"> Fitness </option>
                      <option value="agriculture"> Agriculture </option>
                      <option value="couples"> Couples </option>
              </select>
              <br/><br/>
            <div> Accessibility </div>
            <select
                  name="accessibility"
                  value={this.state.accessibility}
                  onChange={(e) => this.setAccessibility(e.target.value)}>
                      <option></option>
                      <option value="public">Public</option>
                      <option value="private"> Private</option>
              </select>
            </form>
            <br/>
            <div className="metadatadiv" onClick={this.uploadPictureToServer}> Upload Image</div>
          </div>
          <br/><br/>
          <div style={{paddingLeft: "10px", display:"flex", flexDirection:"column", alignItems:"flex-start"}}>
          <h2> Available Images </h2>
          <div className="pagecontainerlist">
      <div className="lrcontainerlist">
      <Table  size="small">
          <TableHead>
            <TableRow>
              <TableCell style={{fontSize:"30px"}}>Image Name</TableCell>
              <TableCell style={{fontSize:"30px"}}>Image ID</TableCell>
              <TableCell style={{fontSize:"30px"}}>Delete Image</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {all_images.map(x => 
              <TableRow >
                  <TableCell style={{fontSize:"20px"}}>
                  {` ${x.file_name}`}
                  </TableCell>
                  <TableCell  style={{fontSize:"20px"}}>
                  {` ${x.file_id}`}
                  </TableCell>
                  <TableCell  style={{fontSize:"20px"}}>
                      <div className="metadatadiv" onClick={() => this.deleteImage(x.file_id['$oid'])} key={x.file_id} > Delete </ div>
                  </TableCell>
              </TableRow>)}
          </TableBody>
      </Table>
      </div>
  </div>
  </div>
  </div>
        </div>) } else {
          return <p> You Must Login. </p>
        }
  }
}

export default withRouter(withAuth0(ImageManager));
