import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Box, Typography, Link } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    rootBox: {
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
        }
    },
    footerNav: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        marginRight: 'auto',
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(0),

        [theme.breakpoints.down('md')]: {
            width: '100%',
            marginLeft: 'auto',
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(2),
        }
    },
    footerLink: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.down('md')]: {
            marginBottom: theme.spacing(2),
        }
    },
}));

export default function DesktopFooter(props) {
    const classes = useStyles();

    const content = {
        'brand': { image: 'https://drive.google.com/file/d/12Rm2_U9Nmemm4hx_bXVGxtkyqZ4GKm1Y', width: 110 },
        'copy': '© 2023 Data News Journal, All rights reserved.',
        'privacy': 'Privacy Policy',
        'cookie': 'Cookie Policy',
        ...props.content
    };

    let brand;

    if (content.brand.image) {
        brand = <img src={ content.brand.image } alt="" width={ content.brand.width } />;
    } else {
        brand = content.brand.text || '';
    }

    return (
        <footer>
            <Container maxWidth="lg">
                <Box py={6} display="flex" flexWrap="wrap" alignItems="center" className={classes.rootBox}>
                    <Link href="#" color="inherit" underline="none">
                        {brand}
                    </Link>
                    <Box component="nav" className={classes.footerNav}>
                        <Link href="/privacy_policy" variant="body1" color="textPrimary" className={classes.footerLink}>{content['privacy']}</Link>
                        <Link href="/cookie_policy" variant="body1" color="textPrimary" className={classes.footerLink}>{content['cookie']}</Link>
                    </Box>
                    <Typography color="textSecondary" component="p" variant="caption" gutterBottom={false}>{content['copy']}</Typography>
                </Box>
            </Container>
        </footer>
    );
}