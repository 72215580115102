const player_map = {
    'Marc Jackson':'Marc Jackson',
'Pau Gasol':'Pau Gasol',
'Linton Johnson':'Linton Johnson',
'DeSagana Diop':'DeSagana Diop',
'Chris Kaman':'Chris Kaman',
'Clifford Robinson':'Clifford Robinson',
'Eddy Curry':'Eddy Curry',
'Marcus Camby':'Marcus Camby',
'Andre Iguodala':'Andre Iguodala',
'Jarvis Hayes':'Jarvis Hayes',
'Jeff Foster':'Jeff Foster',
'Dirk Nowitzki':'Dirk Nowitzki',
'Nazr Mohammed':'Nazr Mohammed',
'Shaquille O\'Neal':'Shaquille O\'Neal',
'Dale Davis':'Dale Davis',
'Andre Miller':'Andre Miller',
'Tony Battie':'Tony Battie',
'Darius Miles':'Darius Miles',
'Adonal Foyle':'Adonal Foyle',
'Stephen Jackson':'Stephen Jackson',
'Steve Francis':'Steve Francis',
'Dan Gadzuric':'Dan Gadzuric',
'Troy Murphy':'Troy Murphy',
'Antawn Jamison':'Antawn Jamison',
'Jamaal Magloire':'Jamaal Magloire',
'Zydrunas Ilgauskas':'Zydrunas Ilgauskas',
'Jason Richardson':'Jason Richardson',
'Rick Brunson':'Rick Brunson',
'Udonis Haslem':'Udonis Haslem',
'Tim Duncan':'Tim Duncan',
'Juan Dixon':'Juan Dixon',
'Derek Fisher':'Derek Fisher',
'Speedy Claxton':'Speedy Claxton',
'Corey Maggette':'Corey Maggette',
'Kurt Thomas':'Kurt Thomas',
'Carmelo Anthony':'Carmelo Anthony',
'Maurice Evans':'Maurice Evans',
'Joe Smith':'Joe Smith',
'Jason Terry':'Jason Terry',
'Allen Iverson':'Allen Iverson',
'Viktor Khryapa':'Viktor Khryapa',
'Larry Hughes':'Larry Hughes',
'Samuel Dalembert':'Samuel Dalembert',
'Eduardo Najera':'Eduardo Najera',
'Antoine Walker':'Antoine Walker',
'Metta World Peace':'Metta World Peace',
'Mike Dunleavy':'Mike Dunleavy',
'Kirk Hinrich':'Kirk Hinrich',
'Drew Gooden':'Drew Gooden',
'Bruce Bowen':'Bruce Bowen',
'James Posey':'James Posey',
'Peja Stojakovic':'Peja Stojakovic',
'David West':'David West',
'Gilbert Arenas':'Gilbert Arenas',
'Kenny Thomas':'Kenny Thomas',
'Grant Hill':'Grant Hill',
'Mike Bibby':'Mike Bibby',
'Jeff McInnis':'Jeff McInnis',
'Joel Przybilla':'Joel Przybilla',
'Jamaal Tinsley':'Jamaal Tinsley',
'Michael Redd':'Michael Redd',
'Greg Buckner':'Greg Buckner',
'Michael Ruffin':'Michael Ruffin',
'Dwyane Wade':'Dwyane Wade',
'Damon Stoudamire':'Damon Stoudamire',
'Luol Deng':'Luol Deng',
'Zach Randolph':'Zach Randolph',
'Chris Paul':'Chris Paul',
'Lee Nailon':'Lee Nailon',
'Sasha Pavlovic':'Sasha Pavlovic',
'Dwight Howard':'Dwight Howard',
'Bonzi Wells':'Bonzi Wells',
'Elton Brand':'Elton Brand',
'Rasual Butler':'Rasual Butler',
'Matt Barnes':'Matt Barnes',
'Tony Parker':'Tony Parker',
'Sebastian Telfair':'Sebastian Telfair',
'Anthony Johnson':'Anthony Johnson',
'Brendan Haywood':'Brendan Haywood',
'Willie Green':'Willie Green',
'Scot Pollard':'Scot Pollard',
'Cuttino Mobley':'Cuttino Mobley',
'Jason Williams':'Jason Williams',
'Bobby Simmons':'Bobby Simmons',
'Chris Duhon':'Chris Duhon',
'Austin Croshere':'Austin Croshere',
'Antonio Davis':'Antonio Davis',
'Mo Williams':'Mo Williams',
'Jermaine O\'Neal':'Jermaine O\'Neal',
'Jared Jeffries':'Jared Jeffries',
'Devin Harris':'Devin Harris',
'David Wesley':'David Wesley',
'Nene':'Nene',
'Kenyon Martin':'Kenyon Martin',
'Laron Profit':'Laron Profit',
'Stephon Marbury':'Stephon Marbury',
'Desmond Mason':'Desmond Mason',
'Steve Blake':'Steve Blake',
'P.J. Brown':'P.J. Brown',
'DeShawn Stevenson':'DeShawn Stevenson',
'Corliss Williamson':'Corliss Williamson',
'Darius Songaila':'Darius Songaila',
'Darrell Armstrong':'Darrell Armstrong',
'Josh Howard':'Josh Howard',
'Shareef Abdur-Rahim':'Shareef Abdur-Rahim',
'DerMarr Johnson':'DerMarr Johnson',
'Jamal Crawford':'Jamal Crawford',
'Stromile Swift':'Stromile Swift',
'Trevor Ariza':'Trevor Ariza',
'Brad Miller':'Brad Miller',
'Brent Barry':'Brent Barry',
'David Harrison':'David Harrison',
'Mike James':'Mike James',
'Manu Ginobili':'Manu Ginobili',
'Anfernee Hardaway':'Anfernee Hardaway',
'Michael Sweetney':'Michael Sweetney',
'Erick Dampier':'Erick Dampier',
'Kelvin Cato':'Kelvin Cato',
'Earl Boykins':'Earl Boykins',
'Shane Battier':'Shane Battier',
'Robert Horry':'Robert Horry',
'Jerry Stackhouse':'Jerry Stackhouse',
'Mickael Pietrus':'Mickael Pietrus',
'Mike Miller':'Mike Miller',
'Junior Harrington':'Junior Harrington',
'Pat Garrity':'Pat Garrity',
'Aaron Williams':'Aaron Williams',
'Tyson Chandler':'Tyson Chandler',
'Andres Nocioni':'Andres Nocioni',
'Samaki Walker':'Samaki Walker',
'Theo Ratliff':'Theo Ratliff',
'Jerome Williams':'Jerome Williams',
'Fred Jones':'Fred Jones',
'Calbert Cheaney':'Calbert Cheaney',
'Eric Snow':'Eric Snow',
'Jarrett Jack':'Jarrett Jack',
'Ike Diogu':'Ike Diogu',
'Danny Granger':'Danny Granger',
'Ruben Patterson':'Ruben Patterson',
'Brandon Bass':'Brandon Bass',
'Shaun Livingston':'Shaun Livingston',
'Kwame Brown':'Kwame Brown',
'Beno Udrih':'Beno Udrih',
'Gary Payton':'Gary Payton',
'Alonzo Mourning':'Alonzo Mourning',
'Jake Tsakalidis':'Jake Tsakalidis',
'Brian Cardinal':'Brian Cardinal',
'Chris Andersen':'Chris Andersen',
'Robert Traylor':'Robert Traylor',
'Sarunas Jasikevicius':'Sarunas Jasikevicius',
'Kyle Korver':'Kyle Korver',
'Lionel Chalmers':'Lionel Chalmers',
'Hedo Turkoglu':'Hedo Turkoglu',
'Earl Watson':'Earl Watson',
'Quinton Ross':'Quinton Ross',
'Mario Kasun':'Mario Kasun',
'Zarko Cabarkapa':'Zarko Cabarkapa',
'Jason Kapono':'Jason Kapono',
'Travis Outlaw':'Travis Outlaw',
'Michael Bradley':'Michael Bradley',
'Brian Skinner':'Brian Skinner',
'J.R. Smith':'J.R. Smith',
'Jameer Nelson':'Jameer Nelson',
'Jamison Brewer':'Jamison Brewer',
'Bryon Russell':'Bryon Russell',
'Peter Ramos':'Peter Ramos',
'Zendon Hamilton':'Zendon Hamilton',
'Richie Frahm':'Richie Frahm',
'Rasho Nesterovic':'Rasho Nesterovic',
'Ben Gordon':'Ben Gordon',
'Rodney White':'Rodney White',
'Chris Wilcox':'Chris Wilcox',
'George Lynch':'George Lynch',
'Kendall Gill':'Kendall Gill',
'Zeljko Rebraca':'Zeljko Rebraca',
'John Salmons':'John Salmons',
'Aaron McKie':'Aaron McKie',
'Lucious Harris':'Lucious Harris',
'Kevin Martin':'Kevin Martin',
'Mikki Moore':'Mikki Moore',
'Francisco Elson':'Francisco Elson',
'Eric Piatkowski':'Eric Piatkowski',
'Eddie House':'Eddie House',
'Anthony Peeler':'Anthony Peeler',
'Kevin Ollie':'Kevin Ollie',
'Marcus Fizer':'Marcus Fizer',
'Stacey Augmon':'Stacey Augmon',
'Monta Ellis':'Monta Ellis',
'Shandon Anderson':'Shandon Anderson',
'Greg Ostertag':'Greg Ostertag',
'Glenn Robinson':'Glenn Robinson',
'Marquis Daniels':'Marquis Daniels',
'Nikoloz Tskitishvili':'Nikoloz Tskitishvili',
'Kirk Snyder':'Kirk Snyder',
'Josh Davis':'Josh Davis',
'Matt Freije':'Matt Freije',
'Vin Baker':'Vin Baker',
'DJ Mbenga':'DJ Mbenga',
'Charles Smith':'Charles Smith',
'Othella Harrington':'Othella Harrington',
'Adrian Griffin':'Adrian Griffin',
'Bruno Sundov':'Bruno Sundov',
'Marko Jaric':'Marko Jaric',
'Luis Flores':'Luis Flores',
'Ha Seung-Jin':'Ha Seung-Jin',
'Eddie Gill':'Eddie Gill',
'Frank Williams':'Frank Williams',
'Chris Mihm':'Chris Mihm',
'Kobe Bryant':'Kobe Bryant',
'Al Harrington':'Al Harrington',
'Joe Johnson':'Joe Johnson',
'Ira Newble':'Ira Newble',
'Caron Butler':'Caron Butler',
'Ronald Murray':'Ronald Murray',
'Lamar Odom':'Lamar Odom',
'Luke Ridnour':'Luke Ridnour',
'Josh Smith':'Josh Smith',
'Damon Jones':'Damon Jones',
'Michael Finley':'Michael Finley',
'Ray Allen':'Ray Allen',
'Chucky Atkins':'Chucky Atkins',
'Rashard Lewis':'Rashard Lewis',
'Jerome James':'Jerome James',
'Rodney Rogers':'Rodney Rogers',
'Sam Cassell':'Sam Cassell',
'Nick Van Exel':'Nick Van Exel',
'Gordan Giricek':'Gordan Giricek',
'Zaza Pachulia':'Zaza Pachulia',
'Eddie Jones':'Eddie Jones',
'Deron Williams':'Deron Williams',
'Reggie Evans':'Reggie Evans',
'Matt Harpring':'Matt Harpring',
'Amar\'e Stoudemire':'Amar\'e Stoudemire',
'James Jones':'James Jones',
'Steve Nash':'Steve Nash',
'Raef LaFrentz':'Raef LaFrentz',
'Kevin Garnett':'Kevin Garnett',
'Quentin Richardson':'Quentin Richardson',
'Trenton Hassell':'Trenton Hassell',
'Josh Childress':'Josh Childress',
'Paul Pierce':'Paul Pierce',
'Jarron Collins':'Jarron Collins',
'Reggie Miller':'Reggie Miller',
'Michael Olowokandi':'Michael Olowokandi',
'Shawn Marion':'Shawn Marion',
'Delonte West':'Delonte West',
'Kendrick Perkins':'Kendrick Perkins',
'Troy Hudson':'Troy Hudson',
'Latrell Sprewell':'Latrell Sprewell',
'Ricky Davis':'Ricky Davis',
'Vladimir Radmanovic':'Vladimir Radmanovic',
'Wally Szczerbiak':'Wally Szczerbiak',
'Donyell Marshall':'Donyell Marshall',
'Nate Robinson':'Nate Robinson',
'Brian Cook':'Brian Cook',
'Mehmet Okur':'Mehmet Okur',
'Marvin Williams':'Marvin Williams',
'Donta Smith':'Donta Smith',
'Tyronn Lue':'Tyronn Lue',
'Jonathan Bender':'Jonathan Bender',
'Jason Hart':'Jason Hart',
'Anderson Varejao':'Anderson Varejao',
'Mark Blount':'Mark Blount',
'Tim Thomas':'Tim Thomas',
'Danny Fortson':'Danny Fortson',
'Michael Doleac':'Michael Doleac',
'Etan Thomas':'Etan Thomas',
'Channing Frye':'Channing Frye',
'Stephen Graham':'Stephen Graham',
'Jumaine Jones':'Jumaine Jones',
'Orien Greene':'Orien Greene',
'Antonio Daniels':'Antonio Daniels',
'Brian Grant':'Brian Grant',
'Qyntel Woods':'Qyntel Woods',
'Jared Reiner':'Jared Reiner',
'Nick Collison':'Nick Collison',
'Esteban Batista':'Esteban Batista',
'Alan Henderson':'Alan Henderson',
'Michael Curry':'Michael Curry',
'Casey Jacobsen':'Casey Jacobsen',
'Maurice Taylor':'Maurice Taylor',
'Leandro Barbosa':'Leandro Barbosa',
'Justin Reed':'Justin Reed',
'Derek Anderson':'Derek Anderson',
'Ansu Sesay':'Ansu Sesay',
'Keyon Dooling':'Keyon Dooling',
'Devin Brown':'Devin Brown',
'Al Jefferson':'Al Jefferson',
'Christian Laettner':'Christian Laettner',
'Jackie Butler':'Jackie Butler',
'Milt Palacio':'Milt Palacio',
'Jiri Welsch':'Jiri Welsch',
'Keith McLeod':'Keith McLeod',
'Tom Gugliotta':'Tom Gugliotta',
'Steven Hunter':'Steven Hunter',
'Tierre Brown':'Tierre Brown',
'James Singleton':'James Singleton',
'Ben Wallace':'Ben Wallace',
'Daniel Ewing':'Daniel Ewing',
'Richard Hamilton':'Richard Hamilton',
'James Thomas':'James Thomas',
'Rasheed Wallace':'Rasheed Wallace',
'Robert Whaley':'Robert Whaley',
'Tayshaun Prince':'Tayshaun Prince',
'David Lee':'David Lee',
'Eddie Griffin':'Eddie Griffin',
'Chauncey Billups':'Chauncey Billups',
'Bo Outlaw':'Bo Outlaw',
'Fred Hoiberg':'Fred Hoiberg',
'Marcus Banks':'Marcus Banks',
'Wesley Person':'Wesley Person',
'Mark Madsen':'Mark Madsen',
'Brian Scalabrine':'Brian Scalabrine',
'Tony Allen':'Tony Allen',
'Francisco Garcia':'Francisco Garcia',
'John Thomas':'John Thomas',
'Billy Thomas':'Billy Thomas',
'Malik Rose':'Malik Rose',
'Antonio McDyess':'Antonio McDyess',
'Carlos Arroyo':'Carlos Arroyo',
'Kris Humphries':'Kris Humphries',
'Walter McCarty':'Walter McCarty',
'Luke Jackson':'Luke Jackson',
'Calvin Booth':'Calvin Booth',
'Donell Taylor':'Donell Taylor',
'Anthony Grundy':'Anthony Grundy',
'Awvee Storey':'Awvee Storey',
'Moochie Norris':'Moochie Norris',
'Emeka Okafor':'Emeka Okafor',
'Jacque Vaughn':'Jacque Vaughn',
'Vince Carter':'Vince Carter',
'Primoz Brezec':'Primoz Brezec',
'Jason Kidd':'Jason Kidd',
'Nenad Krstic':'Nenad Krstic',
'Brevin Knight':'Brevin Knight',
'Gerald Wallace':'Gerald Wallace',
'Chris Webber':'Chris Webber',
'Chris Bosh':'Chris Bosh',
'Jalen Rose':'Jalen Rose',
'Jason Collins':'Jason Collins',
'Melvin Ely':'Melvin Ely',
'Rafer Alston':'Rafer Alston',
'Loren Woods':'Loren Woods',
'Richard Jefferson':'Richard Jefferson',
'Obinna Ekezie':'Obinna Ekezie',
'Predrag Drobnjak':'Predrag Drobnjak',
'Dan Dickau':'Dan Dickau',
'Royal Ivey':'Royal Ivey',
'Keith Van Horn':'Keith Van Horn',
'Jackson Vroman':'Jackson Vroman',
'Jabari Smith':'Jabari Smith',
'Tony Delk':'Tony Delk',
'Andrei Kirilenko':'Andrei Kirilenko',
'Matt Carroll':'Matt Carroll',
'Matt Bonner':'Matt Bonner',
'Morris Peterson':'Morris Peterson',
'Andrew DeClercq':'Andrew DeClercq',
'Jason Collier':'Jason Collier',
'Doug Christie':'Doug Christie',
'Jerome Moiso':'Jerome Moiso',
'Johan Petro':'Johan Petro',
'Bostjan Nachbar':'Bostjan Nachbar',
'Rodney Buford':'Rodney Buford',
'Boris Diaw':'Boris Diaw',
'Travis Best':'Travis Best',
'Brandon Hunter':'Brandon Hunter',
'Howard Eisley':'Howard Eisley',
'Keith Bogans':'Keith Bogans',
'Kedrick Brown':'Kedrick Brown',
'Mark Jones':'Mark Jones',
'Lamond Murray':'Lamond Murray',
'Smush Parker':'Smush Parker',
'Bernard Robinson':'Bernard Robinson',
'Joey Graham':'Joey Graham',
'Jose Calderon':'Jose Calderon',
'Zoran Planinic':'Zoran Planinic',
'Erick Strickland':'Erick Strickland',
'Malik Allen':'Malik Allen',
'Luke Walton':'Luke Walton',
'Charlie Villanueva':'Charlie Villanueva',
'Rafael Araujo':'Rafael Araujo',
'Linas Kleiza':'Linas Kleiza',
'Fabricio Oberto':'Fabricio Oberto',
'Andrew Bynum':'Andrew Bynum',
'Yaroslav Korolev':'Yaroslav Korolev',
'Maciej Lampe':'Maciej Lampe',
'Sasha Vujacic':'Sasha Vujacic',
'Carlos Delfino':'Carlos Delfino',
'Andre Owens':'Andre Owens',
'Pape Sow':'Pape Sow',
'Damien Wilkins':'Damien Wilkins',
'Kareem Rush':'Kareem Rush',
'Ryan Bowen':'Ryan Bowen',
'Yao Ming':'Yao Ming',
'Tracy McGrady':'Tracy McGrady',
'Martell Webster':'Martell Webster',
'John Edwards':'John Edwards',
'Juwan Howard':'Juwan Howard',
'Devin Green':'Devin Green',
'Jim Jackson':'Jim Jackson',
'Raymond Felton':'Raymond Felton',
'Lorenzen Wright':'Lorenzen Wright',
'Luther Head':'Luther Head',
'Kenny Anderson':'Kenny Anderson',
'Rashad McCants':'Rashad McCants',
'Bobby Jackson':'Bobby Jackson',
'Shavlik Randolph':'Shavlik Randolph',
'Dikembe Mutombo':'Dikembe Mutombo',
'Antonio Burks':'Antonio Burks',
'Eric Williams':'Eric Williams',
'CJ Miles':'CJ Miles',
'Andray Blatche':'Andray Blatche',
'Josh Powell':'Josh Powell',
'Sergei Monia':'Sergei Monia',
'Andris Biedrins':'Andris Biedrins',
'Will Bynum':'Will Bynum',
'Mark Pope':'Mark Pope',
'Jannero Pargo':'Jannero Pargo',
'Hakim Warrick':'Hakim Warrick',
'Andre Barrett':'Andre Barrett',
'Dahntay Jones':'Dahntay Jones',
'Baron Davis':'Baron Davis',
'Kevin Burleson':'Kevin Burleson',
'Sean Marks':'Sean Marks',
'Raja Bell':'Raja Bell',
'Tony Massenburg':'Tony Massenburg',
'Steven Smith':'Steven Smith',
'Mike Wilks':'Mike Wilks',
'Robert Swift':'Robert Swift',
'Tamar Slay':'Tamar Slay',
'Pat Burke':'Pat Burke',
'Salim Stoudamire':'Salim Stoudamire',
'Scott Padgett':'Scott Padgett',
'Lindsey Hunter':'Lindsey Hunter',
'Horace Jenkins':'Horace Jenkins',
'Jermaine Jackson':'Jermaine Jackson',
'Jon Barry':'Jon Barry',
'Terence Morris':'Terence Morris',
'Jelani McCoy':'Jelani McCoy',
'Elden Campbell':'Elden Campbell',
'Mateen Cleaves':'Mateen Cleaves',
'Kevin Willis':'Kevin Willis',
'LeBron James':'LeBron James',
'Voshon Lenard':'Voshon Lenard',
'Ronald Dupree':'Ronald Dupree',
'Scott Williams':'Scott Williams',
'Dijon Thompson':'Dijon Thompson',
'Sharrod Ford':'Sharrod Ford',
'Julius Hodge':'Julius Hodge',
'Andrew Bogut':'Andrew Bogut',
'Ryan Gomes':'Ryan Gomes',
'Toni Kukoc':'Toni Kukoc',
'Jahidi White':'Jahidi White',
'Eddie Basden':'Eddie Basden',
'Wang Zhi-zhi':'Wang Zhi-zhi',
'John Lucas III':'John Lucas III',
'Lonny Baxter':'Lonny Baxter',
'Allan Houston':'Allan Houston',
'Ervin Johnson':'Ervin Johnson',
'Damone Brown':'Damone Brown',
'Shawn Bradley':'Shawn Bradley',
'Bob Sura':'Bob Sura',
'Gerald Green':'Gerald Green',
'Ron Mercer':'Ron Mercer',
'Jake Voskuhl':'Jake Voskuhl',
'Sean May':'Sean May',
'Reece Gaines':'Reece Gaines',
'Michael Stewart':'Michael Stewart',
'Clar. Weatherspoon':'Clar. Weatherspoon',
'Anthony Carter':'Anthony Carter',
'Cory Alexander':'Cory Alexander',
'Mamadou N\'diaye':'Mamadou N\'diaye',
'Alan Anderson':'Alan Anderson',
'Anthony Roberson':'Anthony Roberson',
'Bracey Wright':'Bracey Wright',
'Ben Handlogten':'Ben Handlogten',
'Gerald Fitch':'Gerald Fitch',
'Randy Livingston':'Randy Livingston',
'Lawrence Roberts':'Lawrence Roberts',
'Devean George':'Devean George',
'Anthony Goldwire':'Anthony Goldwire',
'Von Wafer':'Von Wafer',
'Luke Schenscher':'Luke Schenscher',
'Dajuan Wagner':'Dajuan Wagner',
'Paul Shirley':'Paul Shirley',
'Carlos Boozer':'Carlos Boozer',
'Aaron Miles':'Aaron Miles',
'Vlade Divac':'Vlade Divac',
'Raul Lopez':'Raul Lopez',
'Kaniel Dickens':'Kaniel Dickens',
'Curtis Borchardt':'Curtis Borchardt',
'Derrick Coleman':'Derrick Coleman',
'Anthony Miller':'Anthony Miller',
'Darko Milicic':'Darko Milicic',
'Yuta Tabuse':'Yuta Tabuse',
'Darvin Ham':'Darvin Ham',
'Lou Williams':'Lou Williams',
'Darrick Martin':'Darrick Martin',
'Travis Diener':'Travis Diener',
'Vitaly Potapenko':'Vitaly Potapenko',
'T.J. Ford':'T.J. Ford',
'Kerry Kittles':'Kerry Kittles',
'Jamal Sampson':'Jamal Sampson',
'Charlie Bell':'Charlie Bell',
'Aleksandar Radojevic':'Aleksandar Radojevic',
'Rod Strickland':'Rod Strickland',
'Ryan Humphrey':'Ryan Humphrey',
'Wayne Simien':'Wayne Simien',
'Slava Medvedenko':'Slava Medvedenko',
'Theron Smith':'Theron Smith',
'Marcus Haislip':'Marcus Haislip',
'Tremaine Fowlkes':'Tremaine Fowlkes',
'Britton Johnsen':'Britton Johnsen',
'Chris Taft':'Chris Taft',
'Rawle Marshall':'Rawle Marshall',
'Dorell Wright':'Dorell Wright',
'Antoine Wright':'Antoine Wright',
'Ronny Turiaf':'Ronny Turiaf',
'Geno Carlisle':'Geno Carlisle',
'Noel Felix':'Noel Felix',
'Charles Hayes':'Charles Hayes',
'Lawrence Funderburke':'Lawrence Funderburke',
'Ronnie Price':'Ronnie Price',
'Randy Holcomb':'Randy Holcomb',
'Daniel Santiago':'Daniel Santiago',
'Pavel Podkolzin':'Pavel Podkolzin',
'Boniface N\'Dong':'Boniface N\'Dong',
'Alex Garcia':'Alex Garcia',
'Earl Barron':'Earl Barron',
'Dwayne Jones':'Dwayne Jones',
'Corsley Edwards':'Corsley Edwards',
'Charlie Ward':'Charlie Ward',
'Melvin Sanders':'Melvin Sanders',
'Dion Glover':'Dion Glover',
'Erik Daniels':'Erik Daniels',
'Arvydas Macijauskas':'Arvydas Macijauskas',
'Matt Walsh':'Matt Walsh',
'Alvin Williams':'Alvin Williams',
'Jason Maxiell':'Jason Maxiell',
'Andre Emmett':'Andre Emmett',
'Alex Acker':'Alex Acker',
'Martynas Andriuskevicius':'Martynas Andriuskevicius',
'Tony Bobbitt':'Tony Bobbitt',
'Donnell Harvey':'Donnell Harvey',
'Kirk Penney':'Kirk Penney',
'Ime Udoka':'Ime Udoka',
'Torraye Braggs':'Torraye Braggs',
'Amir Johnson':'Amir Johnson',
'Omar Cook':'Omar Cook',
'Maurice Baker':'Maurice Baker',
'Ibrahim Kutluay':'Ibrahim Kutluay',
'Derrick Zimmerman':'Derrick Zimmerman',
'Ndudi Ebi':'Ndudi Ebi',
'Brandin Knight':'Brandin Knight'
}

export default player_map;