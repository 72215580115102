import { React, useState } from 'react';

const AscDescComponent = (props) => {
    const dimension_data_collector = (e) => {
        const state_component_json = { 'ASC_DESC': e.target.value }
        const query_input_json = {
            input_name: 'ASC_DESC',
            data: e.target.value,
            type: "unquoted_text_input"
        }
        props.input_data_collector(query_input_json, state_component_json);
    }
    return (
        <>
            <p> Sort Data Ascending Or Descending </p>
            <select onChange={(e) => { dimension_data_collector(e) }} value={props.component_inputs['ASC_DESC']}>
                <option value=""></option>
                <option value="ASC">Ascending</option>
                <option value="DESC">Descending</option>
            </select>
        </>
    )
}

export default AscDescComponent