import React from 'react';
import { makeStyles } from '@mui/styles';
import  {Link} from "react-router-dom";

import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


const useStyles = makeStyles({
  root: {
    Width: "100%",
    align:"center"
  },
  media: {
    height: 300,
  },
  title: {
    fontSize: 10,
  },
});

export default function ArticleCardSummary(props) {
  const classes = useStyles();
  const image_url = "https://driving-data-journalism.imgix.net/"+props.analytics_metadata.cover_image;
  return (
    <Card className={classes.root} style={{width:"100%", maxWidth:'1100px', margin: "auto"}}>
      <CardActionArea>
      <Link to={props.link} key={props.link} style={{textDecoration: "none", fontFamily: "JoanRegular", color: "black"}}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2" align="left" style={{fontFamily: "JoanRegular"}}>
            {props.analytics_metadata.title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p" align="left"  style={{fontFamily: "JoanRegular"}}>
            {props.analytics_metadata.description}
          </Typography>
          <Typography className={classes.title} color="textSecondary" align="left" gutterBottom  style={{fontFamily: "JoanRegular"}}>
          {props.analytics_metadata.author ? props.analytics_metadata.author : ''} {props.analytics_metadata.create_date}
        </Typography>

        </CardContent>
        <CardMedia
          className={classes.media}
          image={image_url}
          title={props.analytics_metadata.title}
        />
        </Link>
      </CardActionArea>
    </Card>
  );
}