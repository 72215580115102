import React from 'react'
import MultiStandardFilter from './MultiStandardFilter'
import './FilterStyle.css';

const GroupedFilter = (props) => {
    const new_props = { ...props }

    let component_inputs = {
        [new_props.input_name]: {}
    }
    if (new_props.input_name in new_props.component_inputs) {
        component_inputs = { ...new_props.component_inputs }
    }

    let query_inputs = new_props.query_inputs.map((x, idx) => {
        if (x.input_name === new_props.input_name) {
            return { ...new_props.query_inputs[idx] }
        }
    })

    query_inputs = query_inputs.filter(x => x !== undefined);

    if (query_inputs.length === 0 || !query_inputs[0]) {
        query_inputs = {
            input_name: new_props.input_name,
            data: [],
            type: new_props.filter_type
        }
    } else {
        query_inputs = query_inputs[0]
    }

    const nested_input_data_collector = (query_input_json, state_component_json) => {
        Object.assign(component_inputs[new_props.input_name], state_component_json)
        let filter_in_query_input = false
        query_inputs.data.map((x, idx) => {
            if (x.input_name === query_input_json.input_name) {
                query_inputs.data[idx] = query_input_json
                filter_in_query_input = true
            }
        })
        if (!filter_in_query_input) query_inputs.data.push(query_input_json)

        new_props.input_data_collector(query_inputs, component_inputs)
    }

    const removeFilter = (event, filter_state) => {
        delete component_inputs[new_props.input_name][filter_state]
        query_inputs.data = query_inputs.data.filter(x => x.input_name !== filter_state)
        new_props.input_data_collector(query_inputs, component_inputs)
    }

    const addFilterGroup = (e) => {
        const filter_number = query_inputs.data.length
        const filter_type = e.target.value
        query_inputs.data.push({
            input_name: `MULTI_FILTER_${filter_number}`,
            type: filter_type,
            data: []
        })
        Object.assign(component_inputs[new_props.input_name], { [`MULTI_FILTER_${filter_number}`]: {} })
        new_props.input_data_collector(query_inputs, component_inputs)
    }
    return (
        <>
            <select onChange={addFilterGroup} value="">
                <option value="" >Choose an operator</option>
                <option value="nested_and_join">AND</option>
                <option value="nested_or_join">OR</option>
            </select>
            {query_inputs.data.map((x, idx) => {
                const sub_prop = { ...new_props }
                sub_prop.input_name = `MULTI_FILTER_${idx}`
                sub_prop.component_inputs = component_inputs[new_props.input_name]
                sub_prop.input_data_collector = nested_input_data_collector
                sub_prop.key = idx
                sub_prop.query_inputs = [x]
                return (
                    <div key={idx} style={{flexFlow: "column wrap", alignContent:"space-around"}}>
                        {x.type == "nested_and_join" ? "All Of These" : "Any Of These"}
                        <br/>
                        <button
                            className="filterbutton"
                            onClick={(e) => { removeFilter(e, sub_prop.input_name) }}>
                            Delete This Group
                        </button>
                        <br/>
                        <MultiStandardFilter
                            {...sub_prop}
                            input_name={sub_prop.input_name}
                            type={sub_prop.type}
                            dataset={props.dataset}>
                        </MultiStandardFilter>
                    </div>
                )
            })}
        </>

    )
}

export default GroupedFilter