import React from "react";
import { geoCentroid } from "d3-geo";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  Annotation
} from "react-simple-maps";
import { scaleLinear } from "d3-scale";

import allStates from "./components/allstates.json";
import state_to_name from "./components/state_to_name.json";
import TextChunk from './TextChunk';

const geoUrl = "/us_states.json";

const offsets = {
  VT: [50, -8],
  NH: [34, 2],
  MA: [30, -1],
  RI: [28, 2],
  CT: [35, 10],
  NJ: [34, 1],
  DE: [33, 0],
  MD: [47, 10],
  DC: [49, 21]
};

const UsChoropleth = (props) => {

    const y_vals = props.data_for_graph.map( x => {return x['y']});
    const x_to_y = new Map(props.data_for_graph.map( x => {return [x.x, x.y]}))
    const min_val = Math.min(...y_vals);
    const max_val = Math.max(...y_vals);

    const colorScale = scaleLinear()
    .domain([min_val, max_val])
    .range(["#ffedea", "#ff5233"]);

    const bottom_text = {graph_inputs:{textchunk: props.graph_inputs.textchunk_bottom}}
    const top_text = {graph_inputs:{textchunk: props.graph_inputs.textchunk_top}}  

    const legend = (y, i) =>{
      const rect_width = 20
      const lllm = {}
      const y_step = (max_val-min_val)/5
      if (Object.keys(props.graph_inputs).includes('legend_label_scaler')) {
        lllm['legend_label_scaler'] = (t) => `${Math.round(t / props.graph_inputs['legend_label_scaler'])}`
      } else {
        lllm['legend_label_scaler'] = (t) => `${t}`
      }
      const y_string_val = Math.round(lllm['legend_label_scaler'](y*max_val))
      return (<>
          <rect
              x={i*rect_width*2}
              style={{width:`${rect_width}px`, height:`${rect_width}`}}
              y={rect_width/2+20}
              key={Math.random()}
              fill={colorScale(y_step*i+min_val)}>
          </rect>
          <text style={{ fill: "black" }} fontSize={`.${props.graph_inputs.legend_font_size}em`} x={i*rect_width*2} y={65}>{y_string_val}</text>
      </>)}

  return (
    <div>
    <TextChunk {...top_text} />
    <div>{props.graph_inputs.graph_title ? props.graph_inputs.graph_title : ''}</div>
    <ComposableMap projection="geoAlbersUsa">
      <Geographies geography={geoUrl}>
        {({ geographies }) => (
          <>
            {geographies.map(geo => 
            (
              <Geography
                key={geo.rsmKey}
                stroke="#FFF"
                geography={geo}
                fill={x_to_y.get(state_to_name[geo.properties.name]) ? colorScale(x_to_y.get(state_to_name[geo.properties.name])) : "#F5F4F6"}
              />
            )
            )}
            {geographies.map(geo => {
              const centroid = geoCentroid(geo);
              const cur = allStates.find(s => s.val === geo.id);
              return (
                <g key={geo.rsmKey + "-name"}>
                  {cur &&
                    centroid[0] > -160 &&
                    centroid[0] < -67 &&
                    (Object.keys(offsets).indexOf(cur.id) === -1 ? (
                      <Marker coordinates={centroid}>
                        <text y="2" fontSize={14} textAnchor="middle">
                          {cur.id}
                        </text>
                      </Marker>
                    ) : (
                      <Annotation
                        subject={centroid}
                        dx={offsets[cur.id][0]}
                        dy={offsets[cur.id][1]}
                      >
                        <text x={4} fontSize={14} alignmentBaseline="middle">
                          {cur.id}
                        </text>
                      </Annotation>
                    ))}
                </g>
              );
            })}
          </>
        )}
      </Geographies>
    </ComposableMap>
    <div style={{width:"100%", display:"flex", justifyContent:"left"}}>
        <svg style={{height:"100px"}}>
        <text x={"15%"} y={20} fontSize={`${props.graph_inputs.legend_label_font_size}em`}>{props.graph_inputs.legend_label ?? ""}</text>
          {[0.1, 0.2, 0.4, 0.6, 0.8, 1].map((y, i) => {return legend(y, i)})}
        </svg>
        </div>
    <TextChunk {...bottom_text} />
    </div>
  );
};

export default UsChoropleth;
