import React from 'react'

const filtersMap = (props, on_change_event) => {
    return {
        start_date: {
            operators: ["equals"],
            label: 'Start Date',
            spark_left_operand: () => 'START_DATE',
            right_operand: (curr_value, on_change_event) => {
                return (
                    <input
                        type="date"
                        onChange={(e) => { on_change_event(e, "right_operand") }}
                        value={curr_value} />
                )
            }
        },
        end_date: {
            operators: ["equals"],
            label: 'End Date',
            spark_left_operand: () => 'END_DATE',
            right_operand: (curr_value, on_change_event) => {
                return (
                    <input
                        type="date"
                        onChange={(e) => { on_change_event(e, "right_operand") }}
                        value={curr_value} />
                )
            }
        },
        country: {
            operators: ["equals"],
            label: 'Country',
            spark_left_operand: () => 'COUNTRY',
            right_operand: (curr_value, on_change_event) => {
                return (
                    <input
                        type="text"
                        onChange={(e) => { on_change_event(e, "right_operand") }}
                        value={curr_value} />
                )
            }
        },
        keywords: {
            operators: ["equals"],
            label: 'Search Terms',
            spark_left_operand: () => 'KEYWORDS',
            right_operand: (curr_value, on_change_event) => {
                return (
                    <input
                        type="text"
                        onChange={(e) => { on_change_event(e, "right_operand") }}
                        value={curr_value} />
                )
            }
        }
    }
}

export {filtersMap};