import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';
import './fonts/Joan-Regular.ttf';
import './fonts/X.Template-font-stencil.ttf';
import { Auth0Provider } from "@auth0/auth0-react";
import { getConfig } from "./config";
import history from "./utils/history";

const onRedirectCallback = (appState) => {
    history.push(
        appState && appState.returnTo ? appState.returnTo : window.location.pathname
    );
};

// Please see https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
// for a full list of the available properties on the provider
const config = getConfig();

const providerConfig = {
    domain: config.domain,
    clientId: config.clientId,
    ...(config.audience ? { audience: config.audience } : null),
    redirectUri: window.location.origin,
    scope: "read:current_user update:current_user_metadata",
    onRedirectCallback,
};


axios.defaults.baseURL = 'http://localhost:8000/data_blogging/';


ReactDOM.render(<Auth0Provider {...providerConfig}>
    <App />
    </Auth0Provider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
