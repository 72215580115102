import React from 'react';
import { makeStyles } from '@mui/styles';
import  {Link} from "react-router-dom";
import  {withRouter} from "react-router";

import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {NavLink as RouterNavLink} from "react-router-dom";


const useStyles = makeStyles({
  root: {
    maxWidth: "90%",
    align:"center"
  },
  media: {
    height: 300,
  },
  title: {
    fontSize: 10,
  },
});

const ArticleCardSummaryNoImage = (props) => {
  const classes = useStyles();
  return (
    <Card className={classes.root} style={{ maxWidth:'1100px', margin: "auto", width:"100%", color: "black"}}>
      <CardActionArea>
      <Link to={props.link} key={props.link} style={{textDecoration: "none", color: "black"}}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2" align="left"  style={{fontFamily: "JoanRegular"}}>
            {props.analytics_metadata.title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p" align="left"  style={{fontFamily: "JoanRegular"}}>
            {props.analytics_metadata.description}
          </Typography>
          <Typography className={classes.title} color="textSecondary" align="left" gutterBottom  style={{fontFamily: "JoanRegular"}}>
          {props.analytics_metadata.author ? props.analytics_metadata.author : ''} {props.analytics_metadata.create_date}
          </Typography>
        </CardContent>
        </Link>
      </CardActionArea>
    </Card>
  );
}

export default withRouter(ArticleCardSummaryNoImage)
