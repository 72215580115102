import React from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, Typography, Toolbar, MenuItem, Menu, Button, Link } from '@mui/material';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Logout from '@mui/icons-material/Logout';
import { useState } from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import './DdNavBar.css'
import { Dropdown } from "react-bootstrap";
import { withRouter } from 'react-router';

// const sections = ["World", "US", "Economy", "Markets", "NBA", "NFL", "Climate", "Crime", "Energy", "Real Estate"]
const sections = ["World", "US", "Markets", "NBA", "Real Estate"]

function DdNavBarDesktop() {
    const [isOpen, setIsOpen] = useState(false);
    const {
        user,
        isAuthenticated,
        loginWithRedirect,
        logout,
    } = useAuth0();
    const toggle = () => setIsOpen(!isOpen);

    const logoutWithRedirect = () =>
        logout({
            returnTo: window.location.origin,
        });

    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [visibility, setVisibility] = useState("none")
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const updateVisibility = () => {
        if (visibility == "none") {
            setVisibility("flex")
        } else {
            setVisibility("none")
        }
    }
    return (
        <div style={{ display: "flex", color: "black", flexDirection: "column", backgroundColor: "#f7fafa" }}>
            <div style={{ backgroundColor: "#f7fafa", color: "black", display: "flex", direction: "row", width: "100%" }}>
                <div style={{ paddingTop: "5px", paddingBottom: "5px", fontSize: "40px", fontWeight: "bold", fontFamily: "JoanRegular", alignItems: "center", justifyContent: "center", width: "90%", display: "flex" }}>
                    <Link href="/" underline="none" color="black"> The Data News-Journal </Link>
                </div>

                {/* {!isAuthenticated && (
                    <IconButton
                        id="qsLoginBtn"
                        color="primary"
                        className="btn-margin"
                        onClick={() => loginWithRedirect()}
                        sx={{ fontSize: 15 }}
                    >
                        Sign in
                    </IconButton>
                )}
                {isAuthenticated && (
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 'auto' }}
                        aria-controls={Boolean(anchorEl) ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={Boolean(anchorEl) ? 'true' : undefined}
                    >
                        <span className="user-info">
                            <img
                                src={user.picture}
                                alt="Profile"
                                style={{ borderRadius: 50 }}
                                className="nav-user-profile d-inline-block rounded-circle mr-3"
                                width="30"
                            />
                        </span>
                    </IconButton>)}
                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <MenuItem>
                        <RouterNavLink
                            to="/profile"
                            activeClassName="router-link-exact-active"
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <Avatar />
                        </RouterNavLink>
                        <RouterNavLink
                            to="/profile"
                            activeClassName="router-link-exact-active"
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            Profile
                        </RouterNavLink>
                    </MenuItem>
                    <Divider />
                    <MenuItem>
                        <RouterNavLink
                            to="/myanalyses"
                            id="acctButton"
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            My Articles
                        </RouterNavLink>
                    </MenuItem>
                    <Divider />

                    <MenuItem>
                        <ListItemIcon>
                            <RouterNavLink
                                to="#"
                                id="qsLogoutBtn"
                                onClick={() => logoutWithRedirect()}
                                style={{ textDecoration: 'none', color: 'black' }}
                            >
                                <Logout fontSize="small" />
                            </RouterNavLink>
                        </ListItemIcon>
                        <RouterNavLink
                            to="#"
                            id="qsLogoutBtn"
                            onClick={() => logoutWithRedirect()}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            Log out
                        </RouterNavLink>
                    </MenuItem>
                </Menu> */}
            </div>
            {/* <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", paddingLeft: "10%", paddingRight:"10%", paddingBottom: "15px", paddingTop: "15px" }}>
                {sections.map(section => {
                    return (
                        <RouterNavLink to={`/content_section/${section}`} key={section} style={{ zIndex: 411, textDecoration: "none", color: "black", fontFamily: "JoanRegular", cursor: "pointer" }}>
                            {section}
                        </RouterNavLink>
                    )
                })}

            </div> */}
        </div>
    )
}

export default withRouter(DdNavBarDesktop);


