import styled from "styled-components";
import stripe from "../assets/stripe.svg";
import arrow from "../assets/arrow.svg";
import Loading from "../utils/Loading";
import { useEffect, useState } from "react";
import axios from "axios";

const axiosInstance = axios.create({
    baseURL: 'https://dnj-user-67yqetmaoa-uc.a.run.app/v2/api/payment/',
    timeout: 15000,
    validateStatus: function (status) {
        return status >= 200 && status < 500;
    }
});

const StripeButtonContainer = styled.button`
    width: 400px;
    height: 120px;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    &:hover {
        background: #F6F9FE;
        color: #174ea6;
    }
`;

const StripeLogo = styled.img`
    width: 74px;
    height: 74px;
    border-radius: 8px;
`;

const StripeText = styled.div`
    padding-left: 23px;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
`;

const StripeArrow = styled.img`
    width: 20px;
    height: 20px;
`;

export const StripeButton = (props) => {
    const {user} = props;
    const [account, setAccount] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const loadingView = () => {
        return (
            <StripeButtonContainer>
                <Loading />
            </StripeButtonContainer>
        );
    };

    useEffect(async () => {
        setLoading(true);
        const accountResponse = await axiosInstance.get('account', { params: { userId: user.sub } });
        if (accountResponse.data.url !== undefined) {
            setAccount(accountResponse.data.url);
        }
        setLoading(false);
    }, []);

    const clickStripeButton = async () => {
        if (loading) {
            return;
        }
        if (account !== undefined) {
            window.open(account, '_blank', 'noreferrer');
        } else {
            setLoading(true);
            try {
                const currentUrl = window.location.href;
                const onboardingResponse = await axiosInstance.get('onboarding', 
                    { params: { email: user.email, userId: user.sub, returnUrl: currentUrl } });
                if (onboardingResponse.data.url !== undefined) {
                    setLoading(false);
                    window.open(onboardingResponse.data.url, '_blank', 'noreferrer');
                    return;
                }
                setLoading(false);
            } catch (ex) {
                setLoading(false);
                throw ex;
            }
        }
    };

    const stripeButtonText = account !== undefined ? 
        'See your payout dashboard' : 'Link to your Stripe Account';
    return loading ? loadingView() : (
        <StripeButtonContainer onClick={() => 
            clickStripeButton()}>
            <StripeLogo src={stripe} />
            <StripeText>{stripeButtonText}</StripeText>
            <StripeArrow src={arrow} />
        </StripeButtonContainer>
    );
};

export default StripeButton;
