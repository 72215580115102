const team_map = {'ORL':'Orlando',
'GSW':'Golden State',
'WAS':'Washington Wizards',
'MIA':'Miami Heat',
'POR':'Portland Trailblazers',
'DEN':'Denver Nuggest',
'SAS':'San Antonion Spurs',
'PHI':'Philadelphia 76ers',
'NOH':'New Orleans Pelicans',
'IND':'Indiana Pacers',
'CLE':'Cleveland Cavaliers',
'SAC':'Sacramento Kings',
'ATL':'Atlanta Hawks',
'SEA':'Seattle Supersonics',
'CHI':'Chicago Bulls',
'UTA':'Utah Jazz',
'BOS':'Boston Celtics',
'MIN':'Minnesota Timberwolves',
'NYK':'New York Knicks',
'NJN':'New Jersey Nets',
'TOR':'Toronto Raptors',
'MIL':'Milwaukee Bucks',
'LAL':'Los Angeles Lakers',
'DAL':'Dallas Mavericks',
'HOU':'Houston Rockets',
'PHX':'Phioenix SUns',
'CHA':'Charlotte Hornets',
'LAC':'Los Angeles Clippers',
'MEM':'Memphis Grizzlies',
'DET':'Detroit Pisons',
'NOK':'New Orleans Pelicans',
'NULL':'NULL'}

export default team_map;