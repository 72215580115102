import React from 'react'
import GroupedFilter from '../rawelements/filtercomponents/GroupedFilters';
import StandardDimMetInput from '../rawelements/dimmetriccomponents/StandardDimMetInput';

const ShotChart = (props) => {
  return (
    <>
      <div>Player For Shot Chart Filter</div>
      <GroupedFilter
        {...props}
        input_name="FILTER_1"
        filter_type="nested_or_join">
      </GroupedFilter>
      <div>Comparison Set For Player</div>
      <GroupedFilter
        {...props}
        input_name="FILTER_2"
        filter_type="nested_or_join">
      </GroupedFilter>
    </>
  )
}

export default ShotChart