const AnalysisInputsToGraphVariableMap = {
    BubbleChart: {
        X_DIMENSION: 'x',
        Y_DIMENSION: 'y',
        RADIUS: 'amount',
        LABEL: 'data_label'
    },
    CandleStick: {
        X_DIMENSION: 'x',
        Y_DIMENSION: 'y'
    },
    Chord: {
        ASSISTED_PLAYER: 'assisted_player',
        ASSISTING_PLAYER: 'assisting_player',
        ASSISTS: 'assists'
    },
    NoDimManyMetrics: {
        X_DIMENSION: 'x',
        Y_DIMENSION: 'y'        
    },
    OneDimManyMetrics: {
        X_DIMENSION: 'x',
        Y_DIMENSION: 'y'
    },
    OneDimOneMetric: {
        X_DIMENSION: 'x',
        Y_DIMENSION: 'y'
    },
    ShotChart: {
        OPACITY: 'opacity',
        COLOR: 'color',
        HEXBIN: 'hexbin'
    },
    TwoDimOneMetric: {
        X_DIMENSION: 'x',
        Y_DIMENSION: 'y',
        '2ND_DIMENSION': 'second_dimension'
    }
}

export default AnalysisInputsToGraphVariableMap;