
const dimensionsMap = () => {
    const dim_map = {
        assisting_player: {label: 'Assisting Player', spark: () => 'IF(assist IS NOT NULL, assist, "No Assist")'},
        assisted_player: {label: 'Assisted Player', spark: () => 'IF(assist IS NOT NULL, player, "No Assist")'},
        assisted_shot: {label: 'Assisted Shot', spark: () => 'IF(shot_result="made", IF(assist IS NOT NULL, "Assisted","Unassisted"), "No Made Shot")'},
        away_players: {label: 'Away Players', spark: () => 'away_players'},
        away_team: {label: 'Away Team', spark: () => 'away_team'},
        blocking_player: {label: 'Blocking Player', spark: () => 'IF(block IS NOT NULL, block, "No block")'},
        blocked_player: {label: 'Blocked Player', spark: () => 'IF(block IS NOT NULL, player, "No block")'},
        blocked_shot: {label: 'Blocked Shot', spark: () => 'IF(shot_result="missed", IF(block IS NOT NULL, "Blocked","Missed"), IF(shot_result="made", "Made Shot", "No Shot Attempt"))'},
        date: {label: 'date', spark: () => 'date'},
        month: {label: 'month', spark: () => 'CONCAT(YEAR(date), "-", MONTH(date))'},
        defense_players: {label: 'defense_players', spark: () => 'defense_players'},
        defense_team: {label: 'defense_team', spark: () => 'defense_team'},
        detailed_event_type: {label: 'detailed_event_type', spark: () => 'detailed_event_type'},
        elapsed_time_quarter: {label: 'elapsed_time_quarter', spark: () => 'elapsed_time_quarter'},
        event_type: {label: 'event_type', spark: () => 'event_type'},
        free_throw_num: {label: 'free_throw_num', spark: () => 'free_throw_num'},
        free_throw_outof: {label: 'free_throw_outof', spark: () => 'free_throw_outof'},
        home: {label: 'home', spark: () => 'home'},
        home_players: {label: 'home_players', spark: () => 'home_players'},
        home_team: {label: 'home_team', spark: () => 'home_team'},
        offense_players: {label: 'offense_players', spark: () => 'offense_players'},
        offense_team: {label: 'offense_team', spark: () => 'offense_team'},
        opponent: {label: 'opponent', spark: () => 'opponent'},
        period: {label: 'period', spark: () => 'period'},
        play_length: {label: 'play_length', spark: () => 'play_length'},
        player: {label: 'player', spark: () => 'player'},
        points: {label: 'points', spark: () => 'points'},
        possession: {label: 'possession', spark: () => 'possession'},
        reason: {label: 'reason', spark: () => 'reason'},
        regular_playoffs: {label: 'regular_playoffs', spark: () => 'regular_playoffs'},
        remaining_time_quarter: {label: 'remaining_time_quarter', spark: () => 'remaining_time_quarter'},
        season: {label: 'season', spark: () => 'season'},
        shot_distance: {label: 'shot_distance', spark: () => 'shot_distance'},
        shot_result: {label: 'shot_result', spark: () => 'shot_result'},
        steal: {label: 'steal', spark: () => 'IF(steal IS NOT NULL, steal, "No steal")'},
        team: {label: 'team', spark: () => 'team'}
    };
    return dim_map
};

export {dimensionsMap};