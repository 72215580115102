import React, { useEffect } from 'react';
import server_configs from '../../server_configs';
import { useState } from 'react';
import {useAuth0} from "@auth0/auth0-react";

const ArticleMetaDataCollector = (props) => {
    const [images, setImages] = useState([])
    const {
        user,
        isAuthenticated
    } = useAuth0();

    const GetAllImages = () => useEffect(() => {
        fetch(
          `${server_configs['api_domain']}/data_blogging/get_all_images/`, 
            { 
              method: "GET",
              mode: "cors",
              headers: {
                "Content-Type": "application/json",
              }
            })
              .then(response => {
                return response.json()
                  .then(json => (setImages(json)))})
              }, [])
      GetAllImages()

    return (
        <>
            <label> Select A Cover Image (Optional)</label>
            <select
                name="cover_image"
                value={props.article_meta_inputs['cover_image']}
                onChange={(e) => props.updateArticleMetadata({cover_image:e.target.value})}>
                    <option></option>
                    {images.map(x => <option key={x.file_id} value={x.file_id}> {x.file_name}</option>)}
            </select>
            <br />
            <label> Title</label>
            <input onChange={(e) => props.updateArticleMetadata({title: e.target.value})}
                name="title"
                value={props.article_meta_inputs['title']} />
            <br />
            <label> Description</label>
            <input onChange={(e) => props.updateArticleMetadata({description: e.target.value})} 
                name="description"
                value={props.article_meta_inputs['description']} />
            <br />
            <label> Creation Date</label>
            <div>
            <input type="date" onChange={(e) => props.updateArticleMetadata({create_date: e.target.value})}
                name="date"
                value={props.article_meta_inputs['create_date']} />
            </div>
            <br />
            <label> Article Tags</label>
            <input onChange={(e) => props.updateArticleMetadata({tags: e.target.value})} 
                name="tags"
                value={props.article_meta_inputs['tags']}/>
            <label> Daily Data Viz?</label>
            <input onChange={(e) => {
                let set_ddv = false
                if(props.article_meta_inputs['daily_data_viz']) {
                    set_ddv=false
                } else {
                    set_ddv=true
                }
                props.updateArticleMetadata({daily_data_viz: set_ddv})}} 
                name="daily_data_viz"
                checked={props.article_meta_inputs['daily_data_viz']}
                type="checkbox"/>
        </>
    )
}

export default ArticleMetaDataCollector;
