import React, { Component } from 'react';
import { withRouter } from 'react-router';
import MoreArticles from './MoreArticles';
import TagManager from 'react-gtm-module'
import server_configs from '../../server_configs';
import ArticleElementRouter from '../../components/reader/ArticleElementRouter';
import Footer from '../../components/reader/Footer';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CompletionBar from '../../components/reader/CompletionBar';
import ReactGA from 'react-ga';
import ArticleIntroBlock from '../../components/reader/ArticleIntroBlock';
import { Helmet } from 'react-helmet-async';
import { Link } from "react-router-dom";
import ArticleCardSummaryNoImage from "../../components/reader/ArticleCardSummaryNoImage";
import GoogleAd from '../../components/app/GoogleAd';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import './AnalysisRenderer.css'
import ShareModal from './ShareModal';


class ArticleRendererDesktop extends Component {
    state = {
        article_data: null,
        current_element_col: 0,
        showModal: false,
        hasShownModal: false
    }

    constructor(props) {
        super(props);
        this.sentinel = React.createRef();
        this.observer = null;
    }


    getArticleData = () => {
        const { analysis_id } = this.props.match.params;
        fetch(`${server_configs['api_domain']}/data_blogging/get_analysis?analysis_id=${analysis_id}`, {
            method: "GET",
            mode: "cors",
            headers: { "Content-Type": "application/json" }
        })
            .then(response => {
                response.json()
                    .then(json => {
                        this.setState((state, props) => ({ article_data: json }))
                    });
            })
    }
    getNewArticleData = (server_configs) => {
        return (analysis_id) => {
            fetch(`${server_configs['api_domain']}/data_blogging/get_analysis?analysis_id=${analysis_id}`, {
                method: "GET",
                mode: "cors",
                headers: { "Content-Type": "application/json" }
            })
                .then(response => {
                    response.json()
                        .then(json => {
                            this.setState((state, props) => ({ article_data: json, current_element_col: 0 }))
                            this.scrollToTop()
                        });
                })
        }
    }

    getArticles = () => {
        let query_string = ''
        if (this.props.match != undefined && this.props.match.params.section_name != undefined) {
            const { section_name } = this.props.match.params;
            query_string = '?section='.concat(section_name.toLowerCase().replaceAll(' ', '_'))
        }
        fetch(`${server_configs['api_domain']}/data_blogging/get_analyses${query_string}`, {
            method: "GET",
            mode: "cors",
            headers: { "Content-Type": "application/json" }
        })
            .then(response => {
                response.json()
                    .then(json => {
                        this.setState((state, props) => ({ articles: json }));
                        TagManager.dataLayer({
                            dataLayer: {
                                event: 'PageView',
                                pagePath: 'data-viz-daily'
                            },
                        });
                    });
            })
    }


    componentDidMount = () => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'PageView',
                pagePath: 'analysis-settings'
            },
        });
        this.getArticleData()
        this.getArticles()
        this.observer = new IntersectionObserver((entries) => {
            const [firstEntry] = entries;
            const isIntersecting = firstEntry.isIntersecting || firstEntry.intersectionRatio > 0;
            const { article_data } = this.state;

            if (article_data && isIntersecting) {
                console.log("User has scrolled to the sentinel element.");
                this.setState({ showModal: true });
            }
        });

        if (this.sentinel.current) {
            this.observer.observe(this.sentinel.current);
        }
    }

    componentWillUnmount() {
        if (this.observer) {
            this.observer.disconnect();
        }
    }

    endMvmtTrck = (e) => {
        const { article_data, touchStartLocation, lastTouchLocation, current_element_col } = this.state;
        const analyses = article_data ? article_data.analytics_states : [];
        if (touchStartLocation && lastTouchLocation) {
            const delta = [touchStartLocation[0] - lastTouchLocation[0],
            touchStartLocation[1] - lastTouchLocation[1]];
            const direction = Math.abs(delta[0]) > Math.abs(delta[1]) ? 'x' : 'y';
            if (direction === 'x') {
                const col_change = delta[0] > 0 ? 1 : -1
                if (this.state.current_element_col + col_change >= 0 &&
                    this.state.current_element_col + col_change < analyses.length) {
                    this.scrollToTop()
                    this.setState({
                        current_element_col: this.state.current_element_col + col_change,
                        lastTouchLocation: null,
                        touchStartLocation: null
                    })
                    this.scrollToTop()
                }
            }
        }
    }

    startMvmtTrck = (e) => {
        const touchlist = e.touches[0]
        this.setState({ touchStartLocation: [touchlist.clientX, touchlist.clientY] })
    }

    trckMvmt = (e) => {
        const touchlist = e.touches[0]
        this.setState({ lastTouchLocation: [touchlist.clientX, touchlist.clientY] })

    }

    goToDivTop = (id_div) => {
        const myDiv = document.getElementById("main_div");
        myDiv.scrollTop = 0;
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    scrollToTop = () => {
        // document.getElementById('main_div').scroll({top:0,behavior:'smooth'});

        setTimeout(
            document.getElementById('main_div').scrollTo(0, 0), 250)
    };

    renderTopRight = (article_array) => {
        return article_array.map((x, idx) => {
            if (['title', 'create_date', 'tags'].every(value => Object.keys(x.analytics_metadata).includes(value))) {
                return (<>
                    <ArticleCardSummaryNoImage
                        link={`/analysis/${x.analysis_id.$oid}`}
                        key={x.analysis_id.$oid}
                        analytics_metadata={x.analytics_metadata} />
                    <br />
                </>)
            }
        })
    }

    renderBottomRight = (article_array) => {
        return article_array.map((x, idx) => {
            if (['title', 'create_date', 'tags'].every(value => Object.keys(x.analytics_metadata).includes(value))) {
                return (
                    <>
                        <Link to={`/analysis/${x.analysis_id.$oid}`} key={x.analysis_id.$oid} style={{ float: "left", textDecoration: "none", color: "black" }}>
                            <p align="left" style={{ fontSize: "20px" }}>
                                {x.analytics_metadata.title}
                            </p>
                            <p align="left" style={{ fontSize: "15px", paddingTop: "0px" }}>
                                {x.analytics_metadata.description}
                            </p>
                        </Link>
                        <br />
                    </>
                )
            }
        })
    }

    showBottomComponent = (analyses) => {
        const get_more_articles = () => {
            if (this.state.current_element_col >= analyses.length - 1 &&
                this.state.article_data != undefined &&
                this.state.article_data.analytics_metadata != undefined &&
                this.state.article_data.analytics_metadata != {} &&
                this.state.article_data.analytics_metadata.all_datasets != undefined) {
                return <MoreArticles
                    section_name={this.state.article_data.analytics_metadata.all_datasets[0]}
                    getNewArticle={this.getNewArticleData(server_configs)}
                />
            } else { return <></> }
        }
        const click_down = () => {
            this.scrollToTop()
            this.setState({
                current_element_col: this.state.current_element_col + 1,
                lastTouchLocation: null,
                touchStartLocation: null
            })
        }
        const click_up = () => {
            this.scrollToTop()
            this.setState({
                current_element_col: this.state.current_element_col - 1,
                lastTouchLocation: null,
                touchStartLocation: null
            })
        }
        return (<div style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center"
        }}>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    justifyContent: "center"
                }}>
                {this.state.current_element_col > 0 ?
                    <div
                        onClick={click_up}
                        className="nextpagebutton">
                        {<ArrowBackIcon />}
                    </ div>
                    : <></>}
                <div style={{ minWidth: "10px" }}></div>
                {this.state.current_element_col <= analyses.length - 2 ?
                    <div
                        onClick={click_down}
                        className="nextpagebutton">
                        <ArrowForwardIcon />
                    </ div>
                    : <></>}

            </div>
            {get_more_articles()}
        </div>)
    }


    render() {
        const { showModal, hasShownModal } = this.state;
        const which_ui = window.innerWidth < window.innerHeight ? 'mobile' : 'desktop'
        const { article_data, articles } = this.state;
        const analyses = article_data ? article_data.analytics_states : [];
        return (
            <>
                <Helmet>
                    <title>{article_data ? article_data['analytics_metadata']['title'] : ''}</title>
                    <meta name='description' content={article_data ? article_data['analytics_metadata']['description'] : ''} />
                    <meta property='og:title' content={article_data ? article_data['analytics_metadata']['title'] : ''} />
                    <meta property='og:description' content={article_data ? article_data['analytics_metadata']['description'] : ''} />
                    <meta property='og:image' content={article_data ? `https://driving-data-journalism.imgix.net/${article_data['analytics_metadata']['cover_image']}` : ''} />
                    <meta property='og:url' content={article_data ? `https://www.the-dnj.com/analysis/${article_data['analysis_id']['$oid']}` : ''} />
                    <meta property='og:type' content='article' />
                    <meta name="twitter:creator" content='@DataNewsJournal' />
                    <meta name="twitter:card" content='article' />
                    <meta name="twitter:title" content={article_data ? article_data['analytics_metadata']['title'] : ''} />
                    <meta name="twitter:description" content={article_data ? article_data['analytics_metadata']['description'] : ''} />
                    <meta name="twitter:image" content={article_data ? `https://driving-data-journalism.imgix.net/${article_data['analytics_metadata']['cover_image']}` : ''} />
                    <script type="application/ld+json">
                        {JSON.stringify({
                            "@context": "https://schema.org/",
                            "@type": "Article",
                            "name": article_data ? article_data['analytics_metadata']['title'] : '',
                            "description": article_data ? article_data['analytics_metadata']['description'] : '',
                            "contentReferenceTime": article_data ? article_data['analytics_metadata']['create_date'] : '',
                            "headline": article_data ? article_data['analytics_metadata']['description'] : '',
                            "image": article_data ? `https://driving-data-journalism.imgix.net/${article_data['analytics_metadata']['cover_image']}` : ''
                        })}
                    </script>
                </Helmet>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                    <div style={{ display: "flex", flexDirection: "column", maxHeight: "1500px", overflow: "scroll", maxWidth: "300px", paddingTop: "5px", alignContent: "start", alignItems: "flex-start", justifyContent: "flex-start" }}>
                        <h2 style={{ borderBottom: "1px solid grey", fontFamily: "JoanRegular", width: "100%", textAlign: "left" }}>Most Recent</h2>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start" }}>
                            {articles ? this.renderTopRight(articles.slice(0, 2)) : <></>}
                        </div>
                        <GoogleAd />
                        {/* <div style={{ minHeight: "250px", width: "300px", backgroundColor: "red" }}> Ad </div> */}
                        <br />
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start" }}>
                            {articles ? this.renderTopRight(articles.slice(2, 5)) : <></>}
                        </div>
                        <GoogleAd />
                        {/* <div style={{ minHeight: "250px", width: "300px", backgroundColor: "red" }}> Ad </div> */}
                        <br />
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start", justifyContent: "flex-start",
                            fontFamily: "JoanRegular", paddingTop: "1px", backgroundColor: "#f7fafa",
                            width: "100%", paddingLeft: "6px", paddingRight: "5px", alignContent: "flex-start"
                        }}>
                            {articles ? this.renderBottomRight(articles.slice(5, 20)) : <></>}
                        </div>
                    </div>
                    <div style={{ minWidth: "10px" }}></div>
                    <div style={{ paddingTop: "30px", display: "flex", flexDirection: "column", alignContent: "center" }}>
                        <CompletionBar
                            analysis_length={analyses.length}
                            current_position={this.state.current_element_col} />
                        <br /><br />
                        {this.state.current_element_col == 0 && analyses.length > 0 ? <ArticleIntroBlock {...article_data}></ArticleIntroBlock> : <></>}
                        <div
                            onTouchMove={e => this.trckMvmt(e)}
                            onTouchEnd={e => this.endMvmtTrck(e)}
                            onTouchStart={e => this.startMvmtTrck(e)}
                            className='readerdiv'
                            key={this.state.current_element_col}>
                            <ArticleElementRouter
                                {...analyses[this.state.current_element_col]} />
                            <div style={{ height: "10px" }}></div>
                            <div className='bottom-sentinel' ref={this.sentinel}></div>
                            {!hasShownModal && showModal && (<Popup open={true} modal closeOnDocumentClick={false}>
                                {
                                    close => (<ShareModal onClick={() => this.setState({ showModal: false, hasShownModal: true })} {...article_data} />)
                                }
                            </Popup>)}
                            {this.showBottomComponent(analyses)}
                            <Footer />
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(ArticleRendererDesktop);
