import React, { Component } from 'react';
import { withRouter } from 'react-router';
import useMediaQuery from '@mui/material/useMediaQuery';
import MobileBlog from './MobileBlog';
import DesktopBlog from './DesktopBlog';

import './Blog.css'

const Blog = (props) => {
    const mobile_v_desktop = useMediaQuery('(min-width:600px)');
    return !mobile_v_desktop ? <MobileBlog {...props}/> : <DesktopBlog {...props} />
}

export default withRouter(Blog);