import React from 'react';
import ArticleEditInput from "./ArticleEditInput";

const UsChoroplethInput = (props) => {
    return (
        <>
            <label htmlFor="graph_title"> Graph Title</label>
                <input
                    name="graph_title"
                    value={props.graph_inputs.graph_title}
                    onChange={(e) => props.graph_input_collector({graph_title:e.target.value})} />
                        <label htmlFor="legend_label"> legend label </label>
            <div>
                <input
                    name="legend_label"
                    type="text"
                    value={props.graph_inputs['legend_label']}
                    onChange={(e) => {
                        props.graph_input_collector({legend_label: e.target.value});
                    }}/>
            </div>
            <br/>
            <label htmlFor="legend_label_scaler"> legend label scaler </label>
            <div>
                <input
                    name="legend_label_scaler"
                    type="number"
                    value={props.graph_inputs['legend_label_scaler']}
                    onChange={(e) => {
                        props.graph_input_collector({legend_label_scaler: e.target.value});
                    }}/>
            </div>
            <label htmlFor="legend_label_font_size"> legend label font size </label>
            <div>
                <input
                    name="legend_label_font_size"
                    type="number"
                    value={props.graph_inputs['legend_label_font_size']}
                    onChange={(e) => {
                        props.graph_input_collector({legend_label_font_size: e.target.value});
                    }}/>
            </div>
            <br/>
            <label htmlFor="legend_font_size"> legend font size </label>
            <div>
                <input
                    name="legend_font_size"
                    type="number"
                    value={props.graph_inputs['legend_font_size']}
                    onChange={(e) => {
                        props.graph_input_collector({legend_font_size: e.target.value});
                    }}/>
            </div>
            <br/>
            <label htmlFor="textchunk_top"> text above graph</label>
            <div>
                <ArticleEditInput
                    name="textchunk_top"
                    value={props.graph_inputs['textchunk_top']}
                    onChange={(e) => {
                        props.graph_input_collector({textchunk_top: e});
                    }}/>
            </div>
            <br/><br/><br/><br/><br/>
            <label htmlFor="textchunk_bottom"> text below graph </label>
            <div>
                <ArticleEditInput
                    name="textchunk_bottom"
                    value={props.graph_inputs['textchunk_bottom']}
                    onChange={(e) => {
                        props.graph_input_collector({textchunk_bottom: e});
                    }}/>
            </div>
        </>
    )
}

export default UsChoroplethInput;