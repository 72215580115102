import React, {Component, useEffect, useState} from 'react';
import server_configs from '../../server_configs';
import {Link} from "react-router-dom";
import {Table, TableHead, TableBody, TableRow, TableCell} from '@mui/material';
import BloggerNavBar from '../../components/blogger/BloggerNavBar';
import TagManager from 'react-gtm-module'

import './AnalysisListView.css'
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import Loading from "../../utils/Loading";

const AnalysisListView = () => {
    const [articles, setArticles] = useState([]);

    const {
        user,
        isAuthenticated
    } = useAuth0();

    const getArticles = (user_name) => {
        fetch(`${server_configs['api_domain']}/data_blogging/get_my_analyses?user_name=` + user_name, {
            method: "GET",
            mode: "cors",
            headers: {"Content-Type": "application/json"}
        })
            .then(response => {
                response.json()
                    .then(json => {
                        setArticles(json)
                    });
            })
    }

    const SubmitForApproval = (analysis_id) => {
        fetch(`${server_configs['api_domain']}/data_blogging/submit_for_approval?analysis_id=${analysis_id}`, {
            method: "GET",
            mode: "cors",
            headers: {"Content-Type": "application/json"}
        }).then(response => {getArticles(user.name)})
    }


    const GenerateArticleText = (analysis_id) => {
        fetch(`https://thedatanewsjournal.com/v2/api/generate/article?id=${analysis_id}`, {
            method: "POST",
            mode: "cors",
            headers: {"Content-Type": "application/json"}
        }).then(response => {console.log(response)})
    }

    const Publish = (analysis_id) => {
        fetch(`${server_configs['api_domain']}/data_blogging/publish?analysis_id=${analysis_id}`, {
            method: "GET",
            mode: "cors",
            headers: {"Content-Type": "application/json"}
        }).then(response => {getArticles(user.name)})
    }

    const Promote = (analysis_id) => {
        fetch(`${server_configs['api_domain']}/marketing/run_marketing?analysis_id=${analysis_id}`, {
            method: "GET",
            mode: "cors",
            headers: {"Content-Type": "application/json"}
        }).then(response => {console.log(response)})
    }

    useEffect(() => {
        getArticles(user.name);
        TagManager.dataLayer({
            dataLayer: {
                event: 'PageView',
                pagePath: 'analysis-list'
            },
        });
    }, []);

    const ArticleDeleteApi = (analysis_id) => {
        const method = "GET";

        const request_content = {
            method: method,
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            }
        };

        let request_url = `${server_configs['api_domain']}/data_blogging/delete_analysis/?analysis_id=${analysis_id}`;

        fetch(request_url, request_content)
            .then(response => {
                getArticles(user.name);
            })
    }

    const GetSubmissionState = (submissions, approved, last_saved) => {
        if (approved){
            return 'Approved'
        } else if(submissions.length == 0 || submissions.submit_date == '' || submissions.submit_date < last_saved){
            return 'Unsubmitted'
        } else if(submissions.rejections.length > 0) {
            return `Rejected, reasons: ${submissions.rejections.map(x => x.rejection_reason).concat()}`
        } else {
            return 'Submitted, awaiting review'
        }
    }

    const isWriter = () => {
        if (user['dnj/roles'] !== undefined) {
            return user['dnj/roles'].includes('writer');
        }
        return false;
    };

        if (isAuthenticated && isWriter()) {
            return (
                <div className="pagecontainerlist">
                    <BloggerNavBar/>
                    <div className="lrcontainerlist">
                        <h3>Hi {user.name}, below are your articles</h3>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{fontSize: "20px"}}>Title</TableCell>
                                    <TableCell style={{fontSize: "20px"}}>Description</TableCell>
                                    <TableCell style={{fontSize: "20px"}}>Create Date</TableCell>
                                    <TableCell style={{fontSize: "20px"}}>Submission Status</TableCell>
                                    <TableCell style={{fontSize: "20px"}}>Publishing Status</TableCell>
                                    <TableCell style={{fontSize: "20px"}}>Edit Article</TableCell>
                                    <TableCell style={{fontSize: "20px"}}>View Article</TableCell>
                                    <TableCell style={{fontSize: "20px"}}>Generate Text</TableCell>
                                    <TableCell style={{fontSize: "20px"}}>Submit For Review</TableCell>
                                    <TableCell style={{fontSize: "20px"}}>Publish</TableCell>
                                    <TableCell style={{fontSize: "20px"}}>Delete Article</TableCell>
                                    <TableCell style={{fontSize: "20px"}}>Promote</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {articles.map(x =>
                                    <TableRow>
                                        <TableCell style={{fontSize: "15px"}}>
                                            {` ${x.analytics_metadata.title}`}
                                        </TableCell>
                                        <TableCell style={{fontSize: "15px"}}>
                                            {` ${x.analytics_metadata.description}`}
                                        </TableCell>
                                        <TableCell style={{fontSize: "15px"}}>
                                            {` ${x.analytics_metadata.create_date}`}
                                        </TableCell>
                                        <TableCell style={{fontSize: "15px"}}>
                                            {x.submissions ? ` ${GetSubmissionState(x.submissions, x.approved, x.analytics_metadata.last_saved)}` : 'Error'}
                                        </TableCell>
                                        <TableCell style={{fontSize: "15px"}}>
                                            {x.published ? JSON.stringify(x.published) : 'Error'}
                                        </TableCell>
                                        <TableCell style={{fontSize: "15px"}}>
                                            <Link to={`/analysisbuilder/${x.analysis_id.$oid}`}
                                                  key={x.analysis_id.$oid}> Edit</ Link>
                                        </TableCell>
                                        <TableCell style={{fontSize: "15px"}}>
                                            <Link to={`/analysis/${x.analysis_id.$oid}`}
                                                  key={x.analysis_id.$oid}> View</ Link>
                                        </TableCell>
                                        <TableCell style={{fontSize: "15px"}}>
                                            <button className="metadatadiv"
                                                    onClick={() => GenerateArticleText(x.analysis_id.$oid)}
                                                    key={x.analysis_id.$oid}> Generate
                                            </button>
                                        </TableCell>
                                        <TableCell style={{fontSize: "15px"}}>
                                            <button className="metadatadiv"
                                                 disabled={x.approved || (('submissions' in x) && x.submissions.rejections.length > 0) ? "true" : ""}
                                                 onClick={() => SubmitForApproval(x.analysis_id.$oid)}
                                                 key={x.analysis_id.$oid}> Submit
                                            </button>
                                        </TableCell>
                                        <TableCell style={{fontSize: "15px"}}>
                                            <button className="metadatadiv"
                                                 disabled={x.approved && !x.published ? false : true}
                                                 onClick={() => Publish(x.analysis_id.$oid)}
                                                 key={x.analysis_id.$oid}> Publish
                                            </button>
                                        </TableCell>
                                        <TableCell style={{fontSize: "15px"}}>
                                            <div className="metadatadiv"
                                                 onClick={() => ArticleDeleteApi(x.analysis_id.$oid)}
                                                 key={x.analysis_id.$oid}> Delete
                                            </div>
                                        </TableCell>
                                        <TableCell style={{fontSize: "15px"}}>
                                            <button className="metadatadiv"
                                                 disabled={x.approved && x.published ? false : true}
                                                 onClick={() => Promote(x.analysis_id.$oid)}
                                                 key={x.analysis_id.$oid}> Promote
                                            </button>
                                        </TableCell>
                                    </TableRow>)}
                            </TableBody>
                        </Table>
                    </div>
                </div>
            )
        } else {
            return <h2>You are not authorized to this page.
                Please contact support@datanewsjournal.com for more details.</h2>
        }
}

export default withAuthenticationRequired(AnalysisListView, {
    onRedirecting: () => <Loading/>,
});