import React from 'react'
import StandardFilter from './StandardFilter'
import './FilterStyle.css';


const MultiStandardFilter = (props) => {
    const new_props = { ...props }

    let component_inputs = {
        [new_props.input_name]: {}
    }
    if (new_props.input_name in new_props.component_inputs) {
        component_inputs = { ...new_props.component_inputs }
    }

    let query_inputs = new_props.query_inputs.map((x, idx) => {
        if (x.input_name === new_props.input_name) {
            return { ...new_props.query_inputs[idx] }
        }
    })
    if (query_inputs.length === 0) {
        query_inputs = {
            input_name: new_props.input_name,
            data: [],
            type: new_props.filter_type
        }
    } else {
        query_inputs = query_inputs[0]
    }

    const nested_input_data_collector = (query_input_json, state_component_json, query_inputs) => {
        Object.assign(component_inputs[new_props.input_name], state_component_json)
        let filter_in_query_input = false
        query_inputs.data.map((x, idx) => {
            if (x.input_name === query_input_json.input_name) {
                query_inputs.data[idx] = query_input_json
                filter_in_query_input = true
            }
        })
        if (!filter_in_query_input) query_inputs.data.push(query_input_json)

        new_props.input_data_collector(query_inputs, component_inputs)
    }

    const addFilter = (event) => {
        const curr_idx = Object.keys(component_inputs[new_props.input_name]).length
        Object.assign(component_inputs[new_props.input_name],
            { [`INNER_FILTER_${curr_idx}`]: {} }
        )
        new_props.input_data_collector(query_inputs, component_inputs)
    }

    const removeFilter = (event, filter_state) => {
        delete component_inputs[new_props.input_name][filter_state]
        query_inputs.data = query_inputs.data.filter(x => x.input_name !== filter_state)
        new_props.input_data_collector(query_inputs, component_inputs)
    }

    if (Object.keys(component_inputs[new_props.input_name]).length > 0) {
        const c_ips = component_inputs[new_props.input_name]
        const filterz = Object.keys(c_ips).map((filter_state, idx) => {
            const sub_prop = { ...new_props }
            sub_prop.component_inputs = component_inputs[new_props.input_name]
            sub_prop.input_name = `INNER_FILTER_${idx}`
            sub_prop.input_data_collector = nested_input_data_collector
            sub_prop.key = filter_state
            sub_prop.query_inputs = query_inputs
            return (
                <div key={idx}>
                    <StandardFilter {...sub_prop} />
                    <button className="filterbutton" onClick={(e) => removeFilter(e, filter_state)}>
                        Remove this filter
                    </button>
                    <br/>
                </div>
            )
        })
        return (
            <>
                <button className="filterbutton" onClick={addFilter}>Add a filter</button>
                <br/>
                {filterz}
            </>
        )
    } else {
        return <StandardFilter
            input_name={`INNER_FILTER_0`}
            component_inputs={{ INNER_FILTER_0: null }}
            input_data_collector={nested_input_data_collector}
            query_inputs={query_inputs} 
            dataset={props.dataset}/>
    }
}

export default MultiStandardFilter