import React, { Component } from 'react';
import * as d3Array from 'd3-array';
import * as d3Hexbin from 'd3-hexbin';
import * as d3Scale from 'd3-scale';
import BballCourt from './components/BballCourt'

const Props = {
    screen_width: Number,
    screen_height: Number,
    hover_event: Function,
    hover_away_event: Function,
    click_event: Function,
    // graph_data: Array<Object>, this is still required, but the props stuff needs to be redone
    court_width: Number,
    court_length: Number,
    color_var: String,
    opacity_var: String,
    hex_size_var: String
};

//TODO COMBINE THIS WITH SHOTCHART
const DynamicHexShotChart = (props) => {

    const gen_normalized_hex_radius = () => {
        const radius = d3Array.min([
            props.screen_width / ((props.court_width + 0.5) * Math.sqrt(3)),
            props.screen_height / ((props.court_length + 1 / 3) * 1.5)]);
        return radius;
    }

    const gen_svg_dimensions = () => {
        const rad = gen_normalized_hex_radius();
        return {
            svg_height: (props.court_length + 1 / 3) * 1.5 * rad,
            svg_width: (props.court_width + 1 / 2) * 1.75 * rad,
        }
    }

    const gen_max_hex_radius = () => {
        const radius = d3Array.min([
            props.screen_width / ((props.court_width + 0.5) * Math.sqrt(3)),
            props.screen_height / ((props.court_length + 1 / 3) * 1.5)]);
        return radius;
    }

    const gen_hexbin_centers = () => {
        let centers = [];
        const hex_radius = gen_max_hex_radius();
        for (let i = 1; i < props.court_length; i++) {
            for (let j = 1; j < props.court_width; j++) {
                centers.push([(hex_radius * j * 1.75),
                (hex_radius * i * 1.5)]);
            }
        }
        return centers;
    }

    const gen_opacity_stats = () => {
        const opacities = props.graph_data.map((dat) => {
            return Number(dat[props.opacity_var])
        });
        return {
            min_opacity: d3Array.min(opacities),
            max_opacity: d3Array.max(opacities)
        }
    }

    const gen_color_stats = () => {
        const colors = props.graph_data.map((dat) => {
            return Number(dat.color_var)
        });
        return {
            min_color: d3Array.min(colors),
            max_color: d3Array.max(colors)
        }
    }

    const gen_hex_size_stats = () => {
        const hex_sizes = props.graph_data.map((dat) => {
            return Number(dat.hex_size_var)
        });
        return {
            min_hex_size: d3Array.min(hex_sizes),
            max_hex_size: d3Array.max(hex_sizes)
        }
    }

    const gen_hexbin_fill_data = () => {
        const opacity_stats = gen_opacity_stats()
        const opacity_scaler = d3Scale.scaleLinear()
            .domain([opacity_stats.min_vol, opacity_stats.max_vol])
            .range([0, 1]);

        const color_stats = gen_color_stats()
        const color_scaler = d3Scale.scaleLinear()
            .domain([color_stats.min_color, color_stats.max_color])
            .range(["red", "green"]);

        const max_hex_radius = gen_max_hex_radius();
        const hex_stats = gen_hex_size_stats();
        const hex_size_scaler = d3Scale.scaleLinear()
            .domain([hex_stats.min_hex_size, hex_stats.max_hex_size])
            .range([0, max_hex_radius])

        const graph_data_scaled = props.graph_data.map((dat) => {
            return {
                opacity: dat.opacity,
                color: dat.color,
                opacity_scaled: opacity_scaler(dat[props.opacity_var]),
                color_scaled: color_scaler(dat[props.color_var]),
                hex_radius: hex_size_scaler(dat[props.hex_size_var])
            }
        });
        return graph_data_scaled
    }

    const gen_hexbins = () => {
        const centers = gen_hexbin_centers();
        const hex_radius = gen_normalized_hex_radius();
        const hexbin_paths = d3Hexbin.hexbin();
        const hex_data = gen_hexbin_fill_data();
        return hexbin_paths(centers).map((dat, index) => {
            return (<path d={"M" +
                dat.x + "," +
                dat.y + hexbin_paths.radius(hex_data[index].hex_radius).hexagon()
            }
                key={dat.x + dat.y}
                onMouseOver={props.hover_event}
                onMouseOut={props.hover_away_event}
                onClick={props.click_event}
                data-event={JSON.stringify({
                    index: index,
                    xloc: dat.x,
                    yloc: dat.y,
                    opacity: hex_data[index].opacity,
                    color: hex_data[index].color,
                    radius: hex_data[index].hex_radius
                }
                )}
                stroke={"grey"}
                strokeOpacity={1}
                fillOpacity={hex_data[index].opacity_scaled}
                fill={hex_data[index].color_scaled} />
            );
        })
    }

    const svg_width = gen_svg_dimensions().svg_width;
    const svg_height = gen_svg_dimensions().svg_height;

    return (
        <div>
            <svg
                width={svg_width}
                height={svg_height}>
                <BballCourt
                    screen_width={svg_width}
                    screen_height={svg_height}
                    court_width={props.court_width}
                    court_length={props.court_length} />
                {gen_hexbins()}
            </svg>
        </div>
    );
}

export default DynamicHexShotChart;