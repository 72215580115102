import React from 'react'
import CountrySelector from '../../filtercomponents/CountrySelector';

const filtersMap = (props, on_change_event) => {
    return {
        country: {
            operators: ["equals"],
            label: 'Counry/Economy',
            spark_left_operand: () => 'country',
            right_operand: () => CountrySelector(props, on_change_event, "right_operand")
        },
        start_year: {
            operators: ["equals"],
            label: 'Start year',
            spark_left_operand: () => 'start_year',
            right_operand: (curr_value, on_change_event) => {
                return (
                    <input
                        type="number"
                        onChange={(e) => { on_change_event(e, "right_operand") }}
                        value={curr_value} />
                )
            }
        },
        end_year: {
            operators: ["equals"],
            label: 'End year',
            spark_left_operand: () => 'end_year',
            right_operand: (curr_value, on_change_event) => {
                return (
                    <input
                        type="number"
                        onChange={(e) => { on_change_event(e, "right_operand") }}
                        value={curr_value} />
                )
            }
        },
//TODO Add a date range filter here
        // date_range: {
        //     operators: ["equals"],
        //     label: 'Counry/Economy',
        //     spark_left_operand: () => 'filter',
        //     right_operand: () => CountrySelector(props, on_change_event, "right_operand")
        // }
    }
}

export {filtersMap};