
const metricsMap = () => {
    return {
        games_played: {label: 'Games Played', spark: () => 'COUNT(DISTINCT game_id)'},
        minutes_played: {label: 'Minutes Played', spark: () => 'SUM(play_length) / 60'},
        field_goals: {label: 'FG', spark: () => 'SUM(IF(event_type = "shot" , 1 , 0 ))'},
        field_goal_per_game: {label: 'FG pg', spark: () => 'SUM(IF(event_type = "shot" , 1 , 0 )) / game_count'},
        field_goal_per36: {label: 'FG p36M', spark: () => 'SUM(IF(event_type = "shot" , 1 , 0 )) / SUM(IF(FIND_IN_SET(player, away_players) OR FIND_IN_SET(player, home_players), play_length, 0))/60*36'},
        field_goal_attempts: {label: 'FG Attempts', spark: () => 'SUM(IF(event_type in ("miss", "shot") , 1 , 0 ))'},
        field_goal_attempts_per_game: {label: 'FGA pg', spark: () => 'SUM(IF(event_type in ("miss", "shot") , 1 , 0 )) / COUNT(DISTINCT game_id)'},
        field_goal_attempts_per36: {label: 'FGA p36M', spark: () => 'SUM(IF(event_type in ("miss", "shot") , 1 , 0 )) / sum(play_length)/60*36'},
        field_goal_missed: {label: 'FG Missed', spark: () => 'SUM(IF(event_type ="miss" , 1 , 0 ))'},
        field_goal_missed_per_game: {label: 'FGMiss pg', spark: () => 'SUM(IF(event_type ="miss" , 1 , 0 )) / COUNT(DISTINCT game_id)'},
        field_goal_missed_per36: {label: 'FGMiss p36M', spark: () => 'SUM(IF(event_type ="miss" , 1 , 0 )) / SUM(IF(event_type in ("miss", "shot") , 1 , 0 )) / SUM(IF(event_type = "shot" , 1 , 0 )) / SUM(IF(FIND_IN_SET(player, away_players) OR FIND_IN_SET(player, home_players), play_length, 0))*60*36'},
        field_goal_pct: {label: 'FGPct ', spark: () => 'SUM(IF(event_type = "shot" , 1 , 0 )) / SUM(IF(event_type in ("miss", "shot") , 1 , 0 ))'},
        three_points: {label: '3P', spark: () => 'SUM(IF(event_type = "shot" AND description like "%3PT%" , 1 , 0 ))'},
        three_point_per_game: {label: '3P pg', spark: () => 'SUM(IF(event_type = "shot" AND description like "%3PT%" , 1 , 0 )) / COUNT(DISTINCT game_id)'},
        three_point_per36: {label: '3P p36M', spark: () => 'SUM(IF(event_type = "shot" AND description like "%3PT%" , 1 , 0 )) / SUM(play_length)*36'},
        three_point_attempts: {label: '3P Attempts', spark: () => 'SUM(IF(event_type in ("miss", "shot") AND description like "%3PT%" , 1 , 0 ))'},
        three_point_attempts_per_game: {label: '3PA pg', spark: () => 'SUM(IF(event_type in ("miss", "shot") AND description like "%3PT%" , 1 , 0 )) / COUNT(DISTINCT game_id)'},
        three_point_attempts_per36: {label: '3PA p36M', spark: () => 'SUM(IF(event_type in ("miss", "shot") AND description like "%3PT%" , 1 , 0 )) / SUM(play_length)*60*36'},
        three_point_missed: {label: '3P Missed', spark: () => 'SUM(IF(event_type ="miss" AND description like "%3PT%" , 1 , 0 ))'},
        three_point_missed_per_game: {label: '3PMiss pg', spark: () => 'SUM(IF(event_type ="miss" AND description like "%3PT%" , 1 , 0 )) / COUNT(DISTINCT game_id)'},
        three_point_missed_per36: {label: '3PMiss p36M', spark: () => 'SUM(IF(event_type ="miss" AND description like "%3PT%" , 1 , 0 )) / SUM(IF(event_type in ("miss", "shot") AND description like "%3PT%" , 1 , 0 )) / SUM(IF(FIND_IN_SET(player, away_players) OR FIND_IN_SET(player, home_players), play_length, 0))*60*36'},
        three_point_pct: {label: '3PPct ', spark: () => 'SUM(IF(event_type = "shot" AND description like "%3PT%" , 1 , 0 )) / SUM(IF(event_type in ("miss", "shot") AND description like "%3PT%" , 1 , 0 ))'},
        two_points: {label: '2P', spark: () => 'SUM(IF(event_type = "shot" AND not (description like "%3PT%") , 1 , 0 ))'},
        two_point_per_game: {label: '2P Attempts', spark: () => 'SUM(IF(event_type in ("miss", "shot") AND not (description like "%3PT%") , 1 , 0 ))'},
        two_point_per36: {label: '2P Missed', spark: () => 'SUM(IF(event_type ="miss" AND not (description like "%3PT%") , 1 , 0 ))'},
        two_point_attempts: {label: '2PPct ', spark: () => 'SUM(IF(event_type = "shot" AND not (description like "%3PT%") , 1 , 0 )) / SUM(IF(event_type in ("miss", "shot") AND not (description like "%3PT%") , 1 , 0 ))'},
        two_point_attempts_per_game: {label: 'eFGPct ', spark: () => '(SUM(IF(event_type = "shot" , 1 , 0 )) + 0.5 * SUM(IF(event_type = "shot" AND description like "%3PT%" , 1 , 0 ))) / SUM(IF(event_type in ("miss", "shot") , 1 , 0 ))'},
        two_point_attempts_per36: {label: 'FT', spark: () => 'SUM(IF(free_throw_num IS NOT NULL AND shot_result="made", 1, 0))'},
        two_point_missed: {label: 'FTA', spark: () => 'SUM(IF(free_throw_num IS NOT NULL, 1, 0))'},
        two_point_missed_per_game: {label: 'FTPct ', spark: () => 'SUM(IF(free_throw_num IS NOT NULL AND shot_result="made", 1, 0)) / SUM(IF(free_throw_num IS NOT NULL, 1, 0))'},
        two_point_missed_per36: {label: 'ORB', spark: () => 'SUM(CASE WHEN detailed_event_type ="rebound offensive" THEN 1 ELSE 0 END)'},
        two_point_pct: {label: 'DRB', spark: () => 'SUM(CASE WHEN detailed_event_type ="rebound defensive" THEN 1 ELSE 0 END)'},
        total_rebounds: {label: 'TRB', spark: () => 'SUM(CASE WHEN event_type ="rebound" THEN 1 ELSE 0 END)'},
        assists: {label: 'AST', spark: () => 'SUM(IF(assist IS NOT NULL , 1 , 0 ))'},
        assists_per_game: {label: 'AST Per Game', spark: () => 'SUM(IF(assist IS NOT NULL , 1 , 0 ))/COUNT(DISTINCT game_id)'},
        steals: {label: 'STL', spark: () => 'SUM(IF(steal IS NOT NULL , 1 , 0 ))'},
        steals_per_game: {label: 'STL Per Game', spark: () => 'SUM(IF(steal IS NOT NULL , 1 , 0 ))/COUNT(DISTINCT game_id)'},
        blocks: {label: 'BLK', spark: () => 'SUM(IF(block IS NOT NULL , 1 , 0 ))'},
        blocks_per_game: {label: 'BLK Per Game', spark: () => 'SUM(IF(block IS NOT NULL , 1 , 0 ))/COUNT(DISTINCT game_id)'},
        turnovers: {label: 'TOV', spark: () => 'SUM(IF(event_type="turnover", 1, 0))'},
        turnovers_per_game: {label: 'TOV Per Game', spark: () => 'SUM(IF(event_type="turnover", 1, 0))/COUNT(DISTINCT game_id)'},
        points: {label: 'Pts', spark: () => 'SUM(points)'},
        points_per_game: {label: 'Pts Per Game', spark: () => 'SUM(points)/COUNT(DISTINCT game_id)'},
        fouls: {label: 'Fouls', spark: () => 'SUM(IF(event_type="foul", 1, 0))'},
        games_played_player: {label: 'Games Played Player', spark: (player) => { return (`COUNT(DISTINCT IF(ARRAY_CONTAINS(game_players, '${player}'), game_id, 0))`)}},
        games_started_player: {label: 'Games Started Player', spark: (player) => { return (`COUNT(DISTINCT IF(play_id=1 AND (ARRAY_CONTAINS(offense_players, '${player}') OR ARRAY_CONTAINS(defense_players, '${player}')), game_id, 0))`)}},
        minutes_played_player: {label: 'Minutes Played Player', spark: (player) => { return (`SUM( IF(ARRAY_CONTAINS(offense_players, '${player}') OR ARRAY_CONTAINS(defense_players, '${player}'), play_length, 0)) / 60`)}},
        fg_player: {label: 'FG Player', spark: (player) => { return (`SUM(IF(event_type = "shot" AND player='${player}' , 1 , 0 ))`)}},
        fga_player: {label: 'FG Attempts Player', spark: (player) => { return (`SUM(IF(event_type in ('miss', 'shot') AND player='${player}' , 1 , 0 ))`)}},
        fg_missed_player: {label: 'FG Missed Player', spark: (player) => { return (`SUM(IF(event_type ="miss" AND player='${player}' , 1 , 0 ))`)}},
        fg_pct_player: {label: 'FG PCT Player', spark: (player) => { return (`SUM(IF(event_type = "shot" AND player='${player}' , 1 , 0 )) / SUM(IF(event_type in ('miss', 'shot') AND player='${player}' , 1 , 0 ))`)}},
        three_point_player: {label: '3P Player', spark: (player) => { return (`SUM(IF(event_type = "shot" AND player='${player}' AND description like "%3PT%" , 1 , 0 ))`)}},
        three_point_attempts_player: {label: '3P Attempts Player', spark: (player) => { return (`SUM(IF(event_type in ('miss', 'shot') AND player='${player}' AND description like "%3PT%" , 1 , 0 ))`)}},
        three_point_missed_player: {label: '3P Missed Player', spark: (player) => { return (`SUM(IF(event_type ="miss" AND player='${player}' AND description like "%3PT%" , 1 , 0 ))`)}},
        three_point_pct_player: {label: '3P PCT Player', spark: (player) => { return (`SUM(IF(event_type = "shot" AND player='${player}' AND description like "%3PT%" , 1 , 0 )) / SUM(IF(event_type in ('miss', 'shot') AND player='${player}' AND description like "%3PT%" , 1 , 0 ))`)}},
        two_point_player: {label: '2P Player', spark: (player) => { return (`SUM(IF(event_type = "shot" AND player='${player}' AND not (description like "%3PT%") , 1 , 0 ))`)}},
        two_pa_player: {label: '2PA Player', spark: (player) => { return (`SUM(IF(event_type in ('miss', 'shot') AND player='${player}' AND NOT(description like "%3PT%") , 1 , 0 ))`)}},
        two_point_missed_player: {label: '2P Missed Player', spark: (player) => { return (`SUM(IF(event_type ="miss" AND player='${player}' AND not (description like "%3PT%") , 1 , 0 ))`)}},
        two_point_pct_player: {label: '2P PCT Player', spark: (player) => { return (`SUM(IF(event_type = "shot" AND player='${player}' AND not (description like "%3PT%") , 1 , 0 )) / SUM(IF(event_type in ('miss', 'shot') AND player='${player}' AND NOT(description like "%3PT%") , 1 , 0 ))`)}},
        efg_pct_player: {label: 'eFG PCT Player', spark: (player) => { return (`(SUM(IF(event_type = "shot" AND player='${player}' , 1 , 0 )) + 0.5 * SUM(IF(event_type = "shot" AND player='${player}' AND description like "%3PT%" , 1 , 0 ))) / SUM(IF(event_type in ('miss', 'shot') AND player='${player}' , 1 , 0 ))`)}},
        ft_player: {label: 'FT Player', spark: (player) => { return (`SUM(IF(player='${player}' AND free_throw_num IS NOT NULL AND shot_result='made', 1, 0))`)}},
        fta_player: {label: 'FTA Player', spark: (player) => { return (`SUM(IF(player='${player}' AND free_throw_num IS NOT NULL, 1, 0))`)}},
        ft_pct_player: {label: 'FT PCT Player', spark: (player) => { return (`SUM(IF(player='${player}' AND free_throw_num IS NOT NULL AND shot_result='made', 1, 0)) / SUM(IF(player='${player}' AND free_throw_num IS NOT NULL, 1, 0))`)}},
        orb_player: {label: 'ORB Player', spark: (player) => { return (`SUM(CASE WHEN detailed_event_type ="rebound offensive" AND player='${player}' THEN 1 ELSE 0 END)`)}},
        drb_player: {label: 'DRB Player', spark: (player) => { return (`SUM(CASE WHEN detailed_event_type ="rebound defensive" AND player='${player}' THEN 1 ELSE 0 END)`)}},
        trb_player: {label: 'TRB Player', spark: (player) => { return (`SUM(CASE WHEN event_type ="rebound" AND player='${player}' THEN 1 ELSE 0 END)`)}},
        ast_player: {label: 'AST Player', spark: (player) => { return (`SUM(IF(assist='${player}' , 1 , 0 ))`)}},
        stl_player: {label: 'STL Player', spark: (player) => { return (`SUM(IF(steal='${player}' , 1 , 0 ))`)}},
        blk_player: {label: 'BLK Player', spark: (player) => { return (`SUM(IF(block='${player}' , 1 , 0 ))`)}},
        tov_player: {label: 'TOV Player', spark: (player) => { return (`SUM(IF(event_type='turnover' AND player='${player}', 1, 0))`)}},
        pts_player: {label: 'Pts Player', spark: (player) => { return (`SUM(IF(player='${player}', points, 0))`)}},
        fouls_player: {label: 'Fouls Player', spark: (player) => { return (`SUM(IF(event_type='foul' AND player='${player}', 1, 0))`)}},
        fouls_drawn_player: {label: 'Fouls Drawn Player', spark: (player) => { return (`SUM(IF(event_type='foul' AND opponent='${player}', 1, 0))`)}},
        team_mins_player: {label: 'Team Minutes Player', spark: (player) => { return (`SUM(IF(array_contains(game_players, '${player}'), play_length, 0)) / 60`)}},
        asst_pct_player: {label: 'AST  PCT Player', spark: (player) => { return (`100 * SUM(IF(assist='${player}' , 1 , 0 )) / (SUM( IF(FIND_IN_SET('${player}', offense_players) AND event_type in ('shot') AND player!='${player}' , 1 , 0 )))`)}},
        fga_team_player: {label: 'Team FGA', spark: (player) => { return (`SUM(IF(event_type in ('miss', 'shot') AND (ARRAY_CONTAINS(game_away_players, '${player}') AND  ARRAY_CONTAINS(game_away_players, player) OR ARRAY_CONTAINS(game_home_players, '${player}') AND  ARRAY_CONTAINS(game_home_players, player)), 1 , 0 ))`)}},
        fta_team_player: {label: 'Team FTA', spark: (player) => { return (`IF(free_throw_outof IS NOT NULL AND (ARRAY_CONTAINS(game_away_players, '${player}') AND  ARRAY_CONTAINS(game_away_players, player) OR ARRAY_CONTAINS(game_home_players, '${player}') AND  ARRAY_CONTAINS(game_home_players, player)), 1, 0)`)}},
        fga_team_player: {label: 'TEAM FG', spark: (player) => { return (`SUM(IF(event_type in ('shot') AND (ARRAY_CONTAINS(game_away_players, '${player}') AND  ARRAY_CONTAINS(game_away_players, player) OR ARRAY_CONTAINS(game_home_players, '${player}') AND  ARRAY_CONTAINS(game_home_players, player)), 1 , 0 ))`)}},
        blk_pct_player: {label: 'BLK PCT Player', spark: (player) => { return (`100*(SUM(IF(block='${player}' , 1 , 0 )) * (SUM(IF(array_contains(game_players, '${player}'), play_length, 0)) / 60)) / ((SUM( IF(ARRAY_CONTAINS(offense_players, '${player}') OR ARRAY_CONTAINS(defense_players, '${player}'), play_length, 0)) / 60) * (SUM(IF(event_type in ('miss', 'shot') AND (ARRAY_CONTAINS(game_away_players, '${player}') AND NOT ARRAY_CONTAINS(game_away_players, player) OR ARRAY_CONTAINS(game_home_players, '${player}') AND NOT ARRAY_CONTAINS(game_home_players, player)), 1 , 0 )) - SUM(IF(event_type in ('miss', 'shot') AND description like "%3PT%" AND (ARRAY_CONTAINS(game_away_players, '${player}') AND NOT ARRAY_CONTAINS(game_away_players, player) OR ARRAY_CONTAINS(game_home_players, '${player}') AND NOT ARRAY_CONTAINS(game_home_players, player)), 1 , 0 ))))`)}},
        opp_fga_player: {label: 'Opp FGA', spark: (player) => { return (`SUM(IF(event_type in ('miss', 'shot') AND (ARRAY_CONTAINS(game_away_players, '${player}') AND NOT ARRAY_CONTAINS(game_away_players, player) OR ARRAY_CONTAINS(game_home_players, '${player}') AND NOT ARRAY_CONTAINS(game_home_players, player)), 1 , 0 ))`)}},
        opp_3pa_player: {label: 'Opp 3PA', spark: (player) => { return (`SUM(IF(event_type in ('miss', 'shot') AND description like "%3PT%" AND (ARRAY_CONTAINS(game_away_players, '${player}') AND NOT ARRAY_CONTAINS(game_away_players, player) OR ARRAY_CONTAINS(game_home_players, '${player}') AND NOT ARRAY_CONTAINS(game_home_players, player)), 1 , 0 ))`)}}
    }
}
export {metricsMap}