import React, { Component } from 'react';
import {Redirect} from 'react-router';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import { AppBar} from '@mui/material';

import './App.css';

import AnalysisBuilder from './containers/blogger/AnalysisBuilder';
import AnalysisListView from './containers/blogger/AnalysisListView';
import AnalysisRenderer from './containers/reader/AnalysisRenderer';
import Blog from './containers/reader/Blog';
import AccountSettings from './containers/reader/AccountSettings';
import DataVizDaily from './containers/reader/DataVizDaily';
import ImageManager from './containers/blogger/ImageManager';
import Settings from './containers/blogger/Settings';
import BloggerPerformanceData from './containers/blogger/BloggerPerformanceData';
import DdNavBar from './components/app/DdNavBar';
import TagManager from 'react-gtm-module';
import Profile from "./views/Profile";
import EditArticle from "./containers/editor/EditArticle";
import Review from './containers/blogger/Review';
import BottomNavBar from './components/app/BottomNavBar';
import CookiePolicy from "./containers/pages/CookiePolicy";
import PrivacyPolicy from "./containers/pages/PrivacyPolicy";
// Enable Google Tag Manager for data tracking purposes
const tagManagerArgs = {
  gtmId: 'GTM-WD7S868'
}
TagManager.initialize(tagManagerArgs)

class App extends Component {

  state = {}
  blog = () => <Blog />
  contentSection = () => <Blog />
  analysisBuilder = () => <AnalysisBuilder />
  analysisRenderer = () => <AnalysisRenderer />
  dataVizDaily = () => <DataVizDaily />
  imageManager = () => <ImageManager />
  bloggerPerformanceData = () => <BloggerPerformanceData />
  settings = () => <Settings />

  helmetContext = {};

  render() {
    return (
      <HelmetProvider context={this.helmetContext}>
      <Router>
      <div className="main_container">
          <AppBar position="static">
            <DdNavBar />
          </AppBar>
          <div id="main_div" className="App" style={{alignContent: 'stretch', display: 'flex', flexDirection: 'column', overflow: 'scroll', height:'87vh', paddingTop:'10px', paddingLeft:'7px', paddingRight:'7px', paddingBottom:'0px'}}>
            <Route exact path="/" component={this.blog} />
            <Route path="/analysisbuilder/:analysis_id?" component={this.analysisBuilder} />
            <Route path="/analysis/:analysis_id" component={this.analysisRenderer} />
            <Route path="/dataviz" component={this.dataVizDaily} />
            <Route path="/account"><AccountSettings /></Route>
            <Route path="/edit"><EditArticle /></Route>
            <Route path="/profile" component={Profile} />
            <Route path="/imagemanager" component={this.imageManager} />
            <Route path="/myanalyses"><AnalysisListView /> </Route>
            <Route path="/review"><Review /> </Route>
            <Route path="/myanalysismetrics" component={this.bloggerPerformanceData} />
            <Route path="/settings" component={this.settings} />
            <Route path="/content_section/:section_name" component={this.contentSection} />
            <Route path="/privacy_policy"><PrivacyPolicy /></Route>
            <Route path="/cookie_policy"><CookiePolicy /></Route>
          </div>
      </div>
      <BottomNavBar />
      </Router>
      </HelmetProvider>
    );
  }
}

export default App;
