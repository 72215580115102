import React from 'react';

export default class GoogleAdMobile extends React.Component {
  componentDidMount () {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

    render () {
        return (
            <ins className='adsbygoogle'
            style={{ display: 'block', width:'300px', height:'50px' }}
            data-ad-client='ca-pub-8588307498896448'
            data-ad-layout-key="-fb+5w+4e-db+86"
            data-ad-slot='5802540729'
            data-ad-format='fluid' />
        );
    }
}