import React, { Component } from 'react';
import server_configs from '../../server_configs';
import  {Link} from "react-router-dom";
import { withRouter } from 'react-router';
import ArticleCardSummary from "../../components/reader/ArticleCardSummary";
import ArticleCardSummaryNoImage from "../../components/reader/ArticleCardSummaryNoImage";
import ArticleCardSummarySmallImage from "../../components/reader/ArticleCardSummarySmallImage";

import './Blog.css'

class MoreArticles extends Component {
    state = {
        articles: []
    }
    props = {
        section_name: ''
    }
    getArticles = () => {
            const query_string = '?section='.concat(this.props.section_name.toLowerCase().replaceAll(' ','_'))
        fetch(`${server_configs['api_domain']}/data_blogging/get_analyses${query_string}`, {
                method: "GET",
                mode: "cors",
                headers: { "Content-Type": "application/json" }
            })
                .then(response => {
                    response.json()
                        .then(json => {this.setState((state, props) => ({ articles: json }))});
                })    
        }

    componentDidMount = () => {
        this.setState((state, props) => ({section_name: this.props.section_name}))
        this.getArticles();
    }

    componentDidUpdate = () => {
        let section_name = ''
        if(this.props.match != undefined && this.props.match.params.section_name != undefined){
            ({ section_name } = this.props.match.params);
        }
        if(this.state.section_name != section_name) {
            this.setState((state, props) => ({section_name: section_name}))
            this.getArticles();
        }
    }


    chooseArticleCardRenderFormat = (article, index) => {
        console.log(article)
        if(!Object.keys(article.analytics_metadata).includes("cover_image")) {
            return (
                <>
                    <ArticleCardSummaryNoImage
                        link={`/analysis/${article.analysis_id.$oid}`} 
                        key={article.analysis_id.$oid} 
                        analytics_metadata={article.analytics_metadata}/> 
                    <br />
                    <br />
                </>
            ) 
        } else {
            return (
                <div style={{flex: 1, display:"flex", minWidth:"350px", width:"70vw", maxWidth:"100%", maxHeight:"200px"}} onClick={() => {
                    this.props.getNewArticle(article.analysis_id.$oid)}}>
                    <ArticleCardSummarySmallImage
                        link={`/analysis/${article.analysis_id.$oid}`} 
                        key={article.analysis_id.$oid} 
                        analytics_metadata={article.analytics_metadata}/> 
                    <br />
                    <br />
                </div>
            ) 
        } 
    }

    renderSection = (article_array) => {
        return article_array.map((x, idx) => {
            if(['title','create_date','tags'].every(value => Object.keys(x.analytics_metadata).includes(value))) {
            return this.chooseArticleCardRenderFormat(x, idx)}
        })
    }


    render = () => {
        console.log(this.state)
        const {articles} = this.state;
        const capitalize = str => str.split('_').map(sub => sub.charAt(0).toUpperCase() + sub.slice(1)).join(' ');
        const section_name= capitalize(this.props.section_name);
        return (
            <>            
                <div style={{display:"flex", flexDirection:"column", alignItems:"center", width:"60vw"}}>
                    {<>
                        <div style={{borderBottom: "1px solid grey", width:"90%", display:"flex", textAlign:"left"}}>
                            <h2 style={{fontFamily:"JoanRegular"}}>More Articles With {section_name} Data</h2>
                        </div>
                        <br/>
                    </>}
                    <div style={{flex:1, display:"flex", width:"100%", flexDirection:"column", alignItems:"center", minWidth:"300px"}}>
                        {this.renderSection(articles)}
                    </div>
                </div>
                <div style={{height:"50px"}}>
                    {/* this dive keeps the bottom from being cut off */}
                </div>
            </>
            )
    }
}

export default withRouter(MoreArticles);