
const metricsMap = () => {
    return {
        search_volume: {label: 'Relative Search Volume', spark: () => 'Relative Search Volume'},
    }
}
export { metricsMap };



