import player_map from './player_map';
import team_map from './team_map';

const selector_data = {'assisting_player':player_map,
'assisted_player':player_map,
'assisted_shot':{"Assisted":"Assisted","Unassisted":"Unassisted","No Made Shot":"No Made Shot"},
'away_players':player_map,
'away_team':team_map,
'blocking_player':player_map,
'blocked_player':player_map,
'blocked_shot':{"Blocked":"Blocked","Missed":"Missed","No Shot Attempt":"No Shot Attempt"},
'defense_players':player_map,
'defense_team':team_map,
'event_type':{'start of period': "Start Of Period",'jump ball': "Jump Ball",'miss': "Missed Shot",'shot': "Made Shot",'turnover': "Turnover",'foul': "Foul",'rebound': "Rebound",'free throw':"Free Throw",'violation': "Violation",'timeout': "Time Out",'sub': "Substitution",'end of period':"End Of Period",'unknown':"Unknown",'ejection':"Ejection",'NULL':"NULL"},
'home':team_map,
'home_players':player_map,
'home_team':team_map,
'offense_players':player_map,
'offense_team':team_map,
'opponent':player_map,
'period':{"1":"First","2":"Second","3":"Third", "4":"Fourth", "5": "OT1", "6": "OT2", "7": "OT3"},
'player':player_map,
'possession':player_map,
'reason':{"NULL": "NULL",'bad pass ': "Bad Pass",'s.foul': "Shooting Foul",'poss lost ball': "Lost Ball",'l.b.foul': "Loose Ball Foul",'lost ball ': "Lost Ball", 'p.foul': "Personal Foul",'turnover: shot clock': "Shot Clock Turnover",'off.foul': "Offensive Foul",'foul turnover': "Foul Turnover"},
'regular_playoffs':{"Regular Season":"Regular Season", "Playoff":"Playoff","error":"error"},
'season':{'2004-2005':'Regular Season 2004-2005','2005-2006':'Regular Season 2005-2006','2006-2007':'Regular Season 2006-2007','2007-2008':'Regular Season 2007-2008','2008-2009':'Regular Season 2008-2009','2009-2010':'Regular Season 2009-2010','2010-2011':'Regular Season 2010-2011','2011-2012':'Regular Season 2011-2012','2012-2013':'Regular Season 2012-2013','2013-2014':'Regular Season 2013-2014','2014-2015':'Regular Season 2014-2015','2015-2016':'Regular Season 2015-2016','2016-2017':'Regular Season 2016-2017','2017-2018':'Regular Season 2017-2018','2018-2019':'Regular Season 2018-2019','2019-2020':'Regular Season 2019-2020','2020-2021':'Regular Season 2020-2021','2021-2022':'Regular Season 2021-2022','2022-2023':'Regular Season 2022-2023','2004':'Playoffs 2004','2005':'Playoffs 2005','2006':'Playoffs 2006','2007':'Playoffs 2007','2008':'Playoffs 2008','2009':'Playoffs 2009','2010':'Playoffs 2010','2011':'Playoffs 2011','2012':'Playoffs 2012','2013':'Playoffs 2013','2014':'Playoffs 2014','2015':'Playoffs 2015','2016':'Playoffs 2016','2017':'Playoffs 2017','2018':'Playoffs 2018','2019':'Playoffs 2019','2020':'Playoffs 2020','2021':'Playoffs 2021','2022':'Playoffs 2022'},
'shot_result':{"missed":"Missed Shot", "made": "Made Shot"},
'steal':player_map,
'turnover_player':player_map,
'turnover_team':team_map,
'team':team_map}

export default selector_data;