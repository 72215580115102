import { React, useState } from 'react';
import './GraphInputs.css';

const VictoryStandardGraphInput = (props) => {
    const [isxOpen, setIsxOpen] = useState("none");
    const [isxTickOpen, setIsxTickOpen] = useState("none");
    const [isxAxisOpen, setIsxAxisOpen] = useState("none");

    const [isyOpen, setIsyOpen] = useState("none");
    const [isyTickOpen, setIsyTickOpen] = useState("none");
    const [isyAxisOpen, setIsyAxisOpen] = useState("none");

    const [isPaddingOpen, setIsPaddingOpen] = useState("none");
    const [isLabelOpen, setIsLabelOpen] = useState("none");

    return (
        <>
            <br />
            <label htmlFor="horizontal"> Horizontal Orientation</label>
            <br />
            <select
                name="horizontal"
                value={props.graph_inputs.horizontal}
                onChange={(e) => props.graph_input_collector({ horizontal: e.target.value })}
            >
                <option value={null}></option>
                <option value={true}>Horizontal</option>
                <option value={false}>Not Horizontal</option>
            </select>
            <br />
            <label htmlFor="default_color"> Default Color </label>
            <br />
            <select
                name="default_color"
                value={props.graph_inputs.default_color}
                onChange={(e) => props.graph_input_collector({ default_color: e.target.value })}
            >
                <option value={null}></option>
                <option value={'red'}>Red</option>
                <option value={'orange'}>Orange</option>
                <option value={'yellow'}>Yellow</option>
                <option value={'green'}>Green</option>
                <option value={'blue'}>Blue</option>
                <option value={'purple'}>Purple</option>
                <option value={'black'}>Black</option>
            </select>
            <br />
            <label htmlFor="selected_color"> Selected Color </label>
            <br />
            <select
                name="selected_color"
                value={props.graph_inputs.selected_color}
                onChange={(e) => props.graph_input_collector({ selected_color: e.target.value })}
            >
                <option value={null}></option>
                <option value={'red'}>Red</option>
                <option value={'orange'}>Orange</option>
                <option value={'yellow'}>Yellow</option>
                <option value={'green'}>Green</option>
                <option value={'blue'}>Blue</option>
                <option value={'purple'}>Purple</option>
                <option value={'black'}>Black</option>
            </select>
            <br />
            <button onClick={() => isLabelOpen == "flex" ? setIsLabelOpen("none") : setIsLabelOpen("flex")} > Hide/Show Label Inputs</button>
            <div hidden={isLabelOpen} style={{ display: isLabelOpen, flexDirection: "column", justifyContent: "left", alignItems: "start" }}>

                <label htmlFor="label_font_size"> Label Font Size </label>
                <br />
                <input
                    name="label_font_size"
                    type="number"
                    value={props.graph_inputs.label_font_size}
                    onChange={(e) => props.graph_input_collector({ label_font_size: e.target.value })}
                />
                <br />
                <label htmlFor="label_fill_color"> Label Color </label>
                <br />
                <select
                    name="label_fill_color"
                    value={props.graph_inputs.label_fill_color}
                    onChange={(e) => props.graph_input_collector({ label_fill_color: e.target.value })}
                >
                    <option value={null}></option>
                    <option value={'red'}>Red</option>
                    <option value={'orange'}>Orange</option>
                    <option value={'yellow'}>Yellow</option>
                    <option value={'green'}>Green</option>
                    <option value={'blue'}>Blue</option>
                    <option value={'purple'}>Purple</option>
                    <option value={'black'}>Black</option>
                </select>
                <br />
                <label htmlFor="label_x_offset"> Label X Offset </label>
                <br />
                <input
                    name="label_x_offset"
                    type="number"
                    value={props.graph_inputs.label_x_offset}
                    onChange={(e) => props.graph_input_collector({ label_x_offset: e.target.value })}
                />
                <br />
                <label htmlFor="label_y_offset"> Label Y Offset </label>
                <br />
                <input
                    name="label_y_offset"
                    type="number"
                    value={props.graph_inputs.label_y_offset}
                    onChange={(e) => props.graph_input_collector({ label_y_offset: e.target.value })}
                />
                <br />
            </div>
            <br />
            <button onClick={() => isPaddingOpen == "flex" ? setIsPaddingOpen("none") : setIsPaddingOpen("flex")} > Hide/Show Domain, Range, and Padding Inputs</button>
            <div hidden={isPaddingOpen} style={{ display: isPaddingOpen, flexDirection: "column", justifyContent: "left", alignItems: "start" }}>
                <br />
                <label htmlFor="padding_top"> Padding Top</label>
                <br />
                <input
                    name="padding_top"
                    type="number"
                    value={props.graph_inputs.padding_top}
                    onChange={(e) => props.graph_input_collector({ padding_top: e.target.value })}
                />
                <br />
                <label htmlFor="padding_bottom"> Padding Bottom</label>
                <br />
                <input
                    name="padding_bottom"
                    type="number"
                    value={props.graph_inputs.padding_bottom}
                    onChange={(e) => props.graph_input_collector({ padding_bottom: e.target.value })}
                />
                <br />
                <label htmlFor="padding_left"> Padding Left</label>
                <br />
                <input
                    name="padding_left"
                    type="number"
                    value={props.graph_inputs.padding_left}
                    onChange={(e) => props.graph_input_collector({ padding_left: e.target.value })}
                />
                <br />
                <label htmlFor="padding_right"> Padding Right</label>
                <br />
                <input
                    name="padding_right"
                    type="number"
                    value={props.graph_inputs.padding_right}
                    onChange={(e) => props.graph_input_collector({ padding_right: e.target.value })}
                />

                <br />
                <label htmlFor="domain_min"> Domain Min</label>
                <br />
                <input
                    name="domain_min"
                    type="number"
                    value={props.graph_inputs.domain_min}
                    onChange={(e) => props.graph_input_collector({ domain_min: e.target.value })}
                />
                <br />
                <label htmlFor="domain_max"> Domain Max</label>
                <br />
                <input
                    name="domain_max"
                    type="number"
                    value={props.graph_inputs.domain_max}
                    onChange={(e) => props.graph_input_collector({ domain_max: e.target.value })}
                />
                <br />
                <label htmlFor="range_min"> Range Min </label>
                <br />
                <input
                    name="range_min"
                    type="number"
                    value={props.graph_inputs.range_min}
                    onChange={(e) => props.graph_input_collector({ range_min: e.target.value })}
                />
                <br />
                <label htmlFor="range_max"> Range Max </label>
                <br />
                <input
                    name="range_max"
                    type="number"
                    value={props.graph_inputs.range_max}
                    onChange={(e) => props.graph_input_collector({ range_max: e.target.value })}
                />
                <br />
                <label htmlFor="domain_padding_min"> Domain Padding Min</label>
                <br />
                <input
                    name="domain_padding_min"
                    type="number"
                    value={props.graph_inputs.domain_padding_min}
                    onChange={(e) => props.graph_input_collector({ domain_padding_min: e.target.value })}
                />
                <br />
                <label htmlFor="domain_padding_max"> Domain Padding Max</label>
                <br />
                <input
                    name="domain_padding_max"
                    type="number"
                    value={props.graph_inputs.domain_padding_max}
                    onChange={(e) => props.graph_input_collector({ domain_padding_max: e.target.value })}
                />
                <br />
                <label htmlFor="range_padding_min"> Range Padding Min</label>
                <br />
                <input
                    name="range_padding_min"
                    type="number"
                    value={props.graph_inputs.range_padding_min}
                    onChange={(e) => props.graph_input_collector({ range_padding_min: e.target.value })}
                />
                <br />
                <label htmlFor="range_padding_max"> Range Padding Max</label>
                <br />
                <input
                    name="range_padding_max"
                    type="number"
                    value={props.graph_inputs.range_padding_max}
                    onChange={(e) => props.graph_input_collector({ range_padding_max: e.target.value })}
                />
            </div>
            <br />
            <button onClick={() => isxOpen == "flex" ? setIsxOpen("none") : setIsxOpen("flex")} > Hide/Show x Inputs</button>
            <div hidden={isxOpen} style={{ display: isxOpen, flexDirection: "column", justifyContent: "left", alignItems: "start" }}>
                <label htmlFor="scale_x"> Scale X Axis</label>
                <br />
                <select
                    name="scale_x"
                    value={props.graph_inputs.scale_x}
                    onChange={(e) => props.graph_input_collector({ scale_x: e.target.value })}
                >
                    <option value={null}></option>
                    <option value={'linear'}>Linear</option>
                    <option value={'time'}>Time</option>
                    <option value={'log'}>Log</option>
                    <option value={'sqrt'}>Sqrt</option>
                </select>
                <br />
                <br />
                <label htmlFor="x_tick_scaler"> X Tick Label Scaler </label>
                <br />
                <input
                    name="x_tick_scaler"
                    type="number"
                    value={props.graph_inputs.x_tick_scaler}
                    onChange={(e) => props.graph_input_collector({ x_tick_scaler: e.target.value })}
                />

                <br />
                <label htmlFor="x_tick_size"> X Tick Size </label>
                <br />
                <input
                    name="x_tick_size"
                    type="number"
                    value={props.graph_inputs.x_tick_size}
                    onChange={(e) => props.graph_input_collector({ x_tick_size: e.target.value })}
                />

                <br />
                <label htmlFor="x_tick_label_font_size"> X Tick Label Font Size </label>
                <br />
                <input
                    name="x_tick_label_font_size"
                    type="number"
                    value={props.graph_inputs.x_tick_label_font_size}
                    onChange={(e) => props.graph_input_collector({ x_tick_label_font_size: e.target.value })}
                />

                <br />
                <label htmlFor="x_tick_label_padding"> X Tick Label Padding </label>
                <br />
                <input
                    name="x_tick_label_padding"
                    type="number"
                    value={props.graph_inputs.x_tick_label_padding}
                    onChange={(e) => props.graph_input_collector({ x_tick_label_padding: e.target.value })}
                />

                <br />
                <label htmlFor="x_tick_label_offset"> X Tick Label Offset </label>
                <br />
                <input
                    name="x_tick_label_offset"
                    type="number"
                    value={props.graph_inputs.x_tick_label_offset}
                    onChange={(e) => props.graph_input_collector({ x_tick_label_offset: e.target.value })}
                />

                <br />
                <label htmlFor="x_tick_label_anchor"> X Tick Label Anchor </label>
                <br />
                <select
                    name="x_tick_label_anchor"
                    value={props.graph_inputs.x_tick_label_anchor}
                    onChange={(e) => props.graph_input_collector({ x_tick_label_anchor: e.target.value })}
                >
                    <option value={null}></option>
                    <option value={'start'}>Start</option>
                    <option value={'middle'}>Middle</option>
                    <option value={'end'}>End</option>
                </select>

                <br />
                <label htmlFor="x_tick_label_angle"> X Tick Label Angle </label>
                <br />
                <input
                    name="x_tick_label_angle"
                    type="number"
                    value={props.graph_inputs.x_tick_label_angle}
                    onChange={(e) => props.graph_input_collector({ x_tick_label_angle: e.target.value })}
                />


                <br />
                <label htmlFor="x_axis_label_padding"> X Axis Label Padding </label>
                <br />
                <input
                    name="x_axis_label_padding"
                    type="number"
                    value={props.graph_inputs.x_axis_label_padding}
                    onChange={(e) => props.graph_input_collector({ x_axis_label_padding: e.target.value })}
                />
                <br />
                <label htmlFor="x_axis_label_angle"> X Axis Label Angle </label>
                <br />
                <input
                    name="x_axis_label_angle"
                    type="number"
                    value={props.graph_inputs.x_axis_label_angle}
                    onChange={(e) => props.graph_input_collector({ x_axis_label_angle: e.target.value })}
                />
                <br />
            </div>
            <br />
            <button onClick={() => isyOpen == "flex" ? setIsyOpen("none") : setIsyOpen("flex")} > Hide/Show y Inputs</button>
            <div hidden={isyOpen} style={{ display: isyOpen, flexDirection: "column", justifyContent: "left", alignItems: "start" }}>
                <label htmlFor="scale_y"> Scale Y Axis</label>
                <br />
                <select
                    name="scale_y"
                    value={props.graph_inputs.scale_y}
                    onChange={(e) => props.graph_input_collector({ scale_y: e.target.value })}
                >
                    <option value={null}></option>
                    <option value={'linear'}>Linear</option>
                    <option value={'time'}>Time</option>
                    <option value={'log'}>Log</option>
                    <option value={'sqrt'}>Sqrt</option>
                </select>

                <br />
                <label htmlFor="y_tick_scaler"> Y Tick Label Scaler</label>
                <br />
                <input
                    name="y_tick_scaler"
                    type="number"
                    value={props.graph_inputs.y_tick_scaler}
                    onChange={(e) => props.graph_input_collector({ y_tick_scaler: e.target.value })}
                />

                <br />
                <label htmlFor="y_tick_size"> Y Tick Size </label>
                <br />
                <input
                    name="y_tick_size"
                    type="number"
                    value={props.graph_inputs.y_tick_size}
                    onChange={(e) => props.graph_input_collector({ y_tick_size: e.target.value })}
                />

                <br />
                <label htmlFor="y_tick_label_font_size"> Y Tick Label Font Size </label>
                <br />
                <input
                    name="y_tick_label_font_size"
                    type="number"
                    value={props.graph_inputs.y_tick_label_font_size}
                    onChange={(e) => props.graph_input_collector({ y_tick_label_font_size: e.target.value })}
                />

                <br />
                <label htmlFor="y_tick_label_padding"> Y Tick Label Padding </label>
                <br />
                <input
                    name="y_tick_label_padding"
                    type="number"
                    value={props.graph_inputs.y_tick_label_padding}
                    onChange={(e) => props.graph_input_collector({ y_tick_label_padding: e.target.value })}
                />

                <br />
                <label htmlFor="y_tick_label_angle"> Y Tick Label Angle </label>
                <br />
                <input
                    name="y_tick_label_angle"
                    type="number"
                    value={props.graph_inputs.y_tick_label_angle}
                    onChange={(e) => props.graph_input_collector({ y_tick_label_angle: e.target.value })}
                />

                <br />
                <label htmlFor="y_axis_label_padding"> Y Axis Label Padding </label>
                <br />
                <input
                    name="y_axis_label_padding"
                    type="number"
                    value={props.graph_inputs.y_axis_label_padding}
                    onChange={(e) => props.graph_input_collector({ y_axis_label_padding: e.target.value })}
                />
                <br />
                <label htmlFor="y_axis_label_angle"> Y Axis Label Angle </label>
                <br />
                <input
                    name="y_axis_label_angle"
                    type="number"
                    value={props.graph_inputs.y_axis_label_angle}
                    onChange={(e) => props.graph_input_collector({ y_axis_label_angle: e.target.value })}
                />
            </div>

        </>
    )
}

export default VictoryStandardGraphInput;