import React, { Component } from 'react';
import AnalysisInputRouter from '../../components/inputs/AnalysisInputsRouter';
import GraphRouter from '../../components/graphs/GraphRouter';
import DataSelector from '../../components/blogger/DatasetSelector';
import ArticleMetaDataCollector from '../../components/blogger/ArticleMetaDataCollector';
import server_configs from '../../server_configs';
import { withRouter } from 'react-router';
import TagManager from 'react-gtm-module';
import { withAuth0 } from '@auth0/auth0-react';

import './AnalysisBuilder.css';
import BloggerNavBar from '../../components/blogger/BloggerNavBar';
import TemplateCreator from '../../components/blogger/TemplateCreator';
import AnalysisFromTemplateBuilderEmbedded from '../../components/blogger/AnalysisFromTemplateBuilderEmbedded';
import ResponseLoading from '../../components/blogger/ReponseLoading';

class AnalysisBuilder extends Component {
  state = {
    selected_analysis: "",
    selected_graph: "",
    selected_dataset: "",
    analytics_metadata: { all_datasets: [] }, // this is metadata about the article used for showing home feed cards
    query_inputs: [],// this.dummy_data, //this is the state of the actual query inputs for parser
    graph_inputs: {}, //this is for state of components that map to graph inputs
    component_inputs: {}, //this is for state of components that map to query inputs
    data_for_graph: [], //this is the response from the server with data for graph
    analytics_states: [], //these are saved analysis states
    current_analytics_state_index: "", //index of where in "analytics_states" state is in
    analysis_id: "",
    article_metadata_hidden: false,
    show_template_creator: false,
    show_create_from_template:false,
    loading_message: {show_loading:false, loading_error: false, loading_error_message: ''},
    text_generation_data: {prompt_variable_data_types: {}, prompt_variable_replacements: {}, tokens: {}},
    approved: false,
    submissions: {
      submit_date: '',
      approvals: [],
      rejections: [],
      eligible_approvers:[]
    }
  }


  hide_article_metadata = () => {
    this.setState((state, props) => {
      return {
        article_metadata_hidden: !this.state.article_metadata_hidden
      }
    })
  }

  //this is not saving the component inputs correctly, is overwriting the entire analytics_states for component_inputs only. not happening on save, so must be treating state mutably on component input update

  delKeys = (object, keys) => {
    function delKey(object, nkeys) {
      if (nkeys.length > 0) {
        const key = nkeys.pop()
        const { [key]: deletedKey, ...otherKeys } = object;
        return delKey(otherKeys, nkeys)
      } else {
        return object
      }
    }
    return delKey(object, keys);
  }

  updateUsedDatasets = () => {
    const analytics_metadata = this.state.analytics_metadata
    const all_datasets_old = analytics_metadata.all_datasets
    all_datasets_old.push(this.state.selected_dataset)
    const all_datasets = [...new Set(all_datasets_old)]
    analytics_metadata['all_datasets'] = all_datasets
    this.setState((state, props) => {
      return { analytics_metadata: analytics_metadata }
    })
  }

  saveAnalysisState = (update_type = null) => {
    this.updateUsedDatasets()
    const nsi = [...this.state.analytics_states]
    nsi[this.state.current_analytics_state_index] = this.delKeys(this.state,
      ['analytics_states', 'analysis_id', 'analytics_metadata', 'submissions', 'approved']
    )
    const j = JSON.parse(JSON.stringify(nsi))
    this.setState((state, props) => { return { analytics_states: j } }, () => { if (update_type) this.AnalysisDataApi(update_type) })
  }

  setCurrentAnalyticsStateIndex = (e) => {
    const index_val = e.target.value;
    const loaded_state = JSON.parse(JSON.stringify({ ...this.state.analytics_states[index_val] }))
    this.saveAnalysisState()
    this.setState((state, props) => { return loaded_state })
  }

  createNewAnalysisState = () => {
    if (this.state.data_for_graph != []) { this.saveAnalysisState() }
    this.setState((state, props) => {
      return {
        current_analytics_state_index: this.state.analytics_states.length
      }
    })
  }

  deleteCurrentAnalysisState = () => {
    const casi = this.state.current_analytics_state_index;
    const new_analytics_states = this.state.analytics_states;
    if (this.state.data_for_graph != [] && casi !== "") { 
      new_analytics_states.splice(casi, 1);
      new_analytics_states.map((x, idx) => new_analytics_states[idx]['current_analytics_state_index'] = idx)
    }
    this.setState((state, props) => {
      return {
        analytics_states: new_analytics_states,
        current_analytics_state_index: "",
        selected_analysis: "",
        selected_graph: "",
        selected_dataset: "",
        query_inputs: [],// this.dummy_data, //this is the state of the actual query inputs for parser
        graph_inputs: {}, //this is for state of components that map to graph inputs
        component_inputs: {}, //this is for state of components that map to query inputs
        data_for_graph: [], //this is the response from the server with data for graph
        text_generation_data: {prompt_variable_data_types: {}, prompt_variable_replacements: {}, tokens: {}}, //this is the data for open ai text generation    
        loading_message: {show_loading:false, loading_error: false, loading_error_message: ''}
      }
    })
  }

  setGraphFromTemplate = (graph_type) => {
    this.setState((state, props) => ({'selected_graph': graph_type}))
  }

  updateSimpleChoice = (choice, e) => {
    let old = { ...this.state }
    if(typeof e !== 'string'){old[choice] = e.target.value} else {old[choice]=e}
    if (choice === 'selected_dataset') {
      old['query_inputs'] = []//this.dummy_data
      old['graph_inputs'] = {}
      old['data_for_graph'] = []
      old['component_inputs'] = {}
      old['selected_graph'] = ""
      old['text_generation_data'] = {prompt_variable_data_types: {}, prompt_variable_replacements: {}, tokens: {}}
    }
    if (choice === 'selected_graph' || choice === 'selected_analysis') {
      old['query_inputs'] = []//this.dummy_data
      old['graph_inputs'] = {}
      old['data_for_graph'] = []
      old['component_inputs'] = {}
      old['text_generation_data'] = {prompt_variable_data_types: {}, prompt_variable_replacements: {}, tokens: {}}
    }
    if (choice === 'selected_analysis') {
      old['selected_graph'] = ""
      old['text_generation_data'] = {prompt_variable_data_types: {}, prompt_variable_replacements: {}, tokens: {}}
    }
    this.setState(old)
  }

  getGraphData = async () => {
    const loading_message_copy = JSON.parse(JSON.stringify(this.state.loading_message))
    if(this.state.loading_message.show_loading === true){
      loading_message_copy['show_loading'] = false
    } else {
      loading_message_copy['show_loading'] = true
    }
    this.setState((state, props) => ({loading_message: loading_message_copy}))
    const graph_json_to_send = {
      dataset: this.state.selected_dataset,
      query_inputs: this.state.query_inputs,
      analysis_name: this.state.selected_analysis,
    }
    const response = await fetch(`${server_configs['api_domain']}/data_blogging/get_graph_data/`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(graph_json_to_send)
    })
    const data = await response.json()
    if(Object.keys(data).includes('error')) {
      const loading_message_copy_post_process = JSON.parse(JSON.stringify(this.state.loading_message))
      loading_message_copy_post_process['show_loading'] = true
      loading_message_copy_post_process['loading_error'] = true
      loading_message_copy_post_process['loading_error_message'] = data['error']
      this.setState((state, props) => ({loading_message: loading_message_copy_post_process}))
    } else {
      this.setState((state, props) => ({ data_for_graph: data }));
      let null_data = false
      if((Array.isArray(data) && data.length == 0) || (!Array.isArray(data) && typeof(data) == 'object' && Object.keys(data).length == 0)) {
        null_data = true
      }
      const loading_message_copy_post_process = JSON.parse(JSON.stringify(this.state.loading_message))
      if(this.state.loading_message.show_loading === true && !null_data){
        loading_message_copy_post_process['show_loading'] = false
      } else {
        loading_message_copy_post_process['show_loading'] = true
      }
      if(null_data && loading_message_copy_post_process.show_loading) {
        loading_message_copy_post_process['loading_error_message'] = "There is no data, try adjusting your filters and confirming the metric exists for your dimension."
      }
      this.setState((state, props) => ({loading_message: loading_message_copy_post_process}))  
    }
  }

  showTemplateCreator = () => {
    this.setState((state, props) => ({show_template_creator: !this.state.show_template_creator}))
  }

  showCreateFromTemplate = () => {
    if(!this.state.show_create_from_template) {
      this.createNewAnalysisState()
    }
    this.setState((state, props) => ({show_create_from_template: !this.state.show_create_from_template}))
  }

  CreateUpdateAnalysis = (update_type) => {
    this.setState((state, props) => ({analytics_metadata: {...state.analytics_metadata, last_saved: Math.round(Date.now()/1000)},approved: false}), this.saveAnalysisState(update_type))
  }

  AnalysisDataApi = (update_type) => {
    const send_json = {
      analysis_id: this.state.analysis_id,
      analytics_states: this.state.analytics_states,
      analytics_metadata: this.state.analytics_metadata,
      submissions: this.state.submissions,
      approved: this.state.approved,
      text_generation_data: this.state.text_generation_data
    }

    let method = "POST";
    switch (update_type) {
      case 'get_analysis':
      case 'get_all_analyses':
      case 'create_new_analysis':
        method = "GET";
        break;
      default:
        method = "POST";
        break;
    }

    const request_content = {
      method: method,
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      }
    };

    let request_url = `${server_configs['api_domain']}/data_blogging/${update_type}/`;

    if (method === "POST") {
      request_content['body'] = JSON.stringify(send_json);
    } else {
      const queryString = Object.keys(send_json).map(key => key + '=' + send_json[key]).join('&');
      request_url = request_url + '?' + queryString;
    }

    fetch(request_url, request_content)
      .then(response => {
        response.json()
          .then(text => {
            this.setState((state, props) => ({ ...text }))
          });
      })
  }

  textGenerationInputCollector = (field_map) => {
    const text_generation_data_copy = JSON.parse(JSON.stringify(this.state.text_generation_data))
    Object.keys(field_map).map(x => text_generation_data_copy[x] = field_map[x])
    this.setState((state, props) => ({
      text_generation_data: text_generation_data_copy
    }))
  }

  textGenerationApiCall = async () => {
    const api_payload = {
      selected_analysis: this.state.selected_analysis,
      selected_graph: this.state.selected_graph,
      selected_dataset: this.state.selected_dataset,
      query_inputs: this.state.query_inputs,
      graph_inputs: this.state.graph_inputs,
      component_inputs: this.state.component_inputs,
      text_generation_data: this.state.text_generation_data
    }

    const request_content = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      }
    };

    request_content['body'] = JSON.stringify(api_payload);
    const loading_message_copy_post_process = JSON.parse(JSON.stringify(this.state.loading_message))
    loading_message_copy_post_process['show_loading'] = true
    loading_message_copy_post_process['loading_error'] = false
    this.setState((state, props) => ({loading_message: loading_message_copy_post_process}))
    const response = await fetch('https://thedatanewsjournal.com/v2/api/generate/page', request_content)
    const data = await response.json()
    if(data['ai_output'] == 'failed') {
      loading_message_copy_post_process['show_loading'] = true
      loading_message_copy_post_process['loading_error'] = true
      loading_message_copy_post_process['loading_error_message'] = 'AI output failed, update your Text Generator inputs.'
      this.setState((state, props) => ({loading_message: loading_message_copy_post_process}))
    } else {
      const graph_inputs = this.state.graph_inputs
      graph_inputs['textchunk_top'] = data['ai_output']
      graph_inputs['textchunk_bottom'] = data['analysis_output']
      loading_message_copy_post_process['show_loading'] = false
      loading_message_copy_post_process['loading_error'] = false
      loading_message_copy_post_process['loading_error_message'] = ''
      this.setState((state, props) => ({graph_inputs: graph_inputs, loading_message: loading_message_copy_post_process}))  
    }
  }

  bruteForceInputs = (query_input_json, component_json) => {
    this.setState((state, props) => ({query_inputs: query_input_json, component_inputs: component_json}))
  }

  inputDataCollector = (query_input_json, state_component_json, delete_key = null) => {
    const temp_state = Object.assign({ ...this.state.component_inputs }, state_component_json)
    const new_state_component_json = this.delKeys(temp_state, ['analytics_states'])
    let appended = false
    const new_query_inputs = [...this.state.query_inputs]
    new_query_inputs.forEach(function (v, idx) {
      if (v['input_name'] === query_input_json['input_name']) {
        new_query_inputs[idx] = query_input_json
        appended = true
      }
    })
    if (!appended) {
      new_query_inputs.push(query_input_json)
    }
    if (delete_key) {
      delete new_state_component_json[delete_key]
    }
    this.setState((state, props) => (
      {
        query_inputs: new_query_inputs,
        component_inputs: new_state_component_json
      }
    ))
  }

  graphInputCollector = (graph_input_json, delete_key = null) => {
    const new_graph_input_json = Object.assign(
      { ...this.state.graph_inputs }, graph_input_json
    )
    if (delete_key) {
      delete new_graph_input_json[delete_key]
    }
    this.setState((state, props) => ({ graph_inputs: new_graph_input_json }))
  }

  MetaCollector = (analytics_metadata) => {
    const new_metadata_input_json = Object.assign(
      { ...this.state.analytics_metadata }, analytics_metadata
    )
    this.setState((state, props) => ({ analytics_metadata: new_metadata_input_json }))
  }

  OpenCloseResponseLoaderDialog = () => {
    const loading_message_copy = JSON.parse(JSON.stringify(this.state.loading_message))
    if(this.state.loading_message.show_loading === true){
      loading_message_copy['show_loading'] = false
      loading_message_copy['loading_error'] = false
      loading_message_copy['loading_error_message'] = ''
    } else {
      loading_message_copy['show_loading'] = true
    }
    this.setState((state, props) => ({loading_message: loading_message_copy}))
  }

  componentDidMount() {
    const { analysis_id } = this.props.match.params;
    this.setState({ analysis_id: analysis_id ? analysis_id : '' }, () => {
      if (this.state.analysis_id !== '') { this.AnalysisDataApi('get_analysis') } else { this.createNewAnalysisState() };
    });
    TagManager.dataLayer({
      dataLayer: {
        event: 'PageView',
        pagePath: 'analysis-builder'
      },
    });
  }

  render() {
    if (this.props.auth0.user ? true : false) {
      return (
      <div className="pagecontainer">
        <BloggerNavBar />
        {this.state.show_template_creator ? <TemplateCreator component_inputs={this.state.component_inputs} 
                                                              graph_inputs={this.state.graph_inputs} 
                                                              selected_analysis={this.state.selected_analysis} 
                                                              selected_dataset={this.state.selected_dataset} 
                                                              selected_graph={this.state.selected_graph}
                                                              auth0_user={this.props.auth0.user['sub']}
                                                              template_closer={this.showTemplateCreator}
                                                              query_inputs={this.state.query_inputs}
                                                              text_generation_input_collector = {this.textGenerationInputCollector}
                                                              text_generation_data = {this.state.text_generation_data}/> : 
        this.state.show_create_from_template ? <AnalysisFromTemplateBuilderEmbedded inputDataCollector={this.inputDataCollector}
                                                              graphInputCollector={this.graphInputCollector}
                                                              bruteForceCollector={this.bruteForceInputs}
                                                              analysis_from_template_closer={this.showCreateFromTemplate}
                                                              simple_choice_update={this.updateSimpleChoice}
                                                              graph_setter={this.setGraphFromTemplate}
                                                              text_generation_input_collector = {this.textGenerationInputCollector}/> :                                                       
        <div className="lrcontainer">
          <div className="configcontainer">
            <>
              <h1>Content Creator</h1>
              <br />
              <ResponseLoading loading_message={this.state.loading_message} close_response_loader_dialog={this.OpenCloseResponseLoaderDialog}/>
              {this.state.article_metadata_hidden ?
                <>
                  <div className="metadatadiv" onClick={() => this.hide_article_metadata()}>
                    Hide Article Metadata
                  </div><br />
                  <ArticleMetaDataCollector
                    article_meta_inputs={this.state.analytics_metadata}
                    updateArticleMetadata={this.MetaCollector} />
                </> :
                <div className="metadatadiv" onClick={() => this.hide_article_metadata()}>
                  Show Article Metadata
                </div>
              }
              <br /><br /><br />
              <label htmlFor="stateindexchanger">
                Current Page Number {this.state.current_analytics_state_index}
              </label>
              <br />
              <select
                value={this.state.current_analytics_state_index}
                onChange={this.setCurrentAnalyticsStateIndex}>
                <option value='' key='default'>Choose a Page</option>
                {this.state.analytics_states.map((v, idx) => {
                  return (
                    <option value={idx} key={idx}>
                      {idx}
                    </option>
                  )
                })}
              </select>
              <br/>
            </>
            <div >
              <button
                name="addnewstate"
                onClick={this.createNewAnalysisState}>
                New Page
              </button>
              <br />
              <button
                name="addnewstate"
                onClick={this.showCreateFromTemplate}>
                New Page From Template
              </button>
              <br />
              <button
                name="deletecurrentstate"
                onClick={this.deleteCurrentAnalysisState}>
                Delete Current Page
              </button>
              <br />
              <button
                disabled={Object.keys(this.state.analytics_metadata).includes('title') &&
                  Object.keys(this.state.analytics_metadata).includes('description') &&
                  Object.keys(this.state.analytics_metadata).includes('create_date') ? "" : true}
                name="save_analysis_to_db"
                value={this.state.analysis_id === "" ? "save_new_analysis" : "update_analysis"}
                onClick={(e) => this.CreateUpdateAnalysis(e.target.value)}>
                {this.state.analysis_id === "" ? "Save Analysis" : "Update Analysis"}
              </button>
            </div>
            <br />
            <DataSelector chooseDataset={this.updateSimpleChoice} current_dataset={this.state.selected_dataset} />
            <br />
            <>
              <AnalysisInputRouter
                dataset={this.state.selected_dataset}
                selected_analysis={this.state.selected_analysis}
                chooseAnalysis={this.updateSimpleChoice}
                input_data_collector={this.inputDataCollector}
                selected_graph={this.state.selected_graph}
                component_inputs={this.state.component_inputs}
                graph_inputs={this.state.graph_inputs}
                query_inputs={this.state.query_inputs}
                graph_input_collector={this.graphInputCollector}
                text_generation_input_collector = {this.textGenerationInputCollector}
                text_generation_data = {this.state.text_generation_data}
                text_generation_api_function = {this.textGenerationApiCall}
                loading_message={this.state.loading_message}>
              </AnalysisInputRouter>
              <br />
              <button
                onClick={this.getGraphData}>
                Get My Data
              </button>
              <br /><br />
              <button
                onClick={this.showTemplateCreator}>
                Create Template
              </button>
            </>
            <br />
          </div>
          <div className="articlecontainer">
            <>
              <GraphRouter
                selected_analysis={this.state.selected_analysis}
                selected_graph={this.state.selected_graph}
                chooseGraph={this.updateSimpleChoice}
                data_for_graph={this.state.data_for_graph}
                graph_inputs={this.state.graph_inputs}
                graph_input_collector={this.graphInputCollector}
                component_inputs={this.state.component_inputs}
              ></GraphRouter>
            </>
          </div>
        </div> }
      </div>
    )} else {
      return <p>You have to login first</p>
    }
  }
}

export default withRouter(withAuth0(AnalysisBuilder));
