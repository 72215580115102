
const dimensionsMap = () => {
    const dim_map = {
        run_gap: {label: 'Run Gap', spark: () => 'run_gap'},
        run_location: {label: 'Run Location', spark: () => 'run_location'},
        run_location_detailed: {label: 'Run Location Detailed', spark: () => 'CONCAT(run_location, " ",run_gap)'},
        qb_starting_position: {label: 'QB Starting Position', spark: () => 'IF(shotgun=1,"shotgun","under center")'},
        offense_team: {label: 'Offense Team', spark: () => 'posteam'},
        offense_team_home_away: {label: 'Offense Team Home/Away', spark: () => 'posteam_type'},
        defense_team: {label: 'Defense Team', spark: () => 'defteam'},
        quarter: {label: 'Quarter', spark: () => 'qtr'},
        down: {label: 'Down', spark: () => 'down'},
        yards_to_first_down: {label: 'Yards To First Down', spark: () => 'ydstogo'},
        date: {label: 'Date', spark: () => 'game_dated'},
        game_half: {label: 'Game Half', spark: () => 'game_half'},
        drive_number: {label: 'Drive Number', spark: () => 'drive'},
        yards_to_goal: {label: 'Yards To Goal', spark: () => 'yardline_100'},
        play_type: {label: 'Play Type', spark: () => 'play_type'},
        huddle_or_no_huddle: {label: 'Huddle Or No Huddle', spark: () => 'if(no_hudle=1,"No Huddle", "Huddle")'},
        qb_dropback: {label: 'QB Dropback', spark: () => 'qb_dropback'},
        qb_kneel: {label: 'QB Kneel', spark: () => 'qb_kneel'},
        spike: {label: 'Spike', spark: () => 'qb_spike'},
        scramble_play: {label: 'Scramble Play', spark: () => 'qb_scramble'},
        pass_length_category: {label: 'Pass Length Category', spark: () => 'pass_length'},
        pass_location_category: {label: 'Pass Location Category', spark: () => 'pass_location'},
        pass_location_detailed: {label: 'Pass Location Detailed', spark: () => 'CONCAT(pass_length, " ",pass_location)'},
        score_differential: {label: 'Score Differential', spark: () => 'score_differential'},
        stadium_roof_type: {label: 'Stadium Roof Type', spark: () => 'roof'},
        field_surface_type: {label: 'Field Surface Type', spark: () => 'surface'},
        game_temperature: {label: 'Game Temperature', spark: () => 'temp'},
        game_wind_speed: {label: 'Game Wind Speed', spark: () => 'wind'},
        home_team_coach: {label: 'Home Team Coach', spark: () => 'home_coach'},
        away_team_coach: {label: 'Away Team Coach', spark: () => 'away_coach'},
        stadium: {label: 'Stadium', spark: () => 'game_stadium'},
        passer_name: {label: 'Passer Name', spark: () => 'passer'},
        rusher_name: {label: 'Rusher Name', spark: () => 'rusher'},
        receiver_name: {label: 'Receiver Name', spark: () => 'receiver'},
        penalty_type: {label: 'Penalty Type', spark: () => 'penalty_type'},
        penalty_team: {label: 'Penalty Team', spark: () => 'penalty_team'},
        penalty_player: {label: 'Penalty Player', spark: () => 'penalty_player_named'},
        sacker_name: {label: 'Sacker Name', spark: () => 'sack_player_named'},
        partial_sackers: {label: 'Partial Sackers', spark: () => 'CONCAT(half_sack_1_player_name, " ",half_sack_2_player_name)'},
        fumble_recovery_teams: {label: 'Fumble Recovery Teams', spark: () => 'CONCAT(fumble_recovery_1_team, " ",fumble_recovery_2_team)'},
        fumble_recovery_players: {label: 'Fumble Recovery Players', spark: () => 'CONCAT(fumble_recovery_1_player_name, " ",fumble_recovery_2_player_name)'},
        fumble_teams: {label: 'Fumble Teams', spark: () => 'CONCAT(fumbled_1_team, " ",fumbled_2_team)'},
        fumble_players: {label: 'Fumble Players', spark: () => 'CONCAT(fumbled_1_player_name, " ",fumbled_2_player_name)'},
        passes_defensed_teams: {label: 'Passes Defensed Teams', spark: () => 'CONCAT(pass_defense_1_player_name, " ",pass_defense_2_player_name)'},
        assisted_tackle_players: {label: 'Assisted Tackle Players', spark: () => 'CONCAT(tackle_with_assist_1_player_name, " ",tackle_with_assist_2_player_name)'},
        assisted_tackle_teams: {label: 'Assisted Tackle Teams', spark: () => 'CONCAT(tackle_with_assist_1_team, " ",tackle_with_assist_2_team)'},
        solo_tackle_team: {label: 'Solo Tackle Team', spark: () => 'CONCAT(solo_tackle_1_team, " ",solo_tackle_2_team)'},
        solo_tackle_player: {label: 'Solo Tackle Player', spark: () => 'CONCAT(solo_tackle_1_player_name, " ",solo_tackle_2_player_name)'},
        interception_name: {label: 'Interception Name', spark: () => 'interception_player_name'},
        punt_returner_name: {label: 'Punt Returner Name', spark: () => 'punt_returner_player_name'},
        kickoff_player_name: {label: 'Kickoff Player Name', spark: () => 'kickoff_returner_player_name'},
        punter_name: {label: 'Punter Name', spark: () => 'punter_player_name'},
        fg_kicker_name: {label: 'FG Kicker Name', spark: () => 'kicker_player_name'},
        kickoff_recovery_player_name: {label: 'Kickoff Recovery Player Name', spark: () => 'own_kickoff_recovery_player_name'},
        blocked_kick_player_name: {label: 'Blocked Kick Player Name', spark: () => 'blocked_player_named'},
        tackle_for_loss: {label: 'Tackle For Loss', spark: () => 'CONCAT(tackle_for_loss_1_player_name, " ", tackle_for_loss_2_player_name)'},
        qb_hit: {label: 'QB Hit', spark: () => 'CONCAT(qb_hit_1_player_name, " ", qb_hit_2_player_name)'},
        forced_fumple_player_name: {label: 'Forced Fumple Player Name', spark: () => 'CONCAT(forced_fumble_player_1_player_name, " ", forced_fumble_player_2_team)'},
        forced_fumble_team: {label: 'Forced Fumble Team', spark: () => 'CONCAT(forced_fumble_player_1_team, " ", forced_fumble_player_2_team)'}
    };
    return dim_map
};

export {dimensionsMap};