import React from 'react';
import ArticleEditInput from "./ArticleEditInput";
import VictoryStandardGraphInput from './VictoryStandardGraphInput';

const BubbleChartInput = (props) => {
    return (
        <>
            <label htmlFor="graph title"> Graph Title</label>
                <input
                    name="graphtitle"
                    value={props.graph_inputs.graphtitle}
                    onChange={(e) => props.graph_input_collector({graphtitle:e.target.value})} />
            <label htmlFor="xaxislabel"> xAxis Name</label>
            <input
                name="xaxislabel"
                value={props.graph_inputs.xaxis}
                onChange={(e) => props.graph_input_collector({xaxis:e.target.value})} />
            <label htmlFor="yaxislabel"> yAxis Name</label>
            <input
                name="yaxislabel"
                value={props.graph_inputs.yaxis}
                onChange={(e) => props.graph_input_collector({yaxis:e.target.value})} />
            <VictoryStandardGraphInput {...props}/>
            <label htmlFor="max_bubble_size"> Max Bubble Size </label>
            <div>
                <input
                    name="max_bubble_size"
                    type="number"
                    value={props.graph_inputs.max_bubble_size}
                    onChange={(e) => {
                        props.graph_input_collector({max_bubble_size: e.target.value});
                    }}/>
            </div>
            <label htmlFor="min_bubble_size"> Min Bubble Size </label>
            <div>
                <input
                    name="min_bubble_size"
                    type="number"
                    value={props.graph_inputs.min_bubble_size}
                    onChange={(e) => {
                        props.graph_input_collector({min_bubble_size: e.target.value});
                    }}/>
            </div>
            <label htmlFor="legend_label"> legend label </label>
            <div>
                <input
                    name="legend_label"
                    type="text"
                    value={props.graph_inputs['legend_label']}
                    onChange={(e) => {
                        props.graph_input_collector({legend_label: e.target.value});
                    }}/>
            </div>
            <br/>
            <label htmlFor="legend_x_spacing"> legend element spacing</label>
            <div>
                <input
                    name="legend_x_spacing"
                    type="number"
                    value={props.graph_inputs['legend_x_spacing']}
                    onChange={(e) => {
                        props.graph_input_collector({legend_x_spacing: e.target.value});
                    }}/>
            </div>
            <label htmlFor="legend_label_scaler"> legend label scaler </label>
            <div>
                <input
                    name="legend_label_scaler"
                    type="number"
                    value={props.graph_inputs['legend_label_scaler']}
                    onChange={(e) => {
                        props.graph_input_collector({legend_label_scaler: e.target.value});
                    }}/>
            </div>
            <label htmlFor="legend_label_font_size"> legend label font size </label>
            <div>
                <input
                    name="legend_label_font_size"
                    type="number"
                    value={props.graph_inputs['legend_label_font_size']}
                    onChange={(e) => {
                        props.graph_input_collector({legend_label_font_size: e.target.value});
                    }}/>
            </div>
            <br/>
            <label htmlFor="legend_font_size"> legend font size </label>
            <div>
                <input
                    name="legend_font_size"
                    type="number"
                    value={props.graph_inputs['legend_font_size']}
                    onChange={(e) => {
                        props.graph_input_collector({legend_font_size: e.target.value});
                    }}/>
            </div>
            <br/>
            <label htmlFor="textchunk_top"> text above graph</label>
            <div>
                <ArticleEditInput
                    name="textchunk_top"
                    value={props.graph_inputs['textchunk_top']}
                    onChange={(e) => {
                        props.graph_input_collector({textchunk_top: e});
                    }}/>
            </div>
            <br/><br/><br/><br/><br/>
            <label htmlFor="textchunk_bottom"> text below graph </label>
            <div>
                <ArticleEditInput
                    name="textchunk_bottom"
                    value={props.graph_inputs['textchunk_bottom']}
                    onChange={(e) => {
                        props.graph_input_collector({textchunk_bottom: e});
                    }}/>
            </div>
            <br/><br/><br/><br/><br/>
        </>
    )
}

export default BubbleChartInput;