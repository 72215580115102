import React from 'react';

const datasets = [
    {'name': 'NBA', 'value': 'nba'}, 
    {'name': 'NFL', 'value': 'nfl'}, 
    {'name': 'Markets', 'value': 'markets'}, 
    {'name': 'World Bank', 'value': 'world_bank'}, 
    // {'name': 'Bureau Of Labor & Statistics', 'value': 'b_labor_stats'},
    {'name': 'FRED', 'value': 'fred'},
    {'name': 'FBI Crime', 'value': 'fbi_crime'},
    {'name': 'Google Search Trends', 'value': 'google_trends'}
]
const DataSelector = (props) => {
    return (
        <>
            <label> Choose Dataset</label>
                <select onChange={(e) => props.chooseDataset("selected_dataset", e)} value={props.current_dataset}>
                    <option></option>
                    {datasets.map(dataset => <option value={dataset.value} key={dataset.value}>{dataset.name}</option>)}
                </select>
        </>
    )
}

export default DataSelector;
