import React, {Component, useState, useEffect} from 'react';
import {BrowserRouter as Router, Route, Link} from "react-router-dom";
import './AccountSettings.css'
import Footer from '../../components/reader/Footer.js'
import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga';
import axios from "axios";
import {getConfig} from "../../config";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../../utils/Loading";

const AccountSettings = () => {
    const {
        getAccessTokenSilently,
        loginWithPopup,
        getAccessTokenWithPopup,
        user
    } = useAuth0();

    const [userName, setUserName] = useState('');

    useEffect(() => {
        setUserName(user.name)

        TagManager.dataLayer({
            dataLayer: {
                event: 'PageView',
                pagePath: 'account-settings'
            },
        });
    }, [])

    return (
        <div style={{
            direction: "flex",
            alignContent: "flex-start",
            alignItems: "flex-start",
            display: "flex-column",
            height: "20%"
        }}>
            <div className='masthead'></div>
            <div style={{
                width: "100%",
                paddingTop: "10px",
                paddingLeft: "20px",
                fontFamily: "JoanRegular",
                justifyContent: "flex-start",
                display: "flex",
                alignItems: "flex-start"
            }}>
                <h2> Hello, {userName}</h2>
            </div>
            <div style={{direction: "flex", paddingTop: "50px", height: "200px", paddingLeft: "20px"}}>
                <div className='buttondiv'>
                    <Link style={{fontFamily: "JoanRegular", textDecoration: "none"}} to="/myanalyses"> Your
                        Articles </Link>
                </div>
            </div>
            <Footer/>
        </div>)
}

export default withAuthenticationRequired(AccountSettings, {
    onRedirecting: () => <Loading />,
});