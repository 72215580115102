import React from 'react';
import TextChunk from './TextChunk';
import * as d3 from 'd3';
import ChordDiagram from './components/ChordComponent/ChordDiagram';
import assist_chart_legend from './components/nba_assist_chart_legend.png';
import {useState} from "react";
import { maxWidth } from '@mui/system';


const Chord = (props) => {
    const [isOpen, setIsOpen] = useState(true);
    const data_length = props.data_for_graph.length > 0 ? props.data_for_graph[0]['matrix'][0].length : 0;
    const color_scale = d3.scaleSequential()
              .domain([0,data_length])
              .interpolator(d3.interpolateViridis);
    const colors = Array.from({length:data_length},(v,k)=>v).map((x, i)=>color_scale(i))
    const bottom_text = {graph_inputs:{textchunk: props.graph_inputs.textchunk_bottom}}
    const top_text = {graph_inputs:{textchunk: props.graph_inputs.textchunk_top}}
    const toggle = () => {
      console.log('clicked')
      setIsOpen(!isOpen)
      console.log(isOpen)
    };
  
    return (
      <div>
        <TextChunk {...top_text} />
        {props.data_for_graph.length > 0 ? 
        <>
        <div style={{overflow:"hidden"}}>
          <ChordDiagram
            matrix={props.data_for_graph[0]['matrix']}
            width={Math.min(props.screen_height, props.screen_width)*.9}
            height={Math.min(props.screen_height, props.screen_width)*.9}
            // height={props.screen_height/2}
            outerRadius={Math.min(props.screen_height, props.screen_width)*.3}
            innerRadius={Math.min(props.screen_height, props.screen_width)*.3-7}
            style={{overflow:"hidden", font: '10px sans-serif', transform: [{ rotate: '90deg'}]}}//{{transformOrigin: '0 0', transform: 'rotate(90deg)'}}
            componentId={1}
            groupLabels={props.data_for_graph[0]['nameByIndex']}
            groupColors={colors.slice(0, data_length)}
            svgOnClick={()=>{}}
            graph_inputs={props.graph_inputs}
            groupOnClick={(elem)=>{props.graph_input_collector({'clicked_group':elem})}}
            ribbonOnClick={(elem)=>{props.graph_input_collector({'clicked_ribbon':elem})}}//this is broken but need to do something similar
            resizeWithWindow={true}
            />
        </div>
        <div onClick={() => toggle()} style={{fontSize: "0.8em", backgroundColor:"lightGrey", maxWidth:"150px", float:"center", borderRadius:"5px"}}> How Do I Read This Chart? </div>
        <div hidden={isOpen}>
        <img src={assist_chart_legend} style= {{maxWidth: "350px"}} />
        </div>
        <TextChunk {...bottom_text} />
        </>
            :
        <div/>
        }
        <div>{props.clicked_group}</div>
      </div>
    )

}

export default Chord;