import React, { useEffect } from 'react';
import server_configs from '../../server_configs';
import { useState } from 'react';
import {Table, TableHead, TableBody, TableRow, TableCell} from '@mui/material';

import './DatasetAnalysisDescription.css'

const DatasetAnalysisDescription = (props) => {
    const {dataset, selected_analysis} = props
    const [search_term, setSearchTerm] = useState("")
    const [data_helper_data, setDataHelperData] = useState({})


    const getHelperData = (term, dataset) => {
        fetch(`${server_configs['api_domain']}/data_blogging/get_data_helper_data/?dataset=${dataset}&term=${term}`, {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          }
        })
          .then(response => {
            response.json()
              .then(json => setDataHelperData(json));
          })
      }
    
    const data_helper_input = {
        markets: '',
        nba: '', 
        markets: '', 
        world_bank: '', 
        b_labor_stats: '',
        fred: <input type="text" value={search_term} onChange={(e) => setSearchTerm(e.target.value)} />
    }

    const data_helper_output = {
        markets: '',
        nba: '', 
        markets: '', 
        world_bank: '', 
        b_labor_stats: '',
        fred: <div style={{minWidth:"300px", minHeight:"300px", overflow:"scroll"}}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell style={{fontSize:"20px"}}>Title</TableCell>
                        <TableCell style={{fontSize:"20px"}}>ID</TableCell>
                        <TableCell style={{fontSize:"20px"}}>Data Start Date</TableCell>
                        <TableCell style={{fontSize:"20px"}}>Data End Date</TableCell>
                        <TableCell style={{fontSize:"20px"}}>Units</TableCell>
                        <TableCell  style={{fontSize:"20px"}}>Seasonal Adjustment</TableCell>
                        <TableCell  style={{fontSize:"20px"}}>Last Updated</TableCell>
                        <TableCell  style={{fontSize:"20px"}}>Notes</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {Object.keys(data_helper_data).map(x =>
                    <TableRow >
                        <TableCell  style={{fontSize:"15px"}}>
                        {` ${data_helper_data[x].title}`}
                        </TableCell>
                        <TableCell style={{fontSize:"15px"}}>
                        {` ${data_helper_data[x].id}`}
                        </TableCell>
                        <TableCell  style={{fontSize:"15px"}}>
                        {` ${data_helper_data[x].observation_start}`}
                        </TableCell>
                        <TableCell  style={{fontSize:"15px"}}>
                        {` ${data_helper_data[x].observation_end}`}
                        </TableCell>
                        <TableCell  style={{fontSize:"15px"}}>
                        {` ${data_helper_data[x].units}`}
                        </TableCell>
                        <TableCell  style={{fontSize:"15px"}}>
                        {` ${data_helper_data[x].frequency}`}
                        </TableCell>
                        <TableCell  style={{fontSize:"15px"}}>
                        {` ${data_helper_data[x].seasonal_adjustment}`}
                        </TableCell>
                        <TableCell  style={{fontSize:"15px"}}>
                        {` ${data_helper_data[x].last_updated}`}
                        </TableCell>
                        <TableCell  style={{fontSize:"15px"}}>
                        {` ${data_helper_data[x].notes}`}
                        </TableCell>
                    </TableRow>)}
                </TableBody>
            </Table>
              </div>
    }

    const user_notes = {
        markets: {
            OneDimOneMetric:'placeholder', 
            TextChunk: 'placeholder',
            Tweet: 'Embed a live rendered Tweet with text above and below. \n\n To embed, choose the "Tweet" graph type, then find the URL of the Tweet in the Tweet\'s URL, i.e., https://twitter.com/elonmusk/status/<TWEETID>. \n\n Enter this Tweet ID into the Tweet URL below.',
            ShotChart: 'placeholder',
            Chord: 'placeholder',
            BubbleChart: 'placeholder',
            ImageGraph: 'Write your text above & below an image. You can also add text overlays to images.'},
        nba: {
            OneDimOneMetric:'placeholder', 
            TextChunk: 'placeholder',
            Tweet: 'Embed a live rendered Tweet with text above and below. \n\n To embed, choose the "Tweet" graph type, then find the URL of the Tweet in the Tweet\'s URL, i.e., https://twitter.com/elonmusk/status/<TWEETID>. \n\n Enter this Tweet ID into the Tweet URL below.',
            ShotChart: 'placeholder',
            Chord: 'placeholder',
            BubbleChart: 'placeholder',
            ImageGraph: 'Write your text above & below an image. You can also add text overlays to images.'
        }, 
        markets: {
            OneDimOneMetric:'placeholder', 
            TextChunk: 'placeholder',
            Tweet: 'Embed a live rendered Tweet with text above and below. \n\n To embed, choose the "Tweet" graph type, then find the URL of the Tweet in the Tweet\'s URL, i.e., https://twitter.com/elonmusk/status/<TWEETID>. \n\n Enter this Tweet ID into the Tweet URL below.',
            ShotChart: 'placeholder',
            Chord: 'placeholder',
            BubbleChart: 'placeholder',
            ImageGraph: 'Write your text above & below an image. You can also add text overlays to images.'
        }, 
        world_bank: {
            OneDimOneMetric:'placeholder', 
            TextChunk: 'placeholder',
            Tweet: 'Embed a live rendered Tweet with text above and below. \n\n To embed, choose the "Tweet" graph type, then find the URL of the Tweet in the Tweet\'s URL, i.e., https://twitter.com/elonmusk/status/<TWEETID>. \n\n Enter this Tweet ID into the Tweet URL below.',
            ShotChart: 'placeholder',
            Chord: 'placeholder',
            BubbleChart: 'placeholder',
            ImageGraph: 'Write your text above & below an image. You can also add text overlays to images.'
        }, 
        b_labor_stats: {
            OneDimOneMetric:'placeholder', 
            TextChunk: 'placeholder',
            Tweet: 'Embed a live rendered Tweet with text above and below. \n\n To embed, choose the "Tweet" graph type, find the ID of the Tweet in the Tweet\'s URL, i.e., https://twitter.com/elonmusk/status/<TWEETID>. \n\n Enter this Tweet ID into the Tweet URL below.',
            ShotChart: 'placeholder',
            Chord: 'placeholder',
            BubbleChart: 'placeholder',
            ImageGraph: 'Write your text above & below an image. You can also add text overlays to images.'
        },
        fred: {
            OneDimOneMetric:'This is the Federal Reserve Economic Data (FRED). It consists of 817,000 time-series data sets from 108 sources. \n\n You may search through metrics using the below search box. When you find a series, use this ID for your FRED Metric value. You may optionally filter on Dates.', 
            TextChunk: 'placeholder',
            Tweet: 'Embed a live rendered Tweet with text above and below. \n\n To embed, choose the "Tweet" graph type, find the ID of the Tweet in the Tweet\'s URL, i.e., https://twitter.com/elonmusk/status/<TWEETID>. \n\n Enter this Tweet ID into the Tweet URL below.',
            ShotChart: 'placeholder',
            Chord: 'placeholder',
            BubbleChart: 'placeholder',
            ImageGraph: 'Write your text above & below an image. You can also add text overlays to images.'
        }
    }
    let notes = 'There are no notes for this dataset/analysis combination, it may be unsupported.'
    let data_helper_component = ''
    let data_helper_output_component = ''
    if(Object.keys(user_notes).includes(dataset) && Object.keys(user_notes[dataset]).includes(selected_analysis)) {
        notes = user_notes[dataset][selected_analysis]
        if(Object.keys(data_helper_input).includes(dataset) && data_helper_input[dataset] != '') {
            data_helper_component = data_helper_input[dataset]
            data_helper_output_component = data_helper_output[dataset]
        }
    }
    return (
        <>
        <h4>Information about this dataset & analysis</h4>
        <div style={{textAlign:'left', maxWidth:"400px", whiteSpace:"pre-line"}}>
        {notes}
        </div>
        <br/>
        {data_helper_component != '' ? <>
                <h4> Explore The Dataset </h4> 
                <div className="metadatadiv" onClick={() => getHelperData(search_term, dataset)}>
                    Search Dataset
              </div><br/>
            </> : <></>}
        {data_helper_component}
        {data_helper_output_component}
        </>
    )
}

export default DatasetAnalysisDescription;
