import React from 'react';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ArticleCardSummaryNoImage from './ArticleCardSummaryNoImage';
import { makeStyles } from '@mui/styles';
import {
    EmailShareButton,
    FacebookShareButton,
    InstapaperShareButton,
    LinkedinShareButton,
    RedditShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton
} from "react-share";

import {
    EmailIcon,
    FacebookIcon,
    InstapaperIcon,
    LinkedinIcon,
    RedditIcon,
    TelegramIcon,
    TwitterIcon,
    WhatsappIcon,
  } from "react-share";
const useStyles = makeStyles({
    root: {
        Width: "100%",
        align: "center"
    },
    media: {
        height: 300,
    },
    title: {
        fontSize: 10,
    },
});

const ArticleIntroBlock = (props) => {
    const classes = useStyles();

    const image_url = "https://driving-data-journalism.imgix.net/" + props.analytics_metadata.cover_image;
    return (
        <div display="flex" >
            <br />
            <Card className={classes.root} style={{ maxWidth: '800px', margin: "auto" }} variant="none">
                {props.analytics_metadata.cover_image === undefined ? <></> : <CardMedia
                    className={classes.media}
                    image={image_url}
                    title={props.analytics_metadata.title}
                />}
                <Typography gutterBottom variant="h4" component="h4" align="left" style={{ fontFamily: "JoanRegular" }}>
                    {props.analytics_metadata.title}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p" align="left" style={{ fontFamily: "JoanRegular" }}>
                    {props.analytics_metadata.description}
                </Typography>
                <Typography className={classes.title} color="textSecondary" align="left" gutterBottom style={{ fontFamily: "JoanRegular" }}>
                    {props.analytics_metadata.author} {props.analytics_metadata.create_date}
                </Typography>
            </Card>
            <div style={{ display:"flex", flexDirection:"row", maxWidth: '800px', margin: "auto"}}>
                <EmailShareButton url={window.location.href} subject={props.analytics_metadata.title} body={props.analytics_metadata.description}>
                    <EmailIcon size={32} bgStyle={{fill:"none"}} iconFillColor="grey"/>
                </EmailShareButton >
                <FacebookShareButton url={window.location.href} quote={props.analytics_metadata.description}>
                    <FacebookIcon size={32} bgStyle={{fill:"none"}} iconFillColor="grey"/>
                </ FacebookShareButton>
                <TwitterShareButton url={window.location.href} title={props.analytics_metadata.title}>
                    <TwitterIcon size={32} bgStyle={{fill:"none"}} iconFillColor="grey"/>
                </ TwitterShareButton>
                <LinkedinShareButton url={window.location.href} title={props.analytics_metadata.title} summary={props.analytics_metadata.description} source={"The Data News-Journal"}>
                    <LinkedinIcon size={32} bgStyle={{fill:"none"}} iconFillColor="grey"/>
                </ LinkedinShareButton>
                <RedditShareButton url={window.location.href} title={props.analytics_metadata.title}>
                    <RedditIcon size={32} bgStyle={{fill:"none"}} iconFillColor="grey"/>
                </ RedditShareButton>
                <TelegramShareButton url={window.location.href} title={props.analytics_metadata.title}>
                    <TelegramIcon size={32} bgStyle={{fill:"none"}} iconFillColor="grey"/>
                </ TelegramShareButton>
                <WhatsappShareButton url={window.location.href} title={props.analytics_metadata.title}>
                    <WhatsappIcon size={32} bgStyle={{fill:"none"}} iconFillColor="grey"/>
                </ WhatsappShareButton>
                <WhatsappShareButton url={window.location.href} />
            </div>
            <br/>
        </div>
        
    )
}

export default ArticleIntroBlock;