const server_config = {
    // api_domain: 'http://ec2-52-15-67-131.us-east-2.compute.amazonaws.com:8000',
    // node_domain: 'http://ec2-52-15-67-131.us-east-2.compute.amazonaws.com:3000'
    // node_domain: 'http://bettersportsblog.com',
    // api_domain: 'http://bettersportsblog.com',
    // api_domain: 'http://0.0.0.0:8000/api',
    // node_domain: 'http://localhost:3000'
     api_domain: 'https://thedatanewsjournal.com/api'
    // api_domain: 'http://localhost:8000/api'

}

export default server_config;
