import BloggerNavBar from '../../components/blogger/BloggerNavBar';
import {Link} from "react-router-dom";
import { withRouter } from 'react-router';
import React, { Component } from 'react';

class Settings extends Component {
    render() {
        return ( <>
            <BloggerNavBar/>
        </>)
    }
}

export default withRouter(Settings);
