import { React, useState } from 'react';

import OneDimOneMetric from './analysisinputs/OneDimOneMetric'
import TwoDimOneMetric from './analysisinputs/TwoDimOneMetric'
import ShotChart from './analysisinputs/ShotChart'
import Chord from './analysisinputs/Chord'
import BubbleChart from './analysisinputs/BubbleChart'
import Candlestick from './analysisinputs/Candlestick';
import NoDimManyMetrics from './analysisinputs/NoDimManyMetrics';
import OneDimManyMetrics from './analysisinputs/OneDimManyMetrics';

import TextChunkInput from './graphinputs/TextChunkInput'
import BarGraphInput from './graphinputs/BarGraphInput'
import MultiLineGraphInput from './graphinputs/MultiLineGraphInput'
import StackedAreaGraphInput from './graphinputs/StackedAreaGraphInput'
import StackedBarchartInput from './graphinputs/StackedBarchartInput'
import LineGraphInput from './graphinputs/LineGraphInput'
import TweetInput from './graphinputs/TweetInput'
import ShotChartInput from './graphinputs/ShotChartInput'
import ChordInput from './graphinputs/ChordInput'
import BubbleChartInput from './graphinputs/BubbleChartInput'
import ImageGraphInput from './graphinputs/ImageGraphInput'
import CandlestickInput from './graphinputs/CandlestickInput'
import WorldChoroplethInput from './graphinputs/WorldChoroplethInput'
import UsChoroplethInput from './graphinputs/UsChoroplethInput'

import DatasetAnalysisDescription from '../blogger/DatasetAnalysisDescription';

import OpenAIDialog from '../blogger/OpenAIDialog';

const AnalysisInputRouter = (props) => {
  const [isGraphInputOpen, setIsGraphInputOpen] = useState("none");
  const [isAnalysisInputOpen, setIsAnalysisInputOpen] = useState("none");
  const [isOpenAiOpen, setIsOpenAiOpen] = useState("none");

  const dataset_analysis = {
    'nba': {
      OneDimOneMetric: <OneDimOneMetric {...props} />,
      TwoDimOneMetric: <TwoDimOneMetric {...props} />,
      TextChunk: <div />,
      Tweet: <div />,
      ShotChart: <ShotChart {...props} />,
      Chord: <Chord {...props} />,
      BubbleChart: <BubbleChart {...props} />
    },
    'nfl': {
      OneDimOneMetric: <OneDimOneMetric {...props} />,
      TwoDimOneMetric: <TwoDimOneMetric {...props} />,
      TextChunk: <div />,
      Tweet: <div />,
      BubbleChart: <BubbleChart {...props} />
    },
    'markets': {
      OneDimOneMetric: <OneDimOneMetric {...props} />,
      Candlestick: <Candlestick {...props} />,
      TwoDimOneMetric: <TwoDimOneMetric {...props} />,
      TextChunk: <div />,
      Tweet: <div />
    },
    'world_bank': {
      OneDimOneMetric: <OneDimOneMetric {...props} />,
      TwoDimOneMetric: <TwoDimOneMetric {...props} />,
      TextChunk: <div />,
      Tweet: <div />
    },
    'b_labor_stats': {
      OneDimOneMetric: <OneDimOneMetric {...props} />,
      TextChunk: <div />,
      Tweet: <div />
    },
    'fred': {
      OneDimOneMetric: <OneDimOneMetric {...props} />,
      NoDimManyMetrics: <NoDimManyMetrics {...props} />,
      OneDimManyMetrics: <OneDimManyMetrics {...props} />,
      TextChunk: <div />,
      Tweet: <div />
    },
    'fbi_crime': {
      OneDimOneMetric: <OneDimOneMetric {...props} />,
      TwoDimOneMetric: <TwoDimOneMetric {...props} />,
      TextChunk: <div />,
      Tweet: <div />
    },
  'google_trends': {
      OneDimOneMetric: <OneDimOneMetric {...props} />,
      TwoDimOneMetric: <TwoDimOneMetric {...props} />,
      TextChunk: <div />,
      Tweet: <div />
    }
  }

  const analyses = props.dataset ? dataset_analysis[props.dataset] : {}

  // const analyses = {
  //   OneDimOneMetric: <OneDimOneMetric {...props}/>, 
  //   TwoDimOneMetric: <TwoDimOneMetric {...props}/>,
  //   TextChunk: <div />,
  //   Tweet: <div />,
  //   ShotChart: <ShotChart {...props} />,
  //   Chord: <Chord {...props} />,
  //   BubbleChart: <BubbleChart {...props} />,
  //   ImageGraph: <div />
  // }

  const graph_inputs = {
    BarGraph: <BarGraphInput {...props} />,
    Candlestick: <CandlestickInput {...props} />,
    MultiLineGraph: <MultiLineGraphInput {...props} />,
    StackedBarchart: <StackedBarchartInput {...props} />,
    StackedAreaGraph: <StackedAreaGraphInput {...props} />,
    LineGraph: <LineGraphInput {...props} />,
    TextChunk: <TextChunkInput {...props} />,
    Tweet: <TweetInput {...props} />,
    ShotChart: <ShotChartInput {...props} />,
    Chord: <ChordInput {...props} />,
    BubbleChart: <BubbleChartInput {...props} />,
    ImageGraph: <ImageGraphInput {...props} />,
    WorldChoropleth: <WorldChoroplethInput {...props} />,
    UsChoropleth: <UsChoroplethInput {...props} />
  }
  return (
    <>
      <label htmlFor="analysis_selector"> Choose An Analysis</label>
      <select
        onChange={props.chooseAnalysis.bind(this, "selected_analysis")}
        name="analysis_selector"
        value={props.selected_analysis}>
        <option value="">Choose An Analysis</option>
        {Object.keys(analyses).map(analysis =>
          <option value={analysis} key={analysis}>{analysis}</option>)
        }
      </select>
      {props.selected_analysis != "" && props.selected_dataset != "" ?
        <DatasetAnalysisDescription
          dataset={props.dataset}
          selected_analysis={props.selected_analysis}
        /> :
        <></>
      }
      <br /><br />
      <>
        <button onClick={() => isAnalysisInputOpen == "flex" ? setIsAnalysisInputOpen("none") : setIsAnalysisInputOpen("flex")} > Analysis Inputs</button>
        <div hidden={isAnalysisInputOpen} style={{ display: isAnalysisInputOpen, flexDirection: "column", justifyContent: "left", alignItems: "start" }}>
          <h4>Analysis Inputs</h4>
          {props.selected_graph !== "" ? analyses[props.selected_analysis] : ""}
        </div>
      </>
      <br />
      <>
        <button onClick={() => isGraphInputOpen == "flex" ? setIsGraphInputOpen("none") : setIsGraphInputOpen("flex")} > Graph Inputs</button>
        <div hidden={isGraphInputOpen} style={{ display: isGraphInputOpen, flexDirection: "column", justifyContent: "left", alignItems: "start" }}>
          <h4>Graph Inputs</h4>
          {props.selected_graph !== "" ? graph_inputs[props.selected_graph] : ""}
        </div>
      </>
      <br />
      <>
        <button onClick={() => isOpenAiOpen == "flex" ? setIsOpenAiOpen("none") : setIsOpenAiOpen("flex")} > Text Generator</button>
        <div hidden={isOpenAiOpen} style={{ display: isOpenAiOpen, flexDirection: "column", justifyContent: "left", alignItems: "start" }}>
          <h4>Create Some Text</h4>
          <OpenAIDialog loading_message={props.loading_message} text_generation_api_function = {props.text_generation_api_function} text_generation_data = {props.text_generation_data} selected_analysis={props.selected_analysis} component_inputs={props.component_inputs} text_generation_input_collector={props.text_generation_input_collector}/>
        </div>
      </>
      <br />
    </>)
}

export default AnalysisInputRouter