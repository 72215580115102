const industry_code_map = {
    '0' : "Total nonfarm",
'5000000' : "Total private",
'6000000' : "Goods-producing",
'7000000' : "Service-providing",
'8000000' : "Private service-providing",
'10000000' : "Mining and logging",
'10113300' : "Logging",
'10210000' : "Mining",
'10211000' : "Oil and gas extraction",
'10212000' : "Mining, except oil and gas",
'10212100' : "Coal mining",
'10212111' : "Bituminous coal and lignite surface mining",
'10212113' : "Bituminous coal underground mining and anthracite mining",
'10212200' : "Metal ore mining",
'10212300' : "Nonmetallic mineral mining and quarrying",
'10212310' : "Stone mining and quarrying",
'10212312' : "Crushed and broken limestone mining",
'10212319' : "Other stone mining and quarrying",
'10212320' : "Sand, gravel, clay, and refractory mining",
'10212321' : "Construction sand and gravel mining",
'10212390' : "Other nonmetallic mineral mining",
'10213000' : "Support activities for mining",
'10213112' : "Support activities for oil and gas operations",
'20000000' : "Construction",
'20236000' : "Construction of buildings",
'20236100' : "Residential building",
'20236115' : "New single-family general contractors",
'20236116' : "New multifamily general contractors",
'20236117' : "New housing operative builders",
'20236118' : "Residential remodelers",
'20236200' : "Nonresidential building",
'20236210' : "Industrial building",
'20236220' : "Commercial building",
'20237000' : "Heavy and civil engineering construction",
'20237100' : "Utility system construction",
'20237110' : "Water and sewer system construction",
'20237120' : "Oil and gas pipeline construction",
'20237130' : "Power and communication system construction",
'20237200' : "Land subdivision",
'20237300' : "Highway, street, and bridge construction",
'20237900' : "Other heavy construction",
'20238000' : "Specialty trade contractors",
'20238001' : "Residential specialty trade contractors",
'20238002' : "Nonresidential specialty trade contractors",
'20238100' : "Building foundation and exterior contractors",
'20238101' : "Residential building foundation and exterior contractors",
'20238102' : "Nonresidential building foundation and exterior contractors",
'20238110' : "Poured concrete structure contractors",
'20238120' : "Steel and precast concrete contractors",
'20238130' : "Framing contractors",
'20238140' : "Masonry contractors",
'20238150' : "Glass and glazing contractors",
'20238160' : "Roofing contractors",
'20238170' : "Siding contractors",
'20238190' : "Other building exterior contractors",
'20238200' : "Building equipment contractors",
'20238201' : "Residential building equipment contractors",
'20238202' : "Nonresidential building equipment contractors",
'20238210' : "Electrical contractors",
'20238220' : "Plumbing and HVAC contractors",
'20238290' : "Other building equipment contractors",
'20238300' : "Building finishing contractors",
'20238301' : "Residential building finishing contractors",
'20238302' : "Nonresidential building finishing contractors",
'20238310' : "Drywall and insulation contractors",
'20238320' : "Painting and wall covering contractors",
'20238330' : "Flooring contractors",
'20238340' : "Tile and terrazzo contractors",
'20238350' : "Finish carpentry contractors",
'20238390' : "Other building finishing contractors",
'20238900' : "Other specialty trade contractors",
'20238901' : "Other residential trade contractors",
'20238902' : "Other nonresidential trade contractors",
'20238910' : "Site preparation contractors",
'20238990' : "All other specialty trade contractors",
'30000000' : "Manufacturing",
'31000000' : "Durable goods",
'31321000' : "Wood products",
'31321100' : "Sawmills and wood preservation",
'31321200' : "Plywood and engineered wood products",
'31321900' : "Other wood products",
'31321910' : "Millwork",
'31321911' : "Wood windows and doors",
'31321918' : "Cut stock, resawing lumber, planing, and other millwork, including flooring",
'31321920' : "Wood containers and pallets",
'31321990' : "All other wood products",
'31327000' : "Nonmetallic mineral products",
'31327100' : "Clay products and refractories",
'31327200' : "Glass and glass products",
'31327212' : "Flat glass and other pressed and blown glass and glassware",
'31327215' : "Glass containers and products made of purchased glass",
'31327300' : "Cement and concrete products",
'31327320' : "Ready-mix concrete",
'31327390' : "Other cement and concrete products",
'31327900' : "Lime, gypsum, and other nonmetallic mineral products",
'31331000' : "Primary metals",
'31331100' : "Iron and steel mills and ferroalloy production",
'31331200' : "Steel products from purchased steel",
'31331400' : "Other nonferrous metal production, including alumina and aluminum",
'31331500' : "Foundries",
'31331510' : "Ferrous metal foundries",
'31331520' : "Nonferrous metal foundries",
'31332000' : "Fabricated metal products",
'31332100' : "Forging and stamping",
'31332200' : "Cutlery and hand tools",
'31332300' : "Architectural and structural metals",
'31332310' : "Plate work and fabricated structural products",
'31332312' : "Fabricated structural metal products",
'31332313' : "Prefabricated metal buildings, components, and plate work",
'31332320' : "Ornamental and architectural metal products",
'31332321' : "Metal windows and doors",
'31332322' : "Sheet metal work",
'31332323' : "Ornamental and architectural metal work",
'31332400' : "Boilers, tanks, and shipping containers",
'31332600' : "Hardware, spring, and wire products",
'31332700' : "Machine shops and threaded products",
'31332710' : "Machine shops",
'31332720' : "Turned products and screws, nuts, and bolts",
'31332800' : "Coating, engraving, and heat treating metals",
'31332812' : "Metal heat treating and coating and nonprecious engraving",
'31332813' : "Electroplating, anodizing, and coloring metals",
'31332900' : "Other fabricated metal products",
'31332910' : "Metal valves",
'31332912' : "Fluid power valves and hose fittings",
'31332919' : "All other metal valves",
'31332990' : "All other fabricated metal products",
'31332994' : "Small arms, ammunition, and other ordnance and accessories",
'31332999' : "Miscellaneous fabricated metal products and ball and roller bearings",
'31333000' : "Machinery",
'31333100' : "Agricultural, construction, and mining machinery",
'31333110' : "Agricultural implements",
'31333120' : "Construction machinery",
'31333130' : "Mining and oil and gas field machinery",
'31333200' : "Industrial machinery",
'31333300' : "Commercial and service industry machinery",
'31333400' : "HVAC and commercial refrigeration equipment",
'31333415' : "AC, refrigeration, and forced air heating",
'31333500' : "Metalworking machinery",
'31333511' : "Industrial molds",
'31333514' : "Special tools, dies, jigs, and fixtures",
'31333517' : "Machine tool manufacturing",
'31333519' : "Miscellaneous metalworking machinery",
'31333600' : "Turbine and power transmission equipment",
'31333900' : "Other general purpose machinery",
'31333910' : "Pumps and compressors",
'31333920' : "Material handling equipment",
'31333990' : "All other general purpose machinery",
'31334000' : "Computer and electronic products",
'31334100' : "Computer and peripheral equipment",
'31334200' : "Communications equipment",
'31334220' : "Broadcast and wireless communications equipment",
'31334400' : "Semiconductors and electronic components",
'31334412' : "Bare printed circuit boards",
'31334413' : "Semiconductors and related devices",
'31334418' : "Printed circuit assemblies",
'31334419' : "Electronic connectors and misc. electronic components",
'31334500' : "Electronic instruments",
'31334510' : "Electromedical apparatus",
'31334511' : "Search, detection, and navigation instruments",
'31334513' : "Industrial process variable instruments",
'31334515' : "Electricity and signal testing instruments",
'31334519' : "Miscellaneous electronic instruments",
'31334600' : "Miscellaneous computer and electronic products",
'31335000' : "Electrical equipment and appliances",
'31335100' : "Electric lighting equipment",
'31335200' : "Household appliances",
'31335300' : "Electrical equipment",
'31335311' : "Electric power and specialty transformers",
'31335314' : "Motors and generators, switchgears and switchboard apparatus, and relay and industrial controls",
'31335900' : "Other electrical equipment and components",
'31335910' : "Batteries",
'31335930' : "Wiring devices",
'31335990' : "All other electrical equipment and components",
'31336000' : "Transportation equipment",
'31336001' : "Motor vehicles and parts",
'31336100' : "Motor vehicles",
'31336111' : "Automobiles",
'31336200' : "Motor vehicle bodies and trailers",
'31336211' : "Motor vehicle bodies",
'31336214' : "Truck trailers, motor homes, travel trailers, and campers",
'31336300' : "Motor vehicle parts",
'31336310' : "Motor vehicle gasoline engine and parts",
'31336320' : "Motor vehicle electric equipment",
'31336330' : "Motor vehicle steering and suspension parts",
'31336350' : "Motor vehicle power train components",
'31336370' : "Motor vehicle metal stamping",
'31336390' : "All other motor vehicle parts",
'31336400' : "Aerospace products and parts",
'31336411' : "Aircraft",
'31336412' : "Aircraft engines and engine parts",
'31336413' : "Other aircraft parts and equipment",
'31336419' : "Guided missiles, space vehicles, and parts",
'31336600' : "Ship and boat building",
'31336611' : "Ship building and repairing",
'31336612' : "Boat building",
'31336900' : "Railroad rolling stock and other transportation equipment",
'31337000' : "Furniture and related products",
'31337100' : "Household and institutional furniture",
'31337110' : "Wood kitchen cabinets and countertops",
'31337120' : "Other household and institutional furniture",
'31337121' : "Upholstered household furniture",
'31337122' : "Nonupholstered wood household furniture",
'31337127' : "Miscellaneous household and institutional furniture",
'31337200' : "Office furniture and fixtures",
'31337214' : "Office furniture and custom architectural woodwork and millwork",
'31337215' : "Showcases, partitions, shelving, and lockers",
'31337900' : "Other furniture-related products",
'31339000' : "Miscellaneous durable goods manufacturing",
'31339100' : "Medical equipment and supplies",
'31339112' : "Surgical and medical instruments",
'31339113' : "Surgical appliances and supplies",
'31339116' : "Dental laboratories",
'31339900' : "Other miscellaneous durable goods manufacturing",
'31339910' : "Jewelry and silverware",
'31339920' : "Sporting and athletic goods",
'31339950' : "Signs",
'31339990' : "All other miscellaneous durable goods manufacturing",
'32000000' : "Nondurable goods",
'32311000' : "Food manufacturing",
'32311100' : "Animal food",
'32311200' : "Grain and oilseed milling",
'32311220' : "Flour milling, malt, starch, and vegetable oil",
'32311230' : "Breakfast cereal",
'32311300' : "Sugar and confectionery products",
'32311350' : "Chocolate and confectionery manufacturing",
'32311400' : "Fruit and vegetable preserving and specialty",
'32311410' : "Frozen food",
'32311411' : "Frozen fruits and vegetables",
'32311412' : "Frozen specialty food",
'32311420' : "Fruit and vegetable canning and drying",
'32311500' : "Dairy products",
'32311511' : "Fluid milk",
'32311600' : "Animal slaughtering and processing",
'32311611' : "Animal, except poultry, slaughtering",
'32311613' : "Meat processed from carcasses, and rendering and meat byproduct processing",
'32311615' : "Poultry processing",
'32311700' : "Seafood product preparation and packaging",
'32311800' : "Bakeries and tortilla manufacturing",
'32311810' : "Bread and bakery products",
'32311811' : "Retail bakeries",
'32311813' : "Commercial bakeries and frozen cakes and other pastry products",
'32311830' : "Cookies, crackers, pasta, and tortillas",
'32311900' : "Other food products",
'32311910' : "Snack food",
'32311990' : "Miscellaneous food products",
'32313000' : "Textile mills",
'32313200' : "Fabric mills",
'32314000' : "Textile product mills",
'32314100' : "Textile furnishings mills",
'32314900' : "Other textile product mills",
'32314910' : "Textile bag and canvas mills",
'32314990' : "All other textile product mills",
'32315000' : "Apparel",
'32315280' : "Cut and sew apparel, except contractors",
'32322000' : "Paper and paper products",
'32322100' : "Pulp, paper, and paperboard mills",
'32322120' : "Pulp mills and paper mills",
'32322130' : "Paperboard mills",
'32322200' : "Converted paper products",
'32322210' : "Paperboard containers",
'32322220' : "Paper bags and coated and treated paper",
'32322290' : "Stationery and other converted paper products",
'32323000' : "Printing and related support activities",
'32323117' : "Commercial printing, except screen",
'32324000' : "Petroleum and coal products",
'32324110' : "Petroleum refineries",
'32324190' : "Asphalt paving and roofing materials and other petroleum and coal products",
'32325000' : "Chemicals",
'32325100' : "Basic chemicals",
'32325130' : "Petrochemicals, industrial gases, synthetic dyes, and pigments",
'32325180' : "Other basic inorganic chemicals",
'32325190' : "Other basic organic chemicals",
'32325200' : "Resin, rubber, and artificial fibers",
'32325211' : "Plastics material and resin",
'32325300' : "Agricultural chemicals",
'32325400' : "Pharmaceuticals and medicines",
'32325412' : "Pharmaceutical preparations",
'32325414' : "Miscellaneous medicinal and biological products",
'32325500' : "Paints, coatings, and adhesives",
'32325600' : "Soaps, cleaning compounds, and toiletries",
'32325610' : "Soaps and cleaning compounds",
'32325620' : "Toilet preparations",
'32325900' : "Other chemical products and preparations",
'32326000' : "Plastics and rubber products",
'32326100' : "Plastics products",
'32326110' : "Plastics packaging materials, film, and sheet",
'32326113' : "Nonpackaging plastics film and sheet",
'32326120' : "Plastics pipe, fittings, and profile shapes",
'32326150' : "Foam products",
'32326160' : "Plastics bottles and laminated plastics plate, sheet, and shapes",
'32326190' : "Other plastics products",
'32326200' : "Rubber products",
'32326210' : "Tires",
'32326290' : "All other rubber products",
'32329000' : "Miscellaneous nondurable goods manufacturing",
'32329100' : "Beverages",
'32329110' : "Soft drinks and ice",
'32329111' : "Soft drinks",
'32329140' : "Breweries, wineries, and distilleries",
'32329900' : "Other miscellaneous nondurable goods manufacturing",
'40000000' : "Trade, transportation, and utilities",
'41420000' : "Wholesale trade",
'41423000' : "Durable goods",
'41423100' : "Motor vehicles and parts",
'41423110' : "Motor vehicles",
'41423120' : "New motor vehicle parts",
'41423200' : "Furniture and furnishings",
'41423210' : "Furniture",
'41423220' : "Home furnishings",
'41423300' : "Lumber and construction supplies",
'41423310' : "Lumber and wood",
'41423320' : "Masonry materials",
'41423390' : "Roofing, siding, and other construction materials",
'41423400' : "Commercial equipment",
'41423420' : "Office equipment",
'41423430' : "Computer and software",
'41423450' : "Medical equipment",
'41423490' : "Miscellaneous professional and commercial equipment",
'41423500' : "Metals and minerals",
'41423600' : "Electric goods",
'41423610' : "Electrical equipment and wiring",
'41423690' : "Electric appliances and other electronic parts",
'41423700' : "Hardware and plumbing",
'41423710' : "Hardware",
'41423720' : "Plumbing equipment",
'41423740' : "HVAC and refrigeration equipment",
'41423800' : "Machinery and supplies",
'41423810' : "Construction equipment",
'41423820' : "Farm and garden equipment",
'41423830' : "Industrial machinery",
'41423840' : "Industrial supplies",
'41423850' : "Service establishment equipment",
'41423860' : "Other transportation goods",
'41423900' : "Miscellaneous durable goods",
'41423910' : "Sporting goods",
'41423930' : "Recyclable materials",
'41423940' : "Jewelry",
'41423990' : "Toy, hobby, and other durable goods",
'41424000' : "Nondurable goods",
'41424100' : "Paper and paper products",
'41424120' : "Printing and writing paper and office supplies",
'41424130' : "Industrial paper",
'41424200' : "Druggists' goods",
'41424300' : "Apparel and piece goods",
'41424330' : "Women's and children's clothing",
'41424400' : "Grocery and related products",
'41424410' : "General line grocery",
'41424480' : "Fruits and vegetables",
'41424500' : "Farm product raw materials",
'41424510' : "Grains and field beans",
'41424600' : "Chemicals",
'41424700' : "Petroleum",
'41424800' : "Alcoholic beverages",
'41424810' : "Beer and ale",
'41424820' : "Wine and spirits",
'41424900' : "Misc. nondurable goods",
'41424910' : "Farm supplies",
'41424930' : "Nursery stock and florists' supplies",
'41424990' : "All other nondurable goods wholesalers",
'41425000' : "Electronic markets and agents and brokers",
'41425110' : "Business to business electronic markets",
'41425120' : "Wholesale trade agents and brokers",
'42000000' : "Retail trade",
'42441000' : "Motor vehicle and parts dealers",
'42441100' : "Automobile dealers",
'42441110' : "New car dealers",
'42441120' : "Used car dealers",
'42441200' : "Other motor vehicle dealers",
'42441210' : "Recreational vehicle dealers",
'42441220' : "Motorcycle, boat, and other vehicle dealers",
'42441300' : "Auto parts, accessories, and tire stores",
'42441310' : "Automotive parts and accessories stores",
'42441320' : "Tire dealers",
'42442000' : "Furniture and home furnishings stores",
'42442100' : "Furniture stores",
'42442200' : "Home furnishings stores",
'42442210' : "Floor covering stores",
'42442290' : "Other home furnishings stores",
'42443000' : "Electronics and appliance stores",
'42443141' : "Household appliance stores",
'42443142' : "Electronics stores",
'42444000' : "Building material and garden supply stores",
'42444100' : "Building material and supplies dealers",
'42444110' : "Home centers",
'42444120' : "Paint and wallpaper stores",
'42444130' : "Hardware stores",
'42444190' : "Other building material dealers",
'42444200' : "Lawn and garden equipment and supplies stores",
'42444210' : "Outdoor power equipment stores",
'42444220' : "Nursery, garden, and farm supply stores",
'42445000' : "Food and beverage stores",
'42445100' : "Grocery stores",
'42445110' : "Supermarkets and other grocery stores",
'42445120' : "Convenience stores",
'42445200' : "Specialty food stores",
'42445220' : "Meat markets and fish and seafood markets",
'42445230' : "Fruit and vegetable markets",
'42445290' : "Other specialty food stores",
'42445300' : "Beer, wine, and liquor stores",
'42446000' : "Health and personal care stores",
'42446110' : "Pharmacies and drug stores",
'42446120' : "Cosmetic and beauty supply stores",
'42446130' : "Optical goods stores",
'42446190' : "Other health and personal care stores",
'42446191' : "Food (health) supplement stores",
'42446199' : "All other health and personal care stores",
'42447000' : "Gasoline stations",
'42447110' : "Gasoline stations with convenience stores",
'42447190' : "Other gasoline stations",
'42448000' : "Clothing and clothing accessories stores",
'42448100' : "Clothing stores",
'42448110' : "Men's clothing stores",
'42448120' : "Women's clothing stores",
'42448130' : "Children's and infants' clothing stores",
'42448140' : "Family clothing stores",
'42448150' : "Clothing accessories stores",
'42448190' : "Other clothing stores",
'42448200' : "Shoe stores",
'42448300' : "Jewelry, luggage, and leather goods stores",
'42451000' : "Sporting goods, hobby, book, and music stores",
'42451100' : "Sporting goods and musical instrument stores",
'42451110' : "Sporting goods stores",
'42451120' : "Hobby, toy, and game stores",
'42451130' : "Sewing, needlework, and piece goods stores",
'42451140' : "Musical instrument and supplies stores",
'42451200' : "Book stores and news dealers",
'42452000' : "General merchandise stores",
'42452200' : "Department stores",
'42452300' : "General merchandise stores, including warehouse clubs and supercenters",
'42452311' : "Warehouse clubs and supercenters",
'42452319' : "All other general merchandise stores",
'42453000' : "Miscellaneous store retailers",
'42453100' : "Florists",
'42453200' : "Office supplies, stationery, and gift stores",
'42453210' : "Office supplies and stationery stores",
'42453220' : "Gift, novelty, and souvenir stores",
'42453300' : "Used merchandise stores",
'42453900' : "Other miscellaneous store retailers",
'42453910' : "Pet and pet supplies stores",
'42453920' : "Art dealers",
'42453990' : "All other miscellaneous store retailers",
'42454000' : "Nonstore retailers",
'42454100' : "Electronic shopping and mail-order houses",
'42454200' : "Vending machine operators",
'42454300' : "Direct selling establishments",
'42454310' : "Fuel dealers",
'42454390' : "Other direct selling establishments",
'43000000' : "Transportation and warehousing",
'43481000' : "Air transportation",
'43481100' : "Scheduled air transportation",
'43481200' : "Nonscheduled air transportation",
'43482000' : "Rail transportation",
'43483000' : "Water transportation",
'43484000' : "Truck transportation",
'43484100' : "General freight trucking",
'43484110' : "General freight trucking, local",
'43484120' : "General freight trucking, long-distance",
'43484121' : "General freight trucking, long-distance TL",
'43484122' : "General freight trucking, long-distance LTL",
'43484200' : "Specialized freight trucking",
'43484210' : "Used household and office goods moving",
'43484220' : "Other specialized trucking, local",
'43484230' : "Other specialized trucking, long-distance",
'43485000' : "Transit and ground passenger transportation",
'43485300' : "Taxi and limousine service",
'43485310' : "Taxi service",
'43485320' : "Limousine service",
'43485400' : "School and employee bus transportation",
'43485500' : "Urban, interurban, rural, and charter bus transportation",
'43485900' : "Other ground passenger transportation",
'43486000' : "Pipeline transportation",
'43487000' : "Scenic and sightseeing transportation",
'43488000' : "Support activities for transportation",
'43488100' : "Support activities for air transportation",
'43488110' : "Airport operations",
'43488300' : "Support activities for water transportation",
'43488320' : "Marine cargo handling",
'43488390' : "Support activities for water transportation, except marine cargo",
'43488400' : "Support activities for road transportation",
'43488500' : "Freight transportation arrangement",
'43488900' : "Support activities for other transportation, including rail",
'43492000' : "Couriers and messengers",
'43492100' : "Couriers and express delivery services",
'43492200' : "Local messengers and delivery and private postal service",
'43493000' : "Warehousing and storage",
'43493110' : "General warehousing and storage",
'43493120' : "Refrigerated warehousing and storage",
'43493190' : "Miscellaneous warehousing and storage",
'44220000' : "Utilities",
'44221100' : "Power generation and supply",
'44221110' : "Electric power generation",
'44221112' : "Fossil fuel electric power generation",
'44221118' : "Nuclear and other electric power generation",
'44221120' : "Electric power transmission and distribution",
'44221121' : "Electric bulk power transmission and control",
'44221122' : "Electric power distribution",
'44221200' : "Natural gas distribution",
'44221300' : "Water, sewage and other systems",
'50000000' : "Information",
'50511000' : "Publishing industries, except Internet",
'50511100' : "Newspaper, book, and directory publishers",
'50511110' : "Newspaper publishers",
'50511120' : "Periodical publishers",
'50511130' : "Book publishers",
'50511190' : "Directory, mailing list, and other publishers",
'50511200' : "Software publishers",
'50512000' : "Motion picture and sound recording industries",
'50512110' : "Motion picture and video production",
'50512130' : "Motion picture and video exhibition",
'50515000' : "Broadcasting, except Internet",
'50515100' : "Radio and television broadcasting",
'50515110' : "Radio broadcasting",
'50515120' : "Television broadcasting",
'50515200' : "Cable and other subscription programming",
'50517000' : "Telecommunications",
'50517300' : "Wired and wireless telecommunications carriers",
'50517311' : "Wired telecommunications carriers",
'50517312' : "Wireless telecommunications carriers, except satellite",
'50517900' : "Other telecommunications",
'50517911' : "Telecommunications resellers",
'50518000' : "Data processing, hosting and related services",
'50519000' : "Other information services",
'50519130' : "Internet publishing and broadcasting and web search portals",
'50519190' : "All other information services",
'55000000' : "Financial activities",
'55520000' : "Finance and insurance",
'55521000' : "Monetary authorities - central bank",
'55522000' : "Credit intermediation and related activities",
'55522100' : "Depository credit intermediation",
'55522110' : "Commercial banking",
'55522120' : "Savings institutions",
'55522190' : "Credit unions and other depository credit intermediation",
'55522200' : "Nondepository credit intermediation",
'55522210' : "Credit card issuing",
'55522220' : "Sales financing",
'55522290' : "Other nondepository credit intermediation",
'55522291' : "Consumer lending",
'55522292' : "Real estate credit",
'55522298' : "Miscellaneous nondepository credit intermediation",
'55522300' : "Activities related to credit intermediation",
'55522310' : "Mortgage and nonmortgage loan brokers",
'55522320' : "Financial transaction processing and clearing",
'55522390' : "Other credit intermediation activities",
'55523000' : "Securities, commodity contracts, investments, and funds and trusts",
'55523120' : "Securities brokerage",
'55523200' : "Securities and commodity contracts brokerage and exchanges",
'55523900' : "Other financial investment activities, including funds and trusts",
'55523910' : "Miscellaneous intermediation",
'55523920' : "Portfolio management",
'55523930' : "Investment advice",
'55523990' : "All other financial investment activities, including funds and trusts",
'55524000' : "Insurance carriers and related activities",
'55524100' : "Insurance carriers",
'55524110' : "Direct life and health insurance carriers",
'55524120' : "Direct insurers, except life and health",
'55524126' : "Direct property and casualty insurers",
'55524128' : "Direct title insurance and other direct insurance carriers",
'55524130' : "Reinsurance carriers",
'55524200' : "Insurance agencies, brokerages, and related services",
'55524210' : "Insurance agencies and brokerages",
'55524290' : "Other insurance-related activities",
'55524291' : "Claims adjusting",
'55524292' : "Third-party administration of insurance funds",
'55524298' : "All other insurance-related activities",
'55530000' : "Real estate and rental and leasing",
'55531000' : "Real estate",
'55531100' : "Lessors of real estate",
'55531110' : "Lessors of residential buildings",
'55531120' : "Lessors of nonresidential buildings",
'55531130' : "Miniwarehouse and self-storage unit operators",
'55531190' : "Lessors of other real estate property",
'55531200' : "Offices of real estate agents and brokers",
'55531300' : "Activities related to real estate",
'55531310' : "Real estate property managers",
'55531311' : "Residential property managers",
'55531312' : "Nonresidential property managers",
'55531320' : "Offices of real estate appraisers",
'55531390' : "Other activities related to real estate",
'55532000' : "Rental and leasing services",
'55532100' : "Automotive equipment rental and leasing",
'55532110' : "Passenger car rental and leasing",
'55532120' : "Truck, trailer, and RV rental and leasing",
'55532200' : "Consumer goods rental",
'55532283' : "Home health equipment rental",
'55532300' : "General rental centers",
'55532400' : "Machinery and equipment rental and leasing",
'55532410' : "Heavy machinery rental and leasing",
'55532490' : "Office equipment and other machinery rental and leasing",
'55533000' : "Lessors of nonfinancial intangible assets",
'60000000' : "Professional and business services",
'60540000' : "Professional and technical services",
'60541100' : "Legal services",
'60541110' : "Offices of lawyers",
'60541190' : "Other legal services",
'60541200' : "Accounting and bookkeeping services",
'60541211' : "Offices of certified public accountants",
'60541213' : "Tax preparation services",
'60541214' : "Payroll services",
'60541219' : "Other accounting services",
'60541300' : "Architectural and engineering services",
'60541310' : "Architectural services",
'60541320' : "Landscape architectural services",
'60541340' : "Engineering and drafting services",
'60541370' : "Building inspection, surveying, and mapping services",
'60541380' : "Testing laboratories",
'60541400' : "Specialized design services",
'60541410' : "Interior design services",
'60541430' : "Graphic design services",
'60541500' : "Computer systems design and related services",
'60541511' : "Custom computer programming services",
'60541512' : "Computer systems design services",
'60541513' : "Computer facilities management services",
'60541519' : "Other computer-related services",
'60541600' : "Management and technical consulting services",
'60541610' : "Management consulting services",
'60541611' : "Administrative management consulting services",
'60541612' : "Human resource consulting services",
'60541613' : "Marketing consulting services",
'60541614' : "Process and logistics consulting services",
'60541618' : "Other management consulting services",
'60541620' : "Environmental consulting services",
'60541690' : "Other technical consulting services",
'60541700' : "Scientific research and development services",
'60541710' : "Research and development in the physical, engineering, and life sciences",
'60541713' : "Research and development in nanotechnology",
'60541714' : "Research and development in biotechnology, except nanobiotechnology",
'60541715' : "Research and development in the physical, engineering, and life sciences, except nanotechnology and biotechnology",
'60541720' : "Social science and humanities research",
'60541800' : "Advertising and related services",
'60541810' : "Advertising agencies",
'60541820' : "Public relations agencies",
'60541840' : "Media buying agencies and media representatives",
'60541850' : "Display advertising",
'60541860' : "Direct mail advertising",
'60541890' : "Advertising material distribution and other advertising services",
'60541900' : "Other professional and technical services",
'60541910' : "Marketing research and public opinion polling",
'60541920' : "Photographic services",
'60541940' : "Veterinary services",
'60541990' : "Miscellaneous professional and technical services",
'60550000' : "Management of companies and enterprises",
'60551112' : "Offices of bank holding companies and of other holding companies",
'60551114' : "Managing offices",
'60560000' : "Administrative and waste services",
'60561000' : "Administrative and support services",
'60561100' : "Office administrative services",
'60561200' : "Facilities support services",
'60561300' : "Employment services",
'60561310' : "Employment placement agencies and executive search services",
'60561311' : "Employment placement agencies",
'60561312' : "Executive search services",
'60561320' : "Temporary help services",
'60561330' : "Professional employer organizations",
'60561400' : "Business support services",
'60561410' : "Document preparation services",
'60561420' : "Telephone call centers",
'60561421' : "Telephone answering services",
'60561422' : "Telemarketing bureaus",
'60561430' : "Business service centers",
'60561440' : "Collection agencies",
'60561490' : "Other business support services, including credit bureaus",
'60561500' : "Travel arrangement and reservation services",
'60561510' : "Travel agencies",
'60561520' : "Tour operators",
'60561590' : "Other travel arrangement services",
'60561600' : "Investigation and security services",
'60561610' : "Security and armored car services",
'60561611' : "Investigation services",
'60561613' : "Security guards and patrols and armored car services",
'60561620' : "Security systems services",
'60561700' : "Services to buildings and dwellings",
'60561710' : "Exterminating and pest control services",
'60561720' : "Janitorial services",
'60561730' : "Landscaping services",
'60561740' : "Carpet and upholstery cleaning services",
'60561790' : "Other services to buildings and dwellings",
'60561900' : "Other support services",
'60561910' : "Packaging and labeling services",
'60561920' : "Convention and trade show organizers",
'60561990' : "All other support services",
'60562000' : "Waste management and remediation services",
'60562100' : "Waste collection",
'60562200' : "Waste treatment and disposal",
'60562211' : "Hazardous waste treatment and disposal",
'60562219' : "Nonhazardous waste treatment and disposal",
'60562900' : "Remediation and other waste services",
'60562910' : "Remediation services",
'60562990' : "Materials recovery facilities and other waste management services",
'65000000' : "Education and health services",
'65610000' : "Educational services",
'65611100' : "Elementary and secondary schools",
'65611200' : "Junior colleges",
'65611300' : "Colleges and universities",
'65611400' : "Business, computer, and management training",
'65611420' : "Business and secretarial schools and computer training",
'65611430' : "Management training",
'65611500' : "Technical and trade schools",
'65611600' : "Other schools and instruction",
'65611610' : "Fine arts schools",
'65611620' : "Sports and recreation instruction",
'65611690' : "Miscellaneous schools and instruction",
'65611700' : "Educational support services",
'65620000' : "Health care and social assistance",
'65620001' : "Health care",
'65621000' : "Ambulatory health care services",
'65621100' : "Offices of physicians",
'65621111' : "Offices of physicians, except mental health",
'65621112' : "Offices of mental health physicians",
'65621200' : "Offices of dentists",
'65621300' : "Offices of other health practitioners",
'65621310' : "Offices of chiropractors",
'65621320' : "Offices of optometrists",
'65621330' : "Offices of mental health practitioners",
'65621340' : "Offices of specialty therapists",
'65621390' : "Offices of all other health practitioners",
'65621391' : "Offices of podiatrists",
'65621399' : "Offices of miscellaneous health practitioners",
'65621400' : "Outpatient care centers",
'65621420' : "Outpatient mental health centers",
'65621490' : "Outpatient care centers, except mental health",
'65621491' : "HMO medical centers",
'65621492' : "Kidney dialysis centers",
'65621493' : "Freestanding emergency medical centers",
'65621498' : "Miscellaneous outpatient care centers",
'65621500' : "Medical and diagnostic laboratories",
'65621511' : "Medical laboratories",
'65621512' : "Diagnostic imaging centers",
'65621600' : "Home health care services",
'65621900' : "Other ambulatory health care services",
'65621910' : "Ambulance services",
'65621990' : "All other ambulatory health care services",
'65621991' : "Blood and organ banks",
'65621999' : "Miscellaneous ambulatory health care services",
'65622000' : "Hospitals",
'65622100' : "General medical and surgical hospitals",
'65622200' : "Psychiatric and substance abuse hospitals",
'65622300' : "Other hospitals",
'65623000' : "Nursing and residential care facilities",
'65623100' : "Nursing care facilities",
'65623200' : "Residential mental health facilities",
'65623210' : "Residential intellectual and developmental disability facilities",
'65623220' : "Residential mental health and substance abuse facilities",
'65623300' : "Community care facilities for the elderly",
'65623311' : "Continuing care retirement communities",
'65623312' : "Assisted living facilities for the elderly",
'65623900' : "Other residential care facilities",
'65624000' : "Social assistance",
'65624100' : "Individual and family services",
'65624110' : "Child and youth services",
'65624120' : "Services for the elderly and persons with disabilities",
'65624190' : "Other individual and family services",
'65624200' : "Emergency and other relief services",
'65624210' : "Community food services",
'65624230' : "Community housing, emergency, and relief services",
'65624300' : "Vocational rehabilitation services",
'65624400' : "Child day care services",
'70000000' : "Leisure and hospitality",
'70710000' : "Arts, entertainment, and recreation",
'70711000' : "Performing arts and spectator sports",
'70711100' : "Performing arts companies",
'70711130' : "Musical groups and artists",
'70711190' : "Theater, dance, and other performing arts companies",
'70711200' : "Spectator sports",
'70711211' : "Sports teams and clubs",
'70711212' : "Racetracks",
'70711219' : "Other spectator sports",
'70711300' : "Arts and sports promoters and agents and managers for public figures",
'70711500' : "Independent artists, writers, and performers",
'70712000' : "Museums, historical sites, and similar institutions",
'70712110' : "Museums",
'70712190' : "Historical sites and other similar institutions",
'70713000' : "Amusements, gambling, and recreation",
'70713100' : "Amusement parks and arcades",
'70713200' : "Gambling industries",
'70713210' : "Casinos, except casino hotels",
'70713290' : "Other gambling industries",
'70713900' : "Other amusement and recreation industries",
'70713910' : "Golf courses and country clubs",
'70713920' : "Skiing facilities",
'70713930' : "Marinas",
'70713940' : "Fitness and recreational sports centers",
'70713950' : "Bowling centers",
'70713990' : "All other amusement and recreation industries",
'70720000' : "Accommodation and food services",
'70721000' : "Accommodation",
'70721100' : "Traveler accommodation",
'70721110' : "Hotels and motels, except casino hotels",
'70721120' : "Casino hotels",
'70721190' : "Other traveler accommodation",
'70721191' : "Bed-and-breakfast inns",
'70721199' : "All other traveler accommodation and rooming and boarding houses",
'70721200' : "RV parks and recreational camps",
'70722000' : "Food services and drinking places",
'70722300' : "Special food services",
'70722310' : "Food service contractors",
'70722330' : "Caterers and mobile food services",
'70722400' : "Drinking places, alcoholic beverages",
'70722500' : "Restaurants and other eating places",
'70722511' : "Full-service restaurants",
'70722513' : "Limited-service restaurants",
'70722514' : "Cafeterias, grill buffets, and buffets",
'70722515' : "Snack and nonalcoholic beverage bars",
'80000000' : "Other services",
'80811000' : "Repair and maintenance",
'80811100' : "Automotive repair and maintenance",
'80811110' : "Automotive mechanical and electrical repair",
'80811111' : "General automotive repair",
'80811113' : "Automotive exhaust system and transmission repair",
'80811118' : "Other automotive mechanical and elec. repair",
'80811120' : "Automotive body, interior, and glass repair",
'80811121' : "Automotive body and interior repair",
'80811122' : "Automotive glass replacement shops",
'80811190' : "Other automotive repair and maintenance",
'80811192' : "Car washes",
'80811198' : "Auto oil change shops and all other auto repair and maintenance",
'80811200' : "Electronic equipment repair and maintenance",
'80811212' : "Computer and office machine repair",
'80811219' : "Miscellaneous electronic equipment repair and maintenance",
'80811300' : "Commercial machinery repair and maintenance",
'80811400' : "Household goods repair and maintenance",
'80812000' : "Personal and laundry services",
'80812100' : "Personal care services",
'80812110' : "Hair, nail, and skin care services",
'80812112' : "Barber shops and beauty salons",
'80812113' : "Nail salons",
'80812190' : "Other personal care services",
'80812200' : "Death care services",
'80812210' : "Funeral homes and funeral services",
'80812220' : "Cemeteries and crematories",
'80812300' : "Drycleaning and laundry services",
'80812310' : "Coin-operated laundries and drycleaners",
'80812320' : "Drycleaning and laundry services, except coin-operated",
'80812330' : "Linen and uniform supply",
'80812331' : "Linen supply",
'80812332' : "Industrial launderers",
'80812900' : "Other personal services",
'80812910' : "Pet care services, except veterinary",
'80812930' : "Parking lots and garages",
'80812990' : "All other personal services, including photofinishing",
'80813000' : "Membership associations and organizations",
'80813200' : "Grantmaking and giving services",
'80813211' : "Grantmaking foundations",
'80813212' : "Voluntary health organizations",
'80813219' : "Other grantmaking and giving services",
'80813300' : "Social advocacy organizations",
'80813311' : "Human rights organizations",
'80813319' : "Environment, conservation, and other social advocacy organizations",
'80813400' : "Civic and social organizations",
'80813900' : "Professional and similar organizations",
'80813910' : "Business associations",
'80813920' : "Professional organizations",
'80813930' : "Labor unions and similar labor organizations",
'80813990' : "Miscellaneous professional and similar organizations",
'90000000' : "Government",
'90910000' : "Federal",
'90911000' : "Federal, except U.S. Postal Service",
'90916220' : "Federal hospitals",
'90919110' : "Department of Defense",
'90919120' : "U.S. Postal Service",
'90919999' : "Other Federal government",
'90920000' : "State government",
'90921611' : "State government education",
'90922000' : "State government, excluding education",
'90922622' : "State hospitals",
'90922920' : "State government general administration",
'90922999' : "Other State government",
'90930000' : "Local government",
'90931611' : "Local government education",
'90932000' : "Local government, excluding education",
'90932221' : "Local government utilities",
'90932480' : "Local government transportation",
'90932622' : "Local hospitals",
'90932920' : "Local government general administration",
'90932999' : "Other local government"}

export default industry_code_map;