import React from 'react';
import TextChunk from './TextChunk';
import { VictoryLine, VictoryChart, VictoryTheme, VictoryLabel, VictoryAxis, VictoryContainer } from 'victory';
import VictoryStandardGraphFunction from './components/VictoryStandardGraphFunction';

const LineGraph = (props) => {
  const graph_input_collector = props.graph_input_collector
  const current_graph_input = props.graph_inputs
  const bottom_text = { graph_inputs: { textchunk: props.graph_inputs.textchunk_bottom } }
  const top_text = { graph_inputs: { textchunk: props.graph_inputs.textchunk_top } }
  const processed_graph_configs = VictoryStandardGraphFunction(props);

  return (
    <div>
      <TextChunk {...top_text} />
      <VictoryChart
        theme={VictoryTheme.material}
        // animate={{ duration: 500, easing: "bounce" }}
        {...processed_graph_configs['graph_outer_inputs']}
        containerComponent={
          <VictoryContainer
            style={{ ...props.container_style }}
          />
        }
      >
        <VictoryLabel
          text={props.graph_inputs.graphtitle ? props.graph_inputs.graphtitle : ''}
          x={100}
          y={25}
          style={{fontSize: 11}} >
        </VictoryLabel>
        <VictoryLine
          labelComponent={<VictoryLabel dy={+props.graph_inputs.label_y_offset ?? 0} dx={+props.graph_inputs.label_x_offset ?? 0} />}
          data={props.data_for_graph}
          labels={data => Object.keys(current_graph_input).includes('clicked') && current_graph_input.clicked.includes(data.x) ? data.x : null}
          style={{ data: { stroke: props.graph_inputs.default_color }, labels: { fontSize: +props.graph_inputs.label_font_size ?? 5, fill: props.graph_inputs.label_fill_color ?? "black" } }}
        />
        <VictoryAxis
          dependentAxis
          label={props.graph_inputs.yaxis ? props.graph_inputs.yaxis : ''}
          standalone={false}
          style={processed_graph_configs['tickyaxisStyle']}
          {...processed_graph_configs['y_tick_inputs']}
          fixLabelOverlap={true} />
        <VictoryAxis
          label={props.graph_inputs.xaxis ? props.graph_inputs.xaxis : ''}
          standalone={false}
          style={processed_graph_configs['tickxaxisStyle']}
          {...processed_graph_configs['x_tick_inputs']}
          tickLabelComponent={<VictoryLabel textAnchor={props.graph_inputs.x_tick_label_anchor ?? "start"} dy={+props.graph_inputs.x_tick_label_offset ?? 0} />}
          fixLabelOverlap={true} />
      </VictoryChart>
      <TextChunk {...bottom_text} />
    </div>
  )

}

export default LineGraph