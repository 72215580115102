/*
Need to have inputs for all of these
screen_width: Number,
screen_height: Number,
hover_event: Function,
hover_away_event: Function,
click_event: Function,
// graph_data: Array<Object>, this is still required, but the props stuff needs to be redone
court_width: Number,
court_length: Number,
color_var: String,
opacity_var: String
*/

import React from 'react';
import ArticleEditInput from "./ArticleEditInput";

const ShotChartInput = (props) => {
    const triggerStandardInputs = () => {
        props.graph_input_collector({
            hover_event: () => {},
            hover_away_event: () => {},
            click_event: () => {},
            court_width: 47,
            court_length: 50,
            color_var: 'color',
            opacity_var: 'opacity'})
    }
    return (
        <>
            <label htmlFor="graph_title"> Graph Title</label>
            <input 
                   name="graph_title"
                   type="text" 
                   value={props.graph_inputs['graph_title']} 
                   onChange={(e) => {props.graph_input_collector({graph_title: e.target.value})}}
            />
            <label htmlFor="filter_1_name"> 1st Comparison Position Name For Legend</label>
            <input 
                   name="filter_1_name"
                   type="text" 
                   value={props.graph_inputs['filter_1_name']} 
                   onChange={(e) => {props.graph_input_collector({filter_1_name: e.target.value})}}
            />
            <label htmlFor="textchunk_top"> text above graph</label>
            <div>
                <ArticleEditInput
                    name="textchunk_top"
                    value={props.graph_inputs['textchunk_top']}
                    onChange={(e) => {
                        props.graph_input_collector({textchunk_top: e});
                    }}/>
            </div>
            <br/><br/><br/><br/><br/>
            <label htmlFor="textchunk_bottom"> text below graph </label>
            <div>
                <ArticleEditInput
                    name="textchunk_bottom"
                    value={props.graph_inputs['textchunk_bottom']}
                    onChange={(e) => {
                        props.graph_input_collector({textchunk_bottom: e});
                    }}/>
            </div>
        </>
    )
}

export default ShotChartInput;