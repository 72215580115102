import React, { useState, useEffect } from 'react';
import '../../containers/blogger/AnalysisBuilder.css'
import './OpenAIDialog.css'
import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import server_configs from '../../server_configs'
import AnalysisInputsToGraphVariableMap from '../inputs/rawelements/AnalysisInputsToGraphVariableMap';
import Close from '@mui/icons-material/Close';
import OpenAIDialog from './OpenAIDialog';

const dataset_category_map = {
    nba: ['League Stats', 'Player Stats', 'Team Stats'],
    nfl: ['League Stats', 'Player Stats', 'Team Stats'],
    fred: ['Consumer', 'Economy', 'Employment', 'Government', 'Monetary System', 'Other', 'Real Estate', 'Trade'],
    world_bank: ['Economy', 'Education', 'Environment', 'Natural Resources', 'Other'],
    markets: ['Annual Financials', 'Crypto', 'FX', 'Quarterly Financials', 'Stock Stats'],
    fbi_crime: ['State Level', 'Timseries'],
    google_trends: ['Country Level', 'Timeseries']
}


const TemplateCreator = (props) => {
    const [finalGraphInputs, setFinalGraphInputs] = useState(new Set());
    const [finalComponentInputs, setFinalComponentInputs] = useState(new Set());
    const [templateName, setTemplateName] = useState('');
    const [category, setCategory] = useState('');
    const [accessibilty, setAccessibility] = useState('');


    const analyzerFunction = () => {
        let timeseries = false
        if (Object.values(props.text_generation_data['prompt_variable_data_types']).includes('date')) {
            timeseries = true
        }
        const analysis_to_analyzers = {
            BubbleChart: 'THREE_METRIC_RANKER',
            Candlestick: 'TIME_SERIES_TREND',
            Chord: 'CHORD',
            NoDimManyMetrics: "N_METRIC_TIME_SERIES",
            OneDimManyMetrics: 'N_METRIC_TIME_SERIES',
            OneDimOneMetric: timeseries ? 'TIME_SERIES_TREND' : 'ONE_METRIC_RANKER',
            ShotChart: 'SHOTCHART',
            TwoDimOneMetric: timeseries ? 'N_METRIC_TIME_SERIES' : 'TWO_METRIC_RANKER'
        }
        return analysis_to_analyzers[props.selected_analysis]
    }

    const saveTemplate = () => {
        const analyzer_function = analyzerFunction()
        props.graph_inputs['textchunk_top'] = ''
        props.graph_inputs['textchunk_bottom'] = ''
        const send_json = {
            component_inputs: props.component_inputs,
            graph_inputs: props.graph_inputs,
            configurable_component_inputs: Array.from(finalComponentInputs),
            configurable_graph_inputs: Array.from(finalGraphInputs),
            template_name: templateName,
            creator_id: props.auth0_user,
            selected_analysis: props.selected_analysis,
            selected_dataset: props.selected_dataset,
            selected_graph: props.selected_graph,
            accessibilty: accessibilty,
            category: category,
            query_inputs: props.query_inputs,
            text_generation_data: props.text_generation_data
        }
        
        let method = "POST";

        const request_content = {
            method: method,
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            }
        };

        let request_url = `${server_configs['api_domain']}/data_blogging/save_template/`;

        request_content['body'] = JSON.stringify(send_json);

        fetch(request_url, request_content)
            .then(response => {
                response.json()
                    .then(text => { console.log('article_saved') })
            });
    }

    const updateGraphInputs = (e) => {
        const graph_inputs_set = finalGraphInputs
        if (graph_inputs_set.has(e.target.value)) {
            graph_inputs_set.delete(e.target.value)
        } else {
            graph_inputs_set.add(e.target.value)
        }
        setFinalGraphInputs(graph_inputs_set)
    }

    const updateComponentInputs = (e) => {
        const component_inputs_set = finalComponentInputs
        if (component_inputs_set.has(e.target.value)) {
            component_inputs_set.delete(e.target.value)
        } else {
            component_inputs_set.add(e.target.value)
        }
        setFinalComponentInputs(component_inputs_set)
    }

    const collectAiPromptStrings = (e, x) => {
        const ai_prompts = JSON.parse(JSON.stringify(props.text_generation_data['prompt_variable_replacements']))
        ai_prompts[x] = e.target.value
        ai_prompts[AnalysisInputsToGraphVariableMap[props.selected_analysis][x]] = e.target.value
        props.text_generation_input_collector({'prompt_variable_replacements': ai_prompts})
    }

    const updateDataTypes = (e, x) => {
        const field_data_types = JSON.parse(JSON.stringify(props.text_generation_data['prompt_variable_data_types']))
        field_data_types[x] = e.target.value
        field_data_types[AnalysisInputsToGraphVariableMap[props.selected_analysis][x]] = e.target.value
        props.text_generation_input_collector({'prompt_variable_data_types': field_data_types})
    }

    const templateFieldOption = (x) => {
        const skipValue = (y) => {
            if(['SORT_BY_KEY', 'LIMIT_DATA_VALUE', 'ASC_DESC'].includes(y)) { return true} else {return false}
          }
        return (skipValue(x) ? <></> : <TableRow key={`tr_tf_${x}`}>
            <TableCell style={{ fontSize: "20px" }}>
                <input
                    key={`editable_${x}`}
                    label={x}
                    type="checkbox"
                    value={x}
                    onChange={updateComponentInputs} />
            </TableCell>
            <TableCell style={{ fontSize: "20px" }} key={`tr_name${x}`}>{capitalize(x)}</TableCell>
            <TableCell style={{ fontSize: "20px" }} key={`tr_dtype_${x}`}>
                {!x.includes('FILTER') ? <select key={`ai_dtype_${x}`} value={props.text_generation_data['prompt_variable_data_types'][x]} onChange={e => { updateDataTypes(e, x) }} >
                    <option value=""> </option>
                    <option value="date">Date</option>
                    <option value="number">Number</option>
                    <option value="string">String</option>
                </select> : <></>}
            </TableCell>
            <TableCell style={{ fontSize: "20px" }} key={`tr_filter_${x}`}>
                {!x.includes('FILTER') ? <input key={`ai_name_${x}`} type="text" value={props.text_generation_data['prompt_variable_replacements'][x]} onChange={e => { collectAiPromptStrings(e, x) }} /> : <></>}
            </TableCell>
        </TableRow>)
    }

    const templateFieldOptionGraph = (x) => {
        return (<TableRow key={`tr_tg_${x}`}>
            <TableCell style={{ fontSize: "20px" }} key={x}>
                <input
                    key={`editable_${x}`}
                    label={x}
                    type="checkbox"
                    value={x}
                    onChange={updateGraphInputs} />
            </TableCell>
            <TableCell style={{ fontSize: "20px" }} key={`tr_tg_c_${x}`}>{capitalize(x)}</TableCell>
        </TableRow>)
    }

    const capitalize = (str, lc, all) => {
        str = str.replaceAll("_", " ")
        if (all) {
            return str.split(" ")
                .map(word => capitalize(word, lc))
                .join(" ")
                .split("-")
                .map(word => capitalize(word, false))
                .join("-");
        } else {
            return lc
                ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
                : str.charAt(0).toUpperCase() + str.slice(1);
        }
    }

    const closeTemplateBuilder = () => {
        props.template_closer()
    }

    return (
        <div style={{ display: "flex", flexFlow: "column", alignItems: "start", padding: "20px" }}>
            <div style={{ width:"100%", display: "flex", flexFlow: "column", alignItems: "end" }} onClick={closeTemplateBuilder}>
                <Close />
            </div>
            <button
                name="saveTemplate"
                onClick={saveTemplate}>
                Save Template
            </button>
            <br />
            <label>Template Name</label><input type="text" value={templateName} onChange={e => setTemplateName(e.target.value)} />
            <br/>
            <label>Category</label>
            <select onChange={e => setCategory(e.target.value)}><option></option>{dataset_category_map[props.selected_dataset].map( x => <option value={x}>{x}</option>)}</select>
            <br/>
            <label>Accessibilty</label>
            <select onChange={e => setAccessibility(e.target.value)}><option></option><option value="private">Private</option><option value="public">Public</option></select>
            <h3>Analysis Inputs</h3>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell style={{ fontSize: "20px" }}>Editable?</TableCell>
                        <TableCell style={{ fontSize: "20px" }}>Field</TableCell>
                        <TableCell style={{ fontSize: "20px" }}>Datatype</TableCell>
                        <TableCell style={{ fontSize: "20px" }}>String For AI Prompt </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.keys(props.component_inputs).map(x => templateFieldOption(x))}
                </TableBody>
            </Table>
            {/* <h3>Graph Inputs</h3>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell style={{ fontSize: "20px" }}>Editable?</TableCell>
                        <TableCell style={{ fontSize: "20px" }}>Field</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.keys(props.graph_inputs).map(x => templateFieldOptionGraph(x))}
                </TableBody>
            </Table> */}
            <div style={{ display: "flex", flexFlow: "column", alignItems: "start", padding: "20px" }}>
                <br/>
                <br/>
                <h3> Text Generation Parameters </h3>
            <OpenAIDialog text_generation_data={props.text_generation_data} selected_analysis={props.selected_analysis} component_inputs={props.component_inputs} text_generation_input_collector={props.text_generation_input_collector} skip_field_input={true}/>
            </div>
        </div>
    );
};

export default TemplateCreator;
