import React, { Component } from 'react';
import * as d3Array from 'd3-array';

const Props = {
    screen_width: Number,
    screen_height: Number,
    court_width: Number,
    court_length: Number,
};

const BballCourt = (props) => {

    const gen_svg_dimensions = () => {
        const rad = gen_hex_radius();
        return {
            svg_height: (props.court_length + 1 / 3) * 1.5 * rad,
            svg_width: (props.court_width + 1 / 2) * 1.75 * rad,
        }
    }

    const gen_hex_radius = () => {
        const radius = d3Array.min([
            props.screen_width / ((props.court_width + 0.5) * Math.sqrt(3)),
            props.screen_height / ((props.court_length + 1 / 3) * 1.5)]);
        return radius;
    }

    const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
        const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;
        return {
            x: centerX + (radius * Math.cos(angleInRadians)),
            y: centerY + (radius * Math.sin(angleInRadians))
        };
    }

    const describeThreePtArc = () => {
        const svg_width = gen_svg_dimensions().svg_width;
        const width_scale_factor = svg_width / 50;
        const svg_height = gen_svg_dimensions().svg_height;
        const height_scale_factor = svg_height / 47;
        const x = svg_width / 2;
        const y = 47 * height_scale_factor - 2.5 * height_scale_factor;
        const radius = 23.75 * width_scale_factor;
        const startAngle = 292;
        const endAngle = 68;
        const start = polarToCartesian(x, y, radius, endAngle);
        const end = polarToCartesian(x, y, radius, startAngle);

        const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

        const d = [
            "M", start.x, start.y,
            "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
        ].join(" ");
        return (<path d={d}
            fillOpacity={0}
            strokeOpacity={1}
            stroke={"black"}
            strokeWidth={2} />)
    }

    const describeRestrictedArea = () => {
        const svg_width = gen_svg_dimensions().svg_width;
        const width_scale_factor = svg_width / 50;
        const svg_height = gen_svg_dimensions().svg_height;
        const height_scale_factor = svg_height / 47;
        const x = svg_width / 2;
        const y = 47 * height_scale_factor - 4 * height_scale_factor;
        const radius = 4 * width_scale_factor;
        const startAngle = 270;
        const endAngle = 90;
        const start = polarToCartesian(x, y, radius, endAngle);
        const end = polarToCartesian(x, y, radius, startAngle);

        const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

        const d = [
            "M", start.x, start.y,
            "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
        ].join(" ");
        return (<path d={d}
            fillOpacity={0}
            strokeOpacity={1}
            stroke={"black"}
            strokeWidth={2} />)
    }
    const svg_width = gen_svg_dimensions().svg_width;
    const width_scale_factor = svg_width / 50;
    const svg_height = gen_svg_dimensions().svg_height;
    const height_scale_factor = svg_height / 47;

    return (
        <svg>
            {describeThreePtArc()}
            {describeRestrictedArea()}
            <line x1={21 * width_scale_factor}
                y1={47 * height_scale_factor - 4 * height_scale_factor}
                x2={21 * width_scale_factor}
                y2={47 * height_scale_factor - 2.75 * height_scale_factor}
                fillOpacity={0}
                strokeOpacity={1}
                stroke={"black"}
                strokeWidth={2} />
            <line x1={29 * width_scale_factor}
                y1={47 * height_scale_factor - 4 * height_scale_factor}
                x2={29 * width_scale_factor}
                y2={47 * height_scale_factor - 2.75 * height_scale_factor}
                fillOpacity={0}
                strokeOpacity={1}
                stroke={"black"}
                strokeWidth={2} />
            <circle cx={svg_width / 2}
                cy={47 * height_scale_factor - 4 * height_scale_factor}
                r={1 * width_scale_factor}
                fillOpacity={0}
                strokeOpacity={1}
                stroke={"black"}
                strokeWidth={2} />
            <circle cx={svg_width / 2}
                cy={0}
                r={6 * width_scale_factor}
                fillOpacity={0}
                strokeOpacity={1}
                stroke={"black"}
                strokeWidth={2} />
            <circle cx={svg_width / 2}
                cy={0}
                r={2 * width_scale_factor}
                fillOpacity={0}
                strokeOpacity={1}
                stroke={"black"}
                strokeWidth={2} />
            <circle cx={svg_width / 2}
                cy={47 * height_scale_factor - 19 * height_scale_factor}
                r={6 * width_scale_factor}
                fillOpacity={0}
                strokeOpacity={1}
                stroke={"black"}
                strokeWidth={2} />
            <rect height={47 * height_scale_factor}
                width={50 * width_scale_factor}
                fillOpacity={0}
                strokeOpacity={1}
                stroke={"black"}
                strokeWidth={2} />
            <rect height={19 * height_scale_factor}
                width={16 * width_scale_factor}
                x={17 * width_scale_factor}
                y={47 * height_scale_factor - 19 * height_scale_factor}
                fillOpacity={0}
                strokeOpacity={1}
                stroke={"black"}
                strokeWidth={2} />
            <rect height={19 * height_scale_factor}
                width={12 * width_scale_factor}
                x={19 * width_scale_factor}
                y={47 * height_scale_factor - 19 * height_scale_factor}
                fillOpacity={0}
                strokeOpacity={1}
                stroke={"black"}
                strokeWidth={2} />
            <line x1={3 * width_scale_factor}
                y1={34 * height_scale_factor}
                x2={3 * width_scale_factor}
                y2={47 * height_scale_factor}
                fillOpacity={0}
                strokeOpacity={1}
                stroke={"black"}
                strokeWidth={2} />
            <line x1={47 * width_scale_factor}
                y1={34 * height_scale_factor}
                x2={47 * width_scale_factor}
                y2={47 * height_scale_factor}
                fillOpacity={0}
                strokeOpacity={1}
                stroke={"black"}
                strokeWidth={2} />
        </svg>
    );
}

export default BballCourt;